import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api/manageBannerOfferApi";
import { message } from 'antd';

////// Live Banner /////

export const liveBanners = createAsyncThunk(
  "/managebanners/livebanners",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.liveBanners();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const updateliveUnliveBanners = createAsyncThunk(
  "/managebanners/liveunlivebanners",
  async (query, { rejectWithValue }) => {
    try {
      const resp = await api.liveUnliveBanners(query);
      if (resp.data.code === 200) {
        message.success('Banner Updated Successfully!');
        // return resp.data.data;
      } else {
        message.error('Banner Not Updated / Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const deleteBannersImage = createAsyncThunk(
  "/managebanners/deleteimage",
  async (query, { rejectWithValue }) => {
    try {
      const resp = await api.deleteBannersImage(query);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Banner Deleted Successfully!');
        return resp.data.data;
      } else {
        message.error('Banner Not Deleted / Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

const liveBannerSlice = createSlice({
  name: "liveBanner",
  initialState: {
    data: {
      liveBanners: [],
      deleteBannersImage: [],
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    // =================================================================================================

    builder.addCase(liveBanners.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(liveBanners.fulfilled, (state, action) => {
      state.data.liveBanners = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(liveBanners.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // =================================================================================================

    builder.addCase(updateliveUnliveBanners.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateliveUnliveBanners.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateliveUnliveBanners.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // =================================================================================================

    builder.addCase(deleteBannersImage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteBannersImage.fulfilled, (state, action) => {
      state.data.deleteBannersImage = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteBannersImage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { } = liveBannerSlice.actions;

export default liveBannerSlice.reducer;
