import React, { useState } from "react";
import styled from "styled-components";
import { Typography, Radio } from "antd";
import SearchByClickId from "./searchByClickId";
import SearchByMobile from "./searchByMobile";

const { Title } = Typography;
const Wrapper = styled.div`
  width: 100%;
`;
const BodyContainer = styled.div``;

const RadioContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  padding: 5px 0;
  > div {
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    margin-right: 30px;
  }
`;

const ClickIdOperations = (props) => {
  const [menu_type, setMenuType] = useState("searchByClickId");
  const handleRadio = (e) => {
    setMenuType(e.target.value);
  };
  const selMenu = () => {
    setMenuType("searchByClickId");
  }
  return (
    <Wrapper>
      <Title level={3}>Click Id Operation</Title>
      <div className="row">
        <div className="col-12 col-lg-12">
          {/* <RadioContainer>
            <div>
              <Radio
                type="radio"
                value={"searchByClickId"}
                // name="type"
                checked={menu_type == "searchByClickId"}
                onChange={handleRadio}
              />
              Search By Click Id
            </div>
            <div>
              <Radio
                type="radio"
                value={"searchByMobile"}
                // name="type"
                checked={menu_type == "searchByMobile"}
                onChange={handleRadio}
              />
              Search By Mobile
            </div>
          </RadioContainer> */}

          <BodyContainer>
            <div>
              {menu_type == "searchByClickId" && <SearchByClickId />}


              { /* menu_type == "searchByMobile" && <SearchByMobile /> */}


            </div>
          </BodyContainer>
        </div>
      </div>
    </Wrapper>
  );
};

export default ClickIdOperations;





















// import React, { useEffect, useState } from "react";
// import styled from "styled-components";
// import { Typography, Radio } from "antd";
// import SearchByClickId from "./searchByClickId";
// import SearchByMobile from "./searchByMobile";
// import { searchByClickId } from "../../../redux/slices/crmSlice";
// import { useDispatch, useSelector } from "react-redux";

// const { Title } = Typography;
// const Wrapper = styled.div`
//   width: 100%;
// `;
// const BodyContainer = styled.div``;

// const RadioContainer = styled.div`
//   width: 50%;
//   display: flex;
//   flex-direction: row;
//   padding: 5px 0;
//   > div {
//     color: #000000;
//     font-size: 18px;
//     font-weight: bold;
//     margin-right: 30px;
//   }
// `;

// const ClickIdOperations = (props) => {
//   const dispatch = useDispatch()
//   const [userInfoo, setUserInfoo] = useState([])
//   const state = useSelector(state=>state.crm.data)
//   console.log(state , "Click id operation")
//   // const userInfo = state.searchByClickIdData;
//   // const userBalanceInfo = state.userBalanceInfo;
//   // const clicksDataInfo = state.userClickData;
//   // const redemption_info = state.userRedemptionInfo;
//   // const orderDetailsInfo = state.userOrderDetailsInfo;
//   // const redemptionPaymentInfo = state.userRedemptionPaymentInfo
//   // const requestedBalanceInfo = state.requestedBalanceInfo

//   // // const = state
//   // // const = state
//   // // const = state

  


//   console.log(state, "from index js of click id operation")
//   const [menu_type, setMenuType] = useState("searchByClickId");
//   const handleRadio = (e) => {
//     setMenuType(e.target.value);
//   };
//   const selMenu = () => {
//     setMenuType("searchByClickId");
//   }

//    useEffect(()=>{
  
//     dispatch(searchByClickId());
//     setUserInfoo(userInfo)
//  })
//   return (
//     <Wrapper>
//       <Title level={3}>Click Id Operation</Title>
//       <div className="row">
//         <div className="col-12 col-lg-12">
//           {/* <RadioContainer>
//             <div>
//               <Radio
//                 type="radio"
//                 value={"searchByClickId"}
//                 // name="type"
//                 checked={menu_type == "searchByClickId"}
//                 onChange={handleRadio}
//               />
//               Search By Click Id
//             </div>
//             <div>
//               <Radio
//                 type="radio"
//                 value={"searchByMobile"}
//                 // name="type"
//                 checked={menu_type == "searchByMobile"}
//                 onChange={handleRadio}
//               />
//               Search By Mobile
//             </div>
//           </RadioContainer> */}

//           <BodyContainer>
//             <div>
//             {menu_type == "searchByClickId" && <SearchByClickId searchByClickId={props.searchByClickId} userInfo={props.userInfoo} userBalanceInfo={props.userBalanceInfo} clicksDataInfo={props.clicksDataInfo} orderDetailsInfo={props.orderDetailsInfo} redemptionInfo={props.redemption_info} SearchByClickIdByDate={props.SearchByClickIdByDate} clicksByDate={props.clicksByDate} redemptionPaymentInfo={props.redemptionPaymentInfo} requestedBalanceInfo={props.requestedBalanceInfo} />}


//               { /* menu_type == "searchByMobile" && <SearchByMobile /> */}


//             </div>
//           </BodyContainer>
//         </div>
//       </div>
//     </Wrapper>
//   );
// };

// export default ClickIdOperations;
