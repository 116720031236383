import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { Input, Select, DatePicker, message } from "antd";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  redemptionStatusMaster,
  addVoucherRedemption,
  UpdateRedemptionStatus,
} from "../../../redux/slices/paymentStatusSlice.js";
import axios from "axios";
import AppConstants from "../../../constants/appConstants.js";

const { Option } = Select;

const { TextArea } = Input;

const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  @media screen and (max-width: 480px) {
    display: block;
  }
`;

const Data = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  > label {
    width: 30%;
  }
  > span {
    font-size: 18px;
    font-weight: bolder;
  }
  > input[type="text"] {
    width: 50%;
  }
  @media screen and (max-width: 480px) {
    display: block;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubmitButton = styled.div`
  width: 140px;
  height: 40px;
  display: flex;
  background: #0000ff;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  cursor: pointer;
  > p {
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 0px;
    color: #fff;
    text-align: center;
  }
`;
const DataRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const PaymentStatus = (props) => {
  const dispatch = useDispatch();
  const { paymentStatusDataRow, redemptionStatus } = useSelector(
    (s) => s.paymentStatus
  );
  // const [status, setStatus] = useState({
  //   label: "",
  //   value: "",
  // });
  const [allValues, setAllValues] = useState({
    id: "",
    user_id: "",
    email: "",
    user_name: "",
    mobile: "",
    status: "",
    remarks: "",
    details: "",
    points: "",
    method: "",
    type: "",
    voucher_id: "",
    pin: "",
  });
  const [require, setRequire] = useState(false);
  const [defPaymentDate, setDefPaymentDate] = useState("");
  const [defPaymentDate2, setDefPaymentDate2] = useState("");
  // const [status, setStatus] = useState({
  //   label: "",
  //   value: "",
  // });

  const [status, setStatus] = React.useState("transfer");

  const handleRadioChange = (event) => {
    setStatus(event.target.value);
    setAllValues({ ...allValues, status: event.target.value });
  };

  // function handleChange() {
  //   setAllValues({ ...allValues, status: status.value });
  // }

  // const handlePayoutTest = async (e) => {
  //   e.preventDefault();
  //   try {
  //     await axios.post(`${AppConstants.baseURL}/auth/payoutrazerpay`, allValues)
  //       .then((response) => {
  //         console.log(response.data, "resp==========");
  //         message.success("amount send Successfully")
  //       })
  //       .catch((error) => {
  //         console.error(error, "error===================");
  //         message.error("amount Not Deduct")
  //       });
  //   } catch (error) {
  //     message.error("amount Not Deduct")
  //     console.log(error, "error===================");
  //   }
  // }

  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };
  const handleDateChange = (date, dateString) => {
    setDefPaymentDate(dateString);
  };

  const handleDateChange2 = (date, dateString) => {
    setDefPaymentDate2(dateString);
  };

  useEffect(() => {
    // redemptionStatusMaster();
    dispatch(redemptionStatusMaster());
    console.log(paymentStatusDataRow, "paymentStatusDataRow=====");
    if (paymentStatusDataRow?.id) {
      setAllValues({
        ...allValues,
        id: paymentStatusDataRow.id,
        email: paymentStatusDataRow.email,
        user_id: paymentStatusDataRow.user_id,
        user_name: paymentStatusDataRow.user_name,
        mobile: paymentStatusDataRow.mobile,
        status: paymentStatusDataRow.status,
        remarks: paymentStatusDataRow.remarks,
        details: paymentStatusDataRow.details,
        points: paymentStatusDataRow.points,
        type: paymentStatusDataRow.type,
        method: paymentStatusDataRow.method,
      });
    }
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    let data = {};
    let data1;
    console.log(allValues.method, "inside==============", status, "status");
    if (allValues.type === "voucher" && allValues.method === "Flipkart") {
      data1 = {
        id: allValues.id,
        uid: allValues.user_id,
        voucher_id: allValues.voucher_id,
        pin: allValues.pin,
        points: allValues.points,
        status: allValues.status,
        details: allValues.details,
        remarks: allValues.remarks,
        issue_date: defPaymentDate,
        expiry_date: defPaymentDate2,
      };
      console.log(data1, "data1 fro vocher");
      data.id = allValues.id;
      if (status == "transfer") data.status = "paid";
      else if (status == "rejected") data.status = "rejected";
      else {
        data.status = status;
      }
      data.remarks = allValues.remarks;
      console.log(data1, "data");
      dispatch(addVoucherRedemption(data1)).then(() => {
        dispatch(UpdateRedemptionStatus(data));
      });
    } else if (allValues.method !== "Flipkart" && status === "paid") {
      try {
        // console.log(allValues, "allvalues============");
        allValues.status = status;
        await axios
          .post(`${AppConstants.baseURL}/auth/payoutrazerpay`, allValues)
          .then((response) => {
            console.log(response.data, "resp==========");
            message.success("amount send Successfully");
          })
          .catch((error) => {
            console.error(error, "error===================");
            message.error("amount Not Deduct");
          });
      } catch (error) {
        message.error("amount Not Deduct");
        console.log(error, "error===================");
      }
    } else if (status == "rejected") {
      let dataTosend = {
        id: allValues.id,
        status: "rejected",
        remarks: allValues.remarks,
      };
      dispatch(UpdateRedemptionStatus(dataTosend));
    } else if (status == "transfer") {
      let dataTosend = {
        id: allValues.id,
        status: "paid",
        remarks: allValues.remarks,
      };
      dispatch(UpdateRedemptionStatus(dataTosend));
    }

    //form reset after submit
    setAllValues({
      id: "",
      user_id: "",
      email: "",
      user_name: "",
      mobile: "",
      status: "",
      remarks: "",
      details: "",
      points: "",
      method: "",
      type: "",
      voucher_id: "",
      pin: "",
    });
    setRequire(false);
    setDefPaymentDate("");
    setDefPaymentDate2("");
    props.setMenuType("showRejectedApproval");
  };

  return (
    <>
      <Container className="mt-5">
        {/* <button onClick={handlePayoutTest}>payout</button> */}
        <form onSubmit={submitHandler}>
          <div className="row">
            <div className="col-12 col-lg-12">
              <DataRow className="mt-2">
                <label for="" className="form-label" style={{ width: "40%" }}>
                  user_id
                </label>
                <p className="w-75 font-weight-bold">
                  {allValues.user_id && allValues.user_id}
                </p>
              </DataRow>
              <DataRow className="mt-2">
                <label for="" className="form-label" style={{ width: "40%" }}>
                  User Name
                </label>
                <p className="w-75 font-weight-bold">
                  {allValues.user_name && allValues.user_name}
                </p>
              </DataRow>
              <DataRow className="mt-2">
                <label for="" className="form-label" style={{ width: "40%" }}>
                  mobile
                </label>
                <p className="w-75 font-weight-bold">
                  {allValues.mobile && allValues.mobile}
                </p>
              </DataRow>
              <DataRow className="mt-2">
                <label for="" className="form-label" style={{ width: "40%" }}>
                  Details
                </label>
                <p className="w-75 font-weight-bold">
                  {allValues.details && allValues.details}
                </p>
              </DataRow>
              <DataRow className="mt-2">
                <label for="" className="form-label" style={{ width: "40%" }}>
                  Points
                </label>
                <p className="w-75 font-weight-bold">
                  {allValues.points && allValues.points}
                </p>
              </DataRow>
              {allValues.type === "voucher" &&
                allValues.method === "Flipkart" && (
                  <>
                    <DataRow className="mt-2">
                      <label
                        for=""
                        className="form-label"
                        style={{ width: "40%" }}
                      >
                        Type & Method
                      </label>
                      <p className="w-75 font-weight-bold">
                        {allValues.type && allValues.type} &{" "}
                        {allValues.method && allValues.method}
                      </p>
                    </DataRow>
                    <DataRow className="mb-3">
                      <label for="" className="form-label w-75">
                        Voucher Id
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="voucher_id"
                        onChange={changeHandler}
                        value={allValues.voucher_id}
                      />
                    </DataRow>
                    <DataRow className="mb-3">
                      <label for="" className="form-label w-75">
                        Voucher PIN
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="pin"
                        onChange={changeHandler}
                        value={allValues.pin}
                      />
                    </DataRow>
                    <div className="mb-3 ">
                      <label for="" className="form-label">
                        Issue Date
                      </label>
                      <DatePicker showTime onChange={handleDateChange} />
                    </div>
                    <div className="mb-3 ">
                      <label for="" className="form-label">
                        Expiry Date
                      </label>
                      <DatePicker showTime onChange={handleDateChange2} />
                    </div>
                  </>
                )}
              <DataRow className="mt-3">
                <label
                  for="status"
                  className="form-label"
                  style={{ width: "40%" }}
                >
                  Status
                  {/* <span className="ml-5" style={{ color: "blue" }}>{`(${allValues.status})`}</span> */}
                </label>

                <FormControl>
                  {/* <FormLabel id="demo-controlled-radio-buttons-group">
                    Status
                  </FormLabel> */}
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={status}
                    row
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="transfer"
                      control={<Radio />}
                      label="Approved without Transfer"
                    />
                    <FormControlLabel
                      value="paid"
                      control={<Radio />}
                      label="Approved"
                    />
                    <FormControlLabel
                      value="rejected"
                      control={<Radio />}
                      label="Rejected"
                    />
                  </RadioGroup>
                </FormControl>
                {/* <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Choose payment Status
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={handleChange}
                  >
                    {props.redemptionStatus &&
                      props.redemptionStatus.map((item) => {
                        return (
                          <>
                            {item.status == "rejected" && (
                              <FormControlLabel
                                value={item.status}
                                control={<Radio />}
                                label="Rejected"
                                onChange={() =>
                                  setStatus({
                                    label: "Rejected",
                                    value: item.status,
                                  })
                                }
                              />
                            )}
                            {item.status == "paid" && (
                              <FormControlLabel
                                value={item.status}
                                control={<Radio />}
                                label="Approved"
                                onChange={() =>
                                  setStatus({
                                    label: "Approved",
                                    value: item.status,
                                  })
                                }
                              />
                            )}
                            {item.status != "rejected" ||
                              (item.status != "paid" && (
                                <FormControlLabel
                                  value="transfer"
                                  control={<Radio />}
                                  label="Approved without Transfer"
                                  onChange={() =>
                                    setStatus({
                                      label: "Approved without Transfer",
                                      value: "transfer",
                                    })
                                  }
                                />
                              ))}
                          </>
                        );
                      })}
                  </RadioGroup>
                </FormControl> */}
              </DataRow>
              <DataRow className="mt-5">
                <label for="" className="form-label" style={{ width: "40%" }}>
                  Remarks
                </label>
                <input
                  type="text"
                  className="form-control w-75"
                  name="remarks"
                  onChange={changeHandler}
                  required={require}
                  value={allValues.remarks}
                />
              </DataRow>
            </div>
            <div className="col-12 col-lg-12 my-3 text-center ">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </div>
        </form>
      </Container>
    </>
  );
};

export default PaymentStatus;
