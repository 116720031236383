import axios from "axios";
import AppConstants from "../../constants/appConstants";


const API = axios.create({
    baseURL: `${AppConstants.baseURL}`,
});



//////////////////SHOW NEWS ///////////////////

export const activatedNewsData = (query) => API.get(`/news/activatenews?${query}`);
export const showNews = () => API.get("/news/shownews");



//////////////////ADD NEWS ///////////////////

export const getNewsUploadLogo = () => API.get("/news/getnewsuploadlogo");
export const addNews = (data) => API.post("/news/addnews", data);
export const updateNewsData = (data) => API.post("/news/updatenews", data);



