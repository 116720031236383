import React, { useState, useEffect } from "react";
import {
  createFeatureStore,
  updateFeaturesStores,
  getStoreData,
  gotFeaturedStoreDataRow,
} from "../../../redux/slices/manageStoreSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableCell,
  TableRow,
  TextField,
  tableCellClasses,
  Button,
  TableBody,
  Paper,
  Stack,
  Typography,
  Toolbar,
  Autocomplete,
} from "@mui/material";

const AddFeaturedStore = () => {
  const dispatch = useDispatch();

  const { featuresdata, storedata, featuredStoresDataROW } = useSelector(
    (state) => state.manageStores
  );

  // const newData = useSelector(s=>s)
  // console.log(newData,"edit data")
  // console.log(storedata, "storedata");
  const [isUpdatedData, setUpdatedData] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const [storeData, setStoreData] = useState({
    store_id: "",
    store_name: "",
    rating: 0,
  });

  const changeHandler = (e) => {
    setStoreData({ ...storeData, [e.target.name]: e.target.value });
  };

  const handleAutoComplete = (e, value) => {
    e.preventDefault();
    setStoreData({ ...storeData, store_name: value });
    if (storedata.length > 0) {
      for (var i = 0; i < storedata.length; i++) {
        if (value === storedata[i].store_name) {
          setStoreData({
            ...storeData,
            ...storedata[i],
          });
        }
      }
    }
  };
  const resetForm = () => {
    setStoreData({
      store_id: "",
      store_name: "",
      rating: 0,
    });
    setUpdatedData(false);
    setIsDisable(false);
    let data1 = {};
    dispatch(gotFeaturedStoreDataRow(data1));
  };

  const submitForm = (e) => {
    e.preventDefault();
    let data = {};
    data.store_id = storeData.store_id;
    data.rating = storeData.rating;
    console.log(data, "data");
    console.log(isUpdatedData, "isUpdatedData");
    if (isUpdatedData) {
      dispatch(updateFeaturesStores(data))
        .then(() => resetForm())
        .catch((err) => console.log(err, "err"));
    } else {
      dispatch(createFeatureStore(data))
        .then(() => resetForm())
        .catch((err) => console.log(err, "err"));
    }
  };
  //   console.log(storeData, "store------");
  useEffect(() => {
    dispatch(getStoreData());
    console.log(featuredStoresDataROW, "featuredStoresDataROW");
    if (featuredStoresDataROW && featuredStoresDataROW.store_id) {
      console.log("first");
      setUpdatedData(true);
      // setDisplay(false)
      setIsDisable(!isDisable);
      setStoreData({
        ...storeData,
        store_id: featuredStoresDataROW.store_id
          ? featuredStoresDataROW.store_id
          : "",
        store_name: featuredStoresDataROW.store_name
          ? featuredStoresDataROW.store_name
          : "",
        rating: featuredStoresDataROW.rating
          ? featuredStoresDataROW.rating
          : "",
      });
    }
  }, []);

  return (
    <Paper elevation={3}>
      <form onSubmit={submitForm}>
        <Stack spacing={3} direction="column">
          <Toolbar
            mt={3}
            sx={{
              padding: "1.2rem",
              borderBottom: "2px solid var(--primary-color)",
            }}
          >
            <Typography
              sx={{
                color: "var(--primary-color)",
                fontWeight: "bold",
              }}
              variant="h5"
              id="tableTitle"
            >
              Add New Feature Store
            </Typography>
          </Toolbar>
          <Table
            size="small"
            sx={{
              width: "auto",
              margin: "8px !important",
              marginTop: "16px !important",
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Store Name:</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Autocomplete
                    freeSolo
                    size="small"
                    id="user-combo-box"
                    options={
                      storedata && storedata.map((item) => item.store_name)
                    }
                    sx={{ width: 256 }}
                    value={storeData.store_name}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Store" />
                    )}
                    onChange={(e, val) => handleAutoComplete(e, val)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Enter Priority Number:</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    placeholder="Enter Priority Number"
                    name="rating"
                    fullWidth
                    required
                    value={storeData.rating}
                    onChange={changeHandler}
                    inputProps={{
                      type: "number",
                      min: "0",
                      step: "1",
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  <Button
                    variant="contained"
                    type="submit"
                    size="large"
                    className="d-block mx-auto mt-4 mb-3"
                    onClick={submitForm}
                  >
                    <strong>Submit</strong>
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </form>
    </Paper>
  );
};

export default AddFeaturedStore;
