import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import {
  Typography,
  Divider,
  Input,
  Radio,
  Icon,
  message, AutoComplete
} from "antd";
import queryString from 'query-string';
import { addheaderfooter, getMainMenu } from '../../../redux/slices/headerFooterSlice';
import { useDispatch, useSelector } from "react-redux";

const { Title, Text } = Typography;

const { TextArea } = Input;


const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  @media screen and (max-width: 480px) {
    display: block;
  }
`;


const Data = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  > label {
    width: 30%;
  }
  >span{
    font-size: 18px;
    font-weight: bolder;
  }
  > input[type="text"] {
    width: 50%;
  }
  @media screen and (max-width: 480px) {
    display: block;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubmitButton = styled.div`
  width: 140px;
  height: 40px;
  display: flex;
  background: #0000ff;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  cursor:pointer;
  >p{
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 0px;
    color: #fff;
    text-align: center;
  }
`;
const DataRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const AddSubMenu = (props) => {
  const dispatch = useDispatch();
  const { mainmenuData } = useSelector((state) => ({ ...state.headerFooter }));
  const [allValues, setAllValues] = useState({
    // menu_id:'',
    submenu_name: '',
    submenu_url: '',
  });

  const [id, setId] = useState('');

  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  }

  const cb_func = () => {
    console.log("Executed Menu");
  }
  useEffect(() => {
    let data = {};
    if (props.menu_type = "header") {
      props.menu_type = "header";
      let query = queryString.stringify(data);
      dispatch(getMainMenu(query, cb_func));
    } else {
      data.menu_type = "footer";
      let query = queryString.stringify(data);
      dispatch(getMainMenu(query, cb_func));
    }
  }, []);
  const handleAutoComplete = e => {
    setAllValues({ ...allValues, menu_name: e })
    if (mainmenuData.length > 1) {
      for (var i = 0; i < mainmenuData.length; i++) {
        if (e == mainmenuData[i].store_name) {
          setAllValues({ ...allValues, menu_id: mainmenuData[i].menu_id })
        }
      }
    }
  }


  const submitHandler = (e) => {
    e.preventDefault();
    let data = {};
    data.submenu_name = allValues.submenu_name;
    data.submenu_url = allValues.submenu_url;
    data.menu_type = props.menu_type;
    data.menu_id = allValues.menu_id;
    dispatch(addheaderfooter());
  };

  return (
    <>
      <Container className="mt-5">
        <form onSubmit={submitHandler}>
          <div className="row">
            <div className="col-12 col-lg-12">
              <Data>
                <Text>Choose Menu Name</Text>
                <AutoComplete
                  className="auto-complete"
                  disabled={false}
                  dataSource={mainmenuData && mainmenuData.map((item, index) => item.menu_name)}
                  onSelect={handleAutoComplete}
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </Data>
              <DataRow>
                <label for="" className="form-label w-75">
                  Submenu Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="submenuName"
                  size="large"
                  onChange={changeHandler}
                  required={true}
                />
              </DataRow>
              {/* <Data>
                    <Text>Submenu Name</Text>
                    <Input
                        size="large"
                        type="text"
                        name="submenu_name"
                        onChange={changeHandler}
                    />
                </Data> */}
              <DataRow>
                <label for="" className="form-label w-75">
                  Submenu URL
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="submenuUrl"
                  size="large"
                  onChange={changeHandler}
                  required={true}
                />
              </DataRow>
              {/* <Data>
                    <Text>Submenu URL</Text>
                    <Input
                        size="large"
                        type="text"
                        name="submenu_url"
                        onChange={changeHandler}
                    />
                </Data> */}
              {/* <ButtonContainer>
                    <SubmitButton onClick={submitHandler}>
                        <p>Submit</p>
                    </SubmitButton>
                </ButtonContainer> */}
            </div>
            <div className="col-12 col-lg-12 my-3 text-center ">
              <button
                type="submit"
                className="btn btn-primary"
              // onClick={submitForm}

              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </Container>
    </>
  );
}

export default AddSubMenu;