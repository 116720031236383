import axios from "axios";
import AppConstants from "../../constants/appConstants";

const API = axios.create({
  baseURL: `${AppConstants.baseURL}`,
});

export const manualBonusPoints = (data) =>
  API.post("/points/bonuspoints", data);
export const statusMasterData = () => API.get("/points/statusmaster");
