import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Popconfirm, Typography } from 'antd';
import { useNavigate } from "react-router-dom";
import queryString from 'query-string';
import { compose } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentStatus, gotPaymentStatusDataRow } from "../../../redux/slices/paymentStatusSlice";

const ShowPaymentStatus = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { paymentStatusData } = useSelector(s => s.paymentStatus);
    // console.log(state, "Show Payment status")
    useEffect(() => {
        dispatch(getPaymentStatus());
    }, []);

    const edit = (e, value) => {
        console.log(value, "values==========----------")
        let data = {};
        data.id = value[1];
        data.user_id = value[2];
        data.user_name = value[3];
        data.mobile = value[4];
        data.email = value[5];
        data.points = value[6];
        data.status = value[7];
        data.type = value[8];
        data.method = value[9];
        data.remarks = value[10];
        data.details = value[11];
        console.log(data, "status data")
        dispatch(gotPaymentStatusDataRow(data));
        navigate("/redeem/paymentstatus");
        props.selMenu();
    }
    const cancel = () => {
        console.log("Cancelled");
    }

    const columns = [
        {
            name: "opertaion",
            label: "Operation",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div>
                        <Popconfirm
                            title="Edit this task?"
                            onConfirm={e => edit(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#">Edit</a>
                        </Popconfirm>
                    </div>
                )
            }
        },
        {
            name: "id",
            label: " ID",
            options: {
                filter: false,
                sort: false,
            }
        },
        // {
        //     name: "r_id",
        //     label: "r_id",
        //     options: {
        //         filter: false,
        //         sort: false,
        //     }
        // },
        {
            name: "uid",
            label: "User Id",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "name",
            label: "User Name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "mobile",
            label: "User mobile",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "email",
            label: "User email",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "points",
            label: "Points",
            options: {
                filter: false,
                sort: false,
            }
        },
        // {
        //     name: "activity_wise_points",
        //     label: " Activity Wise Points",
        //     options: {
        //         filter: true,
        //         sort: false,
        //     }
        // },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "type",
            label: "type",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "method",
            label: "method",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "remarks",
            label: "Remarks",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "details",
            label: "Details",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "created_at",
            label: "Created Daters",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "updated_at",
            label: "Updated Date",
            options: {
                filter: true,
                sort: false,
            }
        },

    ];
    const options = {
        filterType: 'uid',
    };

    return (
        <Fragment>
            <MUIDataTable
                title={"Show Payment Status  (Search by redeem Id)"}
                data={paymentStatusData && paymentStatusData}
                columns={columns}
                options={options}
            />
        </Fragment>
    );

}


export default ShowPaymentStatus;
