import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api/testimonialsApi";
import { message } from "antd";


export const showTestimonials = createAsyncThunk(
  "testimonials/showtestimonials",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.showTestimonials();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const activatedTestimonialsData = createAsyncThunk(
  "/testimonials/activatetestimonials",
  async (query, { rejectWithValue }) => {
    try {
      const resp = await api.activatedTestimonialsData(query);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Activated/Deactivated Successfully!');
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Not Activated/Deactivated OR Something went wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const getTestimonialUploadImage = createAsyncThunk(
  "/testimonials/gettestimonialuploadimage",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.getTestimonialUploadImage();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const updateTestimonialsData = createAsyncThunk(
  "/testimonials/updatetestimonials",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.updateTestimonialsData(data);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Updated Successfully!');
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Not Updated / Something went wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const addTestimonials = createAsyncThunk(
  "/testimonials/addtestimonials",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.addTestimonials(data);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Added Successfully!');
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Not Added / Something went wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

const testimonialSlice = createSlice({
  name: "testimonial",
  initialState: {
    loading: false,
    data: {
      showTestimonials: [],
      activatedTestimonialsData: [],
      testimonialsDataRow: [],
      testimonialUploadImage: [],
    },
  },
  reducers: {
    gotTestimonialsDataRow: (state, action) => {
      state.data.testimonialsDataRow = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(showTestimonials.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showTestimonials.fulfilled, (state, action) => {
      state.loading = false;
      state.data.showTestimonials = action.payload;
    });
    builder.addCase(showTestimonials.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ====================================================

    builder.addCase(activatedTestimonialsData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(activatedTestimonialsData.fulfilled, (state, action) => {
      state.data.activatedTestimonialsData = action.payload;
    });
    builder.addCase(activatedTestimonialsData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ====================================================

    builder.addCase(getTestimonialUploadImage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTestimonialUploadImage.fulfilled, (state, action) => {
      state.data.testimonialUploadImage = action.payload;
      state.loading = false;
    });
    builder.addCase(getTestimonialUploadImage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // =============================updateTestimonialsData=======================

    builder.addCase(updateTestimonialsData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateTestimonialsData.fulfilled, (state, action) => {
      state.data.testimonialUploadImage = action.payload;
      state.loading = false;
    });
    builder.addCase(updateTestimonialsData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // =============================addTestimonials=======================

    builder.addCase(addTestimonials.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addTestimonials.fulfilled, (state, action) => {
      state.data.testimonialUploadImage = action.payload;
    });
    builder.addCase(addTestimonials.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { gotTestimonialsDataRow } = testimonialSlice.actions;

export default testimonialSlice.reducer;
