import { makeRequest, makeAsyncRequest } from "../constants/request";
import * as syncActions from "./syncAction";
import AppConstants from "../constants/appConstants";
import { Alert, message } from "antd";
import { BehaviorSubject } from "rxjs";
import { dispatch } from "rxjs/internal/observable/pairs";
// import { handleResponse } from "../helper/handleResponse";
// import { dispatch } from "rxjs/internal/observable/pairs";

/** This file is used to handle Api requets and response */

const currentUserSubject = new BehaviorSubject();
export const authenticationService = {
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

/**
 * Represents Authentication Login Method.
 * @constructor
 * @param {string} email - The email of the user.
 * @param {string} password - The password of the user.
 */

export const login = (data, cbFunc) => (dispatch) => {
  makeAsyncRequest("post", `${AppConstants.baseURL}/auth/login`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success("Logged IN Successfully", 5);
        cbFunc(resp.token);
      } else {
        message.error("Credentials Incorrect/Access Denied");
        message.error(resp.failed, 5);
      }
    })
    .catch((err) => {
      //console.log("Login failed");
    });
};

// /**
//  * Represents Change user password method.
//  * @constructor
//  * @param {string} old_password - The old password stored in database.
//  * @param {string} new_password - The new password to be changed.
//  */

export const changePassword = (data) => (dispatch) => {
  makeAsyncRequest("post", `${AppConstants.baseURL}/auth/changepassword`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.success, 5);
      } else {
        message.error("please enter password");
      }
    })
    .catch((err) => {
      //console.log("error");
    });
};

// /**
//  * Represents Authentication Logout Method.
//  */

export const logout = () => (dispatch) => {
  currentUserSubject.next(null);
  makeAsyncRequest("get", `${AppConstants.baseURL}/auth/logout`)
    .then((resp) => {
      dispatch(syncActions.logout());
      currentUserSubject.next(null);
    })
    .catch((err) => {
      //console.log("Logout failed");
    });
};

/**
 * Represents Authentication Login Method.
 * @constructor
 * @param {string} Name - The Name of the user.
 * @param {string} email - The email of the user.
 * @param {string} password - The password of the user.
 * @param {string} role - The role of the user (Example- Admin, User etc.).
 */

export const signup = (data, cbFunc) => (dispatch) => {
  makeAsyncRequest("post", `${AppConstants.baseURL}/auth/signup`, data)
    .then((resp) => {
      // if (resp.data == 200) {
      message.success("User Registered Successfully", 5);

      cbFunc();
      // } else {
      //   message.error(resp.statusText, 5);
      // }
    })
    .catch((err) => {
      //console.log("registration failed");
    });
};

/**
 * Represents Authenticated get Profile Method that return user details.
 */

export const getProfile =
  (cbFunc, cbFunc1 = "") =>
  (dispatch) => {
    makeRequest("get", `${AppConstants.baseURL}/auth/profile`)
      .then((resp) => {
        dispatch(syncActions.gotProfile(resp.data));
        currentUserSubject.next(resp.data.data);
        cbFunc();
      })
      .catch((err) => {
        if (cbFunc1) cbFunc1();
        // console.log("request error", err);
      });
  };

/**
 * Represents getAlluser method that return list of registered users.
 */

/** Add Activities Action */
export const addActivities = (data, cbFunc) => (dispatch) => {
  makeAsyncRequest("post", `${AppConstants.baseURL}/user/addactivities`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp);
        message.success("Activities Added Successfully");
        cbFunc();
      } else {
        message.error("Activities not added");
        cbFunc();
      }
    })
    .catch((err) => {
      // message.error("Activities not added Successfully");
      //console.log("activities did not added");
    });
};

/** Add Activities Action */

/** Show Activities Action */

export const showActivitiesData = () => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/user/showactivities`)
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success("Show All Tasks Data");
        dispatch(syncActions.gotActivitiesData(resp.data.data));
      } else {
        message.error("error in loading");
      }
    })
    .catch((err) => {
      //console.log("Error in fetching data");
    });
};

export const activeTaskData = () => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/user/activetasks`)
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success("Show All Tasks Data");
        dispatch(syncActions.gotActivitiesData(resp.data.data));
      } else {
        message.error("error in loading");
      }
    })
    .catch((err) => {
      //console.log("Error in fetching data");
    });
};

export const getPartnerId = () => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/user/getpartners`)
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp.data, "response");
        dispatch(syncActions.gotPartnerId(resp.data.data));
      } else {
        message.error("partner id not fetch");
      }
    })
    .catch((err) => {
      //console.log("Error in fetching data");
    });
};
/** Show Activities Action */

export const updateActivitiesData = (data) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/user/updateactivities`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp);
        message.success("Activities data Updated Successfully");
      } else {
        message.error("Activities Data not updated");
      }
    })
    .catch((err) => {
      //console.log("Activities Data not updated");
    });
};
export const activatedTasksData = (query, cbFunc) => (dispatch) => {
  makeAsyncRequest("get", `${AppConstants.baseURL}/user/activatetasks?${query}`)
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp.data);
        message.success("Activities data recieved");
        cbFunc();
      } else {
        message.error("Activities Data not done");
      }
    })
    .catch((err) => {
      //console.log("Activities Data not updated");
    });
};
// export const deactivatedTasksData = (data) => (dispatch) => {
//   makeAsyncRequest(
//     "post",
//     `${AppConstants.baseURL}/user/deactivatetasks`,
//     data
//   )
//     .then((resp) => {
//       if (resp.data.code == 200) {
//         //console.log(resp);
//         message.success("Activities data Deactivated Successfully");
//       }
//     })
//     .catch((err) => {
//       //console.log("Activities Data not updated");
//     });
// };

// export const removeActivityData = (data) => (dispatch) => {
//   makeAsyncRequest(
//     "post",
//     `${AppConstants.baseURL}/user/removeactivities`,
//     data
//   )
//     .then((resp) => {
//       if (resp.data.code == 200) {
//         //console.log(resp);
//         message.success("Remove ACtivty data Successfully");
//       }
//     })
//     .catch((err) => {
//       //console.log("activity data did not deleted");
//     });
// };
/** Add Testimonials Action */

export const addTestimonials = (data) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/testimonials/addtestimonials`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp);
        message.success("Testimonial Added Successfully");
      } else {
        message.error("Data Not Added");
      }
    })
    .catch((err) => {
      //console.log("Testimonial did not added");
    });
};

/** Add Testimonials Action */

/** Show Testimonials Action */

export const showTestimonials = () => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/testimonials/showtestimonials`)
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success("show Testimonials Data");
        dispatch(syncActions.gotTestimonials(resp.data.data));
      } else {
        message.error("error in loading");
      }
    })
    .catch((err) => {
      //console.log("Error in fetching data");
    });
};

export const updateTestimonialsData = (data) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/testimonials/updatetestimonials`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp);
        message.success("testimnials data Updated Successfully");
      } else {
        message.error("Testimonials data not updated");
      }
    })
    .catch((err) => {
      //console.log("Testimonials Data not updated");
    });
};

/** Show Testimonials Action */

export const getTestimonialUploadImage = (data) => (dispatch) => {
  makeAsyncRequest(
    "get",
    `${AppConstants.baseURL}/testimonials/gettestimonialuploadimage`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp.data, "data Testimonial upload images");
        dispatch(syncActions.gotTestimonialUploadImage(resp.data));
        // message.success(resp.data.success);
      }
    })
    .catch((err) => {
      //console.log("request error", err);
    });
};

export const activatedTestimonialsData = (query, cbFunc) => (dispatch) => {
  makeAsyncRequest(
    "get",
    `${AppConstants.baseURL}/testimonials/activatetestimonials?${query}`
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp.data);
        message.success("Testimonials data recieved");
        cbFunc();
      } else {
        message.error("Activities Data not done");
      }
    })
    .catch((err) => {
      //console.log("Testimonials Data not updated");
    });
};

/** Add News Action */

export const addNews = (data, cbFunc) => (dispatch) => {
  makeAsyncRequest("post", `${AppConstants.baseURL}/news/addnews`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp.data.msg);
        message.success("News Added Successfully");
        cbFunc();
      } else {
        message.error(resp.data.msg);
      }
    })
    .catch((err) => {
      //console.log("News did not added");
    });
};

/** Add News Action */

/** Show News Action */

export const showNews = () => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/news/shownews`)
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success("show News Data");
        dispatch(syncActions.gotNews(resp.data.data));
      } else {
        message.error("error in loading");
      }
    })
    .catch((err) => {
      //console.log("Error in fetching data");
    });
};

export const updateNewsData = (data) => (dispatch) => {
  makeAsyncRequest("post", `${AppConstants.baseURL}/news/updatenews`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp);
        message.success("News data Updated Successfully");
      } else {
        message.error("News data not updated");
      }
    })
    .catch((err) => {
      //console.log("News Data not updated");
    });
};

export const activatedNewsData = (query, cbFunc) => (dispatch) => {
  makeAsyncRequest("get", `${AppConstants.baseURL}/news/activatenews?${query}`)
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp.data);
        message.success("News data recieved");
        cbFunc();
      } else {
        message.error("News Data not done");
      }
    })
    .catch((err) => {
      //console.log("News Data not updated");
    });
};
/** Show News Action */

export const getNewsUploadLogo = (data) => (dispatch) => {
  makeAsyncRequest(
    "get",
    `${AppConstants.baseURL}/news/getnewsuploadlogo`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp.data, "data Testimonial upload images");
        dispatch(syncActions.gotNewsUploadLogo(resp.data));
        // message.success(resp.data.success);
      } else {
        message.error("data testimonial uploda failed");
      }
    })
    .catch((err) => {
      //console.log("request error", err);
    });
};

/** Add Gallery Image */

export const saveGalleryImage = (data, cbFunc) => (dispatch) => {
  makeAsyncRequest("post", `${AppConstants.baseURL}/gallery/a`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.success);
        cbFunc();
      } else {
        message.error("Gallery image not added try again");
      }
    })
    .catch((err) => {
      //console.log("request error", err);
    });
};

/** Add Gallery Image */

/** Show Gallery Image */

export const showImages = () => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/gallery/showImages`)
    .then((resp) => {
      if (resp.data.code == 200) {
        dispatch(syncActions.gotImages(resp.data.data));
      } else {
        message.error("Error in fetching data");
      }
    })
    .catch((err) => {
      //console.log("Error in fetching data");
    });
};

/** Show Gallery Image */

/** Add ManageBanners Image */

export const uploadImage = (data, cbFunc) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/managebanners/uploadimage`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.success);
      } else {
        message.error("Image upload failed");
      }
    })
    .catch((err) => {
      //console.log("request error", err);
    });
};

/** Add ManageBanners Image */

/** Add Category Manage Stores */

export const addCategory = (data, cbFunc) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/managestores/addcategory`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp);
        message.success("Category Added Successfully");
        cbFunc();
      } else {
        message.error("Category not added");
      }
    })
    .catch((err) => {
      //console.log("Category did not added");
    });
};

export const activatedStoresCategoryData = (query, cbFunc) => (dispatch) => {
  makeAsyncRequest(
    "get",
    `${AppConstants.baseURL}/managestores/activatestorecategory?${query}`
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp.data);
        message.success("Stores data recieved");
        cbFunc();
      } else {
        message.error("Stores category Data not done");
      }
    })
    .catch((err) => {
      //console.log("Stores Data not updated");
    });
};

export const getalltags = () => (dispatch) => {
  makeAsyncRequest("get", `${AppConstants.baseURL}/managestores/getalltags`)
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp);
        // message.success("AllTags Added Successfully");
        dispatch(syncActions.gotAllTags(resp.data.data));
      } else {
        message.error("Description not added");
      }
    })
    .catch((err) => {
      //console.log("Description did not added");
    });
};

/** Add Category Manage Stores */

/** Add Description Manage Stores */

export const addDescription = (data, cbFunc) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/managestores/adddescription`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp);
        message.success("Description Added Successfully");
        cbFunc();
      } else {
        message.error("Description not added");
      }
    })
    .catch((err) => {
      //console.log("Description did not added");
    });
};
/** Add Description Manage Stores */

export const addAdditionalStoreData = (data, cbFunc) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/managestores/addadditionalstore`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success("Addtional data Added Successfully");
        cbFunc();
      } else {
        message.error("Error in Additional Data");
      }
    })
    .catch((err) => {
      //console.log("Additional Data not added");
    });
};

export const showAdditionalStoreData = () => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/managestores/showadditionalstore`)
    .then((resp) => {
      if (resp.data.code == 200) {
        // //console.log("show data....", resp.data)
        dispatch(syncActions.gotAdditionalStoreData(resp.data.data));
      } else {
        message.error("Data not fetched");
      }
    })
    .catch((err) => {
      //console.log("Error in fetching data");
    });
};

export const updateAdditionalStoreData = (data) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/managestores/updateadditionalstore`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp);
        message.success("Addtional data Updated Successfully");
      } else {
        message.error("Additional Data not updated");
      }
    })
    .catch((err) => {
      //console.log("Additional Data not updated");
    });
};

// update description data

export const updateDescriptionData = (data) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/managestores/updatedescriptions`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp);
        message.success("Description updated Successfully");
      } else {
        message.error("Description not added");
      }
    })
    .catch((err) => {
      //console.log("Description did not updated");
    });
};

/** Add Store Category Manage Stores */

export const addStoreCategory = (data, cbFunc) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/managestores/addstorecategory`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp);
        message.success("Store Category Added Successfully");
        cbFunc();
      } else {
        message.error("Store category not added");
      }
    })
    .catch((err) => {
      //console.log("Store Category did not added");
    });
};

/** Add Store Category Manage Stores */

/** Get Store Category Manage Stores */

export const getStoreCategoryData = () => (dispatch) => {
  makeRequest(
    "get",
    `${AppConstants.baseURL}/managestores/getstorecategorydata`
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        dispatch(syncActions.gotStoreCategoryData(resp.data.data));
      } else {
        message.error("Error in fetching data");
      }
    })
    .catch((err) => {
      //console.log("Error in fetching data");
    });
};

/** Get Store Category Manage Stores */

/** Update Store Category Manage Stores */

export const updateStoreCategoryData = (data, cbFunc) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/managestores/updatestorecategorydata`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp);
        message.success("Store Category update Successfully");
        cbFunc();
      } else {
        message.error("Store category not update");
      }
    })
    .catch((err) => {
      //console.log("Store Category did not updated");
    });
};

/** Update Store Category Manage Stores */

/** Add Image/Link Manage Stores */

export const addImageLink = (data, cbFunc) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/managestores/addimagelink`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp);
        message.success("Image/Link Added Successfully");
        cbFunc();
      } else {
        message.error("Image link not added");
      }
    })
    .catch((err) => {
      //console.log("Image/Link did not added");
    });
};

/** Add Image/Link Manage Stores */

/** Show Description Manage Stores */

export const showDescription = () => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/managestores/showdescriptions`)
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp.data, "show description api inside");
        dispatch(syncActions.gotDescription(resp.data.data));
      } else {
        message.error("error in fetching data");
      }
    })
    .catch((err) => {
      //console.log("Error in fetching data");
    });
};

/** Show Description Manage Stores */

/** Show Images Gallery Module */

export const getAllImages = (limit) => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/gallery/allimage?limit=${limit}`)
    .then((resp) => {
      if (resp.data.code == 200) {
        dispatch(syncActions.gotAllImages(resp.data.data));
      } else {
        message.error("Image loading failed");
      }
    })
    .catch((err) => {
      //console.log("request error", err);
    });
};

export const searchImage = (data) => (dispatch) => {
  makeAsyncRequest("post", `${AppConstants.baseURL}/gallery/searchimage`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        dispatch(syncActions.gotAllImages(resp.data.data));
      } else {
        message.error("Image not found");
      }
    })
    .catch((err) => {
      //console.log("Image not Found", err);
    });
};

export const updateImage = (data, cbFunc) => (dispatch) => {
  makeAsyncRequest("post", `${AppConstants.baseURL}/gallery/updateimage`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.msg);
        cbFunc();
      } else {
        message.error("Image not found");
      }
    })
    .catch((err) => {
      //console.log("Image not Found", err);
    });
};

export const getImageByStore = (query) => (dispatch) => {
  //   makeRequest(
  //     "get",
  //     `${AppConstants.baseURL}/gallery/imagebystore?store=${query}`
  //   )
  //     .then((resp) => {
  //       if (resp.data.code == 200) {
  //         dispatch(syncActions.gotImageByStore(resp.data));
  //       }
  //     })
  //     .catch((err) => {
  //       //console.log("request error", err);
  //     });
};

export const deleteImage = (data, cbFunc) => (dispatch) => {
  makeAsyncRequest("post", `${AppConstants.baseURL}/gallery/deleteimage`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.msg);
        cbFunc();
      } else {
        message.error("Image not found");
      }
    })
    .catch((err) => {
      //console.log("Image not Found");
    });
};

/** Show Images Gallery Module */

/** Live Banners ManageBanners Module */

export const liveBanners = () => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/managebanners/livebanners`)
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success("got all live banners");
        dispatch(syncActions.gotLiveBanners(resp.data.data));
      } else {
        message.error("request error");
      }
    })
    .catch((err) => {
      //console.log("request error", err);
    });
};

export const liveUnliveBanners = (query, cbFunc) => (dispatch) => {
  makeAsyncRequest(
    "get",
    `${AppConstants.baseURL}/managebanners/liveunlivebanners?${query}`
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp.data);
        message.success("Live Image Uploaded Successfully");
        cbFunc();
      } else {
        message.error("Live Image not Uploaded Successfully");
      }
    })
    .catch((err) => {
      //console.log("Live Image not Uploaded Successfully");
    });
};

/** Live Banners ManageBanners Module */

/** Live Offers ManageBanners Module */

export const liveOffers = () => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/managebanners/liveoffers`)
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success("got all live banners");
        //console.log(resp.data);
        dispatch(syncActions.gotLiveOffers(resp.data.data));
      } else {
        message.error("request error");
      }
    })
    .catch((err) => {
      //console.log("request error", err);
    });
};

export const liveUnliveOffers = (query, cbFunc) => (dispatch) => {
  makeAsyncRequest(
    "get",
    `${AppConstants.baseURL}/managebanners/liveunliveoffers?${query}`
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp.data);
        message.success("Live Image Uploaded Successfully");
        cbFunc();
      } else {
        message.error("Live Image not Uploaded Successfully");
      }
    })
    .catch((err) => {
      //console.log("Live Image not Uploaded Successfully");
    });
};
/** Live Offers ManageBanners Module */

/** Delete Image ManageBanners Action */

export const deleteBannersImage = (query, cbFunc) => (dispatch) => {
  //console.log(query);
  makeRequest(
    "get",
    `${AppConstants.baseURL}/managebanners/deleteimage?b_id=${query}`
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.success, 5);
        dispatch(liveBanners());
        cbFunc();
      } else {
        message.error("request error");
      }
    })
    .catch((err) => {
      //console.log("request error", err);
    });
};

/** Delete Image ManageBanners Action */

/** Delete Image ManageBanners Action */

export const deleteOffersImage = (query, cbFunc) => (dispatch) => {
  //console.log(query);
  makeRequest(
    "get",
    `${AppConstants.baseURL}/managebanners/deleteimage?b_id=${query}`
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.success, 5);
        dispatch(liveOffers());
        cbFunc();
      } else {
        message.error("request error");
      }
    })
    .catch((err) => {
      //console.log("request error", err);
    });
};

/** Delete Image ManageBanners Action */

/** Get All Image ManageBanners Action */

export const getAllManageBannersImages = () => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/gallery/getallimages`)
    .then((resp) => {
      if (resp.data.code == 200) {
        dispatch(syncActions.gotAllManageBannersImages(resp.data));
      } else {
        message.error("request error");
      }
    })
    .catch((err) => {
      //console.log("request error", err);
    });
};

/** Get All Image ManageBanners Action */

/** Add Header Footer Action */

export const addheaderfooter = (data, cbFunc) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/headerfooter/addheaderfooter`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp);
        message.success("Header Footer Added Successfully");

        cbFunc();
      } else {
        message.error("header Footer Not added");
      }
    })
    .catch((err) => {
      //console.log("Header Footer did not added");
    });
};

/** Add Header Footer Action */

/** Fetch Menu Data in Sub Menu in Header Footer Action */

export const getMainMenu = (query, cbFunc) => (dispatch) => {
  makeRequest(
    "post",
    `${AppConstants.baseURL}/headerfooter/getmainmenu?${query}`
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp.data, "data rece")
        dispatch(syncActions.gotMainMenu(resp.data.data));
        cbFunc();
      } else {
        message.error("Error in fetching data");
      }
    })
    .catch((err) => {
      //console.log("Error in fetching data");
    });
};

/** Fetch Menu Data in Sub Menu in Header Footer Action */

export const getGalleryUploadImage = (data) => (dispatch) => {
  makeAsyncRequest(
    "get",
    `${AppConstants.baseURL}/headerfooter/getgalleryimages`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        console.log(resp.data, "gallery Images");
        dispatch(syncActions.gotGalleryUploadImage(resp.data));
        message.success(resp.data.success);
      } else {
        message.error("image not uploaded");
      }
    })
    .catch((err) => {
      console.log("request error", err);
    });
};

/** Fetch Category Data in Manage Store in Add Description Action */

export const getCategoryData = () => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/managestores/getcategorydata`)
    .then((resp) => {
      if (resp.data.code == 200) {
        dispatch(syncActions.gotCategoryData(resp.data.data));
      } else {
        message.error("Error in fetching data");
      }
    })
    .catch((err) => {
      //console.log("Error in fetching data");
    });
};

/** Fetch Category Data in Manage Store in Add Description Action */

/** Fetch Store Data in Manage Store in Add Description Action */

export const getStoreData =
  (cbFunc = "") =>
  (dispatch) => {
    makeRequest("get", `${AppConstants.baseURL}/managestores/getallstores`)
      .then((resp) => {
        if (resp.data.code == 200) {
          dispatch(syncActions.gotStoreData(resp.data.data));
          if (cbFunc) cbFunc();
        } else {
          message.error("Error in fetching data");
        }
      })
      .catch((err) => {
        //console.log("Error in fetching data");
      });
  };

export const getActivePartners = (data) => (dispatch) => {
  makeRequest(
    "post",
    `${AppConstants.baseURL}/managestores/getactivepartners`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        console.log(resp.data.data, "heloo============");
        message.success(resp.data.succes);
        dispatch(syncActions.gotActivePartners(resp.data.data));
      } else {
        message.error("Error in fetching data");
      }
    })
    .catch((err) => {
      //console.log("Error in fetching data");
    });
};

/** Fetch Store Data in Manage Store in Add Description Action */

/** Show Header Footer */

export const showheaderfooter = () => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/headerfooter/showheaderfooter`)
    .then((resp) => {
      if (resp.data.code == 200) {
        dispatch(syncActions.gotHeaderFooter(resp.data.data));
      } else {
        message.error("Error in fetching data");
      }
    })
    .catch((err) => {
      //console.log("Error in fetching data");
    });
};

/** Show Header Footer */

/** Show Header Footer Sub Menu */

export const showheaderfootersubmenu = () => (dispatch) => {
  makeRequest(
    "get",
    `${AppConstants.baseURL}/headerfooter/showheaderfootersubmenu`
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        dispatch(syncActions.gotHeaderFooterSubmenu(resp.data.data));
      } else {
        message.error("Error in fetching data");
      }
    })
    .catch((err) => {
      //console.log("Error in fetching data");
    });
};

/** Show Header Footer Sub Menu */

/** Show Contacted Users */

export const showContactedUsers = () => (dispatch) => {
  makeRequest(
    "get",
    `${AppConstants.baseURL}/contactedusers/showcontactedusers`
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        // //console.log(resp.data, "cont g gfhgfh")
        dispatch(syncActions.gotContactedUsers(resp.data.data));
      } else {
        message.error("Error in fetching data");
      }
    })
    .catch((err) => {
      //console.log("Error in fetching data");
    });
};

/** Show Contacted Users */

/** Fetch Image Type Data */

export const getImageType = () => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/gallery/imagetype`)
    .then((resp) => {
      if (resp.data.code == 200) {
        // //console.log("response fetched ", resp.data);
        dispatch(syncActions.gotImageType(resp.data));
      } else {
        message.error("Error in fetching data");
      }
    })
    .catch((err) => {
      //console.log("Error in fetching data");
    });
};

/** Fetch Image Type Data */

/** Check the dimension */

export const checkImageDimension = () => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/gallery/checkDimension`)
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log("response fetched ", resp.data);
        dispatch(syncActions.gotDimension(resp.data));
      } else {
        message.error("error in fetching data");
      }
    })
    .catch((err) => {
      //console.log("Error in fetching data");
    });
};

/** Check the dimension */

/** Add Show Image */

export const saveShowImage = (data) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/gallery/saveShowImage`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.success);
      } else {
        message.error("soeme error occured");
      }
    })
    .catch((err) => {
      //console.log("request error", err);
    });
};

/** Add Show Image  */

/** Data for upload images in banners */

export const getUploadImage = (query) => (dispatch) => {
  makeAsyncRequest(
    "get",
    `${AppConstants.baseURL}/managebanners/getuploadimage?type=${query}`
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp.data, "data upload images");
        dispatch(syncActions.gotUploadImage(resp.data.data));
        message.success(resp.data.msg);
      } else {
        message.error(resp.data.msg);
      }
    })
    .catch((err) => {
      //console.log("request error", err);
    });
};

/** Data for upload images in banners  */

/** save Data for upload images in banners */

export const saveUploadImage = (data) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/managebanners/saveUploadImage`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp.data, "save upload images data");
        message.success(resp.data.success);
      } else {
        message.error("error occured");
      }
    })
    .catch((err) => {
      //console.log("request error", err);
    });
};

/**  save Data for upload images in banners  */

/** Data for upload images in Stores */

export const getStoreUploadImage = (data) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/managestores/getstoreuploadimage`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        // console.log(resp.data, "data store upload images");
        dispatch(syncActions.gotStoreUploadImage(resp.data));
        message.success(resp.data.success);
      } else {
        message.error("image not uploaded");
      }
    })
    .catch((err) => {
      //console.log("request error", err);
    });
};

/** Data for upload images in Stores  */

/** Save Data for upload images in Stores */

export const saveStoreUploadImage = (data) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/managestores/saveStoreUploadImage`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp.data, "save store upload images data");
        message.success(resp.data.success);
      } else {
        message.error("error occured");
      }
    })
    .catch((err) => {
      //console.log("request error", err);
    });
};

/** Save Data for upload images in Stores  */

/** Data for upload images in Activities */

export const getActivityUploadImage = (data) => (dispatch) => {
  makeAsyncRequest(
    "get",
    `${AppConstants.baseURL}/user/getactivityuploadimage`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp.data, "data Activities upload images");
        dispatch(syncActions.gotActivityUploadImage(resp.data));
        message.success(resp.data.success);
      } else {
        message.error("error occured");
      }
    })
    .catch((err) => {
      //console.log("request error", err);
    });
};

/** Data for upload images in Activities  */

/** Api to store affiliate link */

export const addAffiliate = (data) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/affiliate/addaffiliate`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.success);
      } else {
        message.error("error occured");
      }
    })
    .catch((err) => {
      //console.log("request error", err);
    });
};
export const updateAffiliateLinkDetails = (data) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/affiliate/updateaffiliatelinks`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp, "store resp data...");
        message.success("aff Links Update Successfully");
      } else {
        // //console.log(, "error is.............")
        message.error("Aff Links did not Updated");
      }
    })
    .catch((err) => {
      // cbFunc(err)
      console.log("Aff Links did not Updated");
    });
};
export const updateMasterPartnerDetails = (data) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/affiliate/updatemasterpartner`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp, "store resp data...");
        message.success("MasterPartner Update Successfully");
      } else {
        // //console.log(, "error is.............")
        message.error("MasterPartners did not Updated");
      }
    })
    .catch((err) => {
      // cbFunc(err)
      console.log("MasterPartner did not Updated");
    });
};

export const activateDeactivateAffLinks = (query, cbFunc) => (dispatch) => {
  makeAsyncRequest(
    "get",
    `${AppConstants.baseURL}/affiliate/activateafflinks?${query}`
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp, "store resp data...");
        message.success("aff Links Update Successfully");
        cbFunc();
      } else {
        // //console.log(, "error is.............")
        message.error("Aff Links did not Updated");
        cbFunc();
      }
    })
    .catch((err) => {
      // cbFunc(err)
      console.log("Aff Links did not Updated");
      // cbFunc();
    });
};
export const activatePartnerMaster = (data, cbFunc) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/affiliate/activatepartnermaster`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success("partner master  Update Successfully");
        cbFunc();
      } else {
        message.error("Partner master did not Updated");
        cbFunc();
      }
    })
    .catch((err) => {
      console.log("partner Master did not Updated");
    });
};

/** Api to create new store */
export const createStore = (data, cbFunc) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/managestores/createstore`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp, "store resp data...");
        message.success("store Added Successfully");
        cbFunc(resp.data, resp.data.code);
      } else {
        cbFunc(resp.data, resp.data.code);
        // //console.log(, "error is.............")
        message.error("store did not added");
      }
    })
    .catch((err) => {
      cbFunc(err);
      //console.log("store did not added");
    });
};

export const showStore = () => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/managestores/showstore`)
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success("show Store Data");
        dispatch(syncActions.gotStores(resp.data.data));
      } else {
        message.error("error in loading");
      }
    })
    .catch((err) => {
      //console.log("Error in fetching data");
    });
};

export const updateStoresData = (data) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/managestores/updatestores`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp);
        message.success("Stores data Updated Successfully");
      } else {
        message.error("Stores data not updated");
      }
    })
    .catch((err) => {
      //console.log("Stores Data not updated");
    });
};

export const activatedStoresData = (query, cbFunc) => (dispatch) => {
  makeAsyncRequest(
    "get",
    `${AppConstants.baseURL}/managestores/activatestores?${query}`
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp.data);
        message.success("Stores data recieved");
        cbFunc();
      } else {
        message.error("Stores Data not done");
      }
    })
    .catch((err) => {
      //console.log("Stores Data not updated");
    });
};

/** Api to store affiliate partner's Details */

export const getPartners = () => (dispatch) => {
  makeRequest("post", `${AppConstants.baseURL}/affiliate/getallpartners`)
    .then((resp) => {
      if (resp.data.code == 200) {
        dispatch(syncActions.gotAllPartners(resp.data.data));
        message.success("got all partners Successfully");
      } else {
        message.error("error in loading");
      }
    })
    .catch((err) => {
      console.log("Error in fetching data");
    });
};

export const getResponseActivePartners = () => (dispatch) => {
  makeRequest(
    "post",
    `${AppConstants.baseURL}/affiliate/getresponseactivepartners`
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        dispatch(syncActions.gotResponseActivePartners(resp.data.data));
        message.success("got all partners Successfully");
      } else {
        message.error("error in loading");
      }
    })
    .catch((err) => {
      console.log("Error in fetching data");
    });
};
export const getResponseMasterDataCSV = (data) => (dispatch) => {
  if (!data) return dispatch(syncActions.gotResponseMasterDataCSV(""));
  makeRequest(
    "post",
    `${AppConstants.baseURL}/affiliate/responsemastercsv`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        dispatch(syncActions.gotResponseMasterDataCSV(resp.data.data));
        message.success("got all partners Successfully");
      } else {
        message.error("error in loading");
      }
    })
    .catch((err) => {
      console.log("Error in fetching data");
    });
};

export const getActivities = (data) => (dispatch) => {
  makeRequest(
    "post",
    `${AppConstants.baseURL}/affiliate/getallactivities`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        dispatch(syncActions.gotAllActivities(resp.data.data));
        message.success("Added Successfully");
      } else {
        message.error("error in loading");
      }
    })
    .catch((err) => {
      console.log("Error in fetching data");
    });
};

export const addPartners = (data) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/affiliate/addpartners`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.success);
      } else {
        message.error("partner not added");
      }
    })
    .catch((err) => {
      //console.log("request error", err);
    });
};
export const getPartnerMasterData = (data) => (dispatch) => {
  makeAsyncRequest(
    "get",
    `${AppConstants.baseURL}/affiliate/getpartnermaster`,
    data
  ).then((resp) => {
    if (resp.data.code == 200) {
      dispatch(syncActions.gotPartnerMasterData(resp.data.data));
      message.success(resp.data.success);
    } else {
      message.error("partner not showing");
    }
  });
};

export const manualBonusPoints = (data) => (dispatch) => {
  makeAsyncRequest("post", `${AppConstants.baseURL}/points/bonuspoints`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.msg);
        dispatch(syncActions.gotManualBonusPoints(resp.data.data));
      } else {
        message.error("Bonus Points not added");
      }
    })
    .catch((err) => {
      console.log("Category did not added");
    });
};

export const getPoints = (query) => (dispatch) => {
  makeAsyncRequest(
    "get",
    `${AppConstants.baseURL}/points/getpoints?type=${query}`
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp.data, "data Activities upload images");
        dispatch(syncActions.gotPoints(resp.data.data));
        // message.success(resp.data.msg);
      }
    })
    .catch((err) => {
      //console.log("request error", err);
    });
};

export const updatePointerPoster = (data) => (dispatch) => {
  makeAsyncRequest("post", `${AppConstants.baseURL}/points/updatepoints`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp);
        message.success("points data Updated Successfully");
      } else {
        message.error("Points data not updated");
      }
    })
    .catch((err) => {
      //console.log("Points Data not updated");
    });
};

export const addPoints = (data) => (dispatch) => {
  makeAsyncRequest("post", `${AppConstants.baseURL}/points/addpoints`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success("data addes Successfully");
      } else {
        message.error("Data not Added");
      }
    })
    .catch((err) => {
      //console.log("request error", err);
    });
};

export const activatedPointerPosterData = (query, cbFunc) => (dispatch) => {
  makeAsyncRequest(
    "get",
    `${AppConstants.baseURL}/points/activatepoints?${query}`
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp.data);
        message.success("Pointer poster data recieved");
        cbFunc();
      } else {
        message.error("Pointer Poster Data not done");
      }
    })
    .catch((err) => {
      //console.log("Approval Master Data not updated");
    });
};

export const removePointMaster = (data) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/points/removepointmaster`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.success);
        // getPoints("point_master");
        dispatch(getPoints("point_master"));
      } else {
        message.error("Error in fetching data");
      }
    })
    .catch((err) => {
      //console.log("request error", err);
    });
};

export const getActivityCodeData = () => (dispatch) => {
  makeAsyncRequest("get", `${AppConstants.baseURL}/points/getactivitycode`)
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp.data, "data Activities upload images");
        dispatch(syncActions.gotActivityCodeData(resp.data.data));
        // message.success(resp.data.success);
      } else {
        message.error("Error in fetching data");
      }
    })
    .catch((err) => {
      //console.log("request error", err);
    });
};

export const uploadResponseCsv =
  (data, cb = false) =>
  (dispatch) => {
    makeAsyncRequest("post", `${AppConstants.baseURL}/points/uploadcsv`, data)
      .then((resp) => {
        if (resp.data.code == 200) {
          message.success(resp.data.msg);
          if (cb) cb({ success: resp.data.msg });
        } else {
          message.error("Error in fetching data");
          if (cb)
            cb({
              error: resp.data.error
                ? resp.data.error
                : "Error in fetching data",
            });
        }
      })
      .catch((err) => {
        //console.log("request error", err);
      });
  };

// Redemption start
export const addRedemption = (data, cbFunc) => (dispatch) => {
  makeAsyncRequest("post", `${AppConstants.baseURL}/redemption/addredeem`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.success);
        cbFunc();
      } else {
        message.error("redemption code is not added");
      }
    })
    .catch((err) => {
      //console.log("request error", err);
    });
};

export const showRedemption = () => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/redemption/showredeem`)
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success("show redemption Data");
        //console.log(resp.data.data)
        dispatch(syncActions.gotRedeemData(resp.data.data));
      } else {
        message.error("error in loading data");
        //console.log(resp.data.data)
      }
    })
    .catch((err) => {
      //console.log("Error in fetching data");
    });
};

export const getAllowedActivity = () => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/redemption/getallowedactivity`)
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.succes);
        dispatch(syncActions.gotAllowedActivity(resp.data.data));
      } else {
        message.error("Error in fetching data");
      }
    })
    .catch((err) => {
      //console.log("Error in fetching data");
    });
};
// redemption end

// Email start
export const addEmail = (data, cbFunc) => (dispatch) => {
  makeAsyncRequest("post", `${AppConstants.baseURL}/email/addemail`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.success);
        cbFunc();
        // dispatch(syncActions.gotEmailData(resp.data.data));
      } else {
        message.error("email is not added");
        cbFunc();
      }
    })
    .catch((err) => {
      //console.log("request error", err);
    });
};

export const showEmail = () => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/email/showemail`)
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success("show email Data");
        //console.log(resp.data.data)
        dispatch(syncActions.gotEmailData(resp.data.data));
      } else {
        message.error("error in loading data");
        //console.log(resp.data.data)
      }
    })
    .catch((err) => {
      //console.log("Error in fetching data");
    });
};
export const getEmailTemplateMaster = () => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/email/getemailmaster`)
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success("show email Data");
        console.log(resp.data.data, "email master......");
        dispatch(syncActions.gotEmailMasterData(resp.data.data));
      } else {
        message.error("error in loading data");
        //console.log(resp.data.data)
      }
    })
    .catch((err) => {
      //console.log("Error in fetching data");
    });
};

export const activatedEmailData = (query, cbFunc) => (dispatch) => {
  makeAsyncRequest("get", `${AppConstants.baseURL}/email/activatemail?${query}`)
    .then((resp) => {
      if (resp.data.code == 200) {
        // //console.log(resp.data);
        message.success("Email data recieved");
        cbFunc();
      } else {
        message.error("email templates Data not done");
      }
    })
    .catch((err) => {
      //console.log("email templates Data not updated");
    });
};

export const updateTasksData = (data) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/email/updatetasksdata`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp);
        message.success("email data Updated Successfully");
      } else {
        message.error("email data not updated");
      }
    })
    .catch((err) => {
      //console.log("email Data not updated");
    });
};
// email end

export const updatePartnerAsStore = (data) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/affiliate/updatepartnerstore`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        //console.log(resp);
        message.success("partner as a store data Updated Successfully");
      } else {
        message.error("partner as a store data not updated");
      }
    })
    .catch((err) => {
      //console.log("email Data not updated");
    });
};
export const showPartnersAsStore = (data) => (dispatch) => {
  makeAsyncRequest(
    "get",
    `${AppConstants.baseURL}/affiliate/getpartnerstore`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        dispatch(syncActions.gotPartnersAsStore(resp.data.data));
        message.success("partner as a store data Updated Successfully");
      } else {
        message.error("partner as a store data not updated");
      }
    })
    .catch((err) => {
      //console.log("email Data not updated");
    });
};
export const SearchPartnersByStores = (data) => (dispatch) => {
  makeRequest(
    "post",
    `${AppConstants.baseURL}/affiliate/searchpartnersbystore`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success("show partner Data by stores");
        console.log(resp.data.data, "jhkhkh");
        dispatch(syncActions.gotActivePartners(resp.data.data));
      } else {
        message.error("error in loading data");
        //console.log(resp.data.data)
      }
    })
    .catch((err) => {
      //console.log("Error in fetching data");
    });
};

export const addDeeplinkData = (data) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/managestores/adddeeplink`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success("Added data successfully");
      } else {
        message.error("error in added data");
      }
    })
    .catch((err) => {
      console.log("Error in Added data");
    });
};
export const updateDeeplinkData = (data) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/managestores/updatedeeplink`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success("Added data successfully");
      } else {
        message.error("error in added data");
      }
    })
    .catch((err) => {
      console.log("Error in Added data");
    });
};
export const getDeeplinkData = (data) => (dispatch) => {
  makeAsyncRequest(
    "get",
    `${AppConstants.baseURL}/managestores/getdeeplinkdata`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        dispatch(syncActions.gotDeeplinkData(resp.data.data));
        message.success("Added data successfully");
      } else {
        message.error("error in added data");
      }
    })
    .catch((err) => {
      console.log("Error in Added data");
    });
};

// store schema================================================================================

export const addStoreSchema = (data) => (dispatch) => {
  makeAsyncRequest(
    "post",
    `${AppConstants.baseURL}/managestores/addstoreschema`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.msg);
      } else {
        message.error("error in added data");
      }
    })
    .catch((err) => {
      console.log("Error in Added data");
    });
};

export const updateStoreSchema = (data) => (dispatch) => {
  makeAsyncRequest(
    "put",
    `${AppConstants.baseURL}/managestores/updateschema`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.msg);
      } else {
        message.error("error in update data");
      }
    })
    .catch((err) => {
      console.log("Error in update data");
    });
};
export const getStoreSchema = (data) => (dispatch) => {
  makeAsyncRequest(
    "get",
    `${AppConstants.baseURL}/managestores/getschema`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        dispatch(syncActions.gotStoreSchemaData(resp.data.data));
      } else {
        message.error("error in data");
      }
    })
    .catch((err) => {
      console.log("Error in Added data");
    });
};

export const getPaymentStatus = (data) => (dispatch) => {
  makeAsyncRequest(
    "get",
    `${AppConstants.baseURL}/redemption/getpaymentstatus`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        console.log(resp.data, "payment status");
        dispatch(syncActions.gotPaymentStatusData(resp.data));
        // message.success("Payment Status");
      } else {
        message.error("error in data");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const redemptionStatusMaster = (data) => (dispatch) => {
  makeAsyncRequest(
    "get",
    `${AppConstants.baseURL}/redemption/redemptionstatus`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        console.log(resp.data, "payment status");
        dispatch(syncActions.gotRedemptionStatus(resp.data.data));
        // message.success("Payment Status");
      } else {
        message.error("error in data");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const statusMasterData = (data) => (dispatch) => {
  makeAsyncRequest("get", `${AppConstants.baseURL}/points/statusmaster`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        // console.log(resp.data, "payment status")
        dispatch(syncActions.gotRedemptionStatus(resp.data.data));
        // message.success("Payment Status");
      } else {
        message.error("error in data");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const UpdateRedemptionStatus = (data) => (dispatch) => {
  makeAsyncRequest(
    "put",
    `${AppConstants.baseURL}/redemption/updateredemptionstatus`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        console.log(resp.data, "update payment status");
        message.success("update Redemption Payment Status");
      } else {
        message.error("error in data");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

// cashback claims=================================================================================================

export const showCashbackClaims = (data) => (dispatch) => {
  makeAsyncRequest(
    "get",
    `${AppConstants.baseURL}/crm/showcashbackclaims`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        console.log(resp.data, "Cashback Claims status");
        dispatch(syncActions.gotCashbackClaimsData(resp.data.data));
        message.success("Cashbcak claims Status");
      } else {
        message.error("error in data");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};
export const CashbackClaimsStatusMaster = (data) => (dispatch) => {
  makeAsyncRequest(
    "get",
    `${AppConstants.baseURL}/crm/claimsstatusmaster`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        // console.log(resp.data, "Cashback Claims status")
        dispatch(syncActions.gotClaimsStatusMasterData(resp.data.data));
        // message.success("Cashbcak claims Status MASTER");
      } else {
        message.error("error in data");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const updateCashbackClaimStatus = (data, cbfunc) => (dispatch) => {
  makeAsyncRequest("put", `${AppConstants.baseURL}/crm/updateclaimstatus`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        console.log(resp.data, "update claim status");
        message.success("update Cashbak Claims Status");
        cbfunc();
      } else {
        message.error("error in data");
        cbfunc();
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const getInfoByClickId = (data) => (dispatch) => {
  makeAsyncRequest("post", `${AppConstants.baseURL}/crm/infobyclickid`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        console.log(resp.data, "Cashback Claims status");
        dispatch(syncActions.gotInfoByClickId(resp.data));
        // dispatch(syncActions.gotPartnerRespByClickId(resp.data.data1));
        // dispatch(syncActions.gotPartnerRespByClickId(resp.data.data1));
        // message.success("Cashbcak claims Status");
      } else {
        message.error("error in data");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const searchByClickId = (data) => (dispatch) => {
  makeRequest("post", `${AppConstants.baseURL}/crm/searchbyclicks`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        console.log(resp.data, "Cashback Claims status");
        dispatch(syncActions.gotUserClicksInfo(resp.data));
        message.success("Cashbcak claims Status");
      } else {
        message.error("error in data");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const SearchByClickIdByDate = (data) => (dispatch) => {
  makeRequest("post", `${AppConstants.baseURL}/crm/clicksbydate`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        console.log(resp.data, "clicks By date");
        dispatch(syncActions.gotClicksByDate(resp.data));
        message.success("clicks by date");
      } else {
        message.error("error in data");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const AllOrdersCrmDetails = (data) => (dispatch) => {
  makeAsyncRequest("post", `${AppConstants.baseURL}/crm/allordersdata`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        // console.log(resp.data, "Cashback Claims status")
        dispatch(syncActions.gotAllOrdersCrm(resp.data.data));
        // message.success("Cashbcak claims Status MASTER");
      } else {
        message.error("error in data");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};
// export const activatedDeeplinkData = (query, cbFunc) => (dispatch) => {
//   makeAsyncRequest(
//     "get",
//     `${AppConstants.baseURL}/managestores/activatedeeplink?${query}`
//   )
//     .then((resp) => {
//       if (resp.data.code == 200) {
//         message.success("deep link data updated");
//         cbFunc();
//       } else {
//         message.error("deep link Data not done");
//       }
//     })
//     .catch((err) => {
//       //console.log("Stores Data not updated");
//     });
// };

// Dashboard api ===============================================================================================

export const dashboardCompleteDetails = (data) => (dispatch) => {
  makeRequest("post", `${AppConstants.baseURL}/dashboard/dashboarddata`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        // console.log(resp.data, "Dashboard Data")
        dispatch(syncActions.gotDashboardData(resp.data));
        // message.success("clicks by date");
      } else {
        message.error(resp.data.error);
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

// crm api======================================================================================================

export const Top10Results = () => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/crm/pendingpayout`)
    .then((resp) => {
      if (resp.data.code == 200) {
        console.log(resp.data, "Top 10 highest pending payout");
        dispatch(syncActions.gotHighestPayout(resp.data));
        // message.success("clicks by date");
      } else {
        message.error("Error in data");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

// new users==================================================================================================================

export const newUsersDetails = (data) => (dispatch) => {
  makeRequest("post", `${AppConstants.baseURL}/auth/newuser`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        console.log(resp.data.data, "new signup user");
        dispatch(syncActions.gotNewUsersDetails(resp.data.data));
      } else {
        message.error("Error in data");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

// user log clicks=================================================================================================================

export const userLogClicksDetails = (data) => (dispatch) => {
  makeRequest("post", `${AppConstants.baseURL}/auth/userlogclicks`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        console.log(resp.data, "user clicks logs");
        dispatch(syncActions.gotUserLogClicks(resp.data.data));
        // message.success("clicks by date");
      } else {
        message.error("Error in data");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

///////////////////////////module permission =========================================================================

export const addModulePermission = (data) => (dispatch) => {
  makeRequest("post", `${AppConstants.baseURL}/auth/addmodulepermission`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success("module permission Added");
      } else {
        message.error("Error in data");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const modulesByUserData = (data) => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/auth/modulesbyuser`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        dispatch(syncActions.gotModulesByUsersDetails(resp.data.data));
        // message.success("module By User");
      } else {
        message.error("Error in data");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const addRoles = (data, cbfunc) => (dispatch) => {
  makeRequest("post", `${AppConstants.baseURL}/auth/addroles`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.msg);
        cbfunc();
      } else {
        message.error("Error in data");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const updateRoles = (data, cbfunc) => (dispatch) => {
  makeRequest("post", `${AppConstants.baseURL}/auth/updateroles`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.msg);
        cbfunc();
      } else {
        message.error("Error in data");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const showRoles = (data) => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/auth/showroles`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        dispatch(syncActions.gotShowRoles(resp.data));
      } else {
        message.error("Error in data");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const showUserTypeModulesData = (data) => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/auth/showmoduleusertype`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        dispatch(syncActions.gotUserTypeModules(resp.data));
      } else {
        message.error("Error in data");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const getAllPanelUsers = () => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/auth/allpanelusers`)
    .then((resp) => {
      if (resp.data.code == 200) {
        dispatch(syncActions.gotAllPanelUsers(resp.data));
      } else {
        message.error("Error in data");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const allPermissionByUser = (data) => (dispatch) => {
  makeRequest("post", `${AppConstants.baseURL}/auth/userallpermission`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        dispatch(syncActions.gotAllPermissionModule(resp.data));
      } else {
        message.error(resp.data.msg);
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const showExtStoreDomain = (data) => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/managestores/getextstoredom`)
    .then((resp) => {
      if (resp.data.code == 200) {
        dispatch(syncActions.gotExtStoreDomain(resp.data.data));
      } else {
        message.error(resp.data.msg);
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const updateExtStoreDom = (data, cbfunc) => (dispatch) => {
  makeRequest(
    "post",
    `${AppConstants.baseURL}/managestores/updateextstoredom`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.msg);
        cbfunc();
      } else {
        message.error(resp.data.msg);
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const addExtDomainStore = (data, cbfunc) => (dispatch) => {
  makeRequest(
    "post",
    `${AppConstants.baseURL}/managestores/addextdomstore`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.msg);
        cbfunc();
      } else {
        message.error(resp.data.msg);
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

// export const clicksForDashboard = (data) => (dispatch) => {
//   makeRequest("post", `${AppConstants.baseURL}/dashboard/clicksdashboard`, data)
//     .then((resp) => {
//       if (resp.data.code == 200) {
//         console.log(resp.data, "clicks By date")
//         dispatch(syncActions.gotClicksDashboard(resp.data))
//         // message.success("clicks by date");
//       } else {
//         message.error("error in data");
//       }
//     })
//     .catch((err) => {
//       console.log("Error in data");
//     });
// }

// export const balanceDetailsDashboard = (data) => (dispatch) => {
//   makeRequest("post", `${AppConstants.baseURL}/dashboard/balancedashboard`, data)
//     .then((resp) => {
//       if (resp.data.code == 200) {
//         console.log(resp.data, "clicks By date")
//         dispatch(syncActions.gotBalanceDashboard(resp.data))
//         // message.success("balance Details Dashboard");
//       } else {
//         message.error("error in data");
//       }
//     })
//     .catch((err) => {
//       console.log("Error in data");
//     });
// }

// export const claimsDetailsDashboard = (data) => (dispatch) => {
//   makeRequest("post", `${AppConstants.baseURL}/dashboard/claimsdashboard`, data)
//     .then((resp) => {
//       if (resp.data.code == 200) {
//         console.log(resp.data, "clicks By date")
//         dispatch(syncActions.gotClaimsDashboard(resp.data))
//         // message.success("Claims Data Dashboard");
//       } else {
//         message.error("error in data");
//       }
//     })
//     .catch((err) => {
//       console.log("Error in data");
//     });
// }

// export const ordersDetailsForDashboard = (data) => (dispatch) => {
//   makeRequest("post", `${AppConstants.baseURL}/dashboard/ordersdashboard`, data)
//     .then((resp) => {
//       if (resp.data.code == 200) {
//         console.log(resp.data, "clicks By date")
//         dispatch(syncActions.gotOrdersDashboard(resp.data))
//         // message.success("Orders Data Dashboard");
//       } else {
//         message.error("error in data");
//       }
//     })
//     .catch((err) => {
//       console.log("Error in data");
//     });
// }

// export const redemptionDetailsDashboard = (data) => (dispatch) => {
//   makeRequest("post", `${AppConstants.baseURL}/dashboard/redemptiondashboard`, data)
//     .then((resp) => {
//       if (resp.data.code == 200) {
//         console.log(resp.data, "clicks By date")
//         dispatch(syncActions.gotRedemptionDashboard(resp.data))
//         // message.success("Redemption DatDashBoard");
//       } else {
//         message.error("error in data");
//       }
//     })
//     .catch((err) => {
//       console.log("Error in data");
//     });
// }

//////temp

///////////////////==============================Todo tasks actions=========================//////////////////

export const addTodoTaskReminder = (data) => (dispatch) => {
  makeRequest("post", `${AppConstants.baseURL}/auth/addtaskremind`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.msg ? resp.data.msg : "");
        // cbfunc();
      } else {
        message.error(resp.data.msg ? resp.data.msg : "");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const updateTodoTaskRemind = (data) => (dispatch) => {
  makeRequest("post", `${AppConstants.baseURL}/auth/updatetaskremind`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.msg ? resp.data.msg : "");
        // cbfunc();
      } else {
        message.error(resp.data.msg ? resp.data.msg : "");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const deleteTodoTaskRemind = (data) => (dispatch) => {
  makeRequest("post", `${AppConstants.baseURL}/auth/deletetaskremind`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.msg ? resp.data.msg : "");
        // cbfunc();
      } else {
        message.error(resp.data.msg ? resp.data.msg : "");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const updateTodoStatus = (data, cbFunc) => (dispatch) => {
  makeRequest("post", `${AppConstants.baseURL}/auth/updatetodostatus`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.msg ? resp.data.msg : "");
        cbFunc();
        // cbfunc();
      } else {
        cbFunc();
        message.error(resp.data.msg ? resp.data.msg : "");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const snoozeTodoTaskByUser = (data) => () => {
  makeRequest("post", `${AppConstants.baseURL}/auth/snoozetodotask`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.msg ? resp.data.msg : "");
        // cbfunc();
      } else {
        message.error(resp.data.msg ? resp.data.msg : "");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const getTodoTaskByUser = (data) => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/auth/showtodotask`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        // message.success(resp.data.msg ? resp.data.msg : "")
        dispatch(syncActions.gotTaskReminderData(resp.data));
        // cbfunc();
      } else {
        // message.error(resp.data.msg ? resp.data.msg : "");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const showRecurrenceTask = (data) => (dispatch) => {
  makeRequest("post", `${AppConstants.baseURL}/auth/showrecurrencetask`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        // message.success(resp.data.msg ? resp.data.msg : "")
        dispatch(syncActions.gotRecurrenceTask(resp.data));
        // cbfunc();
      } else {
        // message.error(resp.data.msg ? resp.data.msg : "");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const showTodoTaskAssignedAdmin = (data) => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/auth/taskassignbyadmin`, data)
    .then((resp) => {
      if (resp.data.code == 200) {
        // message.success(resp.data.msg ? resp.data.msg : "")
        dispatch(syncActions.gotTaskAssignedByAdmin(resp.data.data));
        // cbfunc();
      } else {
        // message.error(resp.data.msg ? resp.data.msg : "");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const updateFeaturesStores = (data) => () => {
  makeRequest(
    "post",
    `${AppConstants.baseURL}/managestores/updatfeaturestore`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.msg ? resp.data.msg : "");
        // cbfunc();
      } else {
        message.error(resp.data.msg ? resp.data.msg : "");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const activatedFeaturedStoresData = (data) => () => {
  makeRequest(
    "post",
    `${AppConstants.baseURL}/managestores/activatefeaturestore`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.msg ? resp.data.msg : "");
        // cbfunc();
      } else {
        message.error(resp.data.msg ? resp.data.msg : "");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const createFeaturedStore = (data) => () => {
  makeRequest(
    "post",
    `${AppConstants.baseURL}/managestores/addfeaturestore`,
    data
  )
    .then((resp) => {
      if (resp.data.code == 200) {
        message.success(resp.data.msg ? resp.data.msg : "");
        // cbfunc();
      } else {
        message.error(resp.data.msg ? resp.data.msg : "");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};

export const showFeatureStore = () => (dispatch) => {
  makeRequest("get", `${AppConstants.baseURL}/managestores/showfeaturestore`)
    .then((resp) => {
      if (resp.data.code == 200) {
        // message.success(resp.data.msg ? resp.data.msg : "")
        dispatch(syncActions.gotFeaturedStore(resp.data.data));
        // cbfunc();
      } else {
        // message.error(resp.data.msg ? resp.data.msg : "");
      }
    })
    .catch((err) => {
      console.log("Error in data");
    });
};
