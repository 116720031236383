import axios from "axios";
import AppConstants from "../../constants/appConstants";

const API = axios.create({
    baseURL: `${AppConstants.baseURL}`,
});


export const addOffers = (formData) => API.post('/deals/adddeals', formData);
export const getOffers = () => API.get("/deals/getdeals");
export const activatedDealsData = (formData) => API.post("/deals/activatedeals", formData);
export const updateDeals = (formData) => API.post("/deals/updatedeals", formData);