import React, { useEffect, useState } from "react";
import {
  InputLabel,
  FormControl,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { AutoComplete, Select } from "antd";
import Selects from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { showRoles, showUserTypeModulesData } from "../../../../redux/slices/profileSlice";
import { updateRoles } from "../../../../actions/asyncAction";

const RoleWisePermission = (props) => {

  const show_roles = useSelector(state => state.profile.data.showRoles);
  const modules_data = useSelector(state => state.profile.data.modules_data);
  const userTypeModules = useSelector(state => state.profile.data.userTypeModules);
  const dispatch = useDispatch()

  const [role, setRole] = useState({
    id: "",
    user_type: "",
  });
  const [selectedValue, setSelectedValue] = useState([]);

  useEffect(() => {

    dispatch(showRoles())
    // dispatch(showUserTypeModulesDatashowRoles())
    dispatch(showUserTypeModulesData());

  }, []);

  const handleChange = (value) => {
    setRole({ ...role, user_type: value });
    if (show_roles.length > 0) {
      for (var i = 0; i < show_roles.length; i++) {
        if (value == show_roles[i].user_type) {
          setRole({
            ...role,
            id: show_roles[i].id,
            user_type: show_roles[i].user_type,
          });
        }
      }
    }
  };

  let data1 =
    modules_data &&
    modules_data?.map((option, index) => {
      return { label: option.module_name, value: option.module_id };
    });

  const handleModules = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {};
    data.id = role.id;
    data.module_id_array = selectedValue;
    dispatch(updateRoles(data));
  };
  const cbfunc = () => {

    dispatch(showRoles());
    dispatch(showUserTypeModulesData());
  };

  return (
    <div className="container">
      <div className="row">
        <form className="w-100" onSubmit={handleSubmit}>
          <div className="row w-100 mb-5">
            <button
              className="btn btn-primary d-block ml-auto mt-3 mb-5"
              onClick={() => props.setTab("profile")}
            >
              Go Back
            </button>
            <div className="col-md-12 d-flex align-items-center justify-content-center w-100">
              {/* <FormControl fullWidth variant="outlined" size="small"> */}

              <label className="w-50">Select Role</label>
              <Select className="w-50" onChange={handleChange}>
                {show_roles &&
                  show_roles.map((item, index) => {
                    return (
                      <Option value={item.user_type}>{item.user_type}</Option>
                    );
                  })}
              </Select>
              {/* </FormControl> */}
            </div>
            <div className="col-md-12 d-flex align-items-center justify-content-center w-100">
              <div className="d-flex w-100 my-5">
                <div className="d-flex justify-content-between align-items-center w-100">
                  <p className="w-50 font-weight-bold">
                    {" "}
                    {`(${role.user_type})`} -{" "}
                  </p>
                  <Box className="w-50">
                    <Selects
                      className="dropdown"
                      placeholder="Select Module"
                      value={data1?.filter((obj) =>
                        selectedValue.includes(obj.value)
                      )} // set selected values
                      options={data1 ? data1 : []}
                      onChange={handleModules} // assign onChange function
                      isMulti
                      isClearable
                    />
                    {selectedValue && (
                      <div style={{ marginTop: 20, lineHeight: "25px" }}>
                        <div>
                          <b>Selected Value: </b>{" "}
                          {JSON.stringify(selectedValue, null, 2)}
                        </div>
                      </div>
                    )}
                  </Box>
                </div>
              </div>
            </div>
            <button type="submit" class="btn btn-primary d-block m-auto">
              Submit
            </button>
          </div>
        </form>
        <div className="col-md-12">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Role Id</TableCell>
                  <TableCell>Role Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userTypeModules &&
                  Object.keys(userTypeModules).map((key) => {
                    console.log(key, userTypeModules[key], "key");
                    let x = userTypeModules[key].map(
                      (item) => item.module_name
                    );
                    console.log(x, "=============> val of x");
                    return (
                      <>
                        <TableRow>
                          <TableCell>{key}</TableCell>

                          <TableCell>{x.join(", ")}</TableCell>
                        </TableRow>
                      </>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default RoleWisePermission;
