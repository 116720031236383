import React, { useState } from "react";
import styled from "styled-components";
import { Table, message } from "antd";
import MaterialTable from "material-table";

const Wrapper = styled.div`
width: 100%;
display:flex;
flex-direction: column;
`;
const TableContainer = styled.div`
overflow:auto;
max-height:250px
`;


const DescriptionModal = (props) => {
    const [data, setData] = useState([props.cashbackClaimsData]);
    const [status_master, setStatusaMaster] = useState({
        id: "",
        status: ""
    })
    const tableColumns = [
        { title: "Cashback Rate", field: "cb_rate", editable: 'onUpdate' },
        { title: "description", field: "description", editable: 'onUpdate' },
        {
            title: "Delete ROW", editable: 'never', field: "image", render: (record) => (
                <a onClick={remove} name={`${record.cb_rate},~,${record.description}`}>Delete</a>
            ),
        },
    ];

    const changeHandler = (e) => {
        props.setDescriptionData({ ...props.descriptionData, [e.target.name]: e.target.value });
    };

    const add = (e) => {
        props.setDescription_array([...props.description_array, props.descriptionData]);
        document.getElementById('descripiton_element').value = "";
        document.getElementById('cashback_element').value = "";
        message.success('Data Added Successfully!');
    }

    const remove = (e) => {
        const newArr = [...props.description_array];
        let i = 0;
        for (const item of newArr) {
            let d = e.target.name.split(",~,")
            if (item.cb_rate === d[0] && item.description == d[1]) break;
            i++;
        }
        if (i == newArr.length) return console.log('not found');
        newArr.splice(i, 1)
        props.setDescription_array(newArr)
        message.success('Row Deleted Successfully!');
    }

    const columns = [

        {
            title: 'Description',
            dataIndex: 'description',
            key: 'des',
        },
        {
            title: 'Cashback Rate',
            dataIndex: 'cb_rate',
            key: 'cb_rate',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <a onClick={remove} name={`${record.cb_rate},~,${record.description}`}>Delete</a>
            ),
        },
    ];

    return (
        <>
            <MaterialTable
                title="Cashback Claims data.. "
                columns={tableColumns}
                data={props.description_array && props.description_array}
                options={{ search: false, actionsColumnIndex: -1 }}
                editable={{
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const dataUpdate = [...props.description_array];
                                const index = oldData.tableData.id;
                                dataUpdate[index] = newData;
                                props.setDescription_array(dataUpdate)
                                setData([...dataUpdate]);
                                resolve();
                            }, 1000);
                        }),
                }}
            />
            <div className="row">
                <div className="col-6 col-lg-6">
                    <div class="mb-3">
                        <label for="" class="form-label">Description</label>
                        <input type="text" id="descripiton_element" class="form-control" name="description" onChange={changeHandler} />
                    </div>
                </div>
                <div className="col-6 col-lg-6">
                    <div class="mb-3">
                        <label for="" class="form-label">Cashback Rate</label>
                        <input type="text" id="cashback_element" class="form-control" name="cb_rate" onChange={changeHandler} />
                    </div>
                </div>
                <div className="col-6 col-lg-6 my-3 text-center">
                    <button type="submit" class="btn btn-primary" onClick={add}>Add</button>
                </div>

            </div>
        </>
    )

}
export default DescriptionModal;
