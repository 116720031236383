import ActionTypes from "../constants/actionTypes";
import initialState from "../stores/initialState";

export default (state = initialState.dashboard, action) => {
  switch (action.type) {
    case ActionTypes.GOT_DASHBOARD_DATA:
      return {
        ...state,
        mainDataDashboard: action.payload.data,
        signupUsersData: action.payload.signUpUserDataDashboard,
        balanceDataDashboard: action.payload.balanceDataDashboard,
        totalClaimsStatusDashbaord: action.payload.totalClaimsStatusDashbaord,
        totalTasksDataDashbaord: action.payload.totalTasksDataDashbaord,
        referedDataDashboard: action.payload.referedDataDashboard,
        redemptionHistoryDashboard: action.payload.redemptionHistoryDashboard

      };

    // case ActionTypes.GOT_REDEMPTION_DASHBOARD:
    //   return {
    //     ...state,
    //     redemptionDataDashboard: action.payload.data
    //   };
    // case ActionTypes.GOT_ORDERS_DASHBOARD:
    //   return {
    //     ...state,
    //     ordersDataDashboard: action.payload.data
    //   };
    // case ActionTypes.GOT_CLAIMS_DASHBOARD:
    //   return {
    //     ...state,
    //     claimsDataDashboard: action.payload.data
    //   };
    // case ActionTypes.GOT_BALANCE_DASHBOARD:
    //   return {
    //     ...state,
    //     balanceDataDashboard: action.payload.data
    //   };
    // case ActionTypes.GOT_CLICKS_DASHBOARD:
    //   return {
    //     ...state,
    //     clicksDataDashboard: action.payload.data
    //   };

    default:
      return state;
  }
};


