import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";
import AdminMenu from "./adminMenu";
import { Modal, Button } from "antd";
import ChangePassword from "./changePassword";
import AddRoles from "./adminMenu/addNewRole";
import RoleWisePermission from "./adminMenu/roleWisePermissions";
import { useDispatch, useSelector } from "react-redux";
import { showRoles } from "../../redux/slices/profileSlice";
import { getProfile, changePassword } from "../../redux/slices/authSlice";


const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftContainer = styled.div`
  width: 60%;
  border: 1px solid #aaa;
  border-radius: 15px;
  background: #fff;
`;

const RightContainer = styled.div`
  width: 35%;
  border: 1px solid #aaa;
  border-radius: 15px 15px 0px 0px;
  background: #fff;
`;

const ProfileContainer = styled.div`
  /* height: 500px; */
  border-radius: 15px;
  border: 1px solid #eee;
`;

const ProfileLogo = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 50px;
  margin: 50px 0px 0px 50px;
  border: 1px solid #aaa;
  background: linear-gradient(to bottom right, #99ff33 0%, #ffff00 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  > p {
    font-size: 50px;
    font-weight: bolder;
    margin-bottom: 0px;
  }
`;

const ProfileHeadContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const RoleContainer = styled.div`
  width: 160px;
  height: 40px;
  margin-top: 50px;
  padding: 5px;
  border: 1px solid #bbb;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 50px;
  > h4 {
    font-size: 20px;
    font-weight: bolder;
    color: #0e0a9c;
    margin-bottom: 0px;
  }
  > p {
    font-size: 22px;
    font-weight: bolder;
    color: #eb360e;
    margin-bottom: 0px;
    text-transform: capitalize;
  }
`;

const ProfileDataContainer = styled.div`
  height: 200px;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 50px;
  > h4 {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bolder;
    border-bottom: 1px solid #000;
  }
  > p {
    margin-top: 10px;
    font-size: 18px;
    border-bottom: 1px solid #000;
  }
`;

const NameContainer = styled.div`
  display: flex;
  margin-top: 10px;
  > h3 {
    font-size: 22px;
    font-weight: bolder;
    text-transform: capitalize;
    border-bottom: 1px solid #000;
    margin-bottom: 0px;
  }
  > div {
    height: 20px;
    width: 20px;
    border-radius: 10px;
    margin-top: 8px;
    border: 1px solid #aaa;
    background: ${(props) => (props.active ? "#00ff00" : "#ff0000")};
    margin-left: 5px;
  }
`;

const ChangePasswordContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const ChangePasswordButton = styled.div`
  display: flex;
  width: 180px;
  height: 40px;
  border-radius: 15px;
  box-shadow: 2px 2px 0px 0px;
  border: 1px solid #bbb;
  justify-content: center;
  align-items: center;
  background: #990000;
  cursor: pointer;
  > p {
    font-size: 16px;
    color: #fff;
    font-weight: bolder;
    margin-bottom: 0px;
  }
`;

const Profile = (props) => {

  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.getProfile);
  const showRolesData = useSelector(state => state.profile.data.showRoles)
  const state = useSelector(state => state);

  const [profileData, setProfileData] = useState({
    userData: "",
    mode: "",
    isModalVisible: false,
    pass1: "",
    pass2: "",
    isMatched: false,
    cur_password: ""
  });
  const [tab, setTab] = useState("profile");
  useEffect(() => {
    dispatch(getProfile());
    dispatch(showRoles());

  }, []);

  const handlePasswordChange = () => {
    showModal();
  };

  const showModal = () => {
    setProfileData({
      isModalVisible: true,
    });
  };

  const handleOk = () => {
    const { pass1, pass2, cur_password } = profileData;
    let data = {};
    data.old_password = cur_password;
    data.new_password = pass2;
    console.log(data, 'data');
    dispatch(changePassword(data));
    setProfileData({
      isModalVisible: false,
      pass1: "",
      pass2: "",
      cur_password: ""
    });
  };

  const handleCancel = () => {
    setProfileData({
      isModalVisible: false,
    });
  };

  const handleChange = (event) => {
    setProfileData({ ...profileData, [event.target.name]: event.target.value });
  };

  const {
    addRoles,
    updateRoles,
    // showRoles,
    show_roles,
    module_data,
  } = props;

  return (
    <Wrapper>
      {tab == "addroles" && (
        <AddRoles setTab={setTab} />
      )}
      {tab == "rolewisepermission" && (
        <RoleWisePermission setTab={setTab} />
      )}
      {tab == "profile" && (
        <Wrapper>
          {user && (
            <LeftContainer>
              <Modal
                title="Task Inbox"
                visible={profileData.isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                  <Button key="back" onClick={handleCancel}>
                    Cancel
                  </Button>,
                  <Button key="submit" type="primary" onClick={handleOk}>
                    Submit
                  </Button>,
                ]}
              >
                <ChangePassword
                  pass1={profileData.pass1}
                  pass2={profileData.pass2}
                  isMatched={profileData.isMatched}
                  handleChange={handleChange}
                />
              </Modal>
              <ProfileContainer>
                <ProfileHeadContainer>
                  <ProfileLogo>
                    <p>{user && user.name && user.name.slice(0, 1)}</p>
                  </ProfileLogo>
                  <RoleContainer>
                    <h4>Role :</h4>
                    <p>{user.role}</p>
                  </RoleContainer>
                </ProfileHeadContainer>
                <ProfileDataContainer>
                  <NameContainer active={true}>
                    <h3>{user.name}</h3>
                    <div />
                  </NameContainer>
                  <h4>{user.email}</h4>
                </ProfileDataContainer>
                <ChangePasswordContainer>
                  <ChangePasswordButton onClick={handlePasswordChange}>
                    <p>Change Password</p>
                  </ChangePasswordButton>
                </ChangePasswordContainer>
              </ProfileContainer>
            </LeftContainer>
          )}
          {user && user.role == "admin" && (
            <RightContainer>
              <AdminMenu
                user={user}
                addRoles={addRoles}
                updateRoles={updateRoles}
                showRoles={showRolesData}
                module_data={module_data}
                show_roles_data={show_roles}
                showAddRoles={() => {
                  setshowAddRoles(true);
                }}
                tab={tab}
                setTab={setTab}
              />
            </RightContainer>
          )}
        </Wrapper>
      )}
    </Wrapper>
  );
};

export default Profile;