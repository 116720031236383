import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import * as manageStoreApi from "../api/manageStoresApi";

const initialState = {
  featuresdata: [],
  storedata: [],
  categoryData: [],
  descriptionData: [],
  storecategoryData: [],
  descriptionDataRow: [],
  additionalStoreDataRow: [],
  additionalStoreData: [],
  uploadStoreImagesData: [],
  allStores: [],
  storesDataRow: [],
  activePartnersData: [],
  storeCategoryData: [],
  storecategoryDataRow: [],
  deeplinkData: [],
  deeplinkDataRow: [],
  store_schema_data: [],
  store_schema_data_row: [],
  allTags: [],
  extStoreDomainData: [],
  featuredStoresData: [],
  featuredStoresDataROW: {},
  deeplinkMapData: [],
  brokenLinkData: [],
};

export const createFeatureStore = createAsyncThunk(
  "managestores/addfeaturestore",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.createFeatureStore(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Created Successfully!"
        );
        // return resp.data.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Created / Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const showBrokenLink = createAsyncThunk(
  "managestores/showbrokenlinks",
  async (_, { rejectWithValue }) => {
    try {
      // console.log("broken00000");
      const resp = await manageStoreApi.showBrokenLinks();
      // console.log(resp, "broken1");
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const showFeatureStore = createAsyncThunk(
  "managestores/showfeaturestore",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.showFeatureStore();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const activatedFeaturedStoresData = createAsyncThunk(
  "managestores/activatefeaturestore",
  async (query, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.activatedFeaturedStoresData(query);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message
            ? resp.data.message
            : "Activated/Deactivated Successfully!"
        );
        // return resp.data.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Activated/Deactivate OR Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const updateFeaturesStores = createAsyncThunk(
  "managestores/updatfeaturestore",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.updateFeaturesStores(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Updated Successfully!"
        );
        // return resp.data.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Updated / Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getStoreData = createAsyncThunk(
  "managestores/getallstores",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.getStoreData();
      console.log(resp, "broken2");
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getActivePartners = createAsyncThunk(
  "/managestores/getactivepartners",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.getActivePartners(data);
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const createStore = createAsyncThunk(
  "/managestores/createstore",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.createStore(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Created Successfully!"
        );
        // return resp.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Created / Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const addDescription = createAsyncThunk(
  "/managestores/adddescription",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.addDescription(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Added Successfully!"
        );
        // return resp.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Added / Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const addAdditionalStoreData = createAsyncThunk(
  "/managestores/addadditionalstore",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.addAdditionalStoreData(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Added Successfully!"
        );
        // return resp.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Added / Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const showAdditionalStoreData = createAsyncThunk(
  "managestores/showadditionalstore",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.showAdditionalStoreData();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const updateAdditionalStoreData = createAsyncThunk(
  "/managestores/updateadditionalstore",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.updateAdditionalStoreData(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Updated Successfully!"
        );
        // return resp.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Updated / Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const addStoreCategory = createAsyncThunk(
  "/managestores/addstorecategory",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.addStoreCategory(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Added Successfully!"
        );
        // return resp.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Added / Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getStoreCategoryData = createAsyncThunk(
  "managestores/getstorecategorydata",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.getStoreCategoryData();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const updateStoreCategoryData = createAsyncThunk(
  "/managestores/updatestorecategorydata",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.updateStoreCategoryData(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Updated Successfully!"
        );
        // return resp.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Updated / Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getCategoryData = createAsyncThunk(
  "managestores/getcategorydata",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.getCategoryData();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const showDescription = createAsyncThunk(
  "managestores/showdescriptions",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.showDescription();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const activatedStoresData = createAsyncThunk(
  "/managestores/activatestores",
  async (query, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.activatedStoresData(query);
      if (resp.data.code === 200) {
        // message.success(resp.data.message ? resp.data.message : 'Activated/Deactivated Successfully!');
        // return resp.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Activated/Deactivated OR Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const getStoreUploadImage = createAsyncThunk(
  "/managestores/getstoreuploadimage",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.getStoreUploadImage(data);
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const updateDescriptionData = createAsyncThunk(
  "/managestores/updatedescriptions",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.updateDescriptionData(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Updated Successfully!"
        );
        // return resp.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Updated / Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const addCategory = createAsyncThunk(
  "/managestores/addcategory",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.addCategory(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Added Successfully!"
        );
        // return resp.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Added / Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const showStore = createAsyncThunk(
  "managestores/showstore",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.showStore();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const activatedStoresCategoryData = createAsyncThunk(
  "/managestores/activatestorecategory",
  async (query, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.activatedStoresCategoryData(query);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message
            ? resp.data.message
            : "Activated/Deactivated Successfully!"
        );
        // return resp.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Activated/Deactivated OR Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

// Deep Link================================================================================

export const addDeeplinkData = createAsyncThunk(
  "/managestores/adddeeplink",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.addDeeplinkData(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Added Successfully!"
        );
        // return resp.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Added / Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const showDeeplinkMapData = createAsyncThunk(
  "/managestores/getdeeplinkmap",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.showDeeplinkDataApi(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Added Successfully!"
        );
        return resp.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Added / Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const updateDeeplinkData = createAsyncThunk(
  "/managestores/updatedeeplink",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.updateDeeplinkData(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Updated Successfully!"
        );
        // return resp.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Updated / Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getDeeplinkData = createAsyncThunk(
  "managestores/getdeeplinkdata",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.getDeeplinkData();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

// store schema================================================================================

export const addStoreSchema = createAsyncThunk(
  "/managestores/addstoreschema",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.addStoreSchema(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Added Successfully!"
        );
        // return resp.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Addedd / Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const updateStoreSchema = createAsyncThunk(
  "/managestores/updateschema",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.updateStoreSchema(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Updated Successfully!"
        );
        // return resp.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Updated / Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getalltags = createAsyncThunk(
  "managestores/getalltags",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.getalltags();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getStoreSchema = createAsyncThunk(
  "managestores/getschema",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.getStoreSchema();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

///////////////EXT DOMAIN STORE

export const showExtStoreDomain = createAsyncThunk(
  "managestores/getextstoredom",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.showExtStoreDomain();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const updateExtStoreDom = createAsyncThunk(
  "/managestores/updateextstoredom",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.updateExtStoreDom(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Updated Successfully!"
        );
        // return resp.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Updated / Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const addExtDomainStore = createAsyncThunk(
  "/managestores/addextdomstore",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.addExtDomainStore(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Added Successfully!"
        );
        // return resp.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Added / Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const updateStoresData = createAsyncThunk(
  "/managestores/updatestores",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.updateStoresData(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Updated Successfully!"
        );
        // return resp.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Updated / Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
// =========================saveStoreUploadImage===========================
export const saveStoreUploadImage = createAsyncThunk(
  "/managestores/saveStoreUploadImage",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await manageStoreApi.saveStoreUploadImageApi(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Saved Successfully!"
        );
        // return resp.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Saved / Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

const manageStoreSlice = createSlice({
  name: "manageStore",
  initialState,
  reducers: {
    setStoreCategories: (state, action) => {
      state.setStoreCategories = action.payload;
    },
    gotFeaturedStoreDataRow: (state, action) => {
      state.featuredStoresDataROW = action.payload;
    },
    gotDescriptionRow: (state, action) => {
      state.descriptionDataRow = action.payload;
    },
    gotAdditionalStoreRow: (state, action) => {
      state.additionalStoreDataRow = action.payload;
    },
    gotStoresDataRow: (state, action) => {
      state.storesDataRow = action.payload;
    },
    gotStoreCategoryDataRow: (state, action) => {
      state.storecategoryDataRow = action.payload;
    },
    gotDeeplinkDataRow: (state, action) => {
      state.deeplinkDataRow = action.payload;
    },
    gotStoreSchemaRow: (state, action) => {
      state.store_schema_data_row = action.payload;
    },
  },

  extraReducers: (builder) => {
    // =====================================createFeatureStore================================
    builder.addCase(createFeatureStore.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createFeatureStore.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createFeatureStore.rejected, (state, action) => {
      state.error = action.payload;
    });

    // =====================================showfeaturestore================================
    builder.addCase(showFeatureStore.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showFeatureStore.fulfilled, (state, action) => {
      state.featuresdata = action.payload;
      state.loading = false;
    });
    builder.addCase(showFeatureStore.rejected, (state, action) => {
      state.error = action.payload;
    });
    // =====================================showbrokenlink================================
    builder.addCase(showBrokenLink.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showBrokenLink.fulfilled, (state, action) => {
      // console.log(action.payload, "broken2");
      state.brokenLinkData = action.payload;
      state.loading = false;
    });
    builder.addCase(showBrokenLink.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================activatestores=======================================
    builder.addCase(activatedFeaturedStoresData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(activatedFeaturedStoresData.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(activatedFeaturedStoresData.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================updatfeaturestore=======================================
    builder.addCase(updateFeaturesStores.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateFeaturesStores.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateFeaturesStores.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================getStoreData=======================================
    builder.addCase(getStoreData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStoreData.fulfilled, (state, action) => {
      state.loading = false;
      state.storedata = action.payload;
    });
    builder.addCase(getStoreData.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================getStoreData=======================================
    builder.addCase(showExtStoreDomain.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showExtStoreDomain.fulfilled, (state, action) => {
      state.loading = false;
      state.extStoreDomainData = action.payload;
    });
    builder.addCase(showExtStoreDomain.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================getStoreSchema=======================================
    builder.addCase(getStoreSchema.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStoreSchema.fulfilled, (state, action) => {
      state.loading = false;
      state.store_schema_data = action.payload;
    });
    builder.addCase(getStoreSchema.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================getDeeplinkData=======================================
    builder.addCase(getDeeplinkData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDeeplinkData.fulfilled, (state, action) => {
      state.loading = false;
      state.deeplinkData = action.payload;
    });
    builder.addCase(getDeeplinkData.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================showAdditionalStoreData=======================================

    builder.addCase(showAdditionalStoreData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showAdditionalStoreData.fulfilled, (state, action) => {
      state.loading = false;
      state.additionalStoreData = action.payload;
    });
    builder.addCase(showAdditionalStoreData.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================getStoreCategoryData=======================================

    builder.addCase(getStoreCategoryData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStoreCategoryData.fulfilled, (state, action) => {
      state.loading = false;
      state.storeCategoryData = action.payload;
    });
    builder.addCase(getStoreCategoryData.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================showDescription=======================================

    builder.addCase(showDescription.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showDescription.fulfilled, (state, action) => {
      state.loading = false;
      state.descriptionData = action.payload;
    });
    builder.addCase(showDescription.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================showStore=======================================

    builder.addCase(showStore.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showStore.fulfilled, (state, action) => {
      state.loading = false;
      state.allStores = action.payload;
    });
    builder.addCase(showStore.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================getStoreUploadImage=======================================

    builder.addCase(getStoreUploadImage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStoreUploadImage.fulfilled, (state, action) => {
      state.loading = false;
      state.uploadStoreImagesData = action.payload;
    });
    builder.addCase(getStoreUploadImage.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================getActivePartners=======================================

    builder.addCase(getActivePartners.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getActivePartners.fulfilled, (state, action) => {
      state.activePartnersData = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getActivePartners.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================getCategoryData=======================================

    builder.addCase(getCategoryData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCategoryData.fulfilled, (state, action) => {
      state.loading = false;
      state.storecategoryData = action.payload;
    });
    builder.addCase(getCategoryData.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================getalltags=======================================

    builder.addCase(getalltags.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getalltags.fulfilled, (state, action) => {
      state.loading = false;
      state.allTags = action.payload;
    });
    builder.addCase(getalltags.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================createStore=======================================

    builder.addCase(createStore.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createStore.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createStore.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================addDescription=======================================
    builder.addCase(addDescription.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addDescription.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addDescription.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================addAdditionalStoreData=======================================
    builder.addCase(addAdditionalStoreData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addAdditionalStoreData.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addAdditionalStoreData.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================updateAdditionalStoreData=======================================
    builder.addCase(updateAdditionalStoreData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAdditionalStoreData.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateAdditionalStoreData.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================addStoreCategory=======================================
    builder.addCase(addStoreCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addStoreCategory.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addStoreCategory.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================updateStoreCategoryData=======================================
    builder.addCase(updateStoreCategoryData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateStoreCategoryData.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateStoreCategoryData.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================activatedStoresData=======================================
    builder.addCase(activatedStoresData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(activatedStoresData.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(activatedStoresData.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================updateDescriptionData=======================================
    builder.addCase(updateDescriptionData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateDescriptionData.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateDescriptionData.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================addCategory=======================================
    builder.addCase(addCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addCategory.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addCategory.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================activatedStoresCategoryData=======================================
    builder.addCase(activatedStoresCategoryData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(activatedStoresCategoryData.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(activatedStoresCategoryData.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================addDeeplinkData=======================================
    builder.addCase(addDeeplinkData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addDeeplinkData.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addDeeplinkData.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================showDeeplinkMapData=======================================
    builder.addCase(showDeeplinkMapData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showDeeplinkMapData.fulfilled, (state, action) => {
      state.loading = false;
      state.deeplinkMapData = action.payload.data;
    });
    builder.addCase(showDeeplinkMapData.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================updateDeeplinkData=======================================
    builder.addCase(updateDeeplinkData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateDeeplinkData.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateDeeplinkData.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================addStoreSchema=======================================
    builder.addCase(addStoreSchema.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addStoreSchema.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addStoreSchema.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================updateStoreSchema=======================================
    builder.addCase(updateStoreSchema.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateStoreSchema.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateStoreSchema.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================updateExtStoreDom=======================================
    builder.addCase(updateExtStoreDom.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateExtStoreDom.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateExtStoreDom.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================addExtDomainStore=======================================
    builder.addCase(addExtDomainStore.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addExtDomainStore.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addExtDomainStore.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================updateStoresData=======================================
    builder.addCase(updateStoresData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateStoresData.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateStoresData.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ====================================saveStoreUploadImage=======================================
    builder.addCase(saveStoreUploadImage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveStoreUploadImage.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(saveStoreUploadImage.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});

export const {
  gotFeaturedStoreDataRow,
  gotDescriptionRow,
  gotAdditionalStoreRow,
  gotStoresDataRow,
  gotStoreCategoryDataRow,
  gotDeeplinkDataRow,
  gotStoreSchemaRow,
} = manageStoreSlice.actions;
export default manageStoreSlice.reducer;
