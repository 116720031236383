import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Popconfirm, Typography, message } from 'antd';
import { useNavigate } from "react-router-dom";
import queryString from 'query-string';
import { useDispatch, useSelector } from "react-redux";
import { activatePartnerMaster, getPartnerMasterData, gotPartnerMasterDataRow } from "../../../../redux/slices/affiliateSlice";


const ShowMasterPartners = (props) => {

    const partnerMasterData = useSelector(s => s.affiliate.partnerMasterData);
    // let history = useHistory();  //useHistory is being deprecated in react-router v6 or above
    const navigate = useNavigate();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPartnerMasterData());
    }, []);
    const edit = (e, value) => {
        let data = {};
        data.id = value[2];
        data.partner = value[3];
        data.base_url = value[4];
        data.query_url = value[5];
        data.click_id = value[6];
        data.sub_id2 = value[7];
        data.sub_id3 = value[8];
        data.deeplink_pattern = value[9];
        data.updated = value[11];
        dispatch(gotPartnerMasterDataRow(data));
        // navigate("/affiliate/applinking");
        props.setAffType("add_partner");    //do not remove this prop because it is used for component rendering/switching
    }
    const callbackFunc = () => {
        dispatch(getPartnerMasterData());
    };

    const cancel = () => {
        // console.log("Cancelled");
    };

    const activated = (e, value) => {
        let data = {};
        data.id = value[2];
        data.type = "activate";
        let query = queryString.stringify(data);
        dispatch(activatePartnerMaster(query))
        callbackFunc();
    };
    const deactivated = (e, value) => {
        let data = {};
        data.id = value[2];
        data.type = "deactivate";
        let query = queryString.stringify(data);
        dispatch(activatePartnerMaster(query));
        callbackFunc();
    };
    const columns = [
        {
            name: "Action",
            label: "Action",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (

                    <div>
                        <Popconfirm
                            title="activate the activity"
                            onConfirm={e => activated(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#" style={{ padding: 5, fontWeight: "bolder", color: "#078101", border: "1px solid #aaa", borderRadius: "5px", boxShadow: "2px 2px 0px 0px", cursor: "pointer", marginBottom: "10px" }}>Activated</a>
                        </Popconfirm>
                        {/* <Divider type="vertical"></Divider> */}
                        <Popconfirm
                            title="Deactivated"
                            onConfirm={e => deactivated(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#" style={{ padding: 5, fontWeight: "bolder", color: "#FF1502", border: "1px solid #aaa", borderRadius: "5px", boxShadow: "2px 2px 0px 0px", cursor: "pointer" }}>Deactivated</a>
                        </Popconfirm>
                    </div>
                )
            }
        },
        {
            name: "opertaion",
            label: "Operation",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div>
                        <Popconfirm
                            title="Edit this task?"
                            onConfirm={e => edit(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#">Edit</a>
                        </Popconfirm>
                    </div>
                )
            }
        },
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "partner",
            label: "Partner",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "base_url",
            label: "Base URL",
            options: {
                filter: true,
                sort: true,
            }
        },

        {
            name: "query_url",
            label: "Query Url",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "click_id",
            label: "Click Id",
            options: {
                filter: false,
                sort: false,
            }
        },

        {
            name: "sub_id2",
            label: "sub_id2",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "sub_id3",
            label: "sub_id3",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "deeplink_pattern",
            label: "Deeplink Pattern",
            options: {
                filter: true,
                sort: false,
            }
        },


        {
            name: "active",
            label: "Active",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "updated",
            label: "updated",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "created",
            label: "created",
            options: {
                filter: true,
                sort: false,
            }
        }
    ];
    const options = {
        filterType: 'partner',
    };
    return (
        <Fragment>
            <MUIDataTable
                title={"Show Partner master (search by partner)"}
                data={partnerMasterData && partnerMasterData}
                columns={columns}
                options={options}
            />
        </Fragment>
    );
};

export default ShowMasterPartners;




