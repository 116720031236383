import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api/paymentStatusApi";
import { message } from "antd";

const initialState = {
  paymentStatusData: [],
  paymentStatusDataRow: [],
  redemptionStatus: [],
  paymentStatusRejeactedApproval: [],
  paymentStatusProcessing: [],
  userDetailsData: [],
};

export const UpdateRedemptionStatus = createAsyncThunk(
  "/redemption/updateredemptionstatus",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.UpdateRedemptionStatus(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Update Voucher Redemption"
        );
      } else {
        message.error(resp.data.message ? resp.data.message : "Not Update");
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      message.error(
        resp.data.message
          ? resp.data.message
          : "Not Update / Something went Wrong!"
      );
      rejectWithValue(error.resp.data);
    }
  }
);

export const getPaymentStatus = createAsyncThunk(
  "/redemption/getpaymentstatus",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.getPaymentStatus();
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const redemptionStatusMaster = createAsyncThunk(
  "/redemption/redemptionstatus",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.redemptionStatusMaster();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
// export const addVoucherRedemption = createAsyncThunk(
//   "/redemption/addvoucherredemption",
//   async (formData, { rejectWithValue }) => {
//     try {
//       const resp = await api.addVoucherRedemptionApi(formData);
//       if (resp.data.code === 200) {
//         // console.log(resp.data, "redemptionStatusMaster");
//         message.success(resp.data.message ? resp.data.message : "Added Voucher Redemption")
//       } else {
//         //console.log(resp.data, ">>>>>>> redemptionStatusMaster");
//         message.error(resp.data.message ? resp.data.message : "Not Added")
//         return rejectWithValue(resp.data);
//       }
//     } catch (error) {
//       message.error(resp.data.message ? resp.data.message : "Not Added")
//       rejectWithValue(error.resp.data);
//       //console.log(error.resp.data, ">>>>>>>redemptionStatusMaster");
//     }
//   }
// );

export const addVoucherRedemption = createAsyncThunk(
  "/redemption/addvoucherredemption",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await api.addVoucherRedemptionApi(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Added Successfully!"
        );
        // return resp.data.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Added / Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const ManualPaymentUpi = createAsyncThunk(
  "/redemption/payoutrazerpayupi",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await api.ManualPaymentUpiApi(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "UPi payment Successful"
        );
        // return resp.data.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not successfully / Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const userDetails = createAsyncThunk(
  "/redemption/userdetails",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await api.userDetailsApi(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "UPi payment Successful"
        );
        return resp.data.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not successfully / Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

const paymentStatusSlice = createSlice({
  name: "paymentStatus",
  initialState,
  reducers: {
    gotPaymentStatusDataRow: (state, action) => {
      state.paymentStatusDataRow = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getPaymentStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPaymentStatus.fulfilled, (state, action) => {
      state.paymentStatusData = action.payload.data;
      state.paymentStatusRejeactedApproval = action.payload.results2;
      state.paymentStatusProcessing = action.payload.results3;

      state.loading = false;
      state.error = null;
    });
    builder.addCase(getPaymentStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ==============================================================================================
    builder.addCase(redemptionStatusMaster.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(redemptionStatusMaster.fulfilled, (state, action) => {
      state.redemptionStatus = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(redemptionStatusMaster.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ==============================================================================================
    builder.addCase(addVoucherRedemption.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addVoucherRedemption.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(addVoucherRedemption.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    // ==============================================================================================
    builder.addCase(ManualPaymentUpi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(ManualPaymentUpi.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(ManualPaymentUpi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    // ==============================================================================================
    builder.addCase(userDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.userDetailsData = action.payload;
    });
    builder.addCase(userDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ==============================UpdateRedemptionStatus==========================================
    builder.addCase(UpdateRedemptionStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(UpdateRedemptionStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(UpdateRedemptionStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { gotPaymentStatusDataRow } = paymentStatusSlice.actions;

export default paymentStatusSlice.reducer;
