import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api/manageBannerOfferApi";
import { message } from 'antd';


////// Live Banner /////

export const liveOffers = createAsyncThunk(
  "/managebanners/liveOffers",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.liveOffers();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const liveUnliveOffers = createAsyncThunk(
  "/managebanners/liveunliveoffers",
  async (query, { rejectWithValue }) => {
    try {
      const resp = await api.liveUnliveOffers(query);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Offers Updated Successfully!');
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Not Updated / Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const deleteOffersImage = createAsyncThunk(
  "/managebanners/deleteimage",
  async (query, { rejectWithValue }) => {
    try {
      const resp = await api.deleteOffersImage(query);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Offers Deleted Successfully!');
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Not Deleted / Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

const liveOffersSlice = createSlice({
  name: "liveOffer",
  initialState: {
    data: {
      liveOffers: [],
      liveUnliveOffers: [],
      deleteOffersImage: [],
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    // =================================================================================================

    builder.addCase(liveOffers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(liveOffers.fulfilled, (state, action) => {
      state.data.liveOffers = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(liveOffers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // =================================================================================================

    builder.addCase(liveUnliveOffers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(liveUnliveOffers.fulfilled, (state, action) => {
      state.data.liveUnliveOffers = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(liveUnliveOffers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // =================================================================================================

    builder.addCase(deleteOffersImage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteOffersImage.fulfilled, (state, action) => {
      state.data.deleteOffersImage = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteOffersImage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const {} = liveOffersSlice.actions;

export default liveOffersSlice.reducer;
