import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api/dashboardApi";
import { message } from 'antd';


export const dashboardCompleteDetails = createAsyncThunk(
  "/dashboard/dashboarddata",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.dashboardCompleteDetails(data);
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getPaymentStatus = createAsyncThunk(
  "redemption/getpaymentstatus",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.getPaymentStatus();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    loading: false,
    data: {
      dashboardCompleteDetails: [],
      balanceDataDashboard: [],
      redemptionHistoryDashboard: [],
      referedDataDashboard: [],
      signUpUserDataDashboard: [],
      totalClaimsStatusDashbaord: [],
      totalTasksDataDashbaord: [],
      getPaymentStatus: [],
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(dashboardCompleteDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(dashboardCompleteDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.data.dashboardCompleteDetails = action.payload.data;
      state.data.balanceDataDashboard = action.payload.balanceDataDashboard;
      state.data.redemptionHistoryDashboard =
        action.payload.redemptionHistoryDashboard;
      state.data.referedDataDashboard = action.payload.referedDataDashboard;
      state.data.signUpUserDataDashboard =
        action.payload.signUpUserDataDashboard;
      state.data.totalClaimsStatusDashbaord =
        action.payload.totalClaimsStatusDashbaord;
      state.data.totalTasksDataDashbaord =
        action.payload.totalTasksDataDashbaord;
    });
    builder.addCase(dashboardCompleteDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // =================================================================================================

    builder.addCase(getPaymentStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPaymentStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.data.getPaymentStatus = action.payload;
    });
    builder.addCase(getPaymentStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const {} = dashboardSlice.actions;

export default dashboardSlice.reducer;
