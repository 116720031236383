import ActionTypes from "../constants/actionTypes";
import initialState from "../stores/initialState";

export default (state = initialState.points, action) => {
    switch (action.type) {
        case ActionTypes.GOT_ACTIVITY_CODE_DATA:
            return {
                ...state,
                activityCodeData: action.payload
            };
        case ActionTypes.GOT_POINTS:
            return {
                ...state,
                pointsData: action.payload
            };
        case ActionTypes.GOT_POINTS_DATA_ROW:
            return {
                ...state,
                pointsDataRow: action.payload
            }
        case ActionTypes.GOT_AFF_LINKS_DATA:
            return {
                ...state,
                affClicksDataRow: action.payload
            }
        case ActionTypes.GOT_MANUAL_BONUS_POINTS:
            return {
                ...state,
                userNameData: action.payload
            }

        default:
            return state;
    }
}