import React, { Fragment, useState, useEffect, useCallback } from "react";
import MUIDataTable from "mui-datatables";


const ShowPartnerRespClicks = (props) => {
    const columns = [
        {
            name: "partner",
            label: "partner",
            options: {
                filter: true,
                sort: false,
            }
        },

        {
            name: "action_date",
            label: "Action Date",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "sale_amount",
            label: "Sale Amount",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "payout",
            label: "Payout",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "conversion_date",
            label: "conversion Date",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "insert_tm",
            label: "Insert time",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "update_tm",
            label: "Update time",
            options: {
                filter: true,
                sort: false,
            }
        }
    ];
    const options = {
        filterType: 'partner',
    };
    return (
        <Fragment>
            <MUIDataTable
            className="my-5"
                title={"Partnewr Response by click Id  (search by User name)"}
                data={props.partnerRespByClickId && props.partnerRespByClickId}
                columns={columns}
                options={options}
            />
        </Fragment>
    );
}
export default ShowPartnerRespClicks;