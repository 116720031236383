import ActionTypes from "../constants/actionTypes";
import initialState from "../stores/initialState";

export default (state = initialState.activities, action) => {
  switch (action.type) {
    case ActionTypes.GOT_ACTIVITIES_DATA:
      return {
        ...state,
        activityData: action.payload,
      };
    case ActionTypes.GOT_PARTNER_ID:
      return {
        ...state,
        partnerId: action.payload
      }
    case ActionTypes.GOT_ACTIVITIY_UPLOAD_IMAGE:
      return {
        ...state,
        activityImageData: action.payload.data,
      };
    case ActionTypes.GOT_ACTIVITIES_DATA_ROW:
      return {
        ...state,
        activityDataRow: action.payload
      }
    default:
      return state;
  }
};
