import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Popconfirm, Typography, message } from 'antd';
import { useNavigate } from "react-router-dom";
import queryString from 'query-string';
import { useDispatch, useSelector } from "react-redux";
import { activatedEmailData, gotEmailDataRow, showEmail } from "../../../redux/slices/emailSlice";
const ShowEmail = (props) => {
    const dispatch = useDispatch();
    const { emailData } = useSelector(state => state.email.data);
    // let history = useHistory();  //useHistory is being deprecated in react-router v6 or above
    const navigate = useNavigate();



    const edit = (e, value) => {
        let data = {};
        data.id = value[2];
        data.template_id = value[3];
        data.subject = value[4];
        data.email_template = value[5];
        data.sms_template = value[6];
        data.t_type = "html";
        dispatch(gotEmailDataRow(data));
        navigate("/email/addemail");
        props.selMenu();
    }
    const callbackFunc = () => {
        dispatch(showEmail());
    }


    const cancel = () => {
        //console.log("Cancelled");
    }

    const activated = (e, value) => {
        let data = {};
        data.type_id = value[2];
        data.type = "activate";
        let query = queryString.stringify(data);
        dispatch(activatedEmailData(query));
        callbackFunc();
        message.success("Email data Activated Successfully");
    }
    const deactivated = (e, value) => {
        let data = {};
        data.type_id = value[2];
        data.type = "deactivate";
        let query = queryString.stringify(data);
        dispatch(activatedEmailData(query));
        callbackFunc();
        message.success("Email data Deactivated Successfully");

    }

    useEffect(() => {
        dispatch(showEmail());
    }, []);

    const columns = [
        {
            name: "Action",
            label: "Action",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (

                    <div>
                        <Popconfirm
                            title="activate the activity"
                            onConfirm={e => activated(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#" style={{ padding: 5, fontWeight: "bolder", color: "#078101", border: "1px solid #aaa", borderRadius: "5px", boxShadow: "2px 2px 0px 0px", cursor: "pointer", marginBottom: "10px" }}>Activate</a>
                        </Popconfirm>
                        {/* <Divider type="vertical"></Divider> */}
                        <Popconfirm
                            title="Deactivated"
                            onConfirm={e => deactivated(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#" style={{ padding: 5, fontWeight: "bolder", color: "#FF1502", border: "1px solid #aaa", borderRadius: "5px", boxShadow: "2px 2px 0px 0px", cursor: "pointer" }}>Deactivate</a>
                        </Popconfirm>
                    </div>
                )
            }
        },
        {
            name: "opertaion",
            label: "Operation",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div>
                        <Popconfirm
                            title="Edit this task?"
                            onConfirm={e => edit(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#">Edit</a>
                        </Popconfirm>
                    </div>
                )
            }
        },
        {
            name: "id",
            label: "Id",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "template_id",
            label: "Template ID",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "subject",
            label: "Subject",
            options: {
                filter: false,
                sort: false,
                // customBodyRender: (value, tableMeta, updateValue) => <img
                //     src={value} alt="image" style={{ width: "100px" }} />
            }
        },
        {
            name: "email_template",
            label: "Email Template",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => <div dangerouslySetInnerHTML={{
                    __html: value
                }}></div>
            }
        },
        {
            name: "sms_template",
            label: "SMS Template",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => <div dangerouslySetInnerHTML={{
                    __html: value
                }}></div>
            }
        },
        {
            name: "active",
            label: "Active",
            options: {
                filter: true,
                sort: false,

            }
        },
        {
            name: "date",
            label: "Date",
            options: {
                filter: true,
                sort: true,

            }
        },
    ];
    const options = {
        filterType: 'checkbox',
    };
    return (
        <Fragment>
            <MUIDataTable
                title={"Show Email"}
                data={emailData && emailData}
                columns={columns}
                options={options}
            />
        </Fragment>
    );

}

export default ShowEmail
