import React, { Fragment, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Popconfirm, message, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import {
  getDeeplinkData,
  gotDeeplinkDataRow,
} from "../../../../redux/slices/manageStoreSlice";

const ShowDeeplink = (props) => {
  const dispatch = useDispatch();
  const { deeplinkData } = useSelector((s) => s.manageStores);
  // let history = useHistory();  //useHistory is being deprecated in react-router v6 or above
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getDeeplinkData());
  }, []);

  const edit = (e, value) => {
    let data = {};
    data.store_id = value[1];
    data.store_name = value[2];
    // console.log(value, "value----------");
    let dataArray = value[3].slice(1, -1).split(",");
    if (dataArray && dataArray?.length) {
      data.url = dataArray.map((item) => item);
    } else {
      data.url = [];
    }
    // data.url = value[3];
    dispatch(gotDeeplinkDataRow(data));
    // history.push("/managestores/createstore");   //useHistory deperecated in router v6 alternative useNavigate used
    // navigate("/managestores/createstore")
    props.setStoreType("addDeeplink");
  };
  const cancel = () => {
    //console.log("Cancelled");
  };

  const callbackFunc = () => {
    dispatch(getDeeplinkData());
  };
  // const activated = (e, value) => {
  //     let data = {};
  //     data.store_id = value[2];
  //     data.type = "activate";
  //     let query = queryString.stringify(data);
  //     props.activatedDeeplinkData(query, callbackFunc)
  //     message.success("Store data Activated Successfully");
  //     // //console.log(value, "hello")
  // }
  // const deactivated = (e, value) => {
  //     let data = {};
  //     data.store_id = value[2];
  //     data.type = "deactivate";
  //     let query = queryString.stringify(data);
  //     props.activatedDeeplinkData(query, callbackFunc);
  //     message.success("Store data Deactivated Successfully");

  // }

  const columns = [
    // {
    //     name: "Action",
    //     label: "Action",
    //     options: {
    //         filter: false,
    //         customBodyRender: (value, tableMeta, updateValue) => (

    //             <div>
    //                 <Popconfirm
    //                     title="activate the activity"
    //                     onConfirm={e => activated(e, tableMeta.rowData)}
    //                     onCancel={cancel}
    //                     okText="Yes"
    //                     cancelText="No"
    //                 >
    //                     <a href="#" style={{ padding: 5, fontWeight: "bolder", color: "#078101", border: "1px solid #aaa", borderRadius: "5px", boxShadow: "2px 2px 0px 0px", cursor: "pointer", marginBottom: "10px" }}>Activate</a>
    //                 </Popconfirm>
    //                 <Divider type="vertical"></Divider>
    //                 <Popconfirm
    //                     title="Deactivated"
    //                     onConfirm={e => deactivated(e, tableMeta.rowData)}
    //                     onCancel={cancel}
    //                     okText="Yes"
    //                     cancelText="No"
    //                 >
    //                     <a href="#" style={{ padding: 5, fontWeight: "bolder", color: "#FF1502", border: "1px solid #aaa", borderRadius: "5px", boxShadow: "2px 2px 0px 0px", cursor: "pointer" }}>Deactivate</a>
    //                 </Popconfirm>
    //             </div>
    //         )
    //     }
    // },
    {
      name: "opertaion",
      label: "Operation",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <Popconfirm
              title="Edit this task?"
              onConfirm={(e) => edit(e, tableMeta.rowData)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <a href="#">Edit</a>
            </Popconfirm>
          </div>
        ),
      },
    },
    {
      name: "store_id",
      label: "Store ID",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "store_name",
      label: "Store Name",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "url",
      label: "URL",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];
  const options = {
    filterType: "store_name",
  };
  return (
    <Fragment>
      <MUIDataTable
        title={"Show DeepLink (search by store_name)"}
        data={deeplinkData && deeplinkData}
        columns={columns}
        options={options}
      />
    </Fragment>
  );
};
export default ShowDeeplink;
