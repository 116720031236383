import ActionTypes from "../constants/actionTypes";
import initialState from "../stores/initialState";

export default (state = initialState.headerfooter, action) => {
  switch (action.type) {
    case ActionTypes.GOT_MAIN_MENU:
      return {
        ...state,
        mainmenuData: action.payload
      };
      case ActionTypes.GOT_HEADER_FOOTER:
        return {
          ...state,
          headerfooterData: action.payload
        };
        case ActionTypes.GOT_HEADER_FOOTER_SUBMENU:
          return {
            ...state,
            headerfootersubmenuData: action.payload
          };
          case ActionTypes.GOT_GALLERY_IMAGE:
            return{
              ...state,
              galleryUploadImages:action.payload.data
            }
    default:
      return state;
  }
};