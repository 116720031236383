import React, { useState } from "react";
import styled from "styled-components";
import { Typography, Radio } from "antd";
import AddRedeem from "./addRedeem";
import ShowRedeem from "./showRedeem";

const { Title } = Typography;
const Wrapper = styled.div`
  width: 100%;
`;
const BodyContainer = styled.div``;

const RadioContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  padding: 5px 0;
  > div {
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    margin-right: 30px;
  }
`;

const Redemption = () => {
  const [menu_type, setMenuType] = useState("add");
  const handleRadio = (e) => {
    setMenuType(e.target.value);
  };
  const selMenu = () => {
    setMenuType("add");
  }
  return (
    <Wrapper>
      <Title level={3}>Redemption</Title>
      <div className="row">
        <div className="col-12 col-lg-12">
          <RadioContainer>
            <div>
              <Radio
                type="radio"
                value={"add"}
                name="type"
                checked={menu_type == "add"}
                onChange={handleRadio}
              />
              Add Redeem
            </div>
            <div>
              <Radio
                type="radio"
                value={"show"}
                name="type"
                checked={menu_type == "show"}
                onChange={handleRadio}
              />
              Show Redeem
            </div>
          </RadioContainer>

          <BodyContainer>
            <div>
              {menu_type == "add" && <AddRedeem />}
              {menu_type == "show" && <ShowRedeem selMenu={selMenu} />}
            </div>
          </BodyContainer>
        </div>
      </div>
    </Wrapper>
  );
};

export default Redemption;
