import React, { Fragment, useState, useEffect, useCallback } from "react";
import MUIDataTable from "mui-datatables";
import { Popconfirm, Typography, message, Divider } from 'antd';
import { useNavigate } from "react-router-dom";
import queryString from 'query-string';
import AppConstants from "../../../constants/appConstants";
import { useDispatch, useSelector } from "react-redux";
import { activatedTestimonialsData, gotTestimonialsDataRow, showTestimonials } from "../../../redux/slices/testimonialSlice";

const ShowTestimonials = () => {
    const dispatch = useDispatch();
    const [testimonailsData, setTestimonialsData] = useState("")
    // const testimonialsState = useSelector(state => state.testimonails.data.showTestimonials)
    const { data } = useSelector((state) => state.testimonails)
    const testimonialsState = data.showTestimonials;
    const [isDeleted, setIsDeleted] = useState(false);
    // let history = useHistory(); //useHistory is being deprecated in react-router v6 or above
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(showTestimonials((data) => setTestimonialsData(data)));
    }, [dispatch]);

    const edit = (e, value) => {
        let data = {};
        data.t_id = value[2];
        data.name = value[3];
        data.image = value[4];
        data.description = value[5];

        dispatch(gotTestimonialsDataRow(data));
        navigate("/testimonials/addtestimonials");
    }
    const cancel = () => {
        // console.log("Cancelled");
    }

    const callbackFunc = () => {
        dispatch(showTestimonials());
    }
    const activated = (e, value) => {
        let data = {};
        data.t_id = value[2];
        data.type = "activate";
        let query = queryString.stringify(data);
        dispatch(activatedTestimonialsData(query));
        callbackFunc();
        message.success("Testimonial data Activated Successfully");
    }
    const deactivated = (e, value) => {
        let data = {};
        data.t_id = value[2];
        data.type = "deactivate";
        let query = queryString.stringify(data);
        dispatch(activatedTestimonialsData(query));
        callbackFunc();
        message.success("Testimonial data Deactivated Successfully");
    }




    const columns = [
        {
            name: "Action",
            label: "Action",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div style={{ display: 'flex' }}>
                        <Popconfirm
                            title="activate the activity"
                            onConfirm={e => activated(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#" style={{ padding: 5, fontWeight: "bolder", color: "#078101", border: "1px solid #aaa", borderRadius: "5px", boxShadow: "2px 2px 0px 0px", cursor: "pointer", marginBottom: "10px" }}>Activate</a>
                        </Popconfirm>
                        <Divider type="vertical"></Divider>
                        <Popconfirm
                            title="Deactivated"
                            onConfirm={e => deactivated(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#" style={{ padding: 5, fontWeight: "bolder", color: "#FF1502", border: "1px solid #aaa", borderRadius: "5px", boxShadow: "2px 2px 0px 0px", cursor: "pointer" }}>Deactivate</a>
                        </Popconfirm>
                    </div>
                )
            }
        },
        {
            name: "opertaion",
            label: "Operation",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div>
                        <Popconfirm
                            title="Edit this task?"
                            onConfirm={e => edit(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#">Edit</a>
                        </Popconfirm>
                    </div>
                )
            }
        },
        {
            name: "t_id",
            label: "Testimonial ID",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "name",
            label: "Testimonial Name",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "url",
            label: "Image",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => <img
                    src={`${value}`} alt="image" style={{ width: "100px" }} />
            }
        },

        {
            name: "description",
            label: "Testimonial Description",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "active",
            label: "Active",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "created",
            label: "Created",
            options: {
                filter: true,
                sort: false,
            }
        }
    ];
    const options = {
        filterType: 'name',
    };
    return (
        <Fragment>
            <MUIDataTable
                title={"Show Description (search by Testimonial name)"}
                data={testimonialsState && testimonialsState}
                columns={columns}
                options={options}
            />
        </Fragment>
    );
}
export default ShowTestimonials;