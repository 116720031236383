import React, { Fragment, useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import {
  Typography,
  Divider,
  Input,
  Radio,
  Icon,
  message,
  AutoComplete,
  Checkbox
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { addRedemption, getAllowedActivity } from "../../../redux/slices/redemptionSlice";

const { Title, Text } = Typography;

const { TextArea } = Input;

const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  @media screen and (max-width: 480px) {
    display: block;
  }
`;

const Data = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  > label {
    width: 30%;
  }
  > span {
    font-size: 18px;
    font-weight: bolder;
  }
  > input[type="text"] {
    width: 50%;
  }
  @media screen and (max-width: 480px) {
    display: block;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubmitButton = styled.div`
  width: 140px;
  height: 40px;
  display: flex;
  background: #0000ff;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  cursor: pointer;
  > p {
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 0px;
    color: #fff;
    text-align: center;
  }
`;
const DataRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  width:100%;
  // justify-content: space-between;
  >div{
      width:30%;
  }
`;

const RadioContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  padding: 5px 0;
  > div {
    color: #000000;
    font-weight: bold;
    font-size: 14px;
    margin-right: 30px;
  }
`;

const AddRedeem = () => {

  const dispatch = useDispatch();

  const { redeemDataRow, allowedActivityData } = useSelector(s => s.redemption)

  // console.log(data)

  const [checkedArray, setCheckedArray] = useState([]);
  const [allValues, setAllValues] = useState({
    m_id: "",
    type: "",
    method: "",
    allowed_activity: "",
    sub_activity: "",
    priority: "",
  });
  // console.log("all valuess", allValues);

  const [menu_type, setMenuType] = useState("wallet");
  const handleRadio = (e) => {
    setMenuType(e.target.value);
  };

  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
    // console.log(e);
  };
  // const [checkValue, setCheckValue] = useState("");

  useEffect(() => {
    // redeemDataRow;
    if (redeemDataRow.priority) {
      setAllValues({
        ...allValues,
        m_id: redeemDataRow.m_id,
        type: redeemDataRow.type,
        method: redeemDataRow.method,
        allowed_activity: redeemDataRow.allowed_activity,
        sub_activity: redeemDataRow.sub_activity,
        priority: redeemDataRow.priority,
      })
      setMenuType(redeemDataRow.type)
      setCheckedArray(JSON.parse(redeemDataRow.allowed_activity))

    }
  }, []);
  // console.log(allValues, "dataaaaa")

  // const printChecked= (checkedArray)=>{
  //   let marked= checkedArray;
  //   switch(marked){
  //     case 1:
  //       print("purchase");
  //       break;
  //   }
  // }

  const cbFunc = () => {
    // console.log("executed");
  }
  const submitHandler = (e) => {
    e.preventDefault();
    let data = {}
    data.type = menu_type;
    data.method = allValues.method;
    data.allowed_activity = checkedArray;
    data.sub_activity = allValues.sub_activity;
    data.priority = allValues.priority;
    dispatch(addRedemption(data));
    cbFunc();
  };

  // for checkbox
  // const checkedValue = (item ) => {
  //   if (checkedArray.includes(item)) {
  //     setCheckedArray(checkedArray.filter((checks) => checks.item != item));
  //   } else {
  //         // assigning the list to temp variable
  //   const temp = [...checkedArray];

  //   // removing the element using splice
  //   temp.splice(item,1);

  //   // updating the list
  //     setCheckedArray(temp);// or push
  //   }
  // };S

  const handleAdd = (item) => {
    // console.log(value,"add");
    if (checkedArray.indexOf(item) == -1) {
      setCheckedArray([...checkedArray, item]);
      // console.log(value,"value......")
    }
    // setCheckedArray(checkedArray.filter((checks) => checks.item != item));
    // checkedValue();
  }

  const handleRemove = (item) => {
    if (checkedArray.indexOf(item) != -1) {
      const newArr = [...checkedArray];
      let index = newArr.indexOf(item)
      // console.log(index,"djfshghj")
      newArr.splice(index, 1)
      setCheckedArray(newArr)
    }

    //     console.log("remove");
    //     // assigning the list to temp variable
    // const temp = [...checkedArray];

    // // // removing the element using splice
    // // temp.splice(item, 1);
    // // let temp=checkedArray.splice(item, 1)
    // setCheckedArray(temp.splice(item, 1))

    // updating the list
    // setCheckedArray(temp);// or push

  }
  // const [isChecked, setIsChecked] = useState(false);
  const handleChecked = (e) => {
    let checked = e.target.checked;
    const value = e.target.value;
    // const name=e.target.name;
    // console.log(name,"name....")
    if (checked) {
      handleAdd(value)
    } else {
      handleRemove(value)
    }
    // checkedValue(value);
  };

  useEffect(() => {
    dispatch(getAllowedActivity())
  }, [])

  // console.log(checkedArray, "allowedActivity Data...........")
  let ticked = checkedArray.sort();
  return (
    <>
      <Container className="mt-5">
        <form onSubmit={submitHandler}>
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="row">
                <label style={{ width: "30%" }}>Type</label>
                <RadioContainer>
                  <div>
                    <Radio
                      type="radio"
                      name="type"
                      value={"wallet"}
                      checked={menu_type == "wallet"}
                      onChange={handleRadio}
                    />
                    Wallet
                  </div>
                  <div>
                    <Radio
                      type="radio"
                      value={"bank"}
                      name="type"
                      checked={menu_type == "bank"}
                      onChange={handleRadio}
                    />
                    Bank
                  </div>
                </RadioContainer>
              </div>
              <DataRow>
                <label
                  for=""
                  className="form-label"
                  style={{ width: "40%" }}
                >
                  Method
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="method"
                  value={allValues.method}
                  onChange={changeHandler}
                  required={true}
                />
              </DataRow>
              <DataRow>
                <div>
                  <label className="">Allowed Activity</label>
                </div>
                <div
                  style={{
                    padding: "4px 0px",
                    width: "120px",
                  }}
                >
                  <div>
                    {allowedActivityData && allowedActivityData.map((item, index) => (
                      <Checkbox onChange={handleChecked} value={item.id} name={item.master_activity}>{item.sub_activity}</Checkbox>
                    ))}
                    <p style={{ "position": "absolute", "font-weight": "bold", "padding": "0px 4px", "margin-top": "7px" }}>Checked Values:-{ticked.join()}
                    </p>
                  </div>

                </div>
              </DataRow>
              <DataRow className="mt-5">
                <label for="" className="form-label" style={{ width: "40%" }}>
                  Sub Activity
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="sub_activity"
                  onChange={changeHandler}
                  required={true}
                  value={allValues.sub_activity}
                />
              </DataRow>
              <DataRow>
                <label for="" className="form-label " style={{ width: "28.5%" }}>
                  Priority
                </label>
                <input
                  type="number"
                  className="form-control w-25"
                  name="priority"
                  onChange={changeHandler}
                  required={true}
                  value={allValues.priority}
                />
              </DataRow>
            </div>
            <div className="col-12 col-lg-12 my-3 text-center ">
              <button
                type="submit"
                className="btn btn-primary"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </Container>
    </>
  );
};

export default AddRedeem;
