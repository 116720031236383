import ActionTypes from "../constants/actionTypes";
import initialState from "../stores/initialState";

export default (state = initialState.gallery, action) => {
  switch (action.type) {
    case ActionTypes.GOT_ALL_IMAGES:
      return {
        ...state,
        allImages: action.payload,
      };
    case ActionTypes.GOT_IMAGE_BY_STORE:
      return {
        ...state,
        imageData: action.payload.data,
      };
    case ActionTypes.GOT_IMAGE_TYPE:
      return {
        ...state,
        imageTypeData: action.payload.data,
      };
    case ActionTypes.GOT_DIMENSION:
      return {
        ...state,
        dimensionData: action.payload.data,
      };
    default:
      return state;
  }
};
