import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api/dealsApi";
import { message } from 'antd';

const initialState = {
    getOffersData: [],
    datatobeUpdated: []
};

export const addOffers = createAsyncThunk(
    "/deals/adddeals",
    async (formData, { rejectWithValue }) => {
        try {
            const resp = await api.addOffers(formData);
            if (resp.data.code === 200) {
                message.success(resp.data.message ? resp.data.message : 'Added Successfully!');
                // return resp.data.data;
            } else {
                message.error(resp.data.message ? resp.data.message : 'Not Added / Something went Wrong!');
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);
export const getOffers = createAsyncThunk(
    "/deals/getdeals",
    async (_, { rejectWithValue }) => {
        try {
            const resp = await api.getOffers();
            if (resp.data.code === 200) {
                return resp.data.data;
            } else {
                message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);
export const activatedDealsData = createAsyncThunk(
    "/deals/activatedeals",
    async (formData, { rejectWithValue }) => {
        try {
            const resp = await api.activatedDealsData(formData);
            if (resp.data.code === 200) {
                message.success(resp.data.message ? resp.data.message : 'Activated/Deactivated Successfully!');
                // return resp.data.data;
            } else {
                message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);
export const updateDeals = createAsyncThunk(
    "/deals/updatedeals",
    async (formData, { rejectWithValue }) => {
        try {
            const resp = await api.updateDeals(formData);
            if (resp.data.code === 200) {
                message.success(resp.data.message ? resp.data.message : 'Updated Successfully!');
                // return resp.data.data;
            } else {
                message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);

const dealsSlice = createSlice({
    name: "deals",
    initialState,
    reducers: {
        senddatatoEdit: (state, action) => {
            state.datatobeUpdated = action.payload;
        }
    },
    extraReducers: (builder) => {
        // ============================addOffers=========================================
        builder.addCase(addOffers.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addOffers.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(addOffers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });

        // ============================getOffers=====================================
        builder.addCase(getOffers.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getOffers.fulfilled, (state, action) => {
            state.getOffersData = action.payload;
            state.loading = false;
        });
        builder.addCase(getOffers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });

        // ============================activatedDealsData=========================================
        builder.addCase(activatedDealsData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(activatedDealsData.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(activatedDealsData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });

        // ============================updateDeals=========================================
        builder.addCase(updateDeals.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateDeals.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(updateDeals.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
    },
});

export const { senddatatoEdit } = dealsSlice.actions;
export default dealsSlice.reducer;
