import axios from "axios";
import AppConstants from "../../constants/appConstants";


const API = axios.create({
    baseURL: `${AppConstants.baseURL}`,
});

export const getAllPanelUsers = () => API.get("/auth/allpanelusers");
export const allPermissionByUser = (data) => API.post("/auth/userallpermission", data);
export const addModulePermission = (data) => API.post("/auth/addmodulepermission", data)