import ActionTypes from "../constants/actionTypes";
import initialState from "../stores/initialState";

export default (state = initialState.taskreminder, action) => {
    switch (action.type) {
        case ActionTypes.GOT_TASK_REMINDER_DATA:

            return {
                ...state,
                taskreminderData: action.payload.data,
                task_statusData: action.payload.todo_status,
                completeTaskReminderData: action.payload.completetodoResult,
                upcomingResults: action.payload.upcomingResults
            }
        case ActionTypes.GOT_RECURRENCE_TASK:

            return {
                ...state,
                recurrenceData: action.payload.data,
                recurrence_assignUsers: action.payload.assigned_users_recureence
            }
        case ActionTypes.GOT_ASSIGNEDTASK_BY_ADMIN:

            return {
                ...state,
                assignedTaskByAdmin: action.payload,
            }

        case ActionTypes.GOT_UPDATETASK_BY_ADMIN:

            return {
                ...state,
                updatedTaskData: action.payload,
            }

        // case ActionTypes.GOT_TASK_REMINDER_DATA_DATE_INCREMENT:
        //     return{
        //         ...state, 
        //         date:action.payload
        //     }

        case ActionTypes.GOT_TASK_COMPLETE:
            return {
                // taskreminderData:[
                //     ...state.taskreminderData.filter(data=> data!==action.payload)
                // ]
            }
        default:
            return state;
    }
}