import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api/contactedUserApi";
import { message } from 'antd';

export const showContactedUsers = createAsyncThunk(
  "/contactedusers/showcontactedusers",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.showContactedUsers();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

const contactedUserSlice = createSlice({
  name: "contactedUser",
  initialState: {
    data: {
      contactedUser: [],
    },
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {

      // ==================showContactedUsers===============================================================================

    builder.addCase(showContactedUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showContactedUsers.fulfilled, (state, action) => {
      state.data.contactedUser = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(showContactedUsers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const {} = contactedUserSlice.actions;

export default contactedUserSlice.reducer;
