import axios from "axios";
import AppConstants from "../../constants/appConstants";

const API = axios.create({
  baseURL: `${AppConstants.baseURL}`,
});
export const showBrokenLinks = async () =>
  API.get("/managestores/getStoreswithbrokenlink");
export const updateBrokenLink = async (formData) =>
  API.post("/managestores/updateStoreswithbrokenlink", formData);

export const createFeatureStore = async (formData) =>
  API.post("/managestores/addfeaturestore", formData);
export const showFeatureStore = async () =>
  API.get("/managestores/showfeaturestore");
export const activatedFeaturedStoresData = async (query) =>
  API.post(`/managestores/activatefeaturestore?${query}`); //this api requires req.query in controller
export const updateFeaturesStores = async (formData) =>
  API.post("/managestores/updatfeaturestore", formData);
export const getStoreData = async () => API.get("/managestores/getallstores");
export const getActivePartners = (data) =>
  API.post("/managestores/getactivepartners", data);
export const saveStoreUploadImageApi = (data) =>
  API.post("/managestores/saveStoreUploadImage", data);

// export const addActivities = (data) => API.post("/user/addactivities", data);
// export const getActivityUploadImage = (data) => API.get("/user/getactivityuploadimage", data)

export const createStore = (data) =>
  API.post("/managestores/createstore", data);
export const addDescription = (data) =>
  API.post("/managestores/adddescription", data);

export const addAdditionalStoreData = (data) =>
  API.post("/managestores/addadditionalstore", data);
export const showAdditionalStoreData = () =>
  API.get("/managestores/showadditionalstore");
export const updateAdditionalStoreData = (data) =>
  API.post("/managestores/updateadditionalstore", data);
export const addStoreCategory = (data) =>
  API.post("/managestores/addstorecategory", data);

export const getStoreCategoryData = () =>
  API.get("/managestores/getstorecategorydata");
export const updateStoreCategoryData = (data) =>
  API.post("/managestores/updatestorecategorydata", data);
export const getCategoryData = () => API.get("/managestores/getcategorydata");

export const showDescription = () => API.get("/managestores/showdescriptions");

export const addCategory = (data) =>
  API.post("/managestores/addcategory", data);
export const updateDescriptionData = (data) =>
  API.post("/managestores/updatedescriptions", data);
export const getStoreUploadImage = (data) =>
  API.post("/managestores/getstoreuploadimage", data);
export const activatedStoresData = (query) =>
  API.get(`/managestores/activatestores?${query}`);

export const showStore = () => API.get("/managestores/showstore");

export const activatedStoresCategoryData = (query) =>
  API.get(`/managestores/activatestorecategory?${query}s`);

// Deep Link================================================================================

export const addDeeplinkData = (data) =>
  API.post("/managestores/adddeeplink", data);
export const showDeeplinkDataApi = (data) =>
  API.post("/managestores/getdeeplinkmap", data);
export const updateDeeplinkData = (data) =>
  API.post("/managestores/updatedeeplink", data);
export const getDeeplinkData = () => API.get("/managestores/getdeeplinkdata");

// store schema================================================================================

export const addStoreSchema = (data) =>
  API.post("/managestores/addstoreschema", data);
export const updateStoreSchema = (data) =>
  API.put("/managestores/updateschema", data);
export const getStoreSchema = () => API.get("/managestores/getschema");

export const getalltags = () => API.get("/managestores/getalltags");

///////////////EXT DOMAIN STORE

export const showExtStoreDomain = () => API.get("/managestores/getextstoredom");
export const updateExtStoreDom = (data) =>
  API.post("/managestores/updateextstoredom", data);
export const addExtDomainStore = (data) =>
  API.post("/managestores/addextdomstore", data);

export const updateStoresData = (data) =>
  API.post("/managestores/updatestores", data);
