import React from "react";
import styled from "styled-components";
import { deleteOffersImage, liveOffers } from '../../../redux/slices/liveOfferSlice';
import {useDispatch, useSelector} from 'react-redux';

const Image = styled.div`
  display: block;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  border: 1px dotted black;
  width: 100%;
  min-height: 150px;
  margin: auto;
`;

const URL = styled.div`
  display: block;
  width: 85%;
  min-height: 60px;
  border: 1px dotted black;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin: 4% auto;
`;

const FlipCard = (props) => {
  const dispatch = useDispatch();
  const { liveOffers } = useSelector((state) => ({...state.liveOffer.data}));
  const handleDelete = (b_id) => {
    dispatch(deleteOffersImage(b_id));
  }
  useEffect(() => {
    dispatch(liveOffers());
  }, []);

  return (
    <div>
      <div class="flip-card-live">
        <div class="flip-card-inner-live">
          <div class="flip-card-front-live">
            <Image>
              <img src={liveOffers.image} alt="Avatar" style="width:300px;height:300px;" />
            </Image>
          </div>
          <div class="flip-card-back-live">
            <URL>
              <p>{liveOffers.url}</p>
            </URL>
          </div>
        </div>
      </div>
      <button type="button" className="live-image-button" onClick={handleDelete}>Delete</button>
    </div>
  );
}

export default FlipCard;