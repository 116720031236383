import React, { useEffect, useState } from "react";
import {
  Table,
  TableCell,
  TableRow,
  TextField,
  tableCellClasses,
  Button,
  TableBody,
  Paper,
  Stack,
  Typography,
  Toolbar,
  TextareaAutosize,
  Box,
} from "@mui/material";
import { message } from "antd";
import {
  ManualPaymentUpi,
  userDetails,
} from "../../../redux/slices/paymentStatusSlice";
import { useDispatch, useSelector } from "react-redux";

const ManualPayments = () => {
  const dispatch = useDispatch();
  const { userDetailsData } = useSelector((s) => s.paymentStatus);
  const [data, setData] = useState({
    user_id: "",
    name: "",
    mobile: "",
    email: "",
    points: "",
    upi_id: "",
    remarks: "",
  });

  const handleChange = (key) => (event) => {
    setData({ ...data, [key]: event.target.value });
  };

  //   const [upi, setUpi] = useState("");

  //   const handleUpiChange = (event) => {
  //     setUpi(event.target.value);
  //   };

  const handleSubmit = (e) => {
    e.preventDefault();
    let regex = /[\.\-a-z0-9]+@[a-z]+/gm;
    if (!data.upi_id || !regex.test(data.upi_id))
      message.error("Invalid UPI ID");
    else {
      dispatch(ManualPaymentUpi(data));
      setData({
        user_id: "",
        name: "",
        mobile: "",
        email: "",
        points: "",
        upi_id: "",
        remarks: "",
      });
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();

    const user_id = { user_id: data.user_id };
    // console.log(user_id, "data");
    dispatch(userDetails(user_id));
  };

  useEffect(() => {
    setData({
      ...data,
      name:
        userDetailsData && userDetailsData[0] && userDetailsData[0].name
          ? userDetailsData[0].name
          : "",
      mobile:
        userDetailsData && userDetailsData[0] && userDetailsData[0].mobile
          ? userDetailsData[0].mobile
          : "",
      email:
        userDetailsData && userDetailsData[0] && userDetailsData[0].email
          ? userDetailsData[0].email
          : "",
    });
  }, [userDetailsData]);

  return (
    <Paper elevation={3}>
      <form
      // onSubmit={handleSubmit}
      >
        <Stack spacing={3} direction="column">
          <Toolbar
            mt={3}
            sx={{
              padding: "1.2rem",
              borderBottom: "2px solid var(--primary-color)",
            }}
          >
            <Typography
              sx={{
                color: "var(--primary-color)",
                fontWeight: "bold",
              }}
              variant="h5"
              id="tableTitle"
            >
              Manual Payments
            </Typography>
          </Toolbar>
          <Table
            size="small"
            sx={{
              width: "auto",
              margin: "8px !important",
              marginTop: "16px !important",
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Enter User ID:</b>
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TextField
                    sx={{ marginRight: "8px" }}
                    size="small"
                    placeholder="Enter User ID here"
                    fullWidth
                    required
                    value={data.user_id}
                    onChange={handleChange("user_id")}
                  />
                  <Button
                    variant="contained"
                    type="submit"
                    size="large"
                    className="d-block mx-auto mt-4 mb-3"
                    onClick={handleSearch}
                  >
                    <strong>Search</strong>
                  </Button>
                </TableCell>
              </TableRow>

              {data && data.name && (
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Name:</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">
                      <b>{data.name}</b>
                    </Typography>
                  </TableCell>
                </TableRow>
              )}

              {data && data.mobile && (
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Mobile:</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">
                      <b>{data.mobile}</b>
                    </Typography>
                  </TableCell>
                </TableRow>
              )}

              {data && data.email && (
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Email:</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">
                      <b>{data.email}</b>
                    </Typography>
                  </TableCell>
                </TableRow>
              )}

              {data && data.mobile && (
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Enter Points (Amount):</b>
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <TextField
                      sx={{ marginRight: "8px" }}
                      size="small"
                      placeholder="Enter Points (Amount) here"
                      fullWidth
                      required
                      value={data.points}
                      onChange={handleChange("points")}
                      inputProps={{
                        type: "number",
                        min: "0",
                        step: "1",
                      }}
                    />
                  </TableCell>
                </TableRow>
              )}

              {data && data.mobile && (
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Enter UPI ID:</b>
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <TextField
                      sx={{ marginRight: "8px" }}
                      size="small"
                      placeholder="Enter UPI ID here"
                      fullWidth
                      required
                      value={data.upi_id}
                      onChange={handleChange("upi_id")}
                      inputProps={{
                        pattern: "^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$",
                        title: "Invalid  Upi Id",
                      }}
                    />
                  </TableCell>
                </TableRow>
              )}

              {data && data.mobile && (
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Enter Remarks:</b>
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <TextareaAutosize
                      minRows={1}
                      maxRows={4}
                      value={data.remarks}
                      onChange={handleChange("remarks")}
                      style={{
                        width: "100%",
                        padding: "8px 12px",
                        fontSize: "1rem",
                      }}
                      placeholder="Text Remarks....."
                    />
                  </TableCell>
                </TableRow>
              )}

              <TableRow>
                <TableCell colSpan={2}>
                  <Button
                    variant="contained"
                    type="submit"
                    size="large"
                    className="d-block mx-auto mt-4 mb-3"
                    onClick={handleSubmit}
                  >
                    <strong>Submit</strong>
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </form>
    </Paper>
  );
};

export default ManualPayments;
