import React, { useState, useEffect } from "react";
import { AutoComplete, Modal, Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import DescriptionModal from "./descriptionModal";
import {
  activeTaskData,
  addPoints,
  getActivityCodeData,
  updatePointerPoster,
} from "../../../../redux/slices/pointerPosterSlice";
import { getStoreData } from "../../../../redux/slices/manageStoreSlice";

const AddPointMaster = () => {
  const dispatch = useDispatch();
  const { pointsMasterDataRow, getActivityTaskData, activityCodeData } =
    useSelector((s) => s.pointerPoster);
  const [isUpdatedData, setUpdatedData] = useState(false);
  const { storedata } = useSelector((s) => s.manageStores);
  const [pointMasterData, setPointMasterData] = useState({
    id: pointsMasterDataRow ? pointsMasterDataRow.id : "",
    store_id: pointsMasterDataRow ? pointsMasterDataRow.store_id : "",
    store_name: pointsMasterDataRow ? pointsMasterDataRow.store_name : "",
    task_name: pointsMasterDataRow ? pointsMasterDataRow.task_name : "",
    task_id: pointsMasterDataRow ? pointsMasterDataRow.task_id : "",
    user_share_per: pointsMasterDataRow
      ? pointsMasterDataRow.user_share_per
      : null,
    user_share_flat: pointsMasterDataRow
      ? pointsMasterDataRow.user_share_flat
      : null,
    user_share_max: pointsMasterDataRow
      ? pointsMasterDataRow.user_share_max
      : null,
    user_share_min: pointsMasterDataRow
      ? pointsMasterDataRow.user_share_min
      : null,
    activity_name: pointsMasterDataRow
      ? pointsMasterDataRow.activity_code_name
      : "",
    activity_code: pointsMasterDataRow ? pointsMasterDataRow.activity_code : "",
    allow_api_posting: pointsMasterDataRow
      ? pointsMasterDataRow.allow_api_posting
      : 1,
  });

  function convertStringToArrayOfObjects(string) {
    try {
      const array = JSON.parse(string);
      return array;
    } catch (error) {
      console.error("Invalid string format.");
      return [];
    }
  }

  const [descriptionData, setDescriptionData] = useState({
    cb_rate: "",
    description: "",
  });

  const [isDisabled, setIsDisabled] = useState(false);
  const [description_array, setDescription_array] = useState(
    pointsMasterDataRow
      ? convertStringToArrayOfObjects(pointsMasterDataRow.description)
      : []
  );
  const [isVisible, setVisible] = useState(false);
  const [storeInfo, setStoreInfo] = useState([]);
  // const [taskInfo,setTaskInfo]=useState([]);
  const [actictyCode, setActivityCode] = useState([]);

  const [user_share, setUserShare] = useState({
    is_user_share_per: false,
    is_user_share_flat: false,
  });
  const [store_task, setStore_task] = useState({
    is_store_id: false,
    is_task_id: false,
  });

  useEffect(() => {
    dispatch(getActivityCodeData());
    dispatch(activeTaskData());
    dispatch(getStoreData());
    let stores = JSON.parse(localStorage.getItem("stores"));
    if (stores) {
      setStoreInfo(stores);
    }
    setActivityCode(dispatch(getActivityCodeData()));
  }, []);

  useEffect(() => {}, [pointsMasterDataRow]);

  useEffect(() => {
    if (pointsMasterDataRow.length != 0) {
      setUpdatedData(true);
      setIsDisabled(!isDisabled);
      setPointMasterData({
        ...pointMasterData,
        id: pointsMasterDataRow.id,
        store_id: pointsMasterDataRow.store_id,
        store_name: pointsMasterDataRow.store_name,
        activity_code: pointsMasterDataRow.activity_code,
        activity_name: pointsMasterDataRow.activity_code_name,
        user_share_per: pointsMasterDataRow.user_share_per,
        user_share_flat: pointsMasterDataRow.user_share_flat,
        user_share_max: pointsMasterDataRow.user_share_max,
        user_share_min: pointsMasterDataRow.user_share_min,
        store_name: pointsMasterDataRow.store_name,
        task_id: pointsMasterDataRow.task_id,
        task_name: pointsMasterDataRow.task_name,
        allow_api_posting: pointsMasterDataRow.allow_api_posting,
      });
      if (pointsMasterDataRow.description)
        setDescription_array(JSON.parse(pointsMasterDataRow.description));
      // setDescriptionData({
      //     ...descriptionData, cb_rate: "",
      //     description: "",
      // })
    }
  }, []);

  const changeHandler = (e) => {
    setPointMasterData({ ...pointMasterData, [e.target.name]: e.target.value });
    // if(!pointMasterData.user_share_per || pointMasterData.user_share_per == "") pointMasterData.user_share_per = null;
    // if(!pointMasterData.user_share_flat || pointMasterData.user_share_flat == "") pointMasterData.user_share_flat = null;
    // if(!pointMasterData.user_share_max || pointMasterData.user_share_max == "") pointMasterData.user_share_max = null;
    // if(!pointMasterData.user_share_min || pointMasterData.user_share_min == "") pointMasterData.user_share_min = null;

    if (e.target.name == "user_share_per") {
      if (e.target.value) {
        setUserShare({ ...user_share, is_user_share_per: true });
      } else {
        setUserShare({ ...user_share, is_user_share_per: false });
      }
    } else if (e.target.name == "user_share_flat") {
      if (e.target.value == "") {
        setUserShare({ ...user_share, is_user_flat_per: false });
      } else {
        setUserShare({ ...user_share, is_user_flat_per: true });
      }
    }
    if (e.target.value == "") {
      setPointMasterData({ ...pointMasterData, [e.target.name]: null });
    }
  };

  const handleCondition = (e) => {
    e.preventDefault();
    setVisible(!isVisible);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOnChange = (e) => {
    if (storedata.length > 0) {
      for (var i = 0; i < storedata.length; i++) {
        if (e == storedata[i].store_name) {
          return;
        }
      }
      setPointMasterData({ ...pointMasterData, store_name: "", store_id: "" });
      setStore_task({ ...store_task, is_store_id: false });
    }
  };
  const handleOnChange2 = (e) => {
    if (getActivityTaskData.length > 0) {
      for (var i = 0; i < getActivityTaskData.length; i++) {
        if (e == getActivityTaskData[i].task_short_name) {
          return;
        }
      }
      setPointMasterData({ ...pointMasterData, task_name: "", task_id: "" });
      setStore_task({ ...store_task, is_task_id: false });
    }
  };
  const handleAutoComplete = (e) => {
    setPointMasterData({ ...pointMasterData, store_name: e });
    if (storedata.length > 0) {
      for (var i = 0; i < storedata.length; i++) {
        if (e == storedata[i].store_name) {
          setPointMasterData({
            ...pointMasterData,
            store_id: storedata[i].store_id,
            store_name: storedata[i].store_name,
          });

          if (e) {
            setStore_task({ ...store_task, is_store_id: true });
          } else {
            setStore_task({ ...store_task, is_store_id: false });
          }
        }
      }
    }
  };
  const handleAutoComplete1 = (e) => {
    let value = e.split("~");
    let activity_id = value[0];
    let master_activity = value[1];
    setPointMasterData({
      ...pointMasterData,
      activity_name: master_activity,
      activity_code: activity_id,
    });
    if (activityCodeData.length > 0) {
      for (var i = 0; i < activityCodeData.length; i++) {
        if (activity_id == activityCodeData[i].id) {
          setPointMasterData({
            ...pointMasterData,
            activity_code: activityCodeData[i].id,
            activity_name: activityCodeData[i].master_activity,
          });
        }
      }
    }
  };
  const handleAutoComplete2 = (e) => {
    setPointMasterData({ ...pointMasterData, task_name: e });
    if (getActivityTaskData.length > 0) {
      for (var i = 0; i < getActivityTaskData.length; i++) {
        if (e == getActivityTaskData[i].task_short_name) {
          setPointMasterData({
            ...pointMasterData,
            task_id: getActivityTaskData[i].task_id,
            task_name: getActivityTaskData[i].task_short_name,
          });
          if (e) {
            setStore_task({ ...store_task, is_task_id: true });
          } else {
            setStore_task({ ...store_task, is_task_id: false });
          }
        }
      }
    }
  };
  const submitForm = (e) => {
    e.preventDefault();
    let data = {};
    data.id = pointMasterData.id ? pointMasterData.id : "";
    data.store_id = pointMasterData.store_id ? pointMasterData.store_id : "";
    data.task_id = pointMasterData.task_id ? pointMasterData.task_id : "";
    // if (!pointMasterData.user_share_per || pointMasterData.user_share_per === "") data.user_share_per = null;
    data.user_share_per = pointMasterData.user_share_per
      ? pointMasterData.user_share_per
      : null;
    // if (!pointMasterData.user_share_flat || pointMasterData.user_share_flat === "") data.user_share_flat = null;
    data.user_share_flat = pointMasterData.user_share_flat
      ? pointMasterData.user_share_flat
      : null;
    // if (!pointMasterData.user_share_max || pointMasterData.user_share_max === "") data.user_share_max = null;
    data.user_share_max = pointMasterData.user_share_max
      ? pointMasterData.user_share_max
      : null;
    // if (!pointMasterData.user_share_min || pointMasterData.user_share_min === "") data.user_share_min = null;
    data.user_share_min = pointMasterData.user_share_min
      ? pointMasterData.user_share_min
      : null;
    data.activity_code = pointMasterData.activity_code
      ? pointMasterData.activity_code
      : "";
    data.activity_name = pointMasterData.activity_name
      ? pointMasterData.activity_name
      : "";
    data.description = description_array ? description_array : [];
    data.allow_api_posting = pointMasterData.allow_api_posting;
    data.type = "point_master";
    if (isUpdatedData) {
      dispatch(updatePointerPoster(data));
    } else {
      dispatch(addPoints(data));
    }
  };

  const handleRadio = (e) => {
    setPointMasterData({
      ...pointMasterData,
      allow_api_posting: e.target.value,
    });
  };

  return (
    <>
      <Modal
        title="Basic Modal"
        visible={isVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
      >
        <DescriptionModal
          descriptionData={descriptionData}
          description_array={description_array}
          setDescription_array={setDescription_array}
          setDescriptionData={setDescriptionData}
        />
      </Modal>
      <h2 className="my-4">Add Point Master</h2>
      <form onSubmit={submitForm}>
        <div className="row">
          <div className="col-6 col-lg-6">
            <div className="mb-3">
              <label className="form-label">
                Store Name
                <span style={{ color: "blue", fontSize: "25px" }}>
                  {" "}
                  ({pointMasterData.store_name})
                </span>
              </label>
              <AutoComplete
                className="auto-complete"
                id="store_comp"
                dataSource={
                  storedata && storedata.map((item, index) => item.store_name)
                }
                onSelect={handleAutoComplete}
                onChange={handleOnChange}
                // disabled={isDisabled}
                disabled={store_task.is_task_id}
                filterOption={(inputValue, option) =>
                  option.props.children
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </div>
          </div>
          <div className="col-6 col-lg-6">
            <div className="mb-3">
              <label className="form-label">
                Task Name
                <span style={{ color: "blue", fontSize: "25px" }}>
                  {" "}
                  ({pointMasterData.task_name})
                </span>
              </label>
              <AutoComplete
                className="auto-complete"
                dataSource={
                  getActivityTaskData &&
                  getActivityTaskData.map((item, index) => item.task_short_name)
                }
                onSelect={handleAutoComplete2}
                onChange={handleOnChange2}
                disabled={store_task.is_store_id}
                filterOption={(inputValue, option) =>
                  option.props.children
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </div>
          </div>
          <div className="col-6 col-lg-6">
            <div className="mb-3">
              <label className="form-label">
                Activity Code Data
                <span style={{ color: "blue", fontSize: "25px" }}>
                  {" "}
                  ({pointMasterData.activity_name})
                </span>
              </label>
              <AutoComplete
                className="auto-complete"
                disabled={false}
                // value={pointMasterData.activity_name}
                dataSource={
                  activityCodeData &&
                  activityCodeData.map(
                    (item, index) =>
                      `${item.id}~${item.master_activity}~${item.sub_activity}`
                  )
                }
                // disabled={isDisabled}
                onSelect={handleAutoComplete1}
                filterOption={(inputValue, option) =>
                  option.props.children
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </div>
          </div>

          <div className="col-6 col-lg-6">
            <div className="mb-3">
              <label className="form-label">User Share Per</label>
              <input
                type="number"
                className="form-control"
                name="user_share_per"
                onChange={changeHandler}
                disabled={user_share.is_user_flat_per}
                id="input1"
                value={pointMasterData.user_share_per}
                required
                onKeyDown={(e) => {
                  if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                    e.preventDefault(); // prevent the default behavior of changing the input value
                  }
                }}
              />
            </div>
          </div>
          <div className="col-6 col-lg-6">
            <div className="mb-3">
              <label className="form-label">User Share Flat</label>
              <input
                type="number"
                className="form-control"
                name="user_share_flat"
                onChange={changeHandler}
                disabled={user_share.is_user_share_per}
                id="input2"
                value={pointMasterData.user_share_flat}
                onKeyDown={(e) => {
                  if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                    e.preventDefault(); // prevent the default behavior of changing the input value
                  }
                }}
              />
            </div>
          </div>

          <div className="col-6 col-lg-6">
            <div className="mb-3">
              <label className="form-label">User Share Max</label>
              <input
                type="number"
                className="form-control"
                name="user_share_max"
                onChange={changeHandler}
                value={pointMasterData.user_share_max}
                onKeyDown={(e) => {
                  if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                    e.preventDefault(); // prevent the default behavior of changing the input value
                  }
                }}
              />
            </div>
          </div>
          <div className="col-6 col-lg-6">
            <div className="mb-3">
              <label className="form-label">User Share Min</label>
              <input
                type="number"
                className="form-control"
                name="user_share_min"
                onChange={changeHandler}
                value={pointMasterData.user_share_min}
                onKeyDown={(e) => {
                  if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                    e.preventDefault(); // prevent the default behavior of changing the input value
                  }
                }}
              />
            </div>
          </div>

          <div className="col-6 col-lg-6">
            <div className="mb-3">
              <button
                type="submit"
                className="btn btn-primary form-control"
                style={{ marginTop: "30px" }}
                onClick={handleCondition}
              >
                Description
              </button>
            </div>
          </div>

          <div className="mb-3 ml-3">
            <label style={{ display: "flex" }}>Allow API Posting</label>
            <Radio.Group
              name="allow_api_posting"
              value={pointMasterData.allow_api_posting}
            >
              <Radio
                value={1}
                type="radio"
                onChange={handleRadio}
                checked={pointMasterData.allow_api_posting == 1 ? true : false}
              >
                Allowed
              </Radio>
              <Radio
                value={0}
                type="radio"
                onChange={handleRadio}
                checked={pointMasterData.allow_api_posting == 0 ? true : false}
              >
                Not Allowed
              </Radio>
            </Radio.Group>
          </div>
          <div className="col-12 col-lg-12 my-3 text-center">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddPointMaster;
