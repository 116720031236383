import React, { useEffect, useState } from "react";
import {
  Paper,
  Stack,
  Typography,
  Toolbar,
  Box,
  Card,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { deleteTodoTaskRemind, showRecurrenceTask, gotUpdateTaskData } from "../../redux/slices/taskReminderSlice";

const StyledBox = styled((props) => <Box {...props} />)(({ theme }) => ({
  padding: "2px 8px",
  borderRadius: "5px",
  height: "fit-content",
  "& *": {
    display: "inline-block",
  },
  "& .MuiTypography-root:first-of-type": {
    color: "var(--primary-color)",
    opacity: "0.8",
  },
  "& .MuiTypography-root:nth-of-type(2)": {
    color: "var(--bs-gray-700)",
  },
}));

function dateConverter(str) {
  // console.log(str, "str")
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  let hours = ("0" + date.getHours()).slice(-2);
  let minutes = ("0" + date.getMinutes()).slice(-2);
  let seconds = ("0" + date.getSeconds()).slice(-2);
  let year = date.getFullYear();
  return `${year}-${mnth}-${day}`;
}

const RecurrenceTaskUser = (props) => {
  const dispatch = useDispatch();
  const { recurrence_assignUsers, recurrenceData } = useSelector((s) => s.taskReminder);
  const { changeTab } = props;
  // Dialog input
  const [dialogOpen, setDialogOpen] = useState(false);

  //state for dialog open
  const [item, setItem] = useState({});

  const handleOpen = async (val) => {
    setItem(val);
    let data = {};
    data.assigned_to = JSON.parse(val.assigned_to);
    await dispatch(showRecurrenceTask(data));
    setDialogOpen(true);
    // console.log(state, "statein onclick");
    console.log(data, "data");
  };

  const handleClose = () => {
    setItem({});
    setDialogOpen(false);
  };

  const cbFunc = () => {
    dispatch(showRecurrenceTask());
  };
  const handleUpdateTask = async (val) => {
    await dispatch(gotUpdateTaskData(val));
    changeTab("addTodoReminder");
  };
  const handleDeleteTask = async (val) => {
    // console.log(val, "aval");
    let data = {};
    data.id = val.id;
    data.recurrence = val.recurrence;
    await dispatch(deleteTodoTaskRemind(data));
    cbFunc();
  };

  useEffect(() => {
    dispatch(showRecurrenceTask());
  }, []);
  // console.log(recurrence_assignUsers, "recurrence_assignUsers");
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "1%",
        marginTop: "30px",
      }}
    >
      {/*====================================== current task==================== */}
      <Paper elevation={3} sx={{ width: "100%" }}>
        <Stack spacing={3} direction="row">
          <Toolbar
            mt={3}
            sx={{
              padding: "1.2rem",
              borderBottom: "2px solid var(--primary-color)",
            }}
          >
            <Typography
              sx={{
                flex: "1 1 100%",
                color: "var(--primary-color)",
                fontWeight: "bold",
              }}
              variant="h5"
              id="tableTitle"
            >
              Show Recurrence Assigned Tasks By admin For User
            </Typography>
          </Toolbar>
        </Stack>
        <Box
          p={4}
          pt={1}
          className="d-grid"
          sx={{
            gridTemplateColumns: "repeat(auto-fill,minmax(300px,1fr))",
            gap: "24px",
          }}
        >
          {recurrenceData &&
            recurrenceData.map((item) => {
              return (
                <Card
                  sx={{
                    minWidth: "fit-content",
                    border: "2px solid var(--bs-border-color)",
                  }}
                  onClick={() => handleOpen(item)}
                >
                  <CardContent>
                    <Stack spacing={1}>
                      <StyledBox>
                        <Typography>
                          <b>Title :&nbsp;</b>
                        </Typography>
                        <Typography textTransform="capitalize">
                          <b>{item.title && item.title}</b>
                        </Typography>
                      </StyledBox>
                      <StyledBox>
                        <Typography>
                          <b>Description :&nbsp;</b>
                        </Typography>
                        <Typography>
                          <b>{item.description && item.description}</b>
                        </Typography>
                      </StyledBox>
                      <StyledBox>
                        <Typography>
                          <b>Deadline Days :&nbsp;</b>
                        </Typography>
                        <Typography>
                          <b>{item.deadline_days && item.deadline_days}</b>
                        </Typography>
                      </StyledBox>

                      <Stack spacing={3} direction={"row"}>
                        <Button
                          variant="contained"
                          onClick={() => handleUpdateTask(item)}
                          color="info"
                        >
                          <strong>Edit Task</strong>
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => handleDeleteTask(item)}
                          color="info"
                        >
                          <strong>Delete Task</strong>
                        </Button>
                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
              );
            })}
        </Box>
      </Paper>

      {/* //===============================Dialog Box========================== */}
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>
          <b>Dialog Box User</b>
        </DialogTitle>
        <Divider sx={{ borderColor: "black" }} />
        <DialogContent>
          <Card
            sx={{
              minWidth: "fit-content",
              border: "2px solid var(--bs-border-color)",
            }}
          >
            <CardContent>
              <Stack spacing={1}>
                <StyledBox>
                  <Typography>
                    <b>Assinged By :&nbsp;</b>
                  </Typography>
                  <Typography textTransform="capitalize">
                    <b>
                      {item.name && item.name} (
                      {item.assigned_by && item.assigned_by})
                    </b>
                  </Typography>
                </StyledBox>
                {recurrence_assignUsers &&
                  recurrence_assignUsers.length > 0 && (
                    <StyledBox>
                      <Typography>
                        <b>Assinged To :&nbsp;</b>
                      </Typography>
                      {recurrence_assignUsers.map((item_user) => {
                        return (
                          <>
                            <Typography textTransform="capitalize">
                              <b>
                                {item_user.name && item_user.name} (
                                {item_user.user_id && item_user.user_id})
                              </b>
                            </Typography>
                          </>
                        );
                      })}
                    </StyledBox>
                  )}
                <StyledBox>
                  <Typography>
                    <b>Title :&nbsp;</b>
                  </Typography>
                  <Typography textTransform="capitalize">
                    <b>{item.title && item.title}</b>
                  </Typography>
                </StyledBox>
                <StyledBox>
                  <Typography>
                    <b>ID :&nbsp;</b>
                  </Typography>
                  <Typography>
                    <b>{item.id && item.id}</b>
                  </Typography>
                </StyledBox>
                <StyledBox>
                  <Typography>
                    <b>Description :&nbsp;</b>
                  </Typography>
                  <Typography>
                    <b>{item.description && item.description}</b>
                  </Typography>
                </StyledBox>
                <StyledBox>
                  <Typography>
                    <b>Deadline Days :&nbsp;</b>
                  </Typography>
                  <Typography>
                    <b>{item.deadline_days && item.deadline_days}</b>
                  </Typography>
                </StyledBox>
              </Stack>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RecurrenceTaskUser;
