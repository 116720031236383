import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducers";
import initialState from "./initialState";
import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "../redux/slices/authSlice";
import LayoutReducer from "../redux/slices/layoutSlice";
import ProfileReducer from "../redux/slices/profileSlice";
import TestimonialsReducer from "../redux/slices/testimonialSlice";
import EmailReducer from "../redux/slices/emailSlice";
import ContactedUserReducer from "../redux/slices/contactedUserSlice";
import crmReducer from "../redux/slices/crmSlice";
import GalleryReducer from "../redux/slices/gallerySlice";
import DashboardReducer from "../redux/slices/dashboardSlice";
import NewsReducer from "../redux/slices/newsSlice";
import UserRoleManagementReducer from "../redux/slices/userRoleManagementSlice";
import UserDetailsReducer from "../redux/slices/userDetailsSlice";
import LiveBannerReducer from "../redux/slices/liveBannerSlice";
import LiveOffersReducer from "../redux/slices/liveOfferSlice";
import UploadImageReducer from "../redux/slices/uploadImagesSlice";
import ActivitiesReducer from "../redux/slices/activitiesSlice";
import manageStoreReducer from "../redux/slices/manageStoreSlice";
import AffiliateReducer from "../redux/slices/affiliateSlice";
import PointerPosterReducer from "../redux/slices/pointerPosterSlice";
import ManualBonusPointsReducer from "../redux/slices/manualBonusPointsSlice";
import RedemptionReducer from "../redux/slices/redemptionSlice";
import HeaderFooterReducer from "../redux/slices/headerFooterSlice";
import TaskReminderReducer from "../redux/slices/taskReminderSlice";
import PaymentStatusReducer from "../redux/slices/paymentStatusSlice"
import DealsReducer from '../redux/slices/dealsSlice';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(
//   rootReducer,
//   initialState,
//   composeEnhancers(applyMiddleware(thunk))
// );

// export default store;

const store = configureStore({
  reducer : {
    auth : AuthReducer,
    layout : LayoutReducer,
    profile : ProfileReducer,
    testimonails : TestimonialsReducer,
    email : EmailReducer,
    contactedUser : ContactedUserReducer,
    crm : crmReducer,
    gallery : GalleryReducer,
    dashboard : DashboardReducer,
    news:NewsReducer,
    userRoleManagement : UserRoleManagementReducer,
    userDetails : UserDetailsReducer,
    liveBanner : LiveBannerReducer,
    liveOffer : LiveOffersReducer,
    uploadImage : UploadImageReducer,
    activities : ActivitiesReducer,
    manageStores: manageStoreReducer,
    affiliate : AffiliateReducer,
    pointerPoster : PointerPosterReducer,
    manualBonus : ManualBonusPointsReducer,
    redemption:RedemptionReducer,
    headerFooter : HeaderFooterReducer,
    taskReminder : TaskReminderReducer,
    paymentStatus : PaymentStatusReducer,
    deals: DealsReducer,
  }
})


export default store;