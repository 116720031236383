import React, { useState, useEffect } from "react";
import { Button, AutoComplete, Radio, Checkbox } from "antd";
import Popup from "../../../Repeated/Popup";
import styled from "styled-components";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { createStore, getActivePartners, getStoreUploadImage, updateStoresData } from "../../../../redux/slices/manageStoreSlice";

const ImageMainContainer = styled.div`
  position: absolute;
  top: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 300px;
  overflow: auto;
`;
const Wrapper2 = styled.div`
  width: 100%;
  height: 310px;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;
const ImageContainer = styled.div`
  width: auto;
  display: flex;
  height: 200px;
  flex-direction: row;
  padding: 50px;
  border: ${(props) => (props.active ? "1px solid #ffff00" : "none")};
  justify-content: space-between;
`;

const ExtensionDiv = styled.div`
  display: ${(props) => (props.active ? "flex" : "none")};
`;


const CreateStore = (props) => {
  const dispatch = useDispatch();
  const { storesDataRow, uploadStoreImagesData, activePartnersData } = useSelector((s) => ({ ...s.manageStores }));
  console.log(storesDataRow, "storesDataRow");
  console.log(activePartnersData, "activePartnersData");
  const [errorhandle, setErrorHandle] = useState("");
  const [isUpdatedData, setUpdatedData] = useState(false);
  let [inputFields, setinputFields] = useState([
    {
      subdomain: "",
      cart_page: "",
      match_pattern: "",
    },
  ]);

  const [imageId, setImageId] = useState({
    id: "",
    url: "",
  });
  // const [activePartnersData, setActivePartnersData] = useState({
  //     partner_name: "",
  //     partner_id: ""
  // });
  const [checkedArray, setCheckedArray] = useState([]);
  const [displayprop, setDisplay] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [storeData, setStoreData] = useState({
    store_id: "",
    store_name: "",
    store_url: "",
    store_page_url: "",
    partner_id: "",
    active_partner: "",
    image: "",
    cashback_upto: "",
  });

  /////////////========================extension handler==========================??????????????//////////////////////////

  const [isChecked, setIsChecked] = useState(true);
  const [extStoreData, setExtStoreData] = useState({
    id: "",
    domain_name: "",
  });

  const changeExtensionHandler = (index, e) => {
    inputFields[index][e.target.name] = e.target.value;
    setinputFields([...inputFields]);
  };
  const chandleHandler2 = (e) => {
    setExtStoreData({ ...extStoreData, [e.target.name]: e.target.value });
  };

  const handleExtensionChecked = (e) => {
    setIsChecked(!isChecked);
  };

  /////////////======================end of extension handler===============================//////////////////////////////////

  const imageHandler = (img) => {
    setImageId({
      id: img.id,
      url: img.url,
    });
    setIsOpen(!isOpen);
  };
  const changeHandler = (e) => {
    setStoreData({ ...storeData, [e.target.name]: e.target.value });
  };
  const cbFunc = (res, code) => {
    if (code == 200) {
      props.setDisplayType();
    } else {
      setErrorHandle(res.error.sqlMessage);
    }
  };
  const handleAutoComplete = (e) => {
    setStoreData({ ...storeData, active_partner: e });
    if (activePartnersData.length > 0) {
      for (var i = 0; i < activePartnersData.length; i++) {
        if (e == activePartnersData[i].partner) {
          setStoreData({
            ...storeData,
            partner_id: activePartnersData[i].id,
            active_partner: activePartnersData[i].partner,
          });
        }
      }
    }
  };

  const handleAdd = (item) => {
    if (checkedArray.indexOf(item) == -1) {
      setCheckedArray([...checkedArray, item]);
    }
  };

  const handleRemove = (item) => {
    if (checkedArray.indexOf(item) != -1) {
      const newArr = [...checkedArray];
      let index = newArr.indexOf(item);
      newArr.splice(index, 1);
      setCheckedArray(newArr);
    }
  };
  const handleChecked = (e) => {
    let checked = e.target.checked;
    const value = e.target.value;
    if (checked) {
      handleAdd(value);
    } else {
      handleRemove(value);
    }
  };
  const handleAddFields = () => {
    setinputFields([
      ...inputFields,
      { subdomain: "", cart_page: "", match_pattern: "" },
    ]);
  };
  const handleRemoveFields = () => {
    const values = [...inputFields];
    if (values.length < 2) return;
    values.pop();
    setinputFields(values);
  };
  const submitForm = (e) => {
    e.preventDefault();
    let data = {};
    data.store_id = storeData.store_id;
    data.store_name = storeData.store_name;
    data.store_url = storeData.store_url;
    data.store_page_url = storeData.store_page_url;
    data.image = imageId.id;
    data.active_partner = storeData.partner_id;
    // data.partner_name = activePartnersData.name;
    data.cashback_upto = storeData.cashback_upto;
    data.site_table_content = JSON.stringify(checkedArray);

    if (isChecked) {
      data.extdom_id = extStoreData.id;
      data.domain_name = extStoreData.domain_name;
      data.extdomArray = inputFields;
      data.isChecked = isChecked;
    }
    if (isUpdatedData) {
      dispatch(updateStoresData(data));
    } else {
      dispatch(createStore(data));
      cbFunc();
    }
  };
  useEffect(() => {
    let data_type = {};
    data_type.type = "Store";
    dispatch(getStoreUploadImage(data_type));
    if (storesDataRow.store_id) {
      setUpdatedData(!isUpdatedData);
      setDisplay(false)
      setIsDisable(!isDisable);
      setStoreData({
        ...storeData,
        store_id: storesDataRow.store_id,
        store_name: storesDataRow.store_name,
        store_url: storesDataRow.store_url,
        store_page_url: storesDataRow.store_page_url,
        partner_id: storesDataRow.partner_id,
        active_partner: storesDataRow.active_partner,
        image: storesDataRow.image,
        cashback_upto: storesDataRow.cashback_upto,
      });
      setImageId({
        id: storesDataRow.img_id,
        url: storesDataRow.image,
      });
      setExtStoreData({
        ...extStoreData,
        // id: storesDataRow.id,
        domain_name: storesDataRow.domain_name,
      })

      // setCheckedArray(JSON.parse(storesDataRow.site_table_content))
      let data = {};
      data.store_id = storesDataRow.store_id;
      dispatch(getActivePartners(data));
    }
  }, []);

  const handleReload = (e) => {
    e.preventDefault()
    let data_type = {};
    data_type.type = "Store";
    dispatch(getStoreUploadImage(data_type));
  };

  return (
    <>
      <form onSubmit={submitForm}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-6 col-md-6">
              <div className="mb-3">
                <label for="" className="form-label">
                  Store Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="store_name"
                  onChange={changeHandler}
                  value={storeData.store_name}
                  required={true}
                  disabled={isDisable}
                />
              </div>
              <p>{errorhandle}</p>

              <p>
                e.g---<span style={{ color: "blue" }}>flipkart</span>
              </p>
              <div className="mb-3">
                <label for="" className="form-label">
                  Store URL
                </label>
                <input
                  type="url"
                  className="form-control"
                  name="store_url"
                  value={storeData.store_url}
                  onChange={changeHandler}
                  required={true}
                />
              </div>
              <p>{errorhandle}</p>
              <p>
                e.g---
                <span style={{ color: "blue" }}>https://www.flipkart.com/</span>
              </p>
              <div className="mb-3">
                <label for="" className="form-label">
                  Store Page Url
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="store_page_url"
                  value={storeData.store_page_url}
                  onChange={changeHandler}
                  required={true}
                />
              </div>
              <p>{errorhandle}</p>
              <p>
                e.g----<span style={{ color: "blue" }}>flipkart</span>
              </p>
              <div className="mb-3">
                <label for="" className="form-label">
                  Cashback_Upto
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="cashback_upto"
                  value={storeData.cashback_upto}
                  onChange={changeHandler}
                  required={true}
                  maxLength="30"
                />
              </div>
              <p>{errorhandle}</p>
              <p>
                e.g----<span style={{ color: "blue" }}>Get 6.6%off</span>
              </p>


              <div className="mb-3">
                <label style={{ display: "flex" }}>Allow API Posting</label>
                <Checkbox
                  onChange={handleChecked}
                  value={"website"}
                  name={"website"}
                >
                  Website
                </Checkbox>
                <Checkbox
                  onChange={handleChecked}
                  value={"mob_website"}
                  name={"mob_website"}
                >
                  Mobile Website
                </Checkbox>
                <Checkbox onChange={handleChecked} value={"app"} name={"app"}>
                  App
                </Checkbox>
              </div>
              <div className="mb-3">
                <label for="" className="form-label">
                  Image
                </label>
                <img
                  src={`${imageId.url}`}
                  // src={storeData.image}
                  // value={imageId.url}
                  value={storeData.image}
                  alt="image selected"
                  style={{
                    width: "70px",
                    position: "absolute",
                    right: "0",
                    margin: " 20px 0 10px",
                  }}
                />
                <Button
                  size="large"
                  type="primary"
                  name="image"
                  className="form-control mt-5"
                  onClick={(e) => {
                    setIsOpen(!isOpen);
                  }}
                >
                  Choose Image
                </Button>
                {isOpen && (
                  <Popup
                    content={
                      <>
                        <b style={{padding:'10px'}}>Store Images</b>
                        <button onClick={handleReload}>Reload Images</button>
                        <p>
                          <Wrapper2>
                            <ImageMainContainer>
                              {uploadStoreImagesData &&
                                uploadStoreImagesData.map(
                                  (item, index) => (
                                    <ImageContainer>
                                      <img
                                        src={`${item.url}`}
                                        onClick={() => {
                                          imageHandler(item);
                                        }}
                                        alt={`image${index}`}
                                      />
                                      <br />
                                    </ImageContainer>
                                  )
                                )}
                            </ImageMainContainer>
                          </Wrapper2>
                        </p>
                      </>
                    }
                    handleClose={() => {
                      setIsOpen(!isOpen);
                    }}
                  />
                )}
              </div>
              <div className="mb-3">
                <label for="" className="form-label">
                  Active Partner
                </label>
                <p>{storeData.active_partner}</p>
                <AutoComplete
                  // style={{ width: "50% !important" }}
                  // value={storeData.store_name}
                  // value={storeData.active_partner}
                  className="auto-complete"
                  dataSource={
                    activePartnersData &&
                    activePartnersData.map((item, index) => item.partner)
                  }
                  onSelect={handleAutoComplete}
                // filterOption={(inputValue, option) =>
                //     option.props.children
                //         .toUpperCase()
                //         .indexOf(inputValue.toUpperCase()) !== -1
                // }
                />
              </div>
              {displayprop &&
                <div className="mb-3">
                  <label style={{ display: "flex" }}>Exclude extension</label>
                  <Checkbox
                    onChange={handleExtensionChecked}
                    value={isChecked}
                    name={"ExcludeDomain"}
                  >
                    exclude domain
                  </Checkbox>
                </div>
              }

              {/*========================================= extension work=========================== */}

              {isChecked && (
                <>
                  <ExtensionDiv active={displayprop}>
                    <div className="mb-3">
                      <label for="" className="form-label">
                        Domain Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="domain_name"
                        onChange={chandleHandler2}
                        value={extStoreData.domain_name}
                        required={displayprop}
                      />
                    </div>
                    <div className="container-fluid">
                      <div className="row">
                        {inputFields.map((item, index) => (
                          <>
                            <div className="col-md-4">
                              <div className="mb-3">
                                <label
                                  for=""
                                  className="form-label"
                                  style={{ fontSize: "14px" }}
                                >
                                  SubDomain
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="subdomain"
                                  value={item.subdomain}
                                  onChange={(event) =>
                                    changeExtensionHandler(index, event)
                                  }
                                  required={displayprop}
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="mb-3">
                                <label
                                  for=""
                                  className="form-label"
                                  style={{ fontSize: "14px" }}
                                >
                                  Cart Page
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="cart_page"
                                  value={item.cart_page}
                                  onChange={(event) =>
                                    changeExtensionHandler(index, event)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="mb-3">
                                <label
                                  for=""
                                  className="form-label"
                                  style={{ fontSize: "14px" }}
                                >
                                  Match Pattern
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="match_pattern"
                                  value={item.match_pattern}
                                  onChange={(event) =>
                                    changeExtensionHandler(index, event)
                                  }
                                />
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                      <IconButton onClick={handleAddFields}>
                        <AddIcon />
                      </IconButton>
                      <IconButton
                        disabled={inputFields.length === 1}
                        onClick={() => handleRemoveFields()}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </div>
                  </ExtensionDiv>
                </>
              )}
            </div>

            <div className="col-12 col-lg-12 my-3 ">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default CreateStore;
