import React, { useState, useEffect } from "react";
import { AutoComplete } from "antd";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import {
  addDeeplinkData,
  getStoreData,
  showDeeplinkMapData,
  updateDeeplinkData,
} from "../../../../redux/slices/manageStoreSlice";
import { message } from "antd";

import { Button, Stack, Chip } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const AddDeepLink = () => {
  const dispatch = useDispatch();

  const { deeplinkDataRow, storedata, deeplinkMapData } = useSelector(
    (s) => s.manageStores
  );

  const data = useSelector((s) => s.manageStores);
  const [isUpdatedData, setUpdatedData] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [storeData, setStoreData] = useState({
    store_name: "",
    store_id: "",
    url: "",
  });
  const [deeplinkData, setDeeplinkData] = useState([]);
  console.log(deeplinkData, "deeplinkData============");
  const changeHandler = (e) => {
    setStoreData({ ...storeData, [e.target.name]: e.target.value });
  };

  const handleAutoComplete = (e) => {
    setStoreData({ ...storeData, storeName: e });
    if (storedata.length > 1) {
      for (var i = 0; i < storedata.length; i++) {
        if (e == storedata[i].store_name) {
          setStoreData({
            ...storeData,
            store_id: storedata[i].store_id,
            store_name: storedata[i].store_name,
          });
          let data = { store_id: storedata[i].store_id };
          dispatch(showDeeplinkMapData(data))
            .then((elm) =>
              setDeeplinkData(
                elm?.payload?.data?.[0]?.url ? elm?.payload?.data?.[0]?.url : []
              )
            )
            .catch((err) => console.log(err, "err"));
        }
      }
    }
  };

  // add button handler
  const handleAddBtn = () => {
    const index = deeplinkData.indexOf(storeData.url);
    // console.log(index);
    if (
      storeData.url === "" ||
      storeData.url === null ||
      storeData.url === undefined
    ) {
      message.error("No Url");
    } else if (index === -1) {
      setDeeplinkData([...deeplinkData, storeData.url]);
      //   setassignToData([...assignToData, assignUser]);
    } else {
      message.error("Url already added");
    }
  };

  // chip delete handler for user
  const handleDelete = (item) => {
    console.log(item, "dfghjkl");
    let data = { store_id: storeData.store_id, url: item };
    dispatch(updateDeeplinkData(data));
    setDeeplinkData(deeplinkData.filter((key) => key !== item));
    // setassignToData(assignToData.filter((key) => key.fp_uid !== item));
  };
  //   console.log(deeplinkMapData, "deeplinkMapData");
  useEffect(() => {
    dispatch(getStoreData());
    if (deeplinkDataRow.url) {
      setUpdatedData(!isUpdatedData);
      setIsDisable(!isDisable);
      setStoreData({
        ...storeData,
        store_id: deeplinkDataRow.store_id,
        store_name: deeplinkDataRow.store_name,
      });
      setDeeplinkData(deeplinkDataRow.url.length ? deeplinkDataRow.url : []);
    }
  }, []);

  const submitForm = (e) => {
    e.preventDefault();
    let data = { store_id: storeData.store_id, url: deeplinkData };
    // if (isUpdatedData) {
    //   dispatch(updateDeeplinkData(data));
    // } else {
    dispatch(addDeeplinkData(data));
    // }
  };

  return (
    <>
      <form onSubmit={submitForm}>
        <div className="row">
          <div className="col-6 col-lg-6">
            <div className="mb-3">
              <label for="" className="form-label">
                Store Name
              </label>
              <h6>{storeData.store_name}</h6>
              <AutoComplete
                // style={{ width: "50% !important" }}
                // value={storeData.store_name}
                required={true}
                className="auto-complete form-control"
                dataSource={
                  storedata && storedata.map((item, index) => item.store_name)
                }
                disabled={isDisable}
                onSelect={handleAutoComplete}
                filterOption={(inputValue, option) =>
                  option.props.children
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </div>

            <p>
              e.g---<span style={{ color: "blue" }}>flipkart</span>
            </p>
            <div className="mb-3">
              {/* <label for="" className="form-label">
                URL
              </label>
              <input
                type="text"
                className="form-control"
                name="url"
                value={deeplinkData.url}
                onChange={changeHandler}
                required={true}
              /> */}
              {/* {deeplinkMapData && deeplinkMapData.length > 0 && (
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={deeplinkMapData.url && deeplinkMapData.url}
                  // getOptionLabel={(option) => option.url}
                  defaultValue={[deeplinkMapData[0].url]}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="filterSelectedOptions"
                      placeholder="Favorites"
                    />
                  )}
                />
              )} */}
              <label for="" className="form-label">
                URL
              </label>
              <div className="d-flex">
                <input
                  type="text"
                  className="form-control"
                  name="url"
                  value={storeData.url}
                  onChange={changeHandler}
                  required={true}
                />{" "}
                <Button
                  variant="contained"
                  color="success"
                  sx={{ margin: "10px" }}
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={handleAddBtn}
                >
                  <strong>Add</strong>
                </Button>
              </div>
            </div>

            {deeplinkData?.length && deeplinkData?.length >= 0 ? (
              <div>
                <Stack spacing={4}>
                  {deeplinkData?.map((item, index) => (
                    <Chip
                      key={`chip-${index}`}
                      label={item}
                      color="warning"
                      sx={{ width: "fit-content" }}
                      size="small"
                      onDelete={() => handleDelete(item)}
                    />
                  ))}
                </Stack>
              </div>
            ) : null}
          </div>
          <div className="col-12 col-lg-12 my-3 ">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </div>
      </form>
      <div className="my-4">
        <ul>
          <li>
            Depplink work on all the sub domains then keep the subDomain blank
            example---: flipkart.com
          </li>
          <li>
            If you want to work it on specific sub domain then enter the
            specific subdomain then enter the full domain name. In case of
            multiple sub domain, then seperated it by comma example------:
            www.flipkart.com,dl.flipkart.com,aff.flipkart.com{" "}
          </li>
          <li>
            In case of path please mention the path as well example------:
            www.puma.com/in or www.puma.com/us or puma.com/in
          </li>
        </ul>
      </div>
    </>
  );
};

export default AddDeepLink;
