import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Typography, Divider, Input, Radio, Icon, message } from "antd";
import AddAdditionalStoreData from "./AddAddtionalStoreData";
import ShowAdditionalStoreData from "./ShowAdditionalStoreData";
const { Title } = Typography;
const Wrapper = styled.div`
  width: 100%;

  > div {
    > div {
      > button {
        background: linear-gradient(
            259deg,
            rgb(236, 94, 58) 0%,
            rgb(249, 186, 99) 100%
          )
          0% 0% no-repeat padding-box padding-box transparent;
        padding: 7px 20px;
        border-radius: 20px;
        float: right;
      }
    }
  }
`;
const BodyContainer = styled.div`
  overflow: auto;
`;
const Container = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const AdditionalStoreData = (props) => {
  const [storeType, setStoreType] = useState("showAdditionalStoreData");
  const changeTab = () => {
    setStoreType("additionalStoreData");
  };
  const changeTab2 = () => {
    setStoreType("showAdditionalStoreData");
  };
  const setMainPage = () => {
    setStoreType("createStore");
  };
  const setDisplayType = () => {
    setStoreType("addDescription");
  };
  return (
    <Wrapper>
      <Title className="my-3" level={3}>
        Additional Store Data{" "}
      </Title>
      <div className="row">
        <div className="col-12 col-lg-12">
          {storeType == "showAdditionalStoreData" && (
            <button onClick={changeTab}>Add Additional Store Data</button>
          )}
          {storeType == "additionalStoreData" && (
            <button onClick={changeTab2}>Show Additional Store Data</button>
          )}
        </div>
      </div>
      <BodyContainer>
        {storeType == "additionalStoreData" && <AddAdditionalStoreData />}
        {storeType == "showAdditionalStoreData" && (
          <ShowAdditionalStoreData setStoreType={setStoreType} />
        )}
      </BodyContainer>
    </Wrapper>
  );
};

export default AdditionalStoreData;
