import axios from "axios";
import AppConstants from "../../constants/appConstants";


const API = axios.create({
    baseURL: `${AppConstants.baseURL}`,
});

///////////////////SHOW TESTIMONIALS////////////////////

export const showTestimonials = () => API.get("/testimonials/showtestimonials");
export const activatedTestimonialsData = (query) => API.get(`/testimonials/activatetestimonials?${query}`);


///////////////////ADD TESTIMONIALS////////////////////

export const getTestimonialUploadImage = () => API.get("/testimonials/gettestimonialuploadimage")
export const updateTestimonialsData = (data) => API.post("/testimonials/updatetestimonials", data);
export const addTestimonials = (data) => API.post("/testimonials/addtestimonials", data);


// gotTestimonialsDataRow sync action 