import ActionTypes from "../constants/actionTypes";
import initialState from "../stores/initialState";

export default (state = initialState.crm, action) => {
  switch (action.type) {
    case ActionTypes.GOT_CASHBACK_CLAIMS_DATA:
      return {
        ...state,
        cashbackClaimsData: action.payload
      };
    case ActionTypes.GOT_CASHBACK_CLAIMS_MASTER_DATA:
      return {
        ...state,
        cashbackClaimsMasterData: action.payload
      };
    case ActionTypes.GOT_INFO_BY_CLICK_ID:
      return {
        ...state,
        infoByClickId: action.payload.data,
        partnerRespByClickId: action.payload.data1,
        getAllDateAffClicksId: action.payload.data2,

      };
    case ActionTypes.GOT_USER_CLICK_INFO:
      return {
        ...state,
        userInfo: action.payload.data,
        userBalanceInfo: action.payload.userBalanceInfo,
        clicksDataInfo: action.payload.clicksDataInfo,
        orderDetailsInfo: action.payload.orderDetailsInfo,
        redemptionInfo: action.payload.redemptionInfo,
        redemptionPaymentInfo: action.payload.redemptionPaymentStatus,
        requestedBalanceInfo: action.payload.requestedBalanceInfo
      };
    case ActionTypes.GOT_CLICKS_BY_DATE:
      return {
        ...state,
        clicksByDate: action.payload.data
      };
    case ActionTypes.GOT_ALL_ORDERS_CRM:
      return {
        ...state,
        allOrdersCrm: action.payload
      }
    case ActionTypes.GOT_HIGHEST_PENDING_PAYOUT:
      return {
        ...state,
        pendingPayout: action.payload.data,
        top10Orders: action.payload.top10Orders
      }

    default:
      return state;
  }
};
