import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { showBrokenLink } from "../../../redux/slices/manageStoreSlice";
import { updateBrokenLink } from "../../../redux/api/manageStoresApi";
const BrokenLinksTable = () => {
  const dispatch = useDispatch();
  const { brokenLinkData } = useSelector((state) => state.manageStores);

  useEffect(() => {
    dispatch(showBrokenLink());
  }, [dispatch]);

  const handleUpdate = async (id) => {
    try {
      console.log("id", id);
      // const formData = new FormData();
      // formData.append("id", id);
      // console.log("formData", formData);
      const response = await updateBrokenLink({ id: id });
      console.log(response, "response");
      // console.log("Link updated successfully:", response);

      // Refresh the broken links data
      if(response.data.code === 200){
        dispatch(showBrokenLink());
      }
     
    } catch (error) {
      console.error("Error updating link:", error);
    }
  };
  const brokenLinks = brokenLinkData.filter((link) => link.is_broken === 1);

  return (
    <div>
      <h2>Broken Links</h2>
      <table>
        <thead>
          <tr>
            <th>Store Name</th>
            <th>Link</th>
            <th>Break Time</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {brokenLinks.map((link) => (
            <tr key={link.id}>
              <td>{link.store_name}</td>
              <td>{link.link}</td>
              <td>{new Date(link.break_time).toLocaleString()}</td>
              <td>
                <button onClick={() => handleUpdate(link.id)}>Resolved</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BrokenLinksTable;
