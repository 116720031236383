import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Popconfirm, Typography } from 'antd';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getStoreSchema, gotStoreSchemaRow } from "../../../../redux/slices/manageStoreSlice";


const ShowStoreSchema = (props) => {
    // let history = useHistory();  //useHistory is being deprecated in react-router v6 or above
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { store_schema_data } = useSelector(s => s.manageStores);


    const confirm = (e, value) => {
        let data = {};
        data.schema_id = value[1];
        data.store_id = value[2];
        data.store_name = value[3];
        data.store_schema = value[4];
        dispatch(gotStoreSchemaRow(data))
        props.setDisplayType();
    }
    const cancel = () => {
        //console.log("Cancelled");
    }

    useEffect(() => {
        dispatch(getStoreSchema());
    }, [])

    const columns = [
        {
            name: "opertaion",
            label: "Operation",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div>
                        <Popconfirm
                            title="Edit this task?"
                            onConfirm={e => confirm(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#">Edit</a>
                        </Popconfirm>
                    </div>
                )
            }
        },
        {
            name: "id",
            label: "Schema ID",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "store_id",
            label: "STore ID",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "store_name",
            label: "Store Name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "store_schema",
            label: "Store Schema",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "active",
            label: "Active",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "created_at",
            label: "Created",
            options: {
                filter: true,
                sort: false,
            }
        }
    ];
    const options = {
        filterType: 'store_name',
    };
    return (
        <Fragment>
            <MUIDataTable
                title={"Show Description (search by store_name)"}
                data={store_schema_data && store_schema_data}
                columns={columns}
                options={options}
            />
        </Fragment>
    );
}
export default ShowStoreSchema;