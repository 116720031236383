import React, { useState } from 'react'
import styled from 'styled-components';
import { Typography, Radio } from "antd";
import AddMainMenu from '../AddMainMenu';
import AddSubMenu from '../AddSubMenu';
import ShowHeaderFooter from '../ShowHeaderFooter';

const { Title } = Typography;
const Wrapper = styled.div`
  width: 100%;
`;
const BodyContainer = styled.div`


`;

const SelectAction = (props) => {
    const [action_type, setActionType] = useState("add_main_menu");
    const handleRadio = (e) => {
        setActionType(e.target.value);
    }

    return (
        <Wrapper className="my-3">
            <Title level={5}>Select Option</Title>
            <div className="row my-3">
                <div className="col-6 col-lg-6 d-flex">
                    {/* <Radio.Group name="action_type"> */}
                    <Radio onChange={handleRadio} value={"add_main_menu"} type="radio" checked={action_type == "add_main_menu"}>Add Main Menu</Radio>
                    <Radio onChange={handleRadio} value={"add_sub_menu"} type="radio" checked={action_type == "add_sub_menu"}>Add Sub Menu</Radio>
                    <Radio onChange={handleRadio} value={"show_Header_Footer"} type="radio" checked={action_type == "show_Header_Footer"}>Show Header Footer</Radio>
                    {/* </Radio.Group> */}
                </div>

            </div>
            <BodyContainer>
                {action_type == "add_main_menu" && <AddMainMenu menu_type={props.menu_type} />}
                {action_type == "add_sub_menu" && <AddSubMenu menu_type={props.menu_type} />}
                {action_type == "show_Header_Footer" && <ShowHeaderFooter />}
            </BodyContainer>
        </Wrapper>
    )
}

export default SelectAction;
