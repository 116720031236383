import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api/redemptionApi";
import { message } from "antd";

const initialState = {
  redeemData: [],
  allowedActivityData: [],
  redeemDataRow: [],
};

export const addRedemption = createAsyncThunk(
  "/redemption/addredeem",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.addRedemption(data);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Added Successfully!');
        // return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Not Added!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const showRedemption = createAsyncThunk(
  "/redemption/showredeem",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.showRedemption();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const getAllowedActivity = createAsyncThunk(
  "/redemption/getallowedactivity",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.getAllowedActivity();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

const redemptionSlice = createSlice({
  name: "redemption",
  initialState,
  reducers: {
    gotRedeemDataRow: (state, action) => {
      state.redeemDataRow = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(showRedemption.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showRedemption.fulfilled, (state, action) => {
      state.redeemData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(showRedemption.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ==============================================================================================
    builder.addCase(getAllowedActivity.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllowedActivity.fulfilled, (state, action) => {
      state.allowedActivityData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getAllowedActivity.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // =============================addRedemption====================================
    builder.addCase(addRedemption.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addRedemption.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(addRedemption.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { gotRedeemDataRow } = redemptionSlice.actions;

export default redemptionSlice.reducer;
