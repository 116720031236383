import React from "react";
import styled from "styled-components";
import {Input} from "antd";
import {SearchOutlined,DeleteFilled} from "@ant-design/icons";
import AppConstants from "../../../constants/appConstants";

const {TextArea}=Input;


const Wrapper=styled.div`
width: 100%;
display: flex;
`;

const LeftContainer=styled.div`
width: 65%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: #eee;
border-right: 1px solid #bbb;
>img{
    width: 70%;
}
`;

const RightContainer=styled.div`
width: 35%;
display: flex;
flex-direction: column;
padding: 10px;
`;

const InputData=styled.div`
width: 100%;
display: flex;
flex-direction: column;
margin-bottom: 10px;
>p{
    font-size: 18px;
    font-weight: bolder;
    margin-bottom: 0px;
}
>input{
    width: 100%;
}
>div{
    border: 1px solid #eee;
    >p{
        font-size: 16px;
    }
}
`;

const CopyButton=styled.div`
width: 50px;
border: 1px solid #aaa;
display: flex;
justify-content: center;
align-items: center;
position: absolute;
right: 35px;
bottom: 105px;
cursor:pointer;
>p{
    font-size: 16px;
    margin-bottom: 0px;
}
`;


const ActionButtonsContainer=styled.div`
width: 100%;
display: flex;
justify-content: flex-end;
`;

const ActionButton=styled.div`
width: 40px;
padding: 5px;
border: 1px solid #bbb;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
cursor: pointer;
border-radius: 5px;
background:${props=>props.active? "#dc3545":"#28a745"};
margin-right: 10px;
>span{
    >svg{
        font-size: 20px;
        color: #fff;
    }
}
`;



const EditImageModal=(props)=>{
    return(
        <Wrapper>
            <LeftContainer>
                <ActionButtonsContainer>
                <ActionButton active={true} onClick={()=>props.handleDelete(props.imageData.url)}>
                <DeleteFilled />
                </ActionButton>
                </ActionButtonsContainer>
                <img src={`${AppConstants.imageURL}${props.imageData && props.imageData.url}`} alt={props.imageData && props.imageData.alt} />
            </LeftContainer>
            <RightContainer>
                <InputData>
                <p>Title</p>
                <Input type="text" name="title" value={props.imageData && props.imageData.title} onChange={props.handleChange}   />
                </InputData>
                <InputData>
                <p>Alt Text</p>
                <Input type="text" name="alt_text" value={props.imageData && props.imageData.alt_text} onChange={props.handleChange} />
                </InputData>
                {/* <InputData>
                <p>Description</p>
                <TextArea  name="description" value={props.imageData && props.imageData.description} onChange={props.handleChange}/>
                </InputData> */}
                <InputData>
                <p>URL</p>
                {/* <Input type="text" value={`${props.url.substring(0,30)}...`}  /> */}
                <div>
                    <p>{`${props.imageData.url.substring(0,30)}...`}</p>
                </div>
                <CopyButton onClick={()=>navigator.clipboard.writeText(props.imageData.url)}>
                    <p>Copy</p>
                </CopyButton>
                </InputData>
            </RightContainer>
        </Wrapper>
    )
}

export default EditImageModal;