import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Popconfirm, Typography } from 'antd';
import { useNavigate } from "react-router-dom";
import queryString from 'query-string';
import { compose } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { gotRedeemDataRow, showRedemption } from "../../../redux/slices/redemptionSlice";

const ShowRedeem = (props) => {

    const dispatch = useDispatch();
    const { redeemData } = useSelector(s => s.redemption);
    // let history = useHistory();  //useHistory is being deprecated in react-router v6 or above
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(showRedemption()); 
    }, []);

    const edit = (e, value) => {
        let data = {};
        data.m_id = value[1];
        data.type = value[2];
        data.method = value[3];
        data.allowed_activity = value[4];
        data.sub_activity = value[5];
        data.priority = value[6];
        dispatch(gotRedeemDataRow(data))
        navigate("/redemption/addredeem");
        props.selMenu();
    }
    const callbackFunc = () => {
        dispatch(showRedemption());

    }


    const cancel = () => {
        // console.log("Cancelled");
    }
    const columns = [
        // {
        //     name: "Action",
        //     label: "Action",
        //     options: {
        //         filter: false,
        //         customBodyRender: (value, tableMeta, updateValue) => (

        //             <div>
        //                 <Popconfirm
        //                     title="activate the activity"
        //                     onConfirm={e => activated(e, tableMeta.rowData)}
        //                     onCancel={cancel}
        //                     okText="Yes"
        //                     cancelText="No"
        //                 >
        //                     <a href="#" style={{ padding: 5, fontWeight: "bolder", color: "#078101", border: "1px solid #aaa", borderRadius: "5px", boxShadow: "2px 2px 0px 0px", cursor: "pointer", marginBottom: "10px" }}>Activated</a>
        //                 </Popconfirm>
        //                 {/* <Divider type="vertical"></Divider> */}
        //                 <Popconfirm
        //                     title="Deactivated"
        //                     onConfirm={e => deactivated(e, tableMeta.rowData)}
        //                     onCancel={cancel}
        //                     okText="Yes"
        //                     cancelText="No"
        //                 >
        //                     <a href="#" style={{ padding: 5, fontWeight: "bolder", color: "#FF1502", border: "1px solid #aaa", borderRadius: "5px", boxShadow: "2px 2px 0px 0px", cursor: "pointer" }}>Deactivated</a>
        //                 </Popconfirm>
        //             </div>
        //         )
        //     }
        // },
        {
            name: "opertaion",
            label: "Operation",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div>
                        <Popconfirm
                            title="Edit this task?"
                            onConfirm={e => edit(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#">Edit</a>
                        </Popconfirm>
                    </div>
                )
            }
        },
        {
            name: "m_id",
            label: "Redeem ID",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "type",
            label: "Type",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "method",
            label: "Method",
            options: {
                filter: true,
                sort: true,
                // customBodyRender: (value, tableMeta, updateValue) => <img
                //     src={value} alt="image" style={{ width: "100px" }} />
            }
        },
        {
            name: "allowed_activity",
            label: "Allowed Activity",
            options: {
                filter: false,
                sort: false,
                // customBodyRender: (value) => <div><ul>{JSON.parse(value).map((item,index)=>(<li>{item}</li>))}</ul></div>
            }
        },
        {
            name: "sub_activity",
            label: "Sub Activity",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "priority",
            label: "Priority",
            options: {
                filter: true,
                sort: false,
            }
        },
    ];
    const options = {
        filterType: 'm_id',
    };
    return (
        <Fragment>
            <MUIDataTable
                title={"Show Description  (Search by redeem Id)"}
                data={redeemData && redeemData}
                columns={columns}
                options={options}
            />
        </Fragment>
    );

}


export default ShowRedeem;
