import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Typography, Divider, Input, Radio, Icon, message } from "antd";
import AddApprovalMaster from "./Add_Approval_Master";
import ShowApprovalMaster from "./Show_Approval_Master";
const { Title } = Typography;
const Wrapper = styled.div`
  width: 100%;

  >div{
      >div{
        >button{
            background: linear-gradient(259deg, rgb(236, 94, 58) 0%, rgb(249, 186, 99) 100%) 0% 0% no-repeat padding-box padding-box transparent;
            padding:7px 20px;
            border-radius:20px;
            float:right;
        }
      }
  }

`;
const BodyContainer = styled.div`
  overflow: auto;
`;
const Container = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const ApprovalMaster = () => {
    const [addPointsData, setPointsData] = useState("show_approval_master");
    const changeTab = () => {
        setPointsData("add_approval_master");
    };
    const changeTab2 = () => {
        setPointsData("show_approval_master");
    };
    return (
        <Wrapper>
            {/* <Title className="my-3" level={3}>Approval Master </Title> */}
            <div className="row">
                <div className="col-12 col-lg-12">
                    {addPointsData == "show_approval_master" && (
                        <button onClick={changeTab}>Add Approval Master</button>
                    )}
                    {addPointsData == "add_approval_master" && (
                        <button onClick={changeTab2}>Show Approval Master</button>
                    )}
                </div>
            </div>
            <BodyContainer>
                {addPointsData == "add_approval_master" && <AddApprovalMaster />}
                {addPointsData == "show_approval_master" && <ShowApprovalMaster setPointsData={setPointsData} />}
            </BodyContainer>
        </Wrapper>
    );
};

export default ApprovalMaster;
