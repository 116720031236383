import axios from "axios";
import AppConstants from "../../constants/appConstants";

const API = axios.create({
  baseURL: `${AppConstants.baseURL}`,
});

export const login = (loginData) => API.post("/auth/login", loginData);
export const signupApi = (signupData) => API.post("/auth/signup", signupData);
export const logout = () => API.get("/auth/logout");
export const getProfile = () => API.get("/auth/profile");
export const showRolesApi = () => API.get("/auth/showroles");
export const changePassword = (data) => API.post("/auth/changepassword", data);
