import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api/emailApi";
import { message } from 'antd';


export const showEmail = createAsyncThunk(
  "/email/showemail",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.showEmail();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const addEmail = createAsyncThunk(
  "/email/addemail",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.addEmail(data);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Added Successfully!');
        // return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const activatedEmailData = createAsyncThunk(
  "/email/activatemail",
  async (query, { rejectWithValue }) => {
    try {
      const resp = await api.activatedEmailData(query);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Activated/Deactivated Successfully!');
        // return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const updateTasksData = createAsyncThunk(
  "/email/updatetasksdata",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.updateTasksData();
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Updated Successfully!');
        // return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const getEmailTemplateMaster = createAsyncThunk(
  "/email/getemailmaster",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.getEmailTemplateMaster();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

const emailSlice = createSlice({
  name: "email",
  initialState: {
    data: {
      emailData: [],
      emailDataRow: [],
      emailMasterData: [],
    },
    loading: false,
    error: null,
  },
  reducers: {
    gotEmailDataRow: (state, action) => {
      state.data.emailDataRow = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(showEmail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showEmail.fulfilled, (state, action) => {
      state.data.emailData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(showEmail.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // =============================================================

    builder.addCase(getEmailTemplateMaster.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEmailTemplateMaster.fulfilled, (state, action) => {
      state.data.emailMasterData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getEmailTemplateMaster.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // =============================addEmail================================

    builder.addCase(addEmail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addEmail.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(addEmail.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // =============================activatedEmailData================================

    builder.addCase(activatedEmailData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(activatedEmailData.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(activatedEmailData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // =============================updateTasksData================================

    builder.addCase(updateTasksData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateTasksData.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateTasksData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { gotEmailDataRow } = emailSlice.actions;

export default emailSlice.reducer;
