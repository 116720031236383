import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Typography, Divider, Input, Radio, Icon, message } from "antd";
import AddStoreSchema from "./AddStoreSchema";
import ShowStoreSchema from "./ShowStoreSchema";
const { Title } = Typography;
const Wrapper = styled.div`
  width: 100%;

  >div{
      >div{
        >button{
            background: linear-gradient(259deg, rgb(236, 94, 58) 0%, rgb(249, 186, 99) 100%) 0% 0% no-repeat padding-box padding-box transparent;
            padding:7px 20px;
            border-radius:20px;
            float:right;
        }
      }
  }

`;
const BodyContainer = styled.div`
  overflow: auto;
`;
const Container = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const StoreSchema = () => {
    const [storeType, setStoreType] = useState("showStoreSchema");
    const changeTab = () => {
        setStoreType("addStoreSchema");
    };
    const changeTab2 = () => {
        setStoreType("showStoreSchema");
    };

    const setDisplayType = () => {
        setStoreType("addStoreSchema");
    };
    return (
        <Wrapper>
            <Title className="my-3" level={3}>Store Schema </Title>
            <div className="row">
                <div className="col-12 col-lg-12">
                    {storeType == "showStoreSchema" && (
                        <button onClick={changeTab}>Add Store Schema</button>
                    )}
                    {storeType == "addStoreSchema" && (
                        <button onClick={changeTab2}>Show Store Schema</button>
                    )}
                </div>
            </div>
            <BodyContainer>
                {storeType == "addStoreSchema" && (
                    <AddStoreSchema />
                )}
                {storeType == "showStoreSchema" && (
                    <ShowStoreSchema setDisplayType={setDisplayType} />
                )}
            </BodyContainer>
        </Wrapper>
    );
};

export default StoreSchema;
