import ActionTypes from "../constants/actionTypes";
import initialState from "../stores/initialState";

export default (state = initialState.managebanners, action) => {
  switch (action.type) {
    case ActionTypes.GOT_LIVE_BANNERS:
      return {
        ...state,
        allBannersImages: action.payload,
      };
    case ActionTypes.GOT_LIVE_OFFERS:
      return {
        ...state,
        allOffersImages: action.payload,
      };
    case ActionTypes.GOT_ALL_MANAGE_BANNERS_IMAGES:
      return {
        ...state,
        allImages: action.payload.data,
      };
    case ActionTypes.GOT_UPLOAD_IMAGE:
      return {
        ...state,
        uploadImagesData: action.payload,
      };
    default:
      return state;
  }
};
