import React from 'react'
import {
  CRow,
  CCol,
  CWidgetStatsA,
  CWidgetStatsF
} from '@coreui/react'
// import { getStyle } from '@coreui/utils'
// import { CChartLine } from '@coreui/react-chartjs'
import LineChart from './Chart/LineChart'
import { dashboardCompleteDetails, getPaymentStatus } from "../../redux/slices/dashboardSlice";
import { useSelector } from 'react-redux';
import {
  Box,
} from '@mui/material';


const WidgetsDropdown = () => {
  const state = useSelector(state => state.dashboard.data);
  // totalSignupUsers=============================================
  let totalSignupUsers = 0;
  state.signUpUserDataDashboard && state.signUpUserDataDashboard.forEach(element => {
    totalSignupUsers += element.uid
  });

  let signup_user_uid = [];
  let signup_date = [];
  state.signUpUserDataDashboard && state.signUpUserDataDashboard.map((item) => {
    signup_user_uid.push(item.uid);
    signup_date.push(item.c_date)
  });
  return (
    <Box>
      <CRow>
        <CCol style={{ width: "100%", objectFit: "contain" }}>
          <CWidgetStatsA
            className="mb-5 font-weight-bold"
            // color="danger"
            value={
              <> {totalSignupUsers} </>
            }
            title="Total New Users"
            style={{ width: "100%", border: '2px solid #D3D3D3' }}
            chart={
              <LineChart
                data={{
                  labels: signup_date,
                  datasets: [{
                    label: 'Users',
                    data: signup_user_uid,
                    backgroundColor: [
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgba(255, 206, 86, 0.2)',
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(153, 102, 255, 0.2)',
                      'rgba(255, 159, 64, 0.2)'
                    ],
                    borderColor: [
                      'rgba(255, 99, 132, 1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)'
                    ],
                    borderWidth: 1
                  }]
                }}
                options={{
                  maintainAspectRatio: true,
                  legend: {
                    labels: {
                      fontSize: 25,
                    },
                  },
                }
                }
              />
            }
          />
        </CCol>
      </CRow >
      <CRow>
        <CCol sm={6} lg={3}>
          <CWidgetStatsA
            className="mb-4 pb-4 font-weight-bold"
            color="primary"
            value={<>        </>
            }
            title="Total User Balance"
            chart={
              <>
                <CWidgetStatsF
                  className="mb-3"
                  color="primary"
                  padding={false}
                  title="Pending Balance"
                  value={state.balanceDataDashboard && state.balanceDataDashboard.length && state.balanceDataDashboard[0].pending} />
                <CWidgetStatsF
                  className="mb-3"
                  color="primary"
                  padding={false}
                  title="Approved Balance"
                  value={state.balanceDataDashboard && state.balanceDataDashboard.length && state.balanceDataDashboard[0].approved}
                />
                <CWidgetStatsF
                  color="primary"
                  padding={false}
                  title="Redeemed Balance"
                  value={state.balanceDataDashboard && state.balanceDataDashboard.length && state.balanceDataDashboard[0].redeemed} />
              </>
            }

          />
        </CCol>
        <CCol sm={6} lg={3}>

          <CWidgetStatsA
            className="mb-4 pb-4 font-weight-bold"
            color="warning"
            value={<>        </>
            }
            title="Total Payment Status"
            chart={
              <>
                <CWidgetStatsF
                  className="mb-3"
                  style={{ fontSize: "20px" }}
                  // color="primary"
                  padding={false}
                  title="payment status"
                  value={state.getPaymentStatus && state.getPaymentStatus.length ? state.getPaymentStatus.length : 0} />
              </>
            }

          />

        </CCol>

      </CRow>
    </Box>
  )
}

export default WidgetsDropdown
