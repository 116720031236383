import React, { useState, useEffect } from 'react'
import { DatePicker, Radio, } from 'antd';

// import queryString from 'query-string';
import moment from "moment";
import { addPoints, updatePointerPoster, getPoints, getStoreData } from "../../../../redux/slices/pointerPosterSlice";
import { useDispatch, useSelector } from "react-redux";
import {
    Autocomplete,
    TextField,
    Paper,
    Typography,
    Toolbar,
    Stack,
    Table,
    TableRow,
    TableCell,
    TableBody,
    tableCellClasses,
    Button
} from "@mui/material";

const AddApprovalMaster = () => {
    const dispatch = useDispatch();
    const state = useSelector((s) => s.pointerPoster);
    const [isUpdatedData, setUpdatedData] = useState(false);
    const [storeData, setStoreData] = useState({
        store_name: "",
        store_id: ""
    });
    const [approvalData, setApprovalData] = useState({
        id: "",
        camp_id: "",
        partner_id: "",
        // partner_name: "",
        resp_camp_id: "",
        after_days: "",
        after_month: "",
        exact_day: "",
    });

    const [dateData, setDateData] = useState({
        exp_date: "",
        payment_date: ""
    });

    const [isDisabled, setIsDisabled] = useState(false);

    const changeHandler = (e) => {
        setApprovalData({ ...approvalData, [e.target.name]: e.target.value });
        // setStoreData({ ...storeData, [e.target.name]: e.target.value })
    };
    const handleExpDateChange = (date, dateString) => {
        setDateData({ ...dateData, exp_date: dateString });
    }
    const handlePayDateChange = (date, dateString) => {
        setDateData({ ...dateData, payment_date: dateString });
    }
    const getConvertedDate = (isoDate) => {
        let newDate = moment(isoDate).add(330, 'minutes').utc().format("YYYY-MM-DD");
        return newDate;
    }
    const handleAutoComplete = e => {
        setStoreData({ ...storeData, storeName: e })
        if (storeData.length > 1) {
            for (var i = 0; i < storeData.length; i++) {
                if (e == storeData[i].store_name) {
                    setStoreData({ ...storeData, store_id: storeData[i].store_id, store_name: storeData[i].store_name })
                }
            }
        }
    }

    useEffect(() => {
        dispatch(getStoreData());
        if (state.pointsDataRow) {
            setUpdatedData(!isUpdatedData);
            setIsDisabled(!isDisabled);
            setApprovalData({
                ...approvalData,
                camp_id: state.pointsDataRow.camp_id,
                partner_id: state.pointsDataRow.partner_id,
                // partner_name: "",
                resp_camp_id: state.pointsDataRow.resp_camp_id,
                after_days: state.pointsDataRow.after_days,
                after_month: state.pointsDataRow.after_month,
                exact_day: state.pointsDataRow.exact_day,
            })
            setStoreData({
                ...storeData,
                store_name: state.pointsDataRow.store_name,
                store_id: state.pointsDataRow.store_id
            })
            setDateData({ ...dateData, payment_date: getConvertedDate(state.pointsDataRow.payment_date), exp_date: getConvertedDate(state.pointsDataRow.expected_date) });
        }

    }, []) //for testing purpose

    useEffect(() => {
        dispatch(getPoints());
    }, [])

    const submitForm = (e) => {
        e.preventDefault();
        let data = {};
        data.id = approvalData.id;
        data.type = "approval_master";
        // let query = queryString.stringify(data);
        data.store_id = storeData.store_id;
        data.camp_id = approvalData.camp_id;
        data.partner_id = approvalData.partner_id;
        data.resp_camp_id = approvalData.resp_camp_id;
        // data.partner = approvalData.partner_name;
        data.exp_date = dateData.exp_date;
        data.payment_date = dateData.payment_date;
        data.after_days = approvalData.after_days;
        data.after_month = approvalData.after_month;
        data.exact_day = approvalData.exact_day;
        if (isUpdatedData) {
            dispatch(updatePointerPoster(data));
        }
        else {
            dispatch(addPoints(data));
        }
    }



    return (
        <Paper elevation={3}>
            <form onSubmit={submitForm}>
                <Stack spacing={3} direction="column">
                    <Toolbar
                        mt={3}
                        sx={{
                            padding: "1.2rem",
                            borderBottom: "2px solid var(--primary-color)",
                        }}
                    >
                        <Typography
                            sx={{
                                color: "var(--primary-color)",
                                fontWeight: "bold",
                            }}
                            variant="h5"
                            id="tableTitle"
                        >
                            Add Approval Master
                        </Typography>
                    </Toolbar>
                    <Table
                        size="small"
                        sx={{
                            width: "auto",
                            margin: "8px !important",
                            marginTop: "16px !important",
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: "none",
                            },
                        }}
                    >
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>Store Name:</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Autocomplete
                                        freeSolo
                                        size='small'
                                        id="user-combo-box"
                                        options={state.allstoreData && state.allstoreData.map((item) => item.store_name)}
                                        sx={{ width: 256 }}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder='Select Store Name' />
                                        )}
                                        onChange={(e) => changeHandler(e)}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>Partner ID:</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        placeholder="Enter Partner ID"
                                        fullWidth
                                        required
                                        value={approvalData.partner_id}
                                        onChange={changeHandler}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>Camp ID:</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        placeholder="Enter Camp ID"
                                        fullWidth
                                        required
                                        value={approvalData.camp_id}
                                        onChange={changeHandler}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>Response Camp Id:</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        placeholder="Enter Response Camp ID"
                                        fullWidth
                                        required
                                        value={approvalData.resp_camp_id}
                                        onChange={changeHandler}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>Expected Date:</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <DatePicker onChange={handleExpDateChange} required />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>Payment Date:</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <DatePicker onChange={handlePayDateChange} required />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>After Days:</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        placeholder="Enter After Days Value"
                                        fullWidth
                                        required
                                        value={approvalData.after_days}
                                        onChange={changeHandler}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>After Month:</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        placeholder="Enter After Month Value"
                                        fullWidth
                                        required
                                        value={approvalData.after_month}
                                        onChange={changeHandler}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>Exact Day:</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        placeholder="Enter Exact Day Value"
                                        fullWidth
                                        required
                                        value={approvalData.exact_day}
                                        onChange={changeHandler}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        size="large"
                                        className="d-block mx-auto mt-4 mb-3"
                                        onClick={submitForm}
                                    >
                                        <strong>Submit</strong>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Stack>
                {/* <div className="row">
                        <div className="col-6 col-lg-6">
                            <div class="mb-3">
                                <label for="" class="form-label">Store Name<span style={{ color: "blue", fontSize: "25px" }}> ({storeData.store_name})</span></label>
                                <Autocomplete
                                    freeSolo
                                    size='small'
                                    id="user-combo-box"
                                    options={state.allstoreData && state.allstoreData.map((item) => item.store_name)}
                                    sx={{ width: 256 }}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder='Select Store Name' />
                                    )}
                                    onChange={(e) => changeHandler(e)}
                                />
                            </div>

                        </div>
                        <div className="col-6 col-lg-6">
                            <div class="mb-3">
                                <label for="" class="form-label">Camp ID</label>
                                <input type="text" class="form-control" name="camp_id" onChange={changeHandler} value={approvalData.camp_id} required={true} />
                            </div>
                        </div>
                        <div className="col-6 col-lg-6">
                            <div class="mb-3">
                                <label for="" class="form-label">Partner ID</label>
                                <input type="text" class="form-control" name="partner_id" onChange={changeHandler} value={approvalData.partner_id} required />
                            </div>
                        </div>
                        <div className="col-6 col-lg-6">
                            <div class="mb-3">
                                <label for="" class="form-label">Response Camp Id</label>
                                <input type="text" class="form-control" name="resp_camp_id" onChange={changeHandler} value={approvalData.resp_camp_id} required />
                            </div>
                        </div>

                        <div className="col-6 col-lg-6">
                            <div class="mb-3">
                                <label for="" class="form-label">Expected Date<span style={{ color: "blue", fontSize: "15px" }}> ({dateData.exp_date})</span></label>
                                <DatePicker onChange={handleExpDateChange} required />
                            </div>
                        </div>
                        <div className="col-6 col-lg-6">
                            <div class="mb-3">
                                <label for="" class="form-label">Payment Date<span style={{ color: "blue", fontSize: "15px" }}> ({dateData.payment_date})</span></label>
                                <DatePicker onChange={handlePayDateChange} required />
                            </div>
                        </div>
                        <div className="col-6 col-lg-6">
                            <div class="mb-3">
                                <label for="" class="form-label">After Days</label>
                                <input type="text" class="form-control" name="after_days" onChange={changeHandler} value={approvalData.after_days} required />
                            </div>
                        </div>
                        <div className="col-6 col-lg-6">
                            <div class="mb-3">
                                <label for="" class="form-label">After Month</label>
                                <input type="text" class="form-control" name="after_month" onChange={changeHandler} value={approvalData.after_month} required />
                            </div>
                        </div>
                        <div className="col-6 col-lg-6">
                            <div class="mb-3">
                                <label for="" class="form-label">Exact Day</label>
                                <input type="text" class="form-control" name="exact_day" onChange={changeHandler} value={approvalData.exact_day} required />
                            </div>
                        </div>

                        <div className="col-12 col-lg-12 my-3 text-center">
                            <button type="submit" c lass="btn btn-primary" >Submit</button>
                        </div>
                    </div> */}
            </form>
        </Paper>
    )
}

export default AddApprovalMaster;
