import ActionTypes from "../constants/actionTypes";
import initialState from "../stores/initialState";

export default (state = initialState.email, action) => {
  switch (action.type) {
    case ActionTypes.GOT_EMAIL_DATA:
      return {
        ...state,
        emailData: action.payload
      };
    case ActionTypes.GOT_EMAIL_MASTER_DATA:
      return {
        ...state,
        emailMasterData: action.payload
      };

    case ActionTypes.GOT_EMAIL_DATA_ROW:
      return {
        ...state,
        emailDataRow: action.payload
      };
    default:
      return state;
  }
};