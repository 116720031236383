import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api/userRoleManagementApi";
import { message } from "antd";

export const getAllPanelUsers = createAsyncThunk(
  "/auth/allpanelusers",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.getAllPanelUsers();
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const allPermissionByUser = createAsyncThunk(
  "/auth/userallpermission",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.allPermissionByUser(data);
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const addModulePermission = createAsyncThunk(
  "/auth/addmodulepermission",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.addModulePermission(data);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Added Successfully!');
        return resp.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Not Added went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

const userRoleManagementSlice = createSlice({
  name: "userRoleManagement",
  initialState: {
    data: {
      getAllPanelUsers: [],
      userRoles: [],
      allPermissionByUser: [],
      userRoleManagement: [],
      addModulePermissionData: [],
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllPanelUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllPanelUsers.fulfilled, (state, action) => {
      state.data.getAllPanelUsers = action.payload.data;
      state.data.userRoles = action.payload.roleUsers;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getAllPanelUsers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ============================================================================================

    builder.addCase(allPermissionByUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(allPermissionByUser.fulfilled, (state, action) => {
      state.data.allPermissionByUser = action.payload;
      state.data.userRoleManagement = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(allPermissionByUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ============================================================================================

    builder.addCase(addModulePermission.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addModulePermission.fulfilled, (state, action) => {
      state.data.addModulePermissionData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(addModulePermission.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const {} = userRoleManagementSlice.actions;

export default userRoleManagementSlice.reducer;
