import React, { Fragment, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Popconfirm, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { showContactedUsers } from "../../redux/slices/contactedUserSlice";

const ShowContactedUsers = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.contactedUser.data.contactedUser);
  console.log(data, "ShowContactedUsers");

  const confirm = (e, value) => {
    //console.log("Confirmed");
  };
  const cancel = () => {
    //console.log("Cancelled");
  };
  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "name",
      label: "User Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "message",
      label: "Message",
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    // name: "phone_no",
    // label: "Phone No",
    // options: {
    // filter: true,
    // sort: false,
    // }
    // },
    // {
    // name: "active",
    // label: "Active",
    // options: {
    // filter: true,
    // sort: false,
    // }
    // },
    {
      name: "date",
      label: "Created",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const options = {
    filterType: "email",
  };
  useEffect(() => {
    dispatch(showContactedUsers());
  }, []);
  return (
    <Fragment>
      <MUIDataTable
        title={"Show Contacted Users (search by email)"}
        data={data && data}
        columns={columns}
        options={options}
      />
    </Fragment>
  );
};
export default ShowContactedUsers;
