import ActionTypes from "../constants/actionTypes";
import initialState from "../stores/initialState";

export default (state = initialState.testimonials, action) => {
  switch (action.type) {
    case ActionTypes.GOT_TESTIMONIALS:
      return {
        ...state,
        testimonialData: action.payload
      };
    case ActionTypes.GOT_TESTIMONIALS_DATA_ROW:
      return {
        ...state,
        testimonialsDataRow: action.payload
      }
    case ActionTypes.GOT_IMAGE_BY_STORE:
      return {
        ...state,
        imageData: action.payload.data
      };
    case ActionTypes.GOT_TESTIMONIAL_UPLOAD_IMAGE:
      return {
        ...state,
        testimonialUploadImage: action.payload.data
      }
    default:
      return state;
  }
};