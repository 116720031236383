import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api/userDetailsApi";
import { message } from "antd";


export const newUsersDetails = createAsyncThunk(
  "/auth/newuser",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.newUsersDetailsApi(data);
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const userLogClicksDetails = createAsyncThunk(
  "/auth/userlogclicks",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.userLogClicksDetailsApi(data);
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState: {
    loading: false,
    data: {
      newUsersDetailsData: [],
      userLogClicksDetailsData: [],
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(newUsersDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(newUsersDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.data.newUsersDetailsData = action.payload.data;
    });
    builder.addCase(newUsersDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    // ============================================================================================

    builder.addCase(userLogClicksDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userLogClicksDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.data.userLogClicksDetailsData = action.payload;
    });
    builder.addCase(userLogClicksDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const {} = userDetailsSlice.actions;

export default userDetailsSlice.reducer;
