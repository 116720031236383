import React, { useEffect, useState, useRef } from "react";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import {
  liveBanners,
  updateliveUnliveBanners,
  deleteBannersImage,
} from "../../../redux/slices/liveBannerSlice";
import { DatePicker } from "antd";
import moment from "moment";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Paper,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { Image, message, Skeleton } from "antd";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const LiveBanners = () => {
  const dispatch = useDispatch();
  const allBannersImages = useSelector(
    (state) => state.liveBanner.data.liveBanners
  );
  const [open, setOpen] = React.useState(false);
  const [dialogData, setDialogData] = useState({});
  // const ref = useRef();

  function dateConverter(str) {
    // console.log(str, "str")
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    let hours = ("0" + date.getHours()).slice(-2);
    let minutes = ("0" + date.getMinutes()).slice(-2);
    let seconds = ("0" + date.getSeconds()).slice(-2);
    let year = date.getFullYear();
    return `${year}-${mnth}-${day} ${hours}:${minutes}:${seconds}`;
  }
  const handleSubmit = () => {
    let data = {};
    // console.log(dateConverter(dialogData.scheduled_time), "cute1");
    data.b_id = dialogData.b_id;
    data.priority = dialogData.live == 1 ? dialogData.priority : null;
    data.type = dialogData.live == 1 ? "live" : "unlive";
    data.scheduled_time = dialogData.scheduled_time
      ? dateConverter(dialogData.scheduled_time)
      : null;
    let query = queryString.stringify(data);
    // console.log(ref.current, "cute1");
    console.log(data, "cute");
    dispatch(updateliveUnliveBanners(query));
    dispatch(liveBanners());
    setDialogData({});
    setOpen(false);
  };

  const handleEditImage = (e, data) => {
    e.preventDefault();
    setOpen(true);
    setDialogData(data);
  };

  const copyImageUrl = async (e, img_url) => {
    e.preventDefault();
    await navigator.clipboard.writeText(img_url && img_url);
    message.success("Image url Copied");
  };

  const handleDeleteImage = (e, item) => {
    e.preventDefault();
    dispatch(deleteBannersImage(item));
    dispatch(liveBanners());
  };

  useEffect(() => {
    dispatch(liveBanners());
  }, []);

  return (
    <Paper>
      <Stack spacing={3} direction="column">
        <Toolbar
          sx={{
            padding: "1.2rem",
            borderBottom: "2px solid var(--primary-color)",
          }}
        >
          <Typography
            sx={{
              flex: "1 1 100%",
              color: "var(--primary-color)",
              fontWeight: "bold",
            }}
            variant="h5"
          >
            Banners
          </Typography>
        </Toolbar>

        <Stack direction="row" spacing={2} px={4} pb={3}>
          {/* for non live banners   */}
          <Stack direction="column" spacing={2} sx={{ minWidth: "50%" }}>
            <Typography className="text-center" fontSize={"1.2rem"}>
              <b>Non-live banners</b>
            </Typography>
            <Box sx={{ width: "100%", height: "62vh", overflowY: "scroll" }}>
              <Image.PreviewGroup>
                <ImageList cols={1}>
                  {allBannersImages ? (
                    allBannersImages
                      .filter((item) => item.live === 0)
                      .map((item) => (
                        <ImageListItem key={item.b_id}>
                          <Image
                            src={`https://cdn.hyyzo.com/${item.image_url}`}
                            alt={item.b_id ? item.b_id : ""}
                            preview
                            style={{ objectFit: "contain" }}
                          />
                          <ImageListItemBar
                            sx={{ background: "transparent" }}
                            position="top"
                            actionIcon={
                              <Tooltip title="Edit Image Data" arrow>
                                <IconButton
                                  sx={{
                                    borderRadius: "0 0 0 50%",
                                    width: "32px",
                                    height: "32px",
                                    color: "white",
                                    bgcolor: "blue",
                                    "&:hover": {
                                      bgcolor: "#f5f5f5",
                                      color: "black",
                                      filter: "contrast(5)",
                                    },
                                  }}
                                  onClick={(e) => handleEditImage(e, item)}
                                >
                                  <EditIcon sx={{ fontSize: 18 }} />
                                </IconButton>
                              </Tooltip>
                            }
                            actionPosition="right"
                          />
                          <ImageListItemBar
                            sx={{
                              "& .MuiImageListItemBar-titleWrap": {
                                padding: "7px 10px !important",
                              },
                            }}
                            subtitle={
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Box>
                                  <Typography>
                                    <b>
                                      Priority:
                                      {item.priority ? item.priority : " - "},
                                      Live:{item.live}
                                    </b>
                                  </Typography>
                                </Box>
                                <Stack direction="row" spacing={2}>
                                  <Tooltip title="Copy ImageUrl">
                                    <IconButton
                                      size="small"
                                      color="warning"
                                      sx={{
                                        width: "32px",
                                        height: "32px",
                                        color: "black",
                                        bgcolor: "var(--bs-light)",
                                        "&:hover": {
                                          color: "black",
                                          bgcolor: "var(--bs-light)",
                                          filter: "contrast(2)",
                                        },
                                      }}
                                      onClick={(e) =>
                                        copyImageUrl(e, item.image_url)
                                      }
                                    >
                                      <ContentCopyIcon sx={{ fontSize: 18 }} />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Delete Banner">
                                    <IconButton
                                      size="small"
                                      color="error"
                                      sx={{
                                        width: "32px",
                                        height: "32px",
                                        color: "white",
                                        bgcolor: "var(--bs-red)",
                                        "&:hover": {
                                          color: "white",
                                          bgcolor: "var(--bs-red)",
                                          filter: "contrast(2)",
                                        },
                                      }}
                                      onClick={(e) =>
                                        handleDeleteImage(e, item)
                                      }
                                    >
                                      <DeleteIcon sx={{ fontSize: 18 }} />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </Stack>
                            }
                          />
                        </ImageListItem>
                      ))
                  ) : (
                    <Skeleton.Image active />
                  )}
                </ImageList>
              </Image.PreviewGroup>
            </Box>
          </Stack>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: "black" }}
          />
          {/* for live banners */}
          <Stack direction="column" spacing={2} sx={{ minWidth: "50%" }}>
            <Typography className="text-center" fontSize={"1.2rem"}>
              <b>Live banners</b>
            </Typography>
            <Box sx={{ width: "100%", height: "62vh", overflowY: "scroll" }}>
              <Image.PreviewGroup>
                <ImageList cols={1}>
                  {allBannersImages ? (
                    allBannersImages
                      .filter((item) => item.live === 1)
                      .map((item) => (
                        <ImageListItem key={item.b_id}>
                          <Image
                            src={`https://cdn.hyyzo.com/${item.image_url}`}
                            alt={item.b_id ? item.b_id : ""}
                            preview
                            style={{ objectFit: "contain" }}
                          />
                          <ImageListItemBar
                            sx={{ background: "transparent" }}
                            position="top"
                            actionIcon={
                              <Tooltip title="Edit Image Data" arrow>
                                <IconButton
                                  sx={{
                                    borderRadius: "0 0 0 50%",
                                    width: "32px",
                                    height: "32px",
                                    color: "white",
                                    bgcolor: "blue",
                                    "&:hover": {
                                      bgcolor: "#f5f5f5",
                                      color: "black",
                                      filter: "contrast(5)",
                                    },
                                  }}
                                  onClick={(e) => handleEditImage(e, item)}
                                >
                                  <EditIcon sx={{ fontSize: 18 }} />
                                </IconButton>
                              </Tooltip>
                            }
                            actionPosition="right"
                          />
                          <ImageListItemBar
                            sx={{
                              "& .MuiImageListItemBar-titleWrap": {
                                padding: "7px 10px !important",
                              },
                            }}
                            subtitle={
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Box>
                                  <Typography>
                                    <b>
                                      Priority:{" "}
                                      {item.priority ? item.priority : " - "},
                                      Live: {item.live},
                                      {item.scheduled_time && (
                                        <>
                                          Scheduled:{" "}
                                          {moment
                                            .utc(item.scheduled_time)
                                            .format("DD:MM:YYYY HH:mm:ss")}
                                        </>
                                      )}
                                    </b>
                                  </Typography>
                                </Box>
                                <Stack direction="row" spacing={2}>
                                  <Tooltip title="Copy ImageUrl">
                                    <IconButton
                                      size="small"
                                      color="warning"
                                      sx={{
                                        width: "32px",
                                        height: "32px",
                                        color: "black",
                                        bgcolor: "var(--bs-light)",
                                        "&:hover": {
                                          color: "black",
                                          bgcolor: "var(--bs-light)",
                                          filter: "contrast(2)",
                                        },
                                      }}
                                      onClick={(e) =>
                                        copyImageUrl(e, item.image_url)
                                      }
                                    >
                                      <ContentCopyIcon sx={{ fontSize: 18 }} />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </Stack>
                            }
                          />
                        </ImageListItem>
                      ))
                  ) : (
                    <Skeleton.Image active />
                  )}
                </ImageList>
              </Image.PreviewGroup>
            </Box>
          </Stack>
        </Stack>
      </Stack>

      <Dialog open={open}>
        <DialogTitle>
          <b>Edit Banner</b>
        </DialogTitle>
        <Divider sx={{ borderColor: "black" }} />
        <DialogContent>
          <Box mt={2} className="d-flex align-items-center gap-2">
            <Typography>
              <b>Redirect URL - </b>
            </Typography>
            <TextField
              autoFocus
              size="small"
              value={dialogData.url}
              type="string"
              onChange={(e) =>
                setDialogData({ ...dialogData, url: e.target.value })
              }
            />
          </Box>
          <Box mt={2} className="d-flex align-items-center gap-2">
            <Typography>
              <b>Change Status - </b>
            </Typography>
            <RadioGroup
              name="type"
              aria-labelledby="type"
              row
              value={dialogData.live}
              onChange={(e) =>
                setDialogData({ ...dialogData, live: e.target.value })
              }
            >
              <FormControlLabel control={<Radio />} label="Live" value={1} />
              <FormControlLabel
                control={<Radio />}
                label="Non-live"
                value={0}
              />
            </RadioGroup>
          </Box>
          <Box mt={2} className="d-flex align-items-center gap-2">
            <Typography>
              <b>Priority number - </b>
            </Typography>
            <TextField
              autoFocus
              size="small"
              value={dialogData.priority}
              type="Number"
              onChange={(e) =>
                setDialogData({ ...dialogData, priority: e.target.value })
              }
              inputProps={{
                type: "number",
                min: "0",
                step: "1",
              }}
            />
          </Box>

          <Box mt={2} className="d-flex align-items-center gap-2">
            <Typography>
              <b>Scheduled Live</b>
            </Typography>
            <DatePicker
              // format="DD/MM/YYYY hh:mm A"
              onChange={(date, dateString) =>
                setDialogData({
                  ...dialogData,
                  scheduled_time: date?.utc().format(),
                })
              }
              showTime={{ use12Hours: true }}
            />
          </Box>
        </DialogContent>
        <DialogActions p={3}>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default LiveBanners;
