import React, { Component, Fragment, useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Typography, Divider, Input, AutoComplete, message } from "antd";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ImageUploader from "react-images-upload";
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { useDispatch, useSelector } from "react-redux";
import { checkImageDimension, getImageType, saveGalleryImage, getAllImages } from "../../../redux/slices/gallerySlice";
import { getStoreData } from "../../../redux/slices/manageStoreSlice"
import { Autocomplete, TextField } from "@mui/material";


const { Title, Text } = Typography;

const TopContainer = styled.div`
  align-items: center;
  display: block;
  width: 100%;
  justify-content: space-between;
`;
const Requiremts = styled.div`
  color: red;
  font-size: 20px;
  font-weight: bold;
`;

const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  @media screen and (max-width: 480px) {
    display: block;
  }
`;
const Comment = styled.div`
  display: none;
`;

const Data = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  > label {
    width: 30%;
  }
  > span {
    font-size: 18px;
    width: 50% !important;
    font-weight: bolder;
    >input{
      width: 100%;
      border: 1px solid #ddd;
      outline: none;
    }
  }
  > input[type="text"] {
    width: 50%;
  }
  >div{
    width: 50%;
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: baseline;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubmitButton = styled.div`
  width: 140px;
  height: 40px;
  display: flex;
  background: #0000ff;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  cursor: pointer;
  > p {
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 0px;
    color: #fff;
    text-align: center;
  }
`;

function getBase64(img, callback) {
  if (img) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
}

const AddImage = (props) => {
  const dispatch = useDispatch();
  const imageTypeData = useSelector((state) => state.gallery.data.getImageType);
  const { storedata } = useSelector(s => s.manageStores)
  const temp = useSelector(s => s);
  const [value, setValue] = React.useState();
  useEffect(() => {
    dispatch(getImageType());
    dispatch(checkImageDimension());
  }, []);

  const [drop_type, setDropType] = useState("Select Type");
  const [buttonText, setButtonText] = useState("choose image");
  const [error, setError] = useState("");
  const [allValues, setAllValues] = useState({
    title: "",
    alt_text: "",
    store_name: "",
    store_id: ""
  });
  const [pictures, setImg] = useState([]);
  const [storeName, setStoreName] = useState("Select Store");
  const [masterDetails, setMasterdetails] = useState({
    height: 0,
    width: 0,
    comment: "",
  });

  const [storeInfo, setStoreInfo] = useState(storedata ? storedata : []);
  const [allowUpload, setAllowUpload] = useState(true);
  const [dimension, setDimension] = useState({
    hght: 0,
    wdth: 0,
    // isCorrectSize: true,
  });
  const [isCorrectSize, setIsCorrectSize] = useState(true);
  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };
  function getMeta(url, callback) {
    var img1 = new Image();
    img1.src = url;

    img1.onload = function () {
      callback(this.width, this.height);
    };
  }

  const imgData = async (picture) => {
    if (picture && picture.length > 0) {
      await getBase64(picture[0], (image) => {
        if (picture[0])
          getMeta(image, function (width, height) {
            setDimension({ ...dimension, hght: height, wdth: width })
            setError("");
            if (masterDetails.height != null || masterDetails.width != null) {
              if (masterDetails.height == null) {
                if (masterDetails.width != width) {
                  alert("Width Mismatched Upload image of mentioned size");
                  // dimension.isCorrectSize = false;
                  setIsCorrectSize(false)
                } else {
                  setError("");
                  setIsCorrectSize(true)
                  setImg(picture);
                  let title = picture[0].name.split(".")[0];
                  setAllValues({ ...allValues, title: title })
                }
              } else if (masterDetails.width == null) {
                if (masterDetails.height != height) {
                  alert("Height Mismatched Upload image of mentioned size");
                  setIsCorrectSize(false)
                } else {
                  setError("");
                  setIsCorrectSize(true)
                  setImg(picture);
                  let title = picture[0].name.split(".")[0];
                  setAllValues({ ...allValues, title: title })
                }
              } else {
                if (
                  masterDetails.height != height ||
                  masterDetails.width != width
                ) {
                  alert("Incorrect Dimension Upload image of mentioned size");
                  setIsCorrectSize(false)
                } else {
                  setError("");
                  setIsCorrectSize(true)
                  setImg(picture);
                  let title = picture[0].name.split(".")[0];
                  setAllValues({ ...allValues, title: title })
                }
              }
            } else {
              setError("");
              setIsCorrectSize(true)
              setImg(picture);
              let title = picture[0].name.split(".")[0];
              setAllValues({ ...allValues, title: title })
            }

          });
      });
    } else {
      setAllValues({
        ...allValues,
        title: "",
        alt_text: "",
        store_name: "",
        store_id: ""
      })
      setImg([]);
      setDimension({
        ...dimension,
        hght: 0,
        wdth: 0,
      })
      setMasterdetails({
        ...masterDetails,
        height: 0,
        width: 0,
        comment: "",
      })
    }
  };

  const handleAutoComplete = e => {
    // setCategories(...categorystate, e.target.value);
    setAllValues({ ...allValues, store_name: e.target.value })
    if (storeInfo.length > 0) {
      for (var i = 0; i < storeInfo.length; i++) {
        if (e.target.value == storeInfo[i].store_name) {
          setAllValues({ ...allValues, store_id: storeInfo[i].store_id, store_name: e.target.value })
        }
      }
    }
  }

  const handleSelect = (e, val) => {
    console.log(val, 'val');
    setError("");
    {
      imageTypeData &&
        imageTypeData.map((item, index) =>
          item.type == e
            ? item.master_width == null && item.master_height == null
              ? setButtonText("choose image")
              : item.master_width != null && item.master_height != null
                ? setButtonText(
                  "width " +
                  item.master_width +
                  " && height " +
                  item.master_height
                )
                : item.master_height == null
                  ? setButtonText("width " + item.master_width)
                  : setButtonText("height " + item.master_height)
            : ""
        );
    }
    setDropType(e);
  };
  const changeSelector = (e) => {
    setStoreName(e);
  };
  useEffect(() => {

    dispatch(getStoreData());
    let stores = JSON.parse(localStorage.getItem("stores"));
    if (stores) {
      setStoreInfo(stores);
    }
    dispatch(getImageType());
  }, []);
  const submitHandler = e => {
    if (pictures[0] !== undefined) {
      if (allValues.title !== '') {
        if (allValues.alt_text !== '') {
          let data = new FormData();
          data.append("master_height", masterDetails.height);
          data.append("master_width", masterDetails.width);
          data.append("drop_type", drop_type);
          data.append("file", pictures[0]);
          data.append("store_name", allValues.store_name);
          data.append("title", allValues.title);
          data.append("alt_text", allValues.alt_text);
          data.append("image_height", dimension.hght);
          data.append("image_width", dimension.wdth);
          // data.forEach((e) => //console.log(e));
          if (allowUpload) {
            setAllowUpload(false);
            dispatch(saveGalleryImage(data));
            imageCallback();
          }
        } else {
          message.error("alt_text cannot be empty !!", 5)
        }
      } else {
        message.error("Title cannot be empty !!", 5)
      }
    } else {
      message.error("image cannot be empty !!", 5)
    }
    // //console.log(masterDetails,allValues,dimension,pictures[0],"data")
  };

  const imageCallback = () => {
    dispatch(getAllImages(40));
    setAllValues({
      ...allValues,
      title: "",
      alt_text: "",
      store_name: "",
      store_id: ""
    })
    setImg([]);
    setDimension({
      ...dimension,
      hght: 0,
      wdth: 0,
    })
    setMasterdetails({
      ...masterDetails,
      height: 0,
      width: 0,
      comment: "",
    })
    setAllowUpload(true);
  }

  return (
    <Fragment>
      <TopContainer>
        {/* <DropdownButton
          alignRight
          title={drop_type}
          id="dropdown-menu-align-right"
          onSelect={handleSelect}
        >
          {imageTypeData &&
            imageTypeData.map((item, index) => (
              <Dropdown.Item eventKey={item.type}>{item.type}</Dropdown.Item>
            ))}
        </DropdownButton> */}
        <Autocomplete
          disablePortal
          disableClearable
          id="combo-box-demo"
          value={drop_type}
          options={imageTypeData && imageTypeData.map((item) => item.type)}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Select Type" />}
          onChange={(e, drop_type) => handleSelect(drop_type)}
        />

        <Comment>
          {imageTypeData &&
            imageTypeData.map((item, index) => {
              item.type == drop_type
                ? (masterDetails.height = item.master_height)
                : (masterDetails.comment = "");
            })}

          {imageTypeData &&
            imageTypeData.map((item, index) =>
              item.type == drop_type
                ? (masterDetails.width = item.master_width)
                : (masterDetails.comment = "")
            )}
          {/* {//console.log(
            "master height and width is ",
            masterDetails.height,
            " ",
            masterDetails.width
          )} */}
          {/* {setError("")} */}
          {/* {(dimension.isCorrectSize = true)} */}
        </Comment>
        <Requiremts>
          {imageTypeData &&
            imageTypeData.map((item, index) =>
              item.type == drop_type ? item.requirements : ""
            )}
        </Requiremts>
      </TopContainer>
      {drop_type != "Select Type" ? (
        <Fragment>
          <TopContainer>
            <Data>
              <Text></Text>
              <Requiremts>{error}</Requiremts>
              <ImageUploader
                withIcon={true}
                buttonText={buttonText}
                onChange={imgData}
                maxFileSize={2097152}
                withPreview={isCorrectSize}
                imgExtension={[".jpg", ".png", ".jpeg", ".gif", ".webp"]}
                singleImage={true}
                label="Max file Size 2MB Accepted: jpg, png, jpeg, gif, webp"
                id="file"
                fileTypeError=" is not supported file extension"
                className="add-images-uploader sky"
              />
              {/* {
                (//console.log("I am image uploader with dimension"),
                //console.log(dimension))
              } */}
            </Data>
            <Divider />
            <Container>
              <Data>
                <Text>Store Name</Text>
                {/* <AutoCompleteComponent
                  id="atcelement"
                  options={storeInfo && storeInfo.map((item, index) => item.store_name)}
                  fields={{ value: 'store_name' }}
                  placeholder="Find a store"
                  onChange={handleAutoComplete}
                  highlight={true}
                /> */}
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={storedata && storedata.map((item) => item.store_name)}
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label="Select Store" />}
                  onChange={handleAutoComplete}
                />
                {/* <AutoComplete
            disabled={false}
            width={200}
            dataSource={storeInfo && storeInfo.map((item, index) => item.store_name)}
            onSelect={handleAutoComplete}
            filterOption={(inputValue, option) =>
              option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
          /> */}
                {/* <DropdownButton
                  alignRight
                  title={storeName}
                  id="dropdown-menu-align-right"
                  onSelect={changeSelector}
                >
                  {imageTypeData &&
                    imageTypeData.map((item, index) => (
                      <Dropdown.Item eventKey={item.type}>
                        {item.type}
                      </Dropdown.Item>
                    ))}
                  <Dropdown.Item eventKey="Other">Other</Dropdown.Item>
                </DropdownButton> */}
              </Data>
              <Data>
                <Text>Title</Text>
                <Input
                  size="large"
                  type="text"
                  name="title"
                  value={allValues.title}
                  onChange={changeHandler}
                />
              </Data>
              <Data>
                <Text>Alt Text</Text>
                <Input
                  size="large"
                  type="text"
                  name="alt_text"
                  value={allValues.alt_text}
                  onChange={changeHandler}
                />
              </Data>
            </Container>
            <ButtonContainer>
              <SubmitButton onClick={submitHandler}>
                <p>Submit</p>
              </SubmitButton>
            </ButtonContainer>
          </TopContainer>
        </Fragment>
      ) : (
        <Fragment />
      )}
    </Fragment>
  );
};

export default AddImage;
