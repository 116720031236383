import axios from "axios";
import AppConstants from "../../constants/appConstants";


const API = axios.create({
    baseURL: `${AppConstants.baseURL}`,
});

// ======================bannerdata=============================================================
export const liveBanners = () => API.get("/managebanners/livebanners");
export const liveUnliveBanners = (query) => API.get(`/managebanners/liveunlivebanners?${query}`);
export const deleteBannersImage = (query) => API.get(`/managebanners/deleteimage?b_id=${query}`);

// =======================offerdata=============================================================
export const liveOffers = () => API.get("/managebanners/liveoffers");
export const liveUnliveOffers = (query) => API.get(`/managebanners/liveunliveoffers?${query}`);
export const deleteOffersImage = (query) => API.get(`/managebanners/deleteimage?b_id=${query}`);

// ==============================uploadimage====================================================
export const getAllManageBannersImages = () => API.get("/gallery/getallimages");
export const uploadImage = (data) => API.post("/managebanners/uploadimage", data);
export const getUploadImage = (query) => API.get(`/managebanners/getuploadimage?type=${query}`);
export const saveUploadImage = (data) => API.post("/managebanners/saveUploadImage",data);