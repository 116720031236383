import React, { Fragment, useState, useEffect, useCallback } from "react";
import MUIDataTable from "mui-datatables";
import { Popconfirm, Typography, message, Divider } from 'antd';
import { useNavigate } from "react-router-dom";
import queryString from 'query-string';
import { useDispatch, useSelector } from "react-redux";
import { activatedPointerPosterData, getPoints, gotPointsMasterDataRow } from "../../../../redux/slices/pointerPosterSlice";
import {Tooltip} from "@mui/material"


const ShowPointMaster = (props) => {
    const dispatch = useDispatch();
    const pointsData = useSelector(s => s.pointerPoster.pointsData)
    const [storesData, setStoresData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        let type = "point_master"
        dispatch(getPoints(type));
        // var stores_data = JSON.parse(localStorage.getItem("stores"))
        // if (stores_data) {
        //     setStoresData(stores_data)
        //     //console.log(storesData, "check");
        // }
    }, []);

    const getStoreName = (store_id) => {
        //console.log("new data", store_id)
        for (var i = 0; i < storesData.length; i++) {
            if (storesData[i].store_id == store_id) {
                //console.log(storesData[i].store_name, "gt store data");
                return storesData[i].store_name
            }
        }
    }

    const edit = (e, value) => {
        let data = {};
        data.id = value[2];
        data.store_id = value[3];
        data.store_name = value[4];
        data.task_id = value[5];
        data.task_name = value[6];
        data.activity_code_name = value[7];
        data.activity_code = value[8];
        data.user_share_per = value[9];
        data.user_share_flat = value[10];
        data.user_share_max = value[11];
        data.user_share_min = value[12];
        data.description = value[13];
        data.allow_api_posting = value[14];
        data.type = "point_master";
        dispatch(gotPointsMasterDataRow(data))
        // navigate("/points/addpoints");
        props.setPointsData("add_point_master");
    }
    const cancel = () => {
        //console.log("Cancelled");
    }

    const callbackFunc = () => {
        dispatch(getPoints("point_master"));
    }
    const activated = (e, value) => {
        let data = {};
        data.id = value[2];
        data.types = "activate";
        data.type = "point_master"
        let query = queryString.stringify(data);
        dispatch(activatedPointerPosterData(query))
        callbackFunc()
        message.success("Point Master data Activated Successfully");
    }
    const deactivated = (e, value) => {
        let data = {};
        data.id = value[2];
        data.types = "deactivate";
        data.type = "point_master"
        let query = queryString.stringify(data);
        dispatch(activatedPointerPosterData(query));
        callbackFunc();
        message.success("Point Master data Deactivated Successfully");
    };

    const columns = [
        {
            name: "Action",
            label: "Action",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (

                    <div style={{ display: 'flex' }}>
                        <Popconfirm
                            title="activate the activity"
                            onConfirm={e => activated(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#" style={{ padding: 5, fontWeight: "bolder", color: "#078101", border: "1px solid #aaa", borderRadius: "5px", boxShadow: "2px 2px 0px 0px", cursor: "pointer", marginBottom: "10px" }}>Activate</a>
                        </Popconfirm>
                        <Divider type="vertical"></Divider>
                        <Popconfirm
                            title="Deactivated"
                            onConfirm={e => deactivated(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#" style={{ padding: 5, fontWeight: "bolder", color: "#FF1502", border: "1px solid #aaa", borderRadius: "5px", boxShadow: "2px 2px 0px 0px", cursor: "pointer" }}>Deactivate</a>
                        </Popconfirm>
                    </div>
                )
            }
        },
        {
            name: "opertaion",
            label: "Operation",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div>
                        <Popconfirm
                            title="Edit this task?"
                            onConfirm={e => edit(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#">Edit</a>
                        </Popconfirm>
                    </div>
                )
            }
        },
        {
            name: "id",
            label: "Point ID",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "store_id",
            label: "Store ID",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "store_name",
            label: "Store Name",
            options: {
                filter: true,
                sort: true,
                // customBodyRender: (value) => <div>{getStoreName(value)}</div>
            }
        },
        {
            name: "task_id",
            label: "task_id",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "task_short_name",
            label: "task_name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "master_activity",
            label: "Activity Code Name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "activity_code",
            label: "Activity Code",
            options: {
                filter: true,
                sort: true,
            }
        },

        {
            name: "user_share_per",
            label: "user_share_per",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "user_share_flat",
            label: "user_share_flat",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "user_share_max",
            label: "user_share_max",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "user_share_min",
            label: "user_share_min",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "description",
            label: "Description",
            options: {
                customBodyRender: (desc) => {
                  return (
                    <Tooltip title={desc}>
                      <p
                        style={{
                          margin: 0,
                          width: "300px",
                          display: "-webkit-box",
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "pre-wrap" // This ensures that line breaks in the description are respected
                        }}
                      >
                        {desc}
                      </p>
                    </Tooltip>
                  );
                },
                filter: true,
                sort: false,
            }
        },
        {
            name: "allow_api_posting",
            label: "allow_api_posting",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "active",
            label: "Active",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "created_at",
            label: "Created At",
            options: {
                filter: true,
                sort: false,
            }
        }
    ];
    const options = {
        filterType: 'store_name',
    };
    return (
        <Fragment>
            <MUIDataTable
                title={"Show Description  (search by Store name)"}
                data={pointsData && pointsData}
                columns={columns}
                options={options}
            />
        </Fragment>
    );
}
export default ShowPointMaster;