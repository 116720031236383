import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button } from 'antd';
import FlipCard from "./FlipCard";
import queryString from 'query-string';
import AppConstants from "../../../constants/appConstants";
import { useDispatch, useSelector } from "react-redux";
import { liveOffers, liveUnliveOffers } from "../../../redux/slices/liveOfferSlice";

const Wrapper = styled.div`
  width: 100%;
  height: 560px;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;
const Container = styled.div`
  height: 560px;
  width: 50%;
  padding: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const Line = styled.div`
  border-left: 2px solid lightgrey;
  height: 100%;
`;
const Data = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  ${'' /* border: 1px dotted black; */}
  width: 70%;
  min-height: 150px;
`;
const ImageContainer = styled.div`
    width:100%;
    padding:15px;
    >img{
        margin:10px;
    }
    >div{
      display: ${props => props.active ? "flex" : "none"};
    }
    >button{
      display: ${props => props.active ? "flex" : "none"};
    }
`;
const Inp = styled.div`
  width: 100%;
  display:flex;
  margin-bottom:10px;
  >label{
  width:50%
  }
  >input{
  width:50%
  }
`;

const ImageMainContainer = styled.div`
 overflow:auto;
`;
const LiveOffers = () => {
    const dispatch = useDispatch();
    const allOffersImages = useSelector(state => state.liveOffer.data.liveOffers);
    useEffect(() => {
        dispatch(liveOffers())
    }, []);

    const [showButton, setShowButton] = useState(false);
    const [showDeleteButton, setDeleteShowButton] = useState(false);
    const [priority, setPriority] = useState("");

    const callbackFunc = () => {
        dispatch(liveOffers());

    }
    const live = (value) => {
        let data = {};
        data.b_id = value.b_id;
        data.type = "live";
        data.priority = priority;
        let query = queryString.stringify(data);
        dispatch(liveUnliveOffers(query))
    }

    const unlive = (value) => {
        let data = {};
        data.b_id = value.b_id;
        data.type = "unlive";
        let query = queryString.stringify(data);
        dispatch(liveUnliveOffers(query))

    }

    const imageHandler = (data) => {
        setShowButton(!showButton);
    }
    const imageHandler1 = (data) => {
        setDeleteShowButton(!showDeleteButton);
    }

    return (
        <>
            <h1 className="text-center">Live Offers</h1>
            <Wrapper className="upload-image-main">

                <Container className="left-container-upload-image">
                    <h6>ALl live & non-live Offers</h6>
                    <ImageMainContainer>
                        {allOffersImages &&
                            allOffersImages.map((item, index) =>
                                <ImageContainer active={showButton}>
                                    <img style={{ width: "425px", height: "225px" }}
                                        src={`${AppConstants.imageURL}${item.image_url}`}
                                        onClick={() => imageHandler(item)}
                                        alt={`image${index}`}
                                    />
                                    <Inp>
                                        <label>Priority :</label>
                                        <input
                                            type="number"
                                            value={priority}
                                            required
                                            onChange={(e) => setPriority(e.target.value)}
                                            placeholder={`Enter priority in Number`}
                                        />
                                    </Inp>
                                    <button className="align-item-center m-auto px-3 py-2" onClick={() => live(item)}>Live</button>
                                    <br />
                                </ImageContainer>
                            )}
                    </ImageMainContainer>
                </Container>
                <Line className="line-upload-image" />
                <Container className="right-container-upload-image" style={{ "height": "560px" }}>
                    <h6>ALl live Offers</h6>
                    <ImageMainContainer>
                        {allOffersImages &&
                            allOffersImages.map((item, index) =>
                                <ImageContainer active={showDeleteButton}>
                                    {item.live == "1" &&
                                        <>
                                            <h6>{`prioririty of this image is :-${item.priority}`}</h6>
                                            <img style={{ width: "425px", height: "225px" }}
                                                src={`${AppConstants.imageURL}${item.image_url}`}
                                                onClick={() => imageHandler1(item)}
                                                alt={`image${index}`}
                                            />
                                            <button className="align-item-center m-auto px-3 py-2" onClick={() => unlive(item)}>Delete</button>
                                        </>
                                    }
                                    <br />
                                </ImageContainer>
                            )}
                    </ImageMainContainer>
                </Container>
            </Wrapper>
        </>
    );
}

export default LiveOffers;