import React, { Fragment, useState, useEffect, useCallback } from "react";
import MUIDataTable from "mui-datatables";
import { Popconfirm, Typography, message, Divider } from 'antd';
import { useNavigate } from "react-router-dom";
import queryString from 'query-string';
import { useDispatch, useSelector } from "react-redux";
import { getPoints, activatedPointerPosterData, gotPointsDataRow } from "../../../../redux/slices/pointerPosterSlice";


const ShowApprovalMaster = (props) => {
    const dispatch = useDispatch();
    const { allstoreData, pointsData, pointsDataRow } = useSelector((state) => ({ ...state.pointerPoster }));
    const navigate = useNavigate();

    useEffect(() => {
        const type = "approval_master"
        dispatch(getPoints(type));
    }, []);


    const getStoreName = (store_id) => {
        for (var i = 0; i < allstoreData.length; i++) {
            if (allstoreData[i].store_id == store_id) {
                return allstoreData[i].store_name
            }
        }
    }
    const edit = (e, value) => {
        let data = {};
        data.id = value[2];
        data.store_id = value[3];
        data.store_name = getStoreName(value[3]);
        data.camp_id = value[4];
        data.partner_id = value[5];
        data.response_camp_id = value[6];
        data.expected_date = value[7];
        data.payment_date = value[8];
        data.after_days = value[9];
        data.after_month = value[10];
        data.exact_day = value[11];
        data.type = "approval_master";
        dispatch(gotPointsDataRow(data));
        navigate("/points/addpoints");
        props.setPointsData("add_approval_master"); //necessary prop 
    };
    const cancel = () => {
        //console.log("Cancelled");
    };

    const callbackFunc = () => {
        const type = "approval_master"
        dispatch(getPoints(type));
    };
    const activated = (e, value) => {
        let data = {};
        data.id = value[2];
        data.types = "activate";
        data.type = "approval_master"
        let query = queryString.stringify(data);
        dispatch(activatedPointerPosterData(query), callbackFunc);
        message.success("Point Poster data Activated Successfully");
    };
    const deactivated = (e, value) => {
        let data = {};
        data.id = value[2];
        data.types = "deactivate";
        data.type = "approval_master"
        let query = queryString.stringify(data);
        dispatch(activatedPointerPosterData(query), callbackFunc);
        message.success("Point Poster data Deactivated Successfully");
    };

    const columns = [
        {
            name: "Action",
            label: "Action",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (

                    <div>
                        <Popconfirm
                            title="activate the activity"
                            onConfirm={e => activated(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#" style={{ padding: 5, fontWeight: "bolder", color: "#078101", border: "1px solid #aaa", borderRadius: "5px", boxShadow: "2px 2px 0px 0px", cursor: "pointer", marginBottom: "10px" }}>Activate</a>
                        </Popconfirm>
                        <Divider type="vertical"></Divider>
                        <Popconfirm
                            title="Deactivated"
                            onConfirm={e => deactivated(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#" style={{ padding: 5, fontWeight: "bolder", color: "#FF1502", border: "1px solid #aaa", borderRadius: "5px", boxShadow: "2px 2px 0px 0px", cursor: "pointer" }}>Deactivate</a>
                        </Popconfirm>
                    </div>
                )
            }
        },
        {
            name: "opertaion",
            label: "Operation",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div>
                        <Popconfirm
                            title="Edit this task?"
                            onConfirm={e => edit(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#">Edit</a>
                        </Popconfirm>
                    </div>
                )
            }
        },
        {
            name: "id",
            label: "Approval ID",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "store_id",
            label: "Store Name",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <div>{getStoreName(value)}</div>
            }
        },
        {
            name: "camp_id",
            label: "Camp ID",
            options: {
                filter: true,
                sort: true,
            }
        },

        {
            name: "partner_id",
            label: "Partner Id",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "response_camp_id",
            label: "Responsense Camp Id",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "expected_date",
            label: "Expected Date",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "payment_date",
            label: "Payment Date",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "after_days",
            label: "AFter DAys",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "after_month",
            label: "AFter Month",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "exact_day",
            label: "Exact Day",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "active",
            label: "Active",
            options: {
                filter: true,
                sort: false,
            }
        }
    ];
    const options = {
        filterType: 'partner_id',
    };

    return (
        <Fragment>
            <MUIDataTable
                title={"Show Description  (search by partner_id)"}
                data={pointsDataRow && pointsDataRow}
                columns={columns}
                options={options}
            />
        </Fragment>
    );
}
export default ShowApprovalMaster;