import React, { Fragment, useState, useEffect, useCallback } from "react";
import MUIDataTable from "mui-datatables";
import { Popconfirm, Typography, message, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import {
  activatedPointerPosterData,
  getPoints,
  gotPointsDataRow,
} from "../../../../redux/slices/pointerPosterSlice";

const ShowResponseMaster = (props) => {
  const dispatch = useDispatch();
  const [storesData, setStoresData] = useState([]);
  const pointsData = useSelector((s) => s.pointerPoster.pointsData);
  const navigate = useNavigate();
  useEffect(() => {
    let type = "response_master";
    dispatch(getPoints(type));

    var stores_data = JSON.parse(localStorage.getItem("stores"));

    if (stores_data) {
      setStoresData(stores_data);
    }
  }, []);

  const getStoreName = (store_id) => {
    for (var i = 0; i < storesData.length; i++) {
      if (storesData[i].store_id == store_id) {
        return storesData[i].store_name;
      }
    }
  };

  const edit = (e, value) => {
    let data = {};
    console.log(value, "value===========");
    data.id = value[2];
    data.partner_id = value[3];
    data.partner_name = value[4];
    data.unique_id = value[5];
    data.store_id = value[6];
    data.store_name = value[7];
    data.camp_name = value[6];
    data.partner_camp_id = value[7];
    data.action_date = value[8];
    // data.attribution_date = value[9];
    data.sale_amount = value[9];
    data.click_id = value[10];
    data.order_id = value[11];
    data.sub_id2 = value[12];
    data.sub_id3 = value[13];
    // data.status = value[13];
    data.payout = value[14];
    data.conversion_date = value[15];
    data.currency = value[16];
    data.point_posted = value[21];
    data.types = value[22];
    data.type = "response_master";
    dispatch(gotPointsDataRow(data));
    props.setPointsData("add_response_master");
  };
  const cancel = () => {
    //console.log("Cancelled");
  };

  const callbackFunc = () => {
    dispatch(getPoints("response_master"));
  };
  const activated = (e, value) => {
    let data = {};
    data.id = value[2];
    data.types = "activate";
    data.type = "response_master";
    let query = queryString.stringify(data);
    dispatch(activatedPointerPosterData(query));
    message.success("Point Response Master data Activated Successfully");
    callbackFunc();
  };
  const deactivated = (e, value) => {
    let data = {};
    data.id = value[2];
    data.types = "deactivate";
    data.type = "response_master";
    let query = queryString.stringify(data);
    dispatch(activatedPointerPosterData(query));
    message.success("Point Response Master data Deactivated Successfully");
    callbackFunc();
  };

  const columns = [
    {
      name: "Action",
      label: "Action",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{ display: "flex" }}>
            <Popconfirm
              title="activate the activity"
              onConfirm={(e) => activated(e, tableMeta.rowData)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <a
                href="#"
                style={{
                  padding: 5,
                  fontWeight: "bolder",
                  color: "#078101",
                  border: "1px solid #aaa",
                  borderRadius: "5px",
                  boxShadow: "2px 2px 0px 0px",
                  cursor: "pointer",
                  marginBottom: "10px",
                }}
              >
                Activate
              </a>
            </Popconfirm>
            <Divider type="vertical"></Divider>
            <Popconfirm
              title="Deactivated"
              onConfirm={(e) => deactivated(e, tableMeta.rowData)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <a
                href="#"
                style={{
                  padding: 5,
                  fontWeight: "bolder",
                  color: "#FF1502",
                  border: "1px solid #aaa",
                  borderRadius: "5px",
                  boxShadow: "2px 2px 0px 0px",
                  cursor: "pointer",
                }}
              >
                Deactivate
              </a>
            </Popconfirm>
          </div>
        ),
      },
    },
    {
      name: "opertaion",
      label: "Operation",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <Popconfirm
              title="Edit this task?"
              onConfirm={(e) => edit(e, tableMeta.rowData)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <a href="#">Edit</a>
            </Popconfirm>
          </div>
        ),
      },
    },
    {
      name: "id",
      label: "Response ID",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "partner_id",
      label: "Partner ID",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "partner",
      label: "Partner Name",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "unique_id",
      label: "Unique Id",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "camp_name",
      label: "Camp Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "partner_camp_id",
      label: "Partner Camp Id",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "action_date",
      label: "Action Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "sale_amount",
      label: "Sale Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "click_id",
      label: "Click Id",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "order_id",
      label: "Order Id",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "sub_id2",
      label: "Sub Id2",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "sub_id3",
      label: "sub_id_3",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "payout",
      label: "Payout",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "conversion_date",
      label: "Conversion Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "currency",
      label: "Currency",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "active",
      label: "Active",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];
  const options = {
    filterType: "store_name",
  };
  return (
    <Fragment>
      <MUIDataTable
        title={"Show Description  (search by store_name"}
        data={pointsData && pointsData}
        columns={columns}
        options={options}
      />
    </Fragment>
  );
};
export default ShowResponseMaster;
