import React, { useState } from "react";
import styled from "styled-components";
import { Table } from "antd";

const Wrapper = styled.div`
width: 100%;
display:flex;
flex-direction: column;
`;
const TableContainer = styled.div`
overflow:auto;
max-height:250px
`;


const QueryURLModal = (props) => {

    const changeHandler = (e) => {
        props.setQuery_url_data({ ...props.query_url_data, [e.target.name]: e.target.value });
        if (props.typeOfModal == "utmBlankModal") {

            props.setUtmBlankData({ ...props.utmblank_data, [e.target.name]: e.target.value });
        }
    };

    const add = (e) => {
        if (props.typeOfModal == "queryUrlModal") {
            props.setQuery_url_array([...props.query_url_array, props.query_url_data]);

        }
        else if (props.typeOfModal == "utmAddModal") {
            props.setUtm_add_array([...props.utm_add_array, props.query_url_data])
        }
        else {
            props.setUtm_blank_array([...props.utm_blank_array, props.utmblank_data])
        }
    }


    const remove = e => {
        if (props.typeOfModal == "queryUrlModal") {
            const newArr = [...props.query_url_array];
            let i = 0;
            for (const item of newArr) {
                let d = e.target.name.split(",~,")
                if (item.key_id === d[0] && item.key_value == d[1]) break;
                i++;
            }
            if (i == newArr.length) return console.log('not found');
            newArr.splice(i, 1)
            props.setQuery_url_array(newArr);
        }
        else if (props.typeOfModal == "utmAddModal") {
            const newArr = [...props.utm_add_array];
            let i = 0;
            for (const item of newArr) {
                let d = e.target.name.split(",~,")
                if (item.key_id === d[0] && item.key_value == d[1]) break;
                i++;
            }
            if (i == newArr.length) return console.log('not found');
            newArr.splice(i, 1)
            props.setUtm_add_array(newArr);
        }
        else {
            const newArr = [...props.utm_blank_array];
            let i = 0;
            for (const item of newArr) {
                if (item.key_value === e.target.name) break;
                i++;
            }
            if (i == newArr.length) return console.log('not found');
            newArr.splice(i, 1)
            props.setUtm_blank_array(newArr);
        }

    }

    const columns = [

        {
            title: 'Key_id',
            dataIndex: 'key_id',
            key: 'key_id',
        },
        {
            title: 'Key_value',
            dataIndex: 'key_value',
            key: 'key_value',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <a name={`${record.key_id},~,${record.key_value}`} onClick={remove}>Delete</a>
            ),
        },
    ];
    const columns1 = [

        {
            title: 'Key_value',
            dataIndex: `key_value`,
            key: `key_value`,
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <a name={`${record.key_value}`} onClick={remove}>Delete</a>
            ),
        },
    ];

    return (
        <>
            <TableContainer>
                {props.typeOfModal == "queryUrlModal" &&
                    <Table dataSource={props.query_url_array} columns={columns} />
                }
                {props.typeOfModal == "utmAddModal" &&
                    <Table dataSource={props.utm_add_array} columns={columns} />
                }
                {props.typeOfModal == "utmBlankModal" &&
                    <Table dataSource={props.utm_blank_array} columns={columns1} />
                }

            </TableContainer>
            {props.typeOfModal == "queryUrlModal" &&
                <div className="row">
                    <div className="col-6 col-lg-6">
                        <div class="mb-3">
                            <label for="" class="form-label">Key_id</label>
                            <input type="text" class="form-control" name="key_id" onChange={changeHandler} />
                        </div>
                    </div>
                    <div className="col-6 col-lg-6">
                        <div class="mb-3">
                            <label for="" class="form-label">Key_value</label>
                            <input type="text" class="form-control" name="key_value" onChange={changeHandler} />
                        </div>
                    </div>
                    <div className="col-6 col-lg-6 my-3 text-center">
                        <button type="submit" class="btn btn-primary" onClick={add}>Add</button>
                    </div>

                </div>
            }
            {props.typeOfModal == "utmAddModal" &&
                <div className="row">
                    <div className="col-6 col-lg-6">
                        <div class="mb-3">
                            <label for="" class="form-label">Key_id</label>
                            <input type="text" class="form-control" name="key_id" onChange={changeHandler} />
                        </div>
                    </div>
                    <div className="col-6 col-lg-6">
                        <div class="mb-3">
                            <label for="" class="form-label">Key_value</label>
                            <input type="text" class="form-control" name="key_value" onChange={changeHandler} />
                        </div>
                    </div>
                    <div className="col-6 col-lg-6 my-3 text-center">
                        <button type="submit" class="btn btn-primary" onClick={add}>Add</button>
                    </div>

                </div>
            }
            {props.typeOfModal == "utmBlankModal" &&
                <div className="row">
                    <div className="col-6 col-lg-6">
                        <div class="mb-3">
                            <label for="" class="form-label">Key_value</label>
                            <input type="text" class="form-control" name="key_value" onChange={changeHandler} />
                        </div>
                    </div>
                    <div className="col-6 col-lg-6 my-3 text-center">
                        <button type="submit" class="btn btn-primary" onClick={add}>Add</button>
                    </div>

                </div>
            }
        </>
    )

}
export default QueryURLModal;
