import React, { useEffect, useState } from "react";
import { Checkbox } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { addModulePermission, allPermissionByUser, getAllPanelUsers } from "../../redux/slices/userRoleManagementSlice";

// import { useState } from "react";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const UserRoleManagementChange = (props) => {
  const dispatch = useDispatch()
  const { getAllPanelUsers, userRoles, allPermissionByUser, userRoleManagement, addModulePermissionData } = useSelector((state) => ({ ...state.userRoleManagement.data }));
  const permssionUserByModules = userRoleManagement;

  const classes = useStyles();

  let { includedarray, excludedarray, clickedUserData } = props;
  useEffect(() => {
    includedarray && includedarray.map((item) => {
      setincludedPermissions([item.module_id])
    })
    excludedarray && excludedarray.map((item) => {
      setexcludePermissions([item.module_id])
    })

  }, [includedarray, excludedarray])

  const [includedPermissions, setincludedPermissions] = useState(includedarray ? includedarray.map((item) => item.module_id) : []);

  const [excludePermissions, setexcludePermissions] = useState(excludedarray ? excludedarray.map((item) => item.module_id) : []);
  let userId = clickedUserData && clickedUserData.id;

  const handleAdd = (item) => {
    if (excludePermissions.indexOf(item) == -1) {
      setexcludePermissions([...excludePermissions, item]);
    } else if (excludePermissions.includes(item)) {
      handleRemove(item);
    }
  };

  const handleRemove = (item) => {
    if (excludePermissions.includes(item)) {
      const newArr = [...excludePermissions];
      let index = newArr.indexOf(item);
      newArr.splice(index, 1);
      setexcludePermissions(newArr);
    }
  };
  const handleExcludedChecked = (e) => {
    let checked = e.target.checked;
    const value = e.target.value;
    if (checked && checked) {
      handleAdd(value);
    } else {
      handleRemove(value);
    }
    // checkedValue(value);
  };

  const handleIncludedAdd = (item) => {
    if (includedPermissions.indexOf(item) == -1) {
      setincludedPermissions([...includedPermissions, item]);
    } else if (includedPermissions.includes(item)) {
      handleIncludeRemove(item);
    }
  };

  const handleIncludeRemove = (item) => {
    if (includedPermissions.includes(item)) {
      const newArr = [...includedPermissions];
      let index = newArr.indexOf(item);
      newArr.splice(index, 1);
      setincludedPermissions(newArr);
    }
  };
  const handleIncludedChecked = (e) => {
    let checked = e.target.checked;
    const value = e.target.value;
    if (checked && checked) {
      handleIncludedAdd(value);
    } else {
      handleIncludeRemove(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {};
    data.user_id = userId;
    data.included_module_id_array = includedPermissions;
    data.excluded_module_id_array = excludePermissions;
    dispatch(addModulePermission(data));
  };

  return (
    <div className="container-fluid">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-12">
            <button
              className="btn btn-primary d-block ml-auto mt-3"
              onClick={() => props.setTab(false)}
            >
              Go Back
            </button>
            <div className=" d-flex flex-column align-items-center p-4 m-4 ">
              <div className="userid justify-content-between d-flex align-items-center w-100 mb-3">
                <p style={{ marginBottom: "0px" }}> User ID -</p>
                <p style={{ marginBottom: "0px" }}>{props.clickedUserData.id}</p>
              </div>
              <div className="userid justify-content-between d-flex align-items-center w-100 mb-3">
                <p style={{ marginBottom: "0px" }}> User Role -</p>
                <p style={{ marginBottom: "0px" }}>
                  {props.clickedUserData.role}
                </p>
              </div>
              <div className="userid justify-content-between d-flex align-items-center w-100 mb-3">
                <p style={{ marginBottom: "0px" }}>User Name -</p>
                <p style={{ marginBottom: "0px" }}>
                  {props.clickedUserData.username}
                </p>
              </div>
              <div className="userid justify-content-between d-flex align-items-center  w-100 mb-3">
                <p style={{ marginBottom: "0px", marginRight: "30px" }}>
                  {" "}
                  Permissions-{" "}
                </p>
                <p style={{ marginBottom: "0px" }}>
                  {permssionUserByModules &&
                    permssionUserByModules
                      .map((item) => item.module_name)
                      .join(", ")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <TableContainer component={Paper} style={{ marginBottom: "15px" }}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Include</TableCell>
                  <TableCell>Exclude</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className="w-auto">
                    {props.includePermissionModules &&
                      props.includePermissionModules.map((item, index) => {
                        return <Checkbox
                          onChange={handleIncludedChecked}
                          checked={includedPermissions.includes(item.module_id)}
                          value={item.module_id}
                          name={item.module_name}
                          key={index}
                        >

                          {item.module_name}
                        </Checkbox>
                      }
                      )}
                  </TableCell>
                  <TableCell className="w-auto">
                    {props.excludePermissionModules &&
                      props.excludePermissionModules.map((item, index) => (
                        <Checkbox
                          onChange={handleExcludedChecked}
                          checked={excludePermissions.includes(item.module_id)}
                          value={item.module_id}
                          name={item.module_name}
                          key={index}
                        >
                          {item.module_name}
                        </Checkbox>
                      ))}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <button
            className="btn btn-primary m-auto d-block"
            type="submit"
          // 
          >
            Submit
          </button>
        </div>
      </form>

    </div>
  );
};

export default UserRoleManagementChange;