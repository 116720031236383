import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { Typography, Divider, Input, Button } from "antd";
import Popup from "../../Repeated/Popup";
import AppConstants from "../../../constants/appConstants";
import { useDispatch, useSelector } from "react-redux";
import { addNews, getNewsUploadLogo, updateNewsData } from "../../../redux/slices/newsSlice";
import { useLocation } from "react-router";
const { Title, Text } = Typography;

const { TextArea } = Input;

const TopContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  @media screen and (max-width: 480px) {
    display: block;
  }
`;
const ImageMainContainer = styled.div`
  position: absolute;
  top: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 300px;
  overflow: auto;
`;
const Wrapper2 = styled.div`
  width: 100%;
  height: 310px;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;
const ImageContainer = styled.div`
  width: 200px;
  display: flex;
  height: 200px;
  flex-direction: row;
  padding: 50px;
  border: ${(props) => (props.active ? "1px solid #ffff00" : "none")};
  justify-content: space-between;
`;
const Data = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  > label {
    width: 30%;
  }
  > span {
    font-size: 18px;
    font-weight: bolder;
  }
  > input[type="text"] {
    width: 50%;
  }
  @media screen and (max-width: 480px) {
    display: block;
  }
`;

const RadioContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  padding: 5px 0;
  > div {
    color: #666;
    font-size: 14px;
    margin-right: 30px;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubmitButton = styled.div`
  width: 140px;
  height: 40px;
  display: flex;
  background: #0000ff;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  cursor: pointer;
  > p {
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 0px;
    color: #fff;
    text-align: center;
  }
`;

const DataRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const AddNews = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  console.log(location, "location"); //for updation purpose
  const { newsUploadImageData } = useSelector((state) => ({ ...state.news.data }));
  const [imageId, setImageId] = useState({
    id: "",
    url: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [allValues, setAllValues] = useState({
    logo: "",
    link: "",
    n_id: "",
  });
  const [isSelected, setSelected] = useState(false);

  useEffect(() => {
    dispatch(getNewsUploadLogo());
    if (newsUploadImageData.data) {
      setUpdatedData(true);
      setImageId({
        ...imageId,
        id: newsUploadImageData.data.id,
        url: newsUploadImageData.data.url
      });
      setAllValues({
        ...allValues,
        logo: newsUploadImageData.data.id,
        link: newsUploadImageData.data.url,
        n_id: newsUploadImageData.data.id,
      });
    }
  }, []);

  const [isUpdatedData, setUpdatedData] = useState(false);

  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };

  const cb_func = () => {
    //console.log("Executed");
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let data = {};
    data.logo = imageId.id;
    data.n_id = allValues.n_id;
    data.link = allValues.link;
    if (isUpdatedData) {
      dispatch(updateNewsData(data));
    } else {
      dispatch(addNews(data));
      cb_func();
    }
  };

  const imageHandler = (img) => {
    setImageId({
      id: img.id,
      url: img.url,
    });
    setSelected(!isSelected);
  };

  return (
    <Fragment>
      <TopContainer>
        <Title level={3}>Add News</Title>
      </TopContainer>
      <Divider />
      <Container>
        <form onSubmit={submitHandler}>
          <div className="row">
            <div className="col-12 col-lg-12">
              <Data>
                <Text>Image</Text>
                <img
                  src={imageId.url}
                  value={imageId.url}
                  alt="image selected"
                  style={{
                    width: "70px",
                    position: "absolute",
                    right: "270px",
                  }}
                />
                <Button
                  size="large"
                  type="primary"
                  name="image"
                  onClick={(e) => {
                    setIsOpen(!isOpen);
                  }}
                >
                  Choose Image
                </Button>
                {isOpen && (
                  <Popup
                    content={
                      <>
                        <b>News images</b>
                        <p>
                          <Wrapper2>
                            <ImageMainContainer>
                              {newsUploadImageData.data &&
                                newsUploadImageData.data.map((item, index) => (
                                  <ImageContainer active={isSelected}>
                                    <img
                                      src={`${AppConstants.imageURL}${item.url}`}
                                      onClick={() => {
                                        imageHandler(item);
                                      }}
                                      alt={`image${index}`}
                                    />
                                    <br />
                                  </ImageContainer>
                                ))}
                            </ImageMainContainer>
                          </Wrapper2>
                          <Button
                            style={{ marginRight: '5px' }}
                            size="large"
                            type="primary"
                            name="image"
                            onClick={(e) => {
                              setIsOpen(!isOpen);
                            }}
                          >
                            Submit
                          </Button>
                          <Button
                            size="large"
                            type="primary"
                            name="image"
                            onClick={(e) => {
                              setIsOpen(!isOpen);
                            }}
                          >
                            Close
                          </Button>
                        </p>

                        {/* <form
                          onSubmit={() => {
                            setIsOpen(!isOpen);
                          }}
                        >
                          <br />
                          <input type="submit" value="Select" />
                        </form> */}
                      </>
                    }
                    handleClose={() => {
                      setIsOpen(!isOpen);
                    }}
                  />
                )}
              </Data>

              <DataRow>
                <label htmlFor="" className="form-label w-75">
                  Add News
                </label>
                <input
                  type="url"
                  className="form-control"
                  name="link"
                  value={allValues.link}
                  // value={allValues.tips}
                  onChange={changeHandler}
                  required={true}
                />
              </DataRow>
              {/* <ButtonContainer>
                <SubmitButton onClick={submitHandler}>
                  <p>Submit</p>
                </SubmitButton>
              </ButtonContainer> */}
            </div>
            <div className="col-12 col-lg-12 my-3 text-center ">
              <button
                type="submit"
                className="btn btn-primary"
              // onClick={submitForm}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </Container>
    </Fragment>
  );
};

export default AddNews;
