import React, { Component } from "react";
import styled from "styled-components";
import { Typography, Input, Icon } from "antd";
import Images from "../../images";

const { Title, Text } = Typography;

const { TextArea } = Input;

const Wrapper = styled.div`
width: 100%;
`;

const MainContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;

const Data = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  > label {
    width: 30%;
  }
  >span{
    font-size: 18px;
    font-weight: bolder;
  }
  > input[type="text"] {
    width: 50%;
  }
`;

const InputConatiner = styled.div`
width: 250px;
display: flex;
border: 1px solid #bbb;
justify-content: center;
align-items: center;
`;


class ChangePassword extends Component {
    isPassMatched = () => {
        const { pass1, pass2 } = this.props;
        if (pass1 !== "" && pass2 !== "" && pass1 == pass2) {
            return true;
        }
        console.log(pass1, pass2);
    }
    render() {
        const { pass1, pass2, cur_password } = this.props;
        return (
            <Wrapper>
                <Title level={2}>Change Password</Title>
                <MainContainer>
                    <Data>
                        <Text>Enter Current Password</Text>
                        <Input
                            size="large"
                            type="text"
                            name="cur_password"
                            placeholder="Enter Current Password"
                            value={cur_password}
                            onChange={this.props.handleChange}
                        />
                    </Data>
                    <Data>
                        <Text>Enter New Password</Text>
                        <Input
                            size="large"
                            type="text"
                            name="pass1"
                            placeholder="Max 50 Characters"
                            value={pass1}
                            onChange={this.props.handleChange}
                        />
                    </Data>
                    <Data>
                        <Text>Confirm Password</Text>
                        <InputConatiner>
                            <Input
                                size="large"
                                type="password"
                                name="pass2"
                                placeholder="Max 50 Characters"
                                value={pass2}
                                onChange={this.props.handleChange}
                            />
                            {this.isPassMatched() &&
                                <img src={Images.check} style={{ width: "40px", height: "40px" }} />
                                // <Icon type="check" style={{color:"#00ff00",fontSize:"20px",fontWeight:"800"}} />
                            }
                        </InputConatiner>
                    </Data>
                </MainContainer>
            </Wrapper>
        )
    }
}

export default ChangePassword