import React, { useEffect, useState } from "react";
// import { Table } from "antd";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { showRoles, addRoles } from "../../../../redux/slices/profileSlice";
// import { addRoles } from "../../../../redux/api/profileApi";

const AddRoles = (props) => {
  const dispatch = useDispatch();
  const show_roles = useSelector(state => state.profile.data.showRoles)
  const [role, setRole] = useState("");

  useEffect(() => {
    dispatch(showRoles())

  }, []);

  const handleSubmit = (e) => {
    //for dynamic table update (only temporary)
    e.preventDefault();
    let data = {};
    data.user_type = role;
    dispatch(addRoles(data))
    setRole("");
  };

  const cbfunc = () => {
    dispatch(showRoles());
  };

  return (
    <div className="container">
      <div className="row">
        <div className="formContainer col-md-12  ">
          <button
            className="btn btn-primary d-block ml-auto mt-3"
            onClick={() => props.setTab("profile")}
          >
            Go Back
          </button>
          <form onSubmit={handleSubmit}>
            <div class="form-group">
              <label for="role">Role</label>
              <input
                type="text"
                className="form-control"
                id="role"
                aria-describedby="emailHelp"
                placeholder="Enter Role"
                name="role"
                onChange={(e) => setRole(e.target.value)}
              />
              {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
            </div>
            <button type="submit" class="btn btn-primary d-block m-auto">
              Submit
            </button>
          </form>
        </div>
        <div className="table_container col-md-12 mt-3">
          <TableContainer component={Paper} className="mb-3">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Role Id</TableCell>
                  <TableCell>Role Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {show_roles &&
                  show_roles.map((item, index) => {
                    return (
                      <TableRow>
                        <>
                          <TableCell>{item.id}</TableCell>
                          <TableCell>{item.user_type}</TableCell>
                        </>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>


        </div>
      </div>
    </div>
  );
};

export default AddRoles;
