import axios from "axios";
import AppConstants from "../../constants/appConstants";

const API = axios.create({
  baseURL: `${AppConstants.baseURL}`,
});

export const addPoints = (data) => API.post("/points/addpoints", data);
export const removePointMaster = (data) =>
  API.post("/points/removepointmaster", data);
export const uploadResponseCsv = (data) => API.post("/points/uploadcsv", data);
export const getActivityCodeData = () => API.get("/points/getactivitycode");
export const activatedPointerPosterData = (query) =>
  API.get(`/points/activatepoints?${query}`);
export const updatePointerPoster = (data) =>
  API.post("/points/updatepoints", data);
export const activeTaskData = () => API.get("/user/activetasks");
export const getallStores = () => API.get("/managestores/getallstores");

export const getUploadCsvApprovalReportApi = (formData) =>
  API.post("/points/checkuniqueidpointpost", formData);

/////=========Used in affiliate ==============/////////////

export const getPoints = (query) => API.get(`/points/getpoints?type=${query}`);

export const downloadReportsWithUniqueIdHaveIssueApi = (formData) =>
  API.post("/points/downloaderrorcsv", formData);

export const finalApprovalSubmitReportApi = (formData) =>
  API.post("/points/finalapporvalreport", formData);

