import React, { Fragment, useState, useEffect, useCallback } from "react";
import MUIDataTable from "mui-datatables";
import { Popconfirm, Typography, message, Divider } from 'antd';
import { useNavigate } from "react-router-dom";
import queryString from 'query-string';
import { useDispatch, useSelector } from "react-redux";
import { getStoreCategoryData, gotStoreCategoryDataRow } from "../../../../redux/slices/manageStoreSlice";

const ShowCategory = (props) => {
    // let history = useHistory();  //useHistory is being deprecated in react-router v6 or above
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { storeCategoryData } = useSelector((s) => s.manageStores);

    const cbfunc = () => {
        dispatch(getStoreCategoryData());
    }

    const edit = (e, value) => {
        let data = {};
        data.type = "assigncategory";
        data.store_id = value[1];
        data.store_name = value[2];
        data.category_id = value[3];
        data.category_name = value[4];
        data.tags = value[5];
        // let text=value[3];
        // let text1=text.split(",");
        // data.category_id=text;
        // let dataArray=[];
        // let valueOfCatId=JSON.stringify(value[3]).split(",")
        // for(let i=0;i<=valueOfCatId.length;i++){
        //     dataArray.push(valueOfCatId[i])
        // }
        dispatch(gotStoreCategoryDataRow(data));
        props.setStoreType("addCategory");
    };

    const cancel = () => {
        //console.log("Cancelled");
    };

    useEffect(() => {
        dispatch(getStoreCategoryData());
    }, []);

    const columns = [
        // {
        //     name: "Action",
        //     label: "Action",
        //     options: {
        //         filter: false,
        //         customBodyRender: (value, tableMeta, updateValue) => (

        //             <div>
        //                 <Popconfirm
        //                     title="activate the activity"
        //                     onConfirm={e => activated(e, tableMeta.rowData)}
        //                     onCancel={cancel}
        //                     okText="Yes"
        //                     cancelText="No"
        //                 >
        //                     <a href="#" style={{ padding: 5, fontWeight: "bolder", color: "#078101", border: "1px solid #aaa", borderRadius: "5px", boxShadow: "2px 2px 0px 0px", cursor: "pointer", marginBottom: "10px" }}>Aactivated</a>
        //                 </Popconfirm>
        //                 <Divider type="vertical"></Divider>
        //                 <Popconfirm
        //                     title="Deactivated"
        //                     onConfirm={e => deactivated(e, tableMeta.rowData)}
        //                     onCancel={cancel}
        //                     okText="Yes"
        //                     cancelText="No"
        //                 >
        //                     <a href="#" style={{ padding: 5, fontWeight: "bolder", color: "#FF1502", border: "1px solid #aaa", borderRadius: "5px", boxShadow: "2px 2px 0px 0px", cursor: "pointer" }}>Deactivated</a>
        //                 </Popconfirm>
        //             </div>
        //         )
        //     }
        // },

        {
            name: "opertaion",
            label: "Operation",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div>
                        <Popconfirm
                            title="Edit this task?"
                            onConfirm={e => edit(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#">Edit</a>
                        </Popconfirm>
                    </div>
                )
            }
        },
        {
            name: "store_id",
            label: "Store ID",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "store_name",
            label: "Store Name",
            options: {
                filter: false,
                sort: false,
            }
        },

        {
            name: "category_ids",
            label: "Category Id",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "category_names",
            label: "Category Name",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "tags",
            label: "Tags",
            options: {
                filter: false,
                sort: false,
            }
        },

        {
            name: "active",
            label: "Active",
            options: {
                filter: true,
                sort: false,
            }
        },
    ];

    const options = {
        filterType: 'store_name',
    };
    return (
        <Fragment>
            <MUIDataTable
                title={"Show Description  (search by store_name)"}
                data={storeCategoryData && storeCategoryData}
                columns={columns}
                options={options}
            />
        </Fragment>
    );
}
export default ShowCategory;