import ActionTypes from "../constants/actionTypes";
import initialState from "../stores/initialState";

export default (state = initialState.news, action) => {
  switch (action.type) {
    case ActionTypes.GOT_NEWS:
      return {
        ...state,
        newsData: action.payload
      };
    case ActionTypes.GOT_NEWS_UPLOAD_LOGO:
      return {
        ...state,
        newsUploadLogo: action.payload.data
      };
    case ActionTypes.GOT_NEWS_DATA_ROW:
      return {
        ...state,
        newsDataRow: action.payload
      }
    default:
      return state;
  }
};