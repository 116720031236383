import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api/affiliateApi";
import { message } from "antd";

const initialState = {
  partnerMasterData: [],
  partnerMasterDataRow: null,
  affClicksDataRow: [],
  partnerAsStoresData: [],
  partnerAsStoresDataRow: [],
  activePartnersData: [],
  allPartners: [],
  allActivities: [],
  responseActivePartner: [],
  responseCSV: [],
};

export const addAffiliate = createAsyncThunk(
  "/affiliate/addaffiliate",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.addAffiliate(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Added Successfully!"
        );
        // return resp.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Added/Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const updateAffiliateLinkDetails = createAsyncThunk(
  "/affiliate/updateaffiliatelinks",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.updateAffiliateLinkDetails(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Updated Successfully!"
        );
        // return resp.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Updated/Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const addPartners = createAsyncThunk(
  "/affiliate/addpartners",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.addPartners(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Added Successfully!"
        );
        // return resp.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Added/Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const activateDeactivateAffLinks = createAsyncThunk(
  "/affiliate/activateafflinks",
  async (query, { rejectWithValue }) => {
    try {
      const resp = await api.activateDeactivateAffLinks(query);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message
            ? resp.data.message
            : "Activated/Deactivated Successfully!"
        );
        // return resp.data.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Activated/Deactivated OR Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const updatePartnerAsStore = createAsyncThunk(
  "/affiliate/updatepartnerstore",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.updatePartnerAsStore(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Updated Successfully!"
        );
        // return resp.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Updated/Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const SearchPartnersByStores = createAsyncThunk(
  "/affiliate/searchpartnersbystore",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.SearchPartnersByStores(data);
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getPartnerMasterData = createAsyncThunk(
  "/affiliate/getpartnermaster",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.getPartnerMasterData();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const activatePartnerMaster = createAsyncThunk(
  "/affiliate/activatepartnermaster",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.activatePartnerMaster(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message
            ? resp.data.message
            : "Activated/Deactivated Successfully!"
        );
        // return resp.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Activated/Deactivated OR Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const updateMasterPartnerDetails = createAsyncThunk(
  "/affiliate/updatemasterpartner",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.updateMasterPartnerDetails(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Updated Successfully!"
        );
        // return resp.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Updated / Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const showPartnersAsStore = createAsyncThunk(
  "/affiliate/getpartnerstore",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.showPartnersAsStore();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getPartners = createAsyncThunk(
  "/affiliate/getallpartners",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.getPartners();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//the below slice has been removed with formData
export const getActivities = createAsyncThunk(
  "/affiliate/getallactivities",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.getActivities();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getResponseActivePartners = createAsyncThunk(
  "/affiliate/getresponseactivepartners",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.getResponseActivePartners();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getResponseMasterDataCSV = createAsyncThunk(
  "/affiliate/responsemastercsv",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await api.getResponseMasterDataCSV(formData);
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

const affiliateSlice = createSlice({
  name: "affiliate",
  initialState,
  reducers: {
    gotPartnerMasterDataRow: (state, action) => {
      state.partnerMasterDataRow = action.payload;
    },

    gotAffLinksData: (state, action) => {
      state.affClicksDataRow = action.payload;
    },

    gotPartnerAsStoresDataRow: (state, action) => {
      state.partnerAsStoresDataRow = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getPartnerMasterData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPartnerMasterData.fulfilled, (state, action) => {
      state.partnerMasterData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getPartnerMasterData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ==============================================================================================
    builder.addCase(showPartnersAsStore.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showPartnersAsStore.fulfilled, (state, action) => {
      state.partnerAsStoresData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(showPartnersAsStore.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ==============================================================================================
    builder.addCase(SearchPartnersByStores.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(SearchPartnersByStores.fulfilled, (state, action) => {
      state.activePartnersData = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(SearchPartnersByStores.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ====================USED IN POINTER POSTER==========================================================================
    builder.addCase(getPartners.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPartners.fulfilled, (state, action) => {
      state.allPartners = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getPartners.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ==============================================================================================
    builder.addCase(getActivities.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getActivities.fulfilled, (state, action) => {
      state.allActivities = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getActivities.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ==============================================================================================
    builder.addCase(getResponseActivePartners.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getResponseActivePartners.fulfilled, (state, action) => {
      state.responseActivePartner = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getResponseActivePartners.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ==============================================================================================
    builder.addCase(getResponseMasterDataCSV.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getResponseMasterDataCSV.fulfilled, (state, action) => {
      state.responseCSV = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getResponseMasterDataCSV.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ==============================addAffiliate===========================================
    builder.addCase(addAffiliate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addAffiliate.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(addAffiliate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ==============================updateAffiliateLinkDetails===========================================
    builder.addCase(updateAffiliateLinkDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAffiliateLinkDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateAffiliateLinkDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ==============================addPartners===========================================
    builder.addCase(addPartners.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addPartners.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(addPartners.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ==============================activateDeactivateAffLinks===========================================
    builder.addCase(activateDeactivateAffLinks.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(activateDeactivateAffLinks.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(activateDeactivateAffLinks.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ==============================updatePartnerAsStore===========================================
    builder.addCase(updatePartnerAsStore.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePartnerAsStore.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updatePartnerAsStore.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ==============================activatePartnerMaster===========================================
    builder.addCase(activatePartnerMaster.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(activatePartnerMaster.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(activatePartnerMaster.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ==============================updateMasterPartnerDetails===========================================
    builder.addCase(updateMasterPartnerDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateMasterPartnerDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateMasterPartnerDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const {
  gotPartnerMasterDataRow,
  gotAffLinksData,
  gotPartnerAsStoresDataRow,
} = affiliateSlice.actions;

export default affiliateSlice.reducer;
