import React, { useState } from "react";
import styled from "styled-components";
import { Typography, Radio } from "antd";
import PaymentStatus from "./paymentStatus";
import ShowPaymentStatus from "./showPaymentStatus";
import ShowApprovalRejectedStatus from "./showRejectedApprovalStatus";
import ShowProcessingPaymentStatus from "./ShowProcessingStatus";
import ManualPayments from "./ManualPayment/index.js";
import Voucher from "./Voucher";
import { useParams } from "react-router-dom";

const { Title } = Typography;
const Wrapper = styled.div`
  width: 100%;
`;
const BodyContainer = styled.div``;

const RadioContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  margin: 15px auto;
  padding: 5px 0;
  > div {
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    margin-right: 30px;
  }
`;

const PaymentRedemption = (props) => {
  const [menu_type, setMenuType] = useState("show");
  const params = useParams();
  console.log(params, "paramaters");
  const handleRadio = (e) => {
    setMenuType(e.target.value);
  };
  const selMenu = () => {
    setMenuType("add");
  };
  //   const changeMenu = (e) => {
  //     // console.log(window.location.pathname,"pathhh");
  //     setStoreType(window.location.pathname);
  // };

  // paymentStatusRejeactedApproval
  return (
    <Wrapper>
      <BodyContainer>
        {window.location.pathname == "/redeem/paymentstatus" && (
          <div className="row">
            <Title level={3}>Payment Status</Title>
            <div className="col-12 col-lg-12">
              <RadioContainer>
                <div>
                  <Radio
                    type="radio"
                    value={"add"}
                    name="type"
                    checked={menu_type == "add"}
                    onChange={handleRadio}
                  />
                  Payment Status
                </div>
                <div>
                  <Radio
                    type="radio"
                    value={"show"}
                    name="type"
                    checked={menu_type == "show"}
                    onChange={handleRadio}
                  />
                  Show Redeem Status(Pending)
                </div>
                <div>
                  <Radio
                    type="radio"
                    value={"showRejectedApproval"}
                    name="type"
                    checked={menu_type == "showRejectedApproval"}
                    onChange={handleRadio}
                  />
                  Show Redeem Status(Rejected && Approval)
                </div>
                <div>
                  <Radio
                    type="radio"
                    value={"showProcessingPayment"}
                    name="type"
                    checked={menu_type == "showProcessingPayment"}
                    onChange={handleRadio}
                  />
                  Show Redeem Status(Processing)
                </div>
              </RadioContainer>

              <BodyContainer>
                <div>
                  {menu_type == "add" && (
                    <PaymentStatus setMenuType={setMenuType} />
                  )}
                  {menu_type == "show" && (
                    <ShowPaymentStatus selMenu={selMenu} />
                  )}
                  {menu_type == "showRejectedApproval" && (
                    <ShowApprovalRejectedStatus selMenu={selMenu} />
                  )}
                  {menu_type == "showProcessingPayment" && (
                    <ShowProcessingPaymentStatus
                      selMenu={selMenu}
                      setMenuType={setMenuType}
                    />
                  )}
                </div>
              </BodyContainer>
            </div>
          </div>
        )}

        {window.location.pathname == "/redeem/vouchers" && <Voucher />}
        {window.location.pathname == "/redeem/manualpayment" && (
          <ManualPayments />
        )}
      </BodyContainer>
    </Wrapper>
  );
};

export default PaymentRedemption;
