import axios from "axios";
import AppConstants from "../../constants/appConstants";

const API = axios.create({
  baseURL: `${AppConstants.baseURL}`,
});

// =======================PaymentRedemption=====================
export const UpdateRedemptionStatus = (data) =>
  API.put("/redemption/updateredemptionstatus", data);
export const getPaymentStatus = () => API.get("/redemption/getpaymentstatus");
export const redemptionStatusMaster = () =>
  API.get("/redemption/redemptionstatus");
export const addVoucherRedemptionApi = (formData) =>
  API.post("/redemption/addvoucherredemption", formData);

export const ManualPaymentUpiApi = (data) =>
  API.post("/auth/payoutrazerpayupi", data);
export const userDetailsApi = (data) => API.post("/auth/userdetails", data);
