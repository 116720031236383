import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api/taskReminderApi";
import { message } from "antd";



const initialState = {
  taskReminderData: [],
  task_statusData: [],
  assignedTaskByAdmin: [],
  completeTaskReminderData: [],
  updatedTaskData: [],
  allRolesData: [],
  allPanelUsersData: [],
  upcomingResults: [],
  recurrence_assignUsers: [],
  recurrenceData: [],
  user: [],
  date: [],
  task: [],

}


export const addTodoTaskReminder = createAsyncThunk(
  "/auth/addtaskremind",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.addTodoTaskReminder(data);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Added Successfully!');
        // return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Not Added!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const updateTodoTaskRemind = createAsyncThunk(
  "/auth/updatetaskremind",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.updateTodoTaskRemind(data);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Updated Successfully!');
        // return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Not Updated!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const deleteTodoTaskRemind = createAsyncThunk(
  "/auth/deletetaskremind",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.deleteTodoTaskRemind(data);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Deleted Successfully!');
        // return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Not Deleted!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const updateTodoStatus = createAsyncThunk(
  "/auth/updatetodostatus",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.updateTodoStatus(data);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Updated Successfully!');
        // return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Not Updated!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const snoozeTodoTaskByUser = createAsyncThunk(
  "/auth/snoozetodotask",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.snoozeTodoTaskByUser(data);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Snoozed Successfully!');
        // return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Not Snoozed / Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const showRecurrenceTask = createAsyncThunk(
  "/auth/showrecurrencetask",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.showRecurrenceTask(data);
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);


export const getTodoTaskByUser = createAsyncThunk(
  "/auth/showtodotask",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.getTodoTaskByUser();
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const showTodoTaskAssignedAdmin = createAsyncThunk(
  "/auth/taskassignbyadmin",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.showTodoTaskAssignedAdmin();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

const taskReminderSlice = createSlice({
  name: "taskReminder",
  initialState,
  reducers: {
    gotUpdateTaskData: (state, action) => {
      state.updatedTaskData = action.payload;
    },
  },


  extraReducers: (builder) => {

    //   // ========================getTodoTaskByUser====================================================================== 

    builder.addCase(getTodoTaskByUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTodoTaskByUser.fulfilled, (state, action) => {
      state.taskReminderData = action.payload.data;
      state.task_statusData = action.payload.todo_status;
      state.completeTaskReminderData = action.payload.completetodoResult;
      state.upcomingResults = action.payload.upcomingResults;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getTodoTaskByUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });



    //   // ========================showRecurrenceTask====================================================================== 

    builder.addCase(showRecurrenceTask.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showRecurrenceTask.fulfilled, (state, action) => {
      state.recurrenceData = action.payload.data;
      state.recurrence_assignUsers = action.payload.assigned_users_recureence;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(showRecurrenceTask.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });


    //   // ==============================showTodoTaskAssignedAdmin================================================================
    builder.addCase(showTodoTaskAssignedAdmin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showTodoTaskAssignedAdmin.fulfilled, (state, action) => {
      state.assignedTaskByAdmin = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(showTodoTaskAssignedAdmin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    //   // ==============================addTodoTaskReminder================================================================
    builder.addCase(addTodoTaskReminder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addTodoTaskReminder.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(addTodoTaskReminder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    //   // ==============================updateTodoTaskRemind================================================================
    builder.addCase(updateTodoTaskRemind.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateTodoTaskRemind.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateTodoTaskRemind.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    //   // ==============================deleteTodoTaskRemind================================================================
    builder.addCase(deleteTodoTaskRemind.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteTodoTaskRemind.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteTodoTaskRemind.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    //   // ==============================updateTodoStatus================================================================
    builder.addCase(updateTodoStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateTodoStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateTodoStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    //   // ==============================snoozeTodoTaskByUser================================================================
    builder.addCase(snoozeTodoTaskByUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(snoozeTodoTaskByUser.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(snoozeTodoTaskByUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { gotUpdateTaskData, } = taskReminderSlice.actions;

export default taskReminderSlice.reducer;
