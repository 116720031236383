import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Popconfirm, Typography } from 'antd';
import { useNavigate } from "react-router-dom";
import queryString from 'query-string';
import { useDispatch, useSelector } from "react-redux";
import { activateDeactivateAffLinks, gotAffLinksData } from "../../../../redux/slices/affiliateSlice";
import { getPoints } from "../../../../redux/slices/pointerPosterSlice";


const ShowAffLink = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const pointsData = useSelector(s => s.pointerPoster.pointsData);
    useEffect(() => {
        dispatch(getPoints("aff_links"));
    }, []);

    const edit = (e, value) => {
        let data = {};
        data.id = value[1];
        data.store_id = value[2];
        data.store_name = value[3];
        data.camp_id = value[4];
        data.partner_id = value[5];
        data.partner = value[6];
        data.payment_date = value[7];
        data.after_days = value[8];
        data.after_month = value[9];
        data.exact_day = value[10];
        data.utm_add = value[11];
        data.utm_blank = value[12];
        data.deeplink_support = value[13];
        dispatch(gotAffLinksData(data));
        // history.push("/affiliate/applinking"); //useHistory is deperecated in router v6 used useNavigate alternative
        // navigate("/affiliate/applinking");
        props.setAffType("add_aff_link");   //do not remove setAffType is a necessary prop - bharat
    };
    const callbackFunc = () => {
        dispatch(getPoints("aff_links"))
    };

    const cancel = () => {
        // console.log("Cancelled");
    };

    const activated = (e, value) => {
        let data = {};
        data.id = value[2];
        data.type = "activate";
        let query = queryString.stringify(data);
        dispatch(activateDeactivateAffLinks(query));
        callbackFunc();
    };
    const deactivated = (e, value) => {
        let data = {};
        data.id = value[2];
        data.type = "deactivate";
        let query = queryString.stringify(data);
        dispatch(activateDeactivateAffLinks(query));
        callbackFunc();
    };
    const columns = [
        // {
        //     name: "Action",
        //     label: "Action",
        //     options: {
        //         filter: false,
        //         customBodyRender: (value, tableMeta, updateValue) => (

        //             <div>
        //                 <Popconfirm
        //                     title="activate the activity"
        //                     onConfirm={e => activated(e, tableMeta.rowData)}
        //                     onCancel={cancel}
        //                     okText="Yes"
        //                     cancelText="No"
        //                 >
        //                     <a href="#" style={{ padding: 5, fontWeight: "bolder", color: "#078101", border: "1px solid #aaa", borderRadius: "5px", boxShadow: "2px 2px 0px 0px", cursor: "pointer", marginBottom: "10px" }}>Activated</a>
        //                 </Popconfirm>
        //                 {/* <Divider type="vertical"></Divider> */}
        //                 <Popconfirm
        //                     title="Deactivated"
        //                     onConfirm={e => deactivated(e, tableMeta.rowData)}
        //                     onCancel={cancel}
        //                     okText="Yes"
        //                     cancelText="No"
        //                 >
        //                     <a href="#" style={{ padding: 5, fontWeight: "bolder", color: "#FF1502", border: "1px solid #aaa", borderRadius: "5px", boxShadow: "2px 2px 0px 0px", cursor: "pointer" }}>Deactivated</a>
        //                 </Popconfirm>
        //             </div>
        //         )
        //     }
        // },
        {
            name: "opertaion",
            label: "Operation",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div>
                        <Popconfirm
                            title="Edit this task?"
                            onConfirm={e => edit(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#">Edit</a>
                        </Popconfirm>
                    </div>
                )
            }
        },
        {
            name: "id",
            label: "Affiliate ID",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "store_id",
            label: "Store ID",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "store_name",
            label: "Store Name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "camp_id",
            label: "Camp Id",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "partner_id",
            label: "Partner Id",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "partner",
            label: "Partner Name",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "payment_date",
            label: "Payment Date",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "after_days",
            label: "After Days",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "after_month",
            label: "After Month",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "exact_day",
            label: "Exact Day",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "utm_add",
            label: "utm_add",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "utm_blank",
            label: "UTM Blank",
            options: {
                filter: true,
                sort: false,
            }
        }, {
            name: "deeplink_support",
            label: "Deeplink Support",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "active",
            label: "Active",
            options: {
                filter: true,
                sort: false,
            }
        },
    ];
    const options = {
        filterType: 'store_name',
    };
    return (
        <Fragment>
            <MUIDataTable
                title={"Show Affiliate Linking (search by store_name)"}
                data={pointsData && pointsData}
                columns={columns}
                options={options}
            />
        </Fragment>
    );
};

export default ShowAffLink;




