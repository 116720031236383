import React, { useState } from "react";
import styled from "styled-components";
import { Typography } from "antd";
import AddFeaturedStore from "./addFeaturedStore";
import ShowFeaturedStore from "./showFeaturedStore";
const { Title } = Typography;
const Wrapper = styled.div`
  width: 100%;

  >div{
      >div{
        >button{
            background: linear-gradient(259deg, rgb(236, 94, 58) 0%, rgb(249, 186, 99) 100%) 0% 0% no-repeat padding-box padding-box transparent;
            padding:7px 20px;
            border-radius:20px;
            float:right;
        }
      }
  }

`;
const BodyContainer = styled.div`
  overflow: auto;
`;
const Container = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const FeaturedStores = () => {
    const [storeType, setStoreType] = useState("showFeaturedStore");
    const changeTab = (val) => {
        setStoreType(val);
    };
    return (
        <Wrapper>
            <Title className="my-3" level={3}>Create/show Featured Stores </Title>
            <div className="row">
                <div className="col-12 col-lg-12">
                    {storeType == "showFeaturedStore" && (
                        <button onClick={() => changeTab("addFeaturedStore")}>Create Featured Store</button>
                    )}
                    {storeType == "addFeaturedStore" && (
                        <button onClick={() => changeTab("showFeaturedStore")}>Show Featured Store</button>
                    )}
                </div>
            </div>
            <BodyContainer>
                {storeType == "addFeaturedStore" && (
                    <AddFeaturedStore />
                )}
                {storeType == "showFeaturedStore" && <ShowFeaturedStore changeTab={changeTab} />}
            </BodyContainer>
        </Wrapper>
    );
};

export default FeaturedStores;
