import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api/pointerPosterApi";
import { message } from "antd";

const initialState = {
  pointsData: [],
  pointsDataRow: [],
  pointsMasterDataRow: [],
  getActivityTaskData: [],
  activityCodeData: [],
  allstoreData: [],
  csv_id: "",
  ponits_diff_data: [],
  record_not_found: "",
  downloadedDataWithIssueData: [],
};

export const addPoints = createAsyncThunk(
  "/points/addpoints",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.addPoints(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Added Successfully!"
        );
        // return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : "Not Added!");
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const removePointMaster = createAsyncThunk(
  "/points/removepointmaster",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.removePointMaster(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Removed Successfully!"
        );
        // return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : "Not Removed!");
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const uploadResponseCsv = createAsyncThunk(
  "/points/uploadcsv",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.uploadResponseCsv(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Uploaded Successfully!"
        );
        // return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : "Not Uploaded!");
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getUploadCsvApprovalReport = createAsyncThunk(
  "/points/checkuniqueidpointpost",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await api.getUploadCsvApprovalReportApi(formData);
      console.log(resp.data, "resp.data");
      if (resp.data.code === 200) {
        message.success(
          resp.data.msg ? resp.data.msg : "Uploaded Successfully!"
        );
        return resp.data.data;
      } else {
        console.log("eeeee");
        message.error(resp.data.msg ? resp.data.msg : "Not Uploaded!");
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const downloadReportsWithUniqueIdHaveIssue = createAsyncThunk(
  "/points/downloaderrorcsv",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await api.downloadReportsWithUniqueIdHaveIssueApi(formData);
      // console.log(resp.data, "resp.data");
      if (resp.data.code === 200) {
        message.success(
          resp.data.msg ? resp.data.msg : "Uploaded Successfully!"
        );
        return resp.data.data;
      } else {
        console.log("eeeee");
        message.error(resp.data.msg ? resp.data.msg : "Not Uploaded!");
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const finalApprovalSubmitReport = createAsyncThunk(
  "/points/finalapporvalreport",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await api.finalApprovalSubmitReportApi(formData);
      // console.log(resp.data, "resp.data");
      if (resp.data.code === 200) {
        message.success(
          resp.data.msg ? resp.data.msg : "Uploaded Successfully!"
        );
      } else {
        console.log("eeeee");
        message.error(resp.data.msg ? resp.data.msg : "Not Uploaded!");
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getActivityCodeData = createAsyncThunk(
  "/points/getactivitycode",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.getActivityCodeData();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const activatedPointerPosterData = createAsyncThunk(
  "/points/activatepoints",
  async (query, { rejectWithValue }) => {
    try {
      const resp = await api.activatedPointerPosterData(query);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message
            ? resp.data.message
            : "Activated/Deactivated Successfully!"
        );
        // return resp.data.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Activated/Deactivated OR Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const updatePointerPoster = createAsyncThunk(
  "/points/updatepoints",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.updatePointerPoster(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Updated Successfully!"
        );
        // return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : "Not Updated!");
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const activeTaskData = createAsyncThunk(
  "/points/activetasks",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.activeTaskData();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getPoints = createAsyncThunk(
  "/points/getpoints",
  async (query, { rejectWithValue }) => {
    try {
      const resp = await api.getPoints(query);
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getStoreData = createAsyncThunk(
  "/managestores/getallstores",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.getallStores();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

// export const gotPointsDataRow = createAsyncThunk(
//   "/managestores/getallstores",
//   async (_, { rejectWithValue }) => {
//     try {
//       const resp = await api.getallStores();
//       if (resp.data.code === 200) {
//         return resp.data.data;
//       } else {
//         return rejectWithValue(resp.data);
//       }
//     } catch (error) {
//       rejectWithValue(error.resp.data);
//     }
//   }
// );

// export const gotPointsMasterDataRow = createAsyncThunk(
//   "/managestores/getallstores",
//   async (_, { rejectWithValue }) => {
//     try {
//       const resp = await api.getallStores();
//       if (resp.data.code === 200) {
//         return resp.data.data;
//       } else {
//         return rejectWithValue(resp.data);
//       }
//     } catch (error) {
//       rejectWithValue(error.resp.data);
//     }
//   }
// );

const pointerPosterSlice = createSlice({
  name: "pointerPoster",
  initialState,
  reducers: {
    gotPointsDataRow: (state, action) => {
      state.pointsDataRow = action.payload;
    },
    gotPointsMasterDataRow: (state, action) => {
      state.pointsMasterDataRow = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getPoints.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPoints.fulfilled, (state, action) => {
      state.pointsData = action.payload;
      // console.log(action.payload, 'payload');
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getPoints.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // =======================================================================================

    builder.addCase(activeTaskData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(activeTaskData.fulfilled, (state, action) => {
      state.getActivityTaskData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(activeTaskData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // =======================================================================================

    builder.addCase(getActivityCodeData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getActivityCodeData.fulfilled, (state, action) => {
      state.activityCodeData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getActivityCodeData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // =======================================================================================

    builder.addCase(getStoreData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStoreData.fulfilled, (state, action) => {
      state.allstoreData = action.payload;
      state.loading = false;
    });
    builder.addCase(getStoreData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // =======================================================================================

    builder.addCase(updatePointerPoster.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePointerPoster.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updatePointerPoster.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // =======================================================================================

    builder.addCase(getUploadCsvApprovalReport.pending, (state) => {
      state.loading = true;
      state.record_not_found = [];
      state.ponits_diff_data = [];
      state.csv_id = [];
    });
    builder.addCase(getUploadCsvApprovalReport.fulfilled, (state, action) => {
      state.loading = false;
      state.record_not_found = action.payload.record_not_found;
      state.ponits_diff_data = action.payload.ponits_diff_data;
      state.csv_id = action.payload.csv_id;
    });
    builder.addCase(getUploadCsvApprovalReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.record_not_found = [];
      state.ponits_diff_data = [];
      state.csv_id = [];
    });

    builder.addCase(finalApprovalSubmitReport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(finalApprovalSubmitReport.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(finalApprovalSubmitReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(downloadReportsWithUniqueIdHaveIssue.pending, (state) => {
      state.loading = true;
      state.downloadedDataWithIssueData = [];
    });
    builder.addCase(
      downloadReportsWithUniqueIdHaveIssue.fulfilled,
      (state, action) => {
        state.loading = false;
        state.downloadedDataWithIssueData = action.payload;
      }
    );
    builder.addCase(
      downloadReportsWithUniqueIdHaveIssue.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.downloadedDataWithIssueData = [];
      }
    );
    // =============================addPoints=====================================

    builder.addCase(addPoints.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addPoints.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addPoints.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // =============================removePointMaster=====================================

    builder.addCase(removePointMaster.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(removePointMaster.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(removePointMaster.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // =============================uploadResponseCsv=====================================

    builder.addCase(uploadResponseCsv.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(uploadResponseCsv.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(uploadResponseCsv.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // =============================activatedPointerPosterData=====================================

    builder.addCase(activatedPointerPosterData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(activatedPointerPosterData.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(activatedPointerPosterData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { gotPointsDataRow, gotPointsMasterDataRow } =
  pointerPosterSlice.actions;

export default pointerPosterSlice.reducer;
