import axios from "axios";
import AppConstants from "../../constants/appConstants";

const API = axios.create({
    baseURL: `${AppConstants.baseURL}`,
});

// ===============================================================


export const addheaderfooter = (data) => API.post("/headerfooter/addheaderfooter", data);
export const getMainMenu = (query) => API.post(`/headerfooter/getmainmenu?${query}`);

export const showheaderfooter = () => API.get("/headerfooter/showheaderfooter", )
export const showheaderfootersubmenu = () => API.get("/headerfooter/showheaderfootersubmenu")
export const getGalleryUploadImage = () => API.get("/headerfooter/getgalleryimages", )

