import React, { Component, Fragment, useState } from "react";
import styled from "styled-components";
import {
  Typography,
  Divider,
  Input,
  Radio,
  Icon,
  message
} from "antd";
import ImageUploader from 'react-images-upload';
import UploadImage from "../../ManageBanners/UploadImage";
import { useDispatch, useSelector } from "react-redux";
import { saveGalleryImage } from '../../../redux/slices/gallerySlice';


const { Title, Text } = Typography;

const TopContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  @media screen and (max-width: 480px){
    display: block;
  }
`;


const Data = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  > label {
    width: 30%;
  }
  >span{
    font-size: 18px;
    font-weight: bolder;
  }
  > input[type="text"] {
    width: 50%;
  }
  @media screen and (max-width: 480px){
    flex-direction: column;
    align-items: baseline;
  }
`;



const RadioContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  padding: 5px 0;
  > div {
    color: #666;
    font-size: 14px;
    margin-right: 30px;
  }
`;

const ButtonContainer = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
`;

const SubmitButton = styled.div`
width: 140px;
height: 40px;
display: flex;
background: #0000ff;
justify-content: center;
align-items: center;
border-radius: 15px;
cursor:pointer;
>p{
  font-size: 16px;
  font-weight: bolder;
  margin-bottom: 0px;
  color: #fff;
  text-align: center;
}
`;

const AddImages = () => {
  const [allValues, setAllValues] = useState({
    radio_type: 'url',
    store: '',
    URL: ''
  });
  const [pictures, setImg] = useState([]);
  const changeHandler = e => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };

  const imgData = (pictures) => {
    setImg(pictures);
  };

  const submitHandler = () => {
    if (allValues.store == "") {
      message.error("Store Name is Empty! Please Confirm");
    } else {
      if (allValues.radio_type == "url") {
        let data = {};
        data.radio_type = allValues.radio_type;
        data.store = allValues.store;
        data.URL = allValues.image;
        // dispatch(saveGalleryImage(data));
      }
      else if (allValues.radio_type == "image") {
        let data = new FormData();
        data.append('file', pictures[0])
        data.append('store', allValues.store)
        data.append('radio_type', allValues.radio_type)
        // dispatch(saveGalleryImage(data));
      }
    }
  }
  return (
    <Fragment>
      <TopContainer>
        <Title level={3}>
          Add Images
        </Title>
      </TopContainer>
      <Divider />
      <Container>
        <Data>
          <Text>Select Type</Text>
          <RadioContainer>
            <Radio.Group onChange={changeHandler} name="radio_type">
              <Radio
                type="radio"
                value={"url"}
              >
                URL
              </Radio>
              <Radio
                type="radio"
                value={"image"}
              >
                Image
              </Radio>
            </Radio.Group>
          </RadioContainer>
        </Data>
        <Data>
          <Text>Store Name</Text>
          <Input
            size="large"
            type="text"
            name="store"
            onChange={changeHandler}
          />
        </Data>
        {allValues.radio_type == "url" ?
          <Data>
            <Text>URL</Text>
            <Input
              size="large"
              type="text"
              name="image"
              onChange={changeHandler}
            />
          </Data> :
          <Data>
            <Text>Image</Text>
            <ImageUploader
              withIcon={true}
              buttonText='Choose Images'
              onChange={imgData}
              maxFileSize={5242880}
              withPreview={true}
              singleImage={true}
              className="add-images-uploader"
            />
          </Data>
        }
        <ButtonContainer>
          <SubmitButton onClick={submitHandler}>
            <p>Submit</p>
          </SubmitButton>
        </ButtonContainer>
      </Container>
    </Fragment>
  );
}

export default AddImages;