import React, { useEffect } from "react";
import {
  Paper,
  Stack,
  Typography,
  Toolbar,
  Box,
  Card,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { deleteTodoTaskRemind, gotUpdateTaskData, showTodoTaskAssignedAdmin, updateTodoStatus } from "../../redux/slices/taskReminderSlice";

const StyledBox = styled((props) => <Box {...props} />)(({ theme }) => ({
  padding: "2px 8px",
  borderRadius: "5px",
  height: "fit-content",
  "& *": {
    display: "inline-block",
  },
  "& .MuiTypography-root:first-of-type": {
    color: "var(--primary-color)",
    opacity: "0.8",
  },
  "& .MuiTypography-root:nth-of-type(2)": {
    color: "var(--bs-gray-700)",
  },
}));

function dateConverter(str) {
  // console.log(str, "str")
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  let hours = ("0" + date.getHours()).slice(-2);
  let minutes = ("0" + date.getMinutes()).slice(-2);
  let seconds = ("0" + date.getSeconds()).slice(-2);
  let year = date.getFullYear();
  return `${year}-${mnth}-${day}`;
}

const AssignedTaskByAdmin = (props) => {
  const dispatch = useDispatch();
  const { assignedTaskByAdmin, task_statusData } = useSelector((s) => s.taskReminder);
  const { changeTab } = props;
  const cbFunc = () => {
    dispatch(showTodoTaskAssignedAdmin());
  };
  const handleUpdateTask = (val) => {
    dispatch(gotUpdateTaskData(val));
    changeTab("addTodoReminder");
  };
  const handleDeleteTask = async (val) => {
    let data = {};
    data.id = val;
    await dispatch(deleteTodoTaskRemind(data));
    cbFunc();
  };
  const handleChange = async (event, val) => {
    // console.log(event.target.value, val, "val from");
    // setStatus(event.target.value);
    let data = {};
    data.id = val;
    data.status = event.target.value;
    await dispatch(updateTodoStatus(data));
    cbFunc();
  };

  useEffect(() => {
    // showTodoTaskAssignedAdmin();
    dispatch(showTodoTaskAssignedAdmin());
  }, []);
  console;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "1%",
        marginTop: "30px",
      }}
    >
      {/*====================================== current task==================== */}
      <Paper elevation={3} sx={{ width: "70%" }}>
        <Stack spacing={3} direction="row">
          <Toolbar
            mt={3}
            sx={{
              padding: "1.2rem",
              borderBottom: "2px solid var(--primary-color)",
            }}
          >
            <Typography
              sx={{
                flex: "1 1 100%",
                color: "var(--primary-color)",
                fontWeight: "bold",
              }}
              variant="h5"
              id="tableTitle"
            >
              Show Assigned Tasks By admin
            </Typography>
          </Toolbar>
        </Stack>
        <Box
          p={4}
          pt={1}
          className="d-grid"
          sx={{
            gridTemplateColumns: "repeat(auto-fill,minmax(300px,1fr))",
            gap: "24px",
          }}
        >
          {assignedTaskByAdmin &&
            assignedTaskByAdmin.map((item) => {
              if (item.status != 5)
                return (
                  <Card
                    sx={{
                      minWidth: "fit-content",
                      border: "2px solid var(--bs-border-color)",
                    }}
                  >
                    <CardContent>
                      <Stack spacing={1}>
                        {item.assigned_members && (
                          <StyledBox>
                            <Typography>
                              <b>Assinged Memebers :&nbsp;</b>
                            </Typography>
                            <Typography textTransform="capitalize">
                              {item.assigned_members.map((members) => {
                                return (
                                  <b>
                                    {members.name && members.name} (
                                    {members.user_id && members.user_id})
                                  </b>
                                );
                              })}
                            </Typography>
                          </StyledBox>
                        )}
                        {item.assigned_team && (
                          <StyledBox>
                            <Typography>
                              <b>Assinged Team :&nbsp;</b>
                            </Typography>
                            <Typography textTransform="capitalize">
                              {item.assigned_team.map((teams) => {
                                return (
                                  <b>
                                    {teams.name && teams.name} (
                                    {teams.role_id && teams.role_id})
                                  </b>
                                );
                              })}
                            </Typography>
                          </StyledBox>
                        )}
                        <StyledBox>
                          <Typography>
                            <b>Title :&nbsp;</b>
                          </Typography>
                          <Typography textTransform="capitalize">
                            <b>{item.title && item.title}</b>
                          </Typography>
                        </StyledBox>
                        <StyledBox>
                          <Typography>
                            <b>ID :&nbsp;</b>
                          </Typography>
                          <Typography>
                            <b>{item.id && item.id}</b>
                          </Typography>
                        </StyledBox>
                        <StyledBox>
                          <Typography>
                            <b>Description :&nbsp;</b>
                          </Typography>
                          <Typography>
                            <b>{item.description && item.description}</b>
                          </Typography>
                        </StyledBox>
                        {/* <StyledBox>
                        <Typography >
                          <b>Recurrence :&nbsp;</b>
                        </Typography>
                        <Typography >
                          <b>{item.recurrence && item.recurrence}</b>
                        </Typography>
                      </StyledBox> */}
                        <StyledBox>
                          <Typography>
                            <b>Reminder Date :&nbsp;</b>
                          </Typography>
                          <Typography>
                            <b>
                              {dateConverter(
                                item.reminder_date && item.reminder_date
                              )}
                            </b>
                          </Typography>
                        </StyledBox>
                        <StyledBox>
                          <Typography>
                            <b>Deadline Date :&nbsp;</b>
                          </Typography>
                          <Typography>
                            <b>
                              {dateConverter(
                                item.deadline_date && item.deadline_date
                              )}
                            </b>
                          </Typography>
                        </StyledBox>

                        <StyledBox>
                          <Typography>
                            <b>Status :&nbsp;</b>
                          </Typography>
                          <Typography textTransform="capitalize">
                            <b style={{ display: "flex" }}>
                              {item.status && item.status}
                              <FormControl fullWidth>
                                <Select
                                  // value={item.status && item.status}
                                  defaultValue={item.status}
                                  onChange={(e, val) =>
                                    handleChange(e, item.id)
                                  }
                                  style={{ marginLeft: "5px" }}
                                >
                                  {task_statusData &&
                                    task_statusData.map((item) => {
                                      return (
                                        <MenuItem value={item.id}>
                                          {item.status}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>
                            </b>
                          </Typography>
                        </StyledBox>
                        <Stack spacing={3} direction={"row"}>
                          <Button
                            variant="contained"
                            onClick={() => handleUpdateTask(item)}
                            color="info"
                          >
                            <strong>Edit Task</strong>
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => handleDeleteTask(item.id)}
                            color="info"
                          >
                            <strong>Delete Task</strong>
                          </Button>
                        </Stack>
                      </Stack>
                    </CardContent>
                  </Card>
                );
            })}
        </Box>
      </Paper>

      {/*====================================== Closed task==================== */}

      <Paper elevation={3} sx={{ width: "28%" }}>
        <Stack spacing={3} direction="row">
          <Toolbar
            mt={3}
            sx={{
              padding: "1.2rem",
              borderBottom: "2px solid var(--primary-color)",
            }}
          >
            <Typography
              sx={{
                flex: "1 1 100%",
                color: "var(--primary-color)",
                fontWeight: "bold",
              }}
              variant="h5"
              id="tableTitle"
            >
              Show Tasks
            </Typography>
          </Toolbar>
        </Stack>
        <Box
          p={4}
          pt={1}
          className="d-grid"
          sx={{
            gridTemplateColumns: "repeat(auto-fill,minmax(300px,1fr))",
            gap: "24px",
          }}
        >
          {assignedTaskByAdmin &&
            assignedTaskByAdmin.map((item) => {
              if (item.status == 5)
                return (
                  <Card
                    sx={{
                      minWidth: "fit-content",
                      border: "2px solid var(--bs-border-color)",
                    }}
                  >
                    <CardContent>
                      <Stack spacing={1}>
                        {item.assigned_members && (
                          <StyledBox>
                            <Typography>
                              <b>Assinged Memebers :&nbsp;</b>
                            </Typography>
                            <Typography textTransform="capitalize">
                              {item.assigned_members.map((members) => {
                                return (
                                  <b>
                                    {members.name && members.name} (
                                    {members.user_id && members.user_id})
                                  </b>
                                );
                              })}
                            </Typography>
                          </StyledBox>
                        )}
                        {item.assigned_team && (
                          <StyledBox>
                            <Typography>
                              <b>Assinged Team :&nbsp;</b>
                            </Typography>
                            <Typography textTransform="capitalize">
                              {item.assigned_team.map((teams) => {
                                return (
                                  <b>
                                    {teams.name && teams.name} (
                                    {teams.role_id && teams.role_id})
                                  </b>
                                );
                              })}
                            </Typography>
                          </StyledBox>
                        )}
                        <StyledBox>
                          <Typography>
                            <b>Title :&nbsp;</b>
                          </Typography>
                          <Typography textTransform="capitalize">
                            <b>{item.title && item.title}</b>
                          </Typography>
                        </StyledBox>
                        <StyledBox>
                          <Typography>
                            <b>ID :&nbsp;</b>
                          </Typography>
                          <Typography>
                            <b>{item.id && item.id}</b>
                          </Typography>
                        </StyledBox>
                        <StyledBox>
                          <Typography>
                            <b>Description :&nbsp;</b>
                          </Typography>
                          <Typography>
                            <b>{item.description && item.description}</b>
                          </Typography>
                        </StyledBox>
                        <StyledBox>
                          <Typography>
                            <b>Recurrence :&nbsp;</b>
                          </Typography>
                          <Typography>
                            <b>{item.recurrence && item.recurrence}</b>
                          </Typography>
                        </StyledBox>
                        <StyledBox>
                          <Typography>
                            <b>Reminder Date :&nbsp;</b>
                          </Typography>
                          <Typography>
                            <b>
                              {dateConverter(
                                item.reminder_date && item.reminder_date
                              )}
                            </b>
                          </Typography>
                        </StyledBox>
                        <StyledBox>
                          <Typography>
                            <b>Deadline Date :&nbsp;</b>
                          </Typography>
                          <Typography>
                            <b>
                              {dateConverter(
                                item.deadline_date && item.deadline_date
                              )}
                            </b>
                          </Typography>
                        </StyledBox>

                        <StyledBox>
                          <Typography>
                            <b>Status :&nbsp;</b>
                          </Typography>
                          <Typography textTransform="capitalize">
                            <b style={{ display: "flex" }}>
                              {item.status && item.status}
                              <FormControl fullWidth>
                                <Select
                                  // value={item.status && item.status}
                                  defaultValue={item.status}
                                  onChange={(e, val) =>
                                    handleChange(e, item.id)
                                  }
                                  style={{ marginLeft: "5px" }}
                                >
                                  {task_statusData &&
                                    task_statusData.map((item) => {
                                      return (
                                        <MenuItem value={item.id}>
                                          {item.status}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>
                            </b>
                          </Typography>
                        </StyledBox>
                        {/* <Stack spacing={3} direction={"row"}>

                        <Button variant="contained" onClick={() => handleUpdateTask(item)} color='info' >
                          <strong>Edit Task</strong>
                        </Button>
                        <Button variant="contained" onClick={() => handleDeleteTask(item.id)} color='info' >
                          <strong>Delete Task</strong>
                        </Button>
                      </Stack> */}
                      </Stack>
                    </CardContent>
                  </Card>
                );
            })}
        </Box>
      </Paper>
    </div>
  );
};

export default AssignedTaskByAdmin;
