import React, { Fragment, useState, useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import {
  Typography,
  Divider,
  Input,
  Radio,
  Icon,
  message,
  AutoComplete,
  Checkbox,
} from "antd";

import { Editor } from "react-draft-wysiwyg";
import {
  convertFromHTML,
  ContentState,
  EditorState,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from "draft-js-export-html";
import { useDispatch, useSelector } from "react-redux";
import {
  addEmail,
  getEmailTemplateMaster,
  updateTasksData,
} from "../../../redux/slices/emailSlice";

const { Title, Text } = Typography;

const { TextArea } = Input;

const Wrapper = styled.div`
  width: 100%;
`;

const TopContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Container = styled.div`
  width: 90%;
  margin: 0 auto;
`;

const Wrapper2 = styled.div`
  width: 100%;
  height: 310px;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const ImageContainer = styled.div`
  width: 200px;
  display: flex;
  height: 200px;
  flex-direction: row;
  padding: 50px;
  justify-content: space-between;
`;

const ImageMainContainer = styled.div`
  position: absolute;
  top: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 300px;
  overflow: auto;
`;
const Inp = styled.div`
  width: 90px;
  height: 50px;
`;
const Data = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  > label {
    width: 30%;
  }
  > span {
    font-size: 18px;
    font-weight: bolder;
  }
  > input[type="text"] {
    width: 50%;
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: baseline;
    > input[type="text"] {
      width: 90%;
    }
  }
`;

const RadioContainer = styled.div`
  width: 65%;
  display: flex;
  flex-direction: row;
  padding: 5px 0;
  > div {
    color: #666;
    font-size: 14px;
    margin-right: 30px;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubmitButton = styled.div`
  width: 140px;
  height: 40px;
  display: flex;
  background: #0000ff;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  cursor: pointer;
  > p {
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 0px;
    color: #fff;
    text-align: center;
  }
`;
const DescriptionBox = styled.div`
  height: 300px;
  overflow: auto;
  width: 65%;
  border: 1px solid #000;
`;
const DataRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const AddEmail = () => {
  const dispatch = useDispatch();
  const { emailDataRow, emailMasterData } = useSelector(
    (state) => state.email.data
  );
  const [details, setDetails] = useState({
    email_template_data: "",
    sms_template_data: "",
  });
  const [isDisable, setIsDisable] = useState(false);

  const [isUpdate, setIsUpdate] = useState(false);
  const [editorState, setEditor] = useState("");
  const [editorState2, setEditor2] = useState("");
  const [allValues, setAllValues] = useState({
    id: "",
    template_id: "",
    subject: "",
    t_type: "text",
    t_type2: "text",
    description: "",
    instruction: "",
  });

  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };

  const cbFunc = () => {
    //console.log("executed");
  };

  const onEditorStateChange = (editorState) => {
    setEditor(editorState);
    var template = stateToHTML(editorState.getCurrentContent());
    setDetails({ ...details, email_template_data: template });
  };
  const onEditorStateChange2 = (editorState2) => {
    setEditor2(editorState2);
    var template2 = stateToHTML(editorState2.getCurrentContent());
    setDetails({ ...details, sms_template_data: template2 });
  };
  const handleDescChange = (event) => {
    setDetails({ ...details, [event.target.name]: event.target.value });
    const blocksFromHTML = convertFromHTML(event.target.value);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    setEditor(EditorState.createWithContent(state));
  };
  const handleDescChange2 = (event) => {
    setDetails({ ...details, [event.target.name]: event.target.value });
    const blocksFromHTML2 = convertFromHTML(event.target.value);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML2.contentBlocks,
      blocksFromHTML2.entityMap
    );
    setEditor(EditorState.createWithContent(state));
  };

  const handleAutoComplete = (e) => {
    let value = e.split("-");
    let description = value[0];
    let id = value[1];
    setAllValues({ ...allValues, template_id: id, description: description });
    if (emailMasterData.length > 0) {
      for (var i = 0; i < emailMasterData.length; i++) {
        if (id == emailMasterData[i].id) {
          setAllValues({ ...allValues, template_id: emailMasterData[i].id });
        }
      }
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let data = {};
    data.type_name = allValues.type_name;
    data.subject = allValues.subject;
    data.template_id = allValues.template_id;
    // data.email_template = details.email_template_data;
    // data.sms_template = details.sms_template_data;
    if (details.email_template_data)
      data.email_template = details.email_template_data;
    else if (!details.email_template_data) {
      details.email_template_data = null;
    }
    if (details.sms_template_data)
      data.sms_template = details.sms_template_data;
    else if (!details.sms_template_data) {
      details.sms_template_data = null;
    }

    data.t_type = allValues.t_type;
    if (isUpdate) {
      data.id = allValues.template_id;
      dispatch(updateTasksData(data));
    } else {
      dispatch(addEmail(data));
      cbFunc();
    }
  };
  useEffect(() => {
    dispatch(getEmailTemplateMaster());
    if (emailDataRow.id) {
      setIsUpdate(!isUpdate);
      setIsDisable(!isDisable);
      setAllValues({
        ...allValues,
        id: emailDataRow.id,
        template_id: emailDataRow.template_id,
        subject: emailDataRow.subject,
        t_type: emailDataRow.t_type,
      });
      setDetails({
        ...details,
        email_template_data: emailDataRow.email_template,
        sms_template_data: emailDataRow.sms_template,
      });
      const blocksFromHTML = convertFromHTML(emailDataRow.email_template);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const editorState = EditorState.createWithContent(state);
      setEditor(editorState);
      if (emailDataRow.sms_template == null) {
        setEditor2("");
      } else {
        const blocksFromHTML2 = convertFromHTML(emailDataRow.sms_template);
        const state2 = ContentState.createFromBlockArray(
          blocksFromHTML2.contentBlocks,
          blocksFromHTML2.entityMap
        );
        const editorState2 = EditorState.createWithContent(state2);
        setEditor2(editorState2);
      }
    }
  }, []);
  return (
    <Wrapper>
      {/* <Divider /> */}
      <Container className="mt-4 ">
        <form onSubmit={submitHandler}>
          <div className="row">
            <div className="col-12 col-lg-12">
              <label for="" className="form-label w-75">
                Subject
              </label>
              <div style={{ "margin-left": "480px" }}>
                <textarea
                  className="w-100"
                  name="subject"
                  rows="4"
                  cols="50"
                  value={allValues.subject}
                  placeholder="Subject....."
                  onChange={changeHandler}
                  required={true}
                />
              </div>
              <div className="d-flex w-100 my-5">
                <div className="d-flex w-50">
                  <label for="template_master" className="form-label">
                    Template master
                  </label>
                  <h6 className="ml-3 text-success">
                    ({allValues.template_id})
                  </h6>
                </div>
                <AutoComplete
                  // style={{ width: "50% !important" }}
                  // value={storeData.store_name}
                  required={true}
                  className="auto-complete form-control w-50"
                  dataSource={
                    emailMasterData &&
                    emailMasterData.map(
                      (item, index) => ` ${item.description}-${item.id}`
                    )
                  }
                  disabled={isDisable}
                  onSelect={handleAutoComplete}
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </div>
              {/* <div>
                {allValues.instruction}
              </div> */}
              <Data>
                <Text>Email Template Type</Text>
                <RadioContainer>
                  <Radio.Group onChange={changeHandler} name="t_type">
                    <Radio
                      type="radio"
                      value={"text"}
                      checked={allValues.t_type == "text"}
                    >
                      Text
                    </Radio>
                    <Radio
                      type="radio"
                      value={"html"}
                      checked={allValues.t_type == "html"}
                    >
                      HTML
                    </Radio>
                  </Radio.Group>
                </RadioContainer>
              </Data>
              <Data>
                <Text>Email Template Editor</Text>
                {allValues.t_type == "text" ? (
                  <DescriptionBox className="editor-change">
                    <Editor
                      editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange}
                      // onContentStateChange={this.onContentStateChange}
                      // ref="draftRef"
                    />
                  </DescriptionBox>
                ) : (
                  <DescriptionBox className="editor-change">
                    <TextArea
                      style={{ width: "100%", height: "100%" }}
                      size="large"
                      name={"email_template_data"}
                      type="text"
                      value={details.email_template_data}
                      placeholder="Related Description....."
                      onChange={handleDescChange}
                    />
                  </DescriptionBox>
                )}
              </Data>

              <Data>
                <Text>SMS Template Type</Text>
                <RadioContainer>
                  <Radio.Group onChange={changeHandler} name="t_type2">
                    <Radio
                      type="radio"
                      value={"text"}
                      checked={allValues.t_type2 == "text"}
                    >
                      Text
                    </Radio>
                    <Radio
                      type="radio"
                      value={"html"}
                      checked={allValues.t_type2 == "html"}
                    >
                      HTML
                    </Radio>
                  </Radio.Group>
                </RadioContainer>
              </Data>
              <Data>
                <Text>SMS Template Editor</Text>
                {allValues.t_type2 == "text" ? (
                  <DescriptionBox className="editor-change">
                    <Editor
                      editorState={editorState2}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange2}
                      // onContentStateChange={this.onContentStateChange}
                      // ref="draftRef"
                    />
                  </DescriptionBox>
                ) : (
                  <DescriptionBox className="editor-change">
                    <TextArea
                      style={{ width: "100%", height: "100%" }}
                      size="large"
                      //  autosize={{ minRows: 2, maxRows: 10 }}
                      name={"sms_template_data"}
                      type="text"
                      value={details.sms_template_data}
                      placeholder="Related Description....."
                      onChange={handleDescChange2}
                    />
                  </DescriptionBox>
                )}
              </Data>
            </div>
            <div className="col-12 col-lg-12 my-3 text-center ">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </div>
        </form>
      </Container>
    </Wrapper>
    // </>
  );
};

export default AddEmail;
