import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api/headerFooterApi";
import { message } from 'antd';

const initialState = {
  mainmenuData: [],
  headerfooterData: [],
  headerfootersubmenuData: [],
  galleryUploadImages: [],
};

export const addheaderfooter = createAsyncThunk(
  "/headerfooter/addheaderfooter",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.addheaderfooter(data);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Added Successfully!');
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Not Added / Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const getMainMenu = createAsyncThunk(
  "/headerfooter/getmainmenu",
  async (query, { rejectWithValue }) => {
    try {
      const resp = await api.getMainMenu(query);
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const showheaderfooter = createAsyncThunk(
  "/headerfooter/showheaderfooter",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.showheaderfooter();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const showheaderfootersubmenu = createAsyncThunk(
  "/headerfooter/showheaderfootersubmenu",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.showheaderfootersubmenu();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getGalleryUploadImage = createAsyncThunk(
  "/headerfooter/getgalleryimages",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.getGalleryUploadImage();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

const headerFooterSlice = createSlice({
  name: "headerFooter",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getMainMenu.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMainMenu.fulfilled, (state, action) => {
      state.mainmenuData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getMainMenu.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ==============================================================================================
    builder.addCase(showheaderfooter.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showheaderfooter.fulfilled, (state, action) => {
      state.headerfooterData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(showheaderfooter.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ==============================================================================================
    builder.addCase(showheaderfootersubmenu.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showheaderfootersubmenu.fulfilled, (state, action) => {
      state.headerfootersubmenuData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(showheaderfootersubmenu.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ======================================================================================================================
    builder.addCase(getGalleryUploadImage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getGalleryUploadImage.fulfilled, (state, action) => {
      state.galleryUploadImages = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getGalleryUploadImage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ====================================addheaderfooter=========================================
    builder.addCase(addheaderfooter.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addheaderfooter.fulfilled, (state, action) => {
      state.galleryUploadImages = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(addheaderfooter.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { } = headerFooterSlice.actions;

export default headerFooterSlice.reducer;
