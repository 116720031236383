"use strict";
Object.defineProperty(exports, "__esModule", {
  value: true,
});
if (process.env.NODE_ENV == "development") {
  exports.default = {
    imageURL: "https://cdn.hyyzo.com/",
    baseURL: "http://localhost:3000/api",
  };
} else {
  exports.default = {
    baseURL: "https://team.hyyzo.com/api",
    imageURL: "https://cdn.hyyzo.com/",
  };
}
// exports.default = {
//   // baseURL: "http://142.93.209.151:5300",
//   // dealURL: "http://192.168.0.20:60211",
//   baseURL: "http://localhost:5300/api",
//   imageURL: "https://cdn.hyyzo.com",
//   // baseURL: "http://165.232.187.136/api",
//   // baseURL: "https://team.hyyzo.com/api"
// };
