import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Typography, Divider, Input, Radio, Icon, message } from "antd";
import AddDeepLink from "./AddDeeplink";
import ShowDeeplink from "./ShowDeeplink";
const { Title } = Typography;
const Wrapper = styled.div`
  width: 100%;

  >div{
      >div{
        >button{
            background: linear-gradient(259deg, rgb(236, 94, 58) 0%, rgb(249, 186, 99) 100%) 0% 0% no-repeat padding-box padding-box transparent;
            padding:7px 20px;
            border-radius:20px;
            float:right;
        }
      }
  }

`;
const BodyContainer = styled.div`
  overflow: auto;
`;
const Container = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const StoreDescription = () => {
    const [storeType, setStoreType] = useState("showDeeplink");
    const changeTab = () => {
        setStoreType("addDeeplink");
    };
    const changeTab2 = () => {
        setStoreType("showDeeplink");
    };
    const setMainPage = () => {
        setStoreType("createStore")
    }
    const setDisplayType = () => {
        setStoreType("addDeeplink");
    };
    return (
        <Wrapper>
            <Title className="my-3" level={3}>DeepLink </Title>
            <div className="row">
                <div className="col-12 col-lg-12">
                    {storeType == "showDeeplink" && (
                        <button onClick={changeTab}>Add DeepLink</button>
                    )}
                    {storeType == "addDeeplink" && (
                        <button onClick={changeTab2}>Show DeepLink</button>
                    )}
                </div>
            </div>
            <BodyContainer>
                {storeType == "addDeeplink" && (
                    <AddDeepLink />
                )}
                {storeType == "showDeeplink" && (
                    <ShowDeeplink setStoreType={setStoreType} />
                )}
            </BodyContainer>
        </Wrapper>
    );
};

export default StoreDescription;
