import axios from "axios";
import AppConstants from "../../constants/appConstants";


const API = axios.create({
    baseURL: `${AppConstants.baseURL}`,
});

export const showEmail = () => API.get("/email/showemail");
export const addEmail = (data) => API.post("/email/addemail", data);
export const activatedEmailData = (query) => API.get(`/email/activatemail?${query}`);
export const updateTasksData = (data) => API.post("/email/updatetasksdata", data);
export const getEmailTemplateMaster = () => API.get("/email/getemailmaster");
