import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { Editor } from "react-draft-wysiwyg";
import {
  convertFromHTML,
  ContentState,
  EditorState,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from "draft-js-export-html";
import {
  Typography,
  Divider,
  Input,
  Radio,
  AutoComplete,
} from "antd";
import htmlToDraft from 'html-to-draftjs';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addStoreSchema, getStoreData, updateStoreSchema } from "../../../../redux/slices/manageStoreSlice";

const { Title, Text } = Typography;

const { TextArea } = Input;

const TopContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const DescriptionBox = styled.div`
  height: 300px;
  overflow: auto;
  width: 50%;
  border: 1px solid #000;
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 480px) {
    display: block;
  }
`;

const RadioContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  padding: 5px 0;
  > div {
    color: #666;
    font-size: 14px;
    margin-right: 30px;
  }
`;

const Data = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  > label {
    width: 30%;
  }
  >span{
    font-size: 18px;
    font-weight: bolder;
  }
  > input[type="text"] {
    width: 50%;
  }
  >div{
    width: 50% !important;
  }
  @media screen and (max-width: 480px) {
    display: block;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubmitButton = styled.div`
  width: 140px;
  height: 40px;
  display: flex;
  background: #0000ff;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  cursor:pointer;
  >p{
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 0px;
    color: #fff;
    text-align: center;
  }
`;

const DataRow = styled.div`
 display:flex;
 margin-bottom:20px;
 justify-content:space-between;
`;


const AddStoreSchema = () => {
  // let history = useHistory();  //useHistory is being deprecated in react-router v6 or above
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { store_schema_data_row, storedata } = useSelector(s => s.manageStores);
  // const storedata = useSelector(s=>s.manageStores.storedata);
  // console.log(data , "store_schema_data_row")


  const [isUpdatedData, setUpdatedData] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const [storeSchema, setStoreSchema] = useState('');
  const [schema_id, setSchemaId] = useState('');
  const [storeData, setStoreData] = useState({
    store_name: "",
    store_id: ""
  });


  const cb_func = () => {
    // history.push("/managestores/addstoreschema");
    navigate("/managestores/addstoreschema");
  }

  const handleAutoComplete = e => {
    setStoreData({ ...storeData, storeName: e })
    if (storedata.length > 0) {
      for (var i = 0; i < storedata.length; i++) {
        if (e == storedata[i].store_name) {
          setStoreData({ ...storeData, store_id: storedata[i].store_id, store_name: storedata[i].store_name })
        }
      }
    }
  }




  const submitHandler = (e) => {
    e.preventDefault();
    let data = {};
    data.schema_id = schema_id;
    data.store_id = storeData.store_id;
    try {
      JSON.parse(storeSchema)
    }
    catch {
      return alert('storeSchema is not a valid json');
    }
    data.store_schema = storeSchema;
    if (isUpdatedData) {
      dispatch(updateStoreSchema(data));
    } else {
      dispatch(addStoreSchema(data));
      cb_func();
    }
  };

  const handleDescChange1 = e => {
    setStoreSchema(e.target.value);
  };

  useEffect(() => {
    dispatch(getStoreData());
    if (store_schema_data_row.store_name) {
      setIsDisable(!isDisable);
      setUpdatedData(!isUpdatedData);
      setStoreData({
        ...storeData, store_name: store_schema_data_row.store_name, store_id: store_schema_data_row.store_id
      })

      setStoreSchema([
        ...storeSchema, store_schema_data_row.store_schema
      ])
      setSchemaId(store_schema_data_row.schema_id)
    }
  }, []);

  return (
    <>
      <TopContainer>
        <Title level={3}>Add Store Schema</Title>
      </TopContainer>
      <Divider />
      <form onSubmit={submitHandler}>
        <div className="row">
          <div className="col-12 col-lg-12">
            <DataRow>
              <label for="" className="form-label w-75">
                Store Name
              </label>
              <h6 style={{ color: "blue", fontSize: "1.8rem" }}>{`(${storeData.store_name})`}</h6>
              <AutoComplete
                required={true}
                className="auto-complete form-control"
                dataSource={storedata && storedata.map((item, index) => item.store_name)}
                disabled={isDisable}
                onSelect={handleAutoComplete}
                filterOption={(inputValue, option) =>
                  option.props.children
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </DataRow>

            <Data>
              <Text>Store Schema</Text>
              <DescriptionBox className="editor-change">
                <TextArea
                  style={{ width: "100%", height: "100%" }}
                  size="large"
                  type="text"
                  className="form-control"
                  value={storeSchema}
                  name="storeSchema"
                  placeholder="Store-Schema....."
                  onChange={handleDescChange1}
                />
              </DescriptionBox>

            </Data>
          </div>
          <div className="col-12 col-lg-12 my-3 text-center ">
            <button type="submit"
              className="btn btn-primary"  >
              Submit
            </button>
          </div>

        </div>
      </form>
    </>
  );
}
export default AddStoreSchema;
