import React, { useState, useEffect } from 'react'
import { DatePicker, Radio, Input, AutoComplete, Checkbox, Modal } from 'antd';
import moment from "moment";
import QueryURLModal from '../../MasterPartners/AddPartners/queryUrlModal';
import { useDispatch, useSelector } from 'react-redux';
import { getStoreData } from '../../../../redux/slices/layoutSlice';
import { getPartnerId } from '../../../../redux/slices/activitiesSlice';
import { addAffiliate, updateAffiliateLinkDetails } from '../../../../redux/slices/affiliateSlice';


const AddAffLink = () => {
    const { affClicksDataRow } = useSelector(s => s.affiliate);

    // const affClicksDataRow = useSelector(s => s.affiliate.affClicksDataRow);
    const storeDataRow = useSelector(s => s.layout.data.storeData);
    const partnerIdData = useSelector(s => s.activities.data.getPartnerId)
    const data = useSelector(s => s.affiliate);

    const dispatch = useDispatch();
    const [isVisible, setVisible] = useState(false);

    const [isUpdatedData, setUpdatedData] = useState(false);
    const [storeData, setStoreData] = useState({
        store_name: "",
        store_id: ""
    });
    const [utm_add_array, setUtm_add_array] = useState([]);
    const [utm_blank_array, setUtm_blank_array] = useState([]);
    const [typeOfModal, setTypeOfModal] = useState("");
    const [query_url_data, setQuery_url_data] = useState({
        key_id: "",
        key_value: "",
    });
    const [utmblank_data, setUtmBlankData] = useState({ key_value: "" });
    const [partnersData, setPartnersData] = useState({
        partner_id: "",
        partner: "",
        aff_links_id: ""
    });

    const [after, setAfter] = useState({
        after_days: false,
        after_month: false,
        exact_day: false
    })
    const [approvalData, setApprovalData] = useState({
        id: "",
        camp_id: "",
        // partner_name: "",
        // resp_camp_id: "",
        after_days: "",
        after_month: "",
        exact_day: "",
        allowed_partner: false,
        deeplink_support: ""
    });

    const [dateData, setDateData] = useState({
        // exp_date: "",
        payment_date: ""
    });

    const [isDisabled, setIsDisabled] = useState(false);


    const handleCondition = (e) => {
        setTypeOfModal(e.target.name);
        e.preventDefault();
        setVisible(true)
    }
    const handleOk = () => {
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };
    const changeHandler = (e) => {
        setApprovalData({ ...approvalData, [e.target.name]: e.target.value });
        if (e.target.name == "after_days") {
            if (e.target.value) {
                setAfter({ ...after, after_days: true })
            }
            else {
                setAfter({ ...after, after_days: false });
            }
        }
        else if (e.target.name == "after_month") {
            if (e.target.value == "") {
                setAfter({ ...after, after_month: false })
            }
            else {
                setAfter({ ...after, after_month: true });
            }
        }
        else if (e.target.name == "exact_day") {
            if (e.target.value == "") {
                setAfter({ ...after, exact_day: false })
            }
            else {
                setAfter({ ...after, exact_day: true });
            }
        }
        // setStoreData({ ...storeData, [e.target.name]: e.target.value })
    };
    // const handleExpDateChange = (date, dateString) => {
    //     setDateData({ ...dateData, exp_date: dateString });
    // }

    const onChange = (e) => {
        setApprovalData({ ...approvalData, allowed_partner: e.target.checked });
    }
    const handlePayDateChange = (date, dateString) => {
        setDateData({ ...dateData, payment_date: dateString });
    }

    const handleAutoComplete = e => {
        setStoreData({ ...storeData, storeName: e })
        if (storeDataRow.length > 1) {
            for (var i = 0; i < storeDataRow.length; i++) {
                if (e == storeDataRow[i].store_name) {
                    setStoreData({ ...storeData, store_id: storeDataRow[i].store_id, store_name: storeDataRow[i].store_name })
                }
            }
        }
    }
    const handleAutoComplete1 = e => {
        setPartnersData({ ...partnersData, partner: e })
        if (partnerIdData.length > 0) {
            for (var i = 0; i < partnerIdData.length; i++) {
                if (e == partnerIdData[i].partner) {
                    setPartnersData({ ...partnersData, partner_id: partnerIdData[i].id, partner: partnerIdData[i].partner, aff_links_id: partnerIdData[i].id })
                }
            }
        }
    }

    useEffect(() => {
        dispatch(getStoreData());
        dispatch(getPartnerId());
        if (affClicksDataRow.id) {
            setUpdatedData(!isUpdatedData);
            setIsDisabled(!isDisabled);
            setApprovalData({
                ...approvalData,
                id: affClicksDataRow.id,
                camp_id: affClicksDataRow.camp_id,
                after_days: affClicksDataRow.after_days,
                after_month: affClicksDataRow.after_month,
                exact_day: affClicksDataRow.exact_day,
                deeplink_support: affClicksDataRow.deeplink_support,
            })
            setPartnersData({
                ...partnersData,
                partner_id: affClicksDataRow.partner_id,
                partner: affClicksDataRow.partner
            })
            setStoreData({
                ...storeData,
                store_name: affClicksDataRow.store_name,
                store_id: affClicksDataRow.store_id
            })
            setDateData({ ...dateData, payment_date: affClicksDataRow.payment_date });

            let Utm_add_array = [];
            try {
                let utm_adds = JSON.parse(partnerMasterDataRow.utm_add);

                Object.keys(utm_adds).forEach(function (key) {
                    Utm_add_array.push({ key_id: key, key_value: utm_adds[key] })
                });
                setUtm_add_array(Utm_add_array);
            } catch (e) {
                console.log(e)
            }


            let Utm_blank_array = [];
            try {
                let utm_blanks = JSON.parse(partnerMasterDataRow.utm_blank);

                utm_blanks.map((key) => {
                    Utm_blank_array.push({ key_value: key })
                });
                setUtm_blank_array(Utm_blank_array);
            } catch (e) {
                console.log(e)
            }
        }
    }, []);

    const submitForm = (e) => {
        e.preventDefault();
        let data = {};
        // let data1={};
        // data.id = approvalData.id;
        // data.type = "approval_master";
        // let query = queryString.stringify(data);
        data.id = approvalData.id;
        data.store_id = storeData.store_id;
        data.camp_id = approvalData.camp_id;
        data.partner_id = partnersData.partner_id;
        data.partner = partnersData.partner;
        // data.resp_camp_id = approvalData.resp_camp_id;
        // data.partner = approvalData.partner_name;
        // data.exp_date = dateData.exp_date;
        data.payment_date = dateData.payment_date;
        data.after_days = approvalData.after_days;
        data.after_month = approvalData.after_month;
        data.exact_day = approvalData.exact_day;
        data.allowed_partner = approvalData.allowed_partner;
        let utm_add = {}
        utm_add_array.map((item) => { utm_add[item.key_id] = item.key_value });
        let utm_blank = [];
        utm_blank_array.map((item) => { utm_blank.push(item.key_value) });
        data.deeplink_support = approvalData.deeplink_support;

        data.utm_add = utm_add;
        data.utm_blank = utm_blank;
        if (isUpdatedData) {
            dispatch(updateAffiliateLinkDetails(data));
        }
        else {
            dispatch(addAffiliate(data));
        }
    };
    return (
        <>
            <Modal title="Basic Modal" visible={isVisible} onOk={handleOk} onCancel={handleCancel} width={1000}>
                <QueryURLModal query_url_data={query_url_data}
                    typeOfModal={typeOfModal}
                    setQuery_url_data={setQuery_url_data}
                    utm_add_array={utm_add_array} setUtm_add_array={setUtm_add_array}
                    utm_blank_array={utm_blank_array} setUtm_blank_array={setUtm_blank_array}
                    setUtmBlankData={setUtmBlankData} utmblank_data={utmblank_data} />
            </Modal>
            <h2 className="my-4">Add Affiliate Linking</h2>
            <form onSubmit={submitForm}>
                <div className="row">
                    <div className="col-6 col-lg-6">
                        <div class="mb-3">
                            <label for="" class="form-label">Store Name<span style={{ color: "blue", fontSize: "25px" }}> ({storeData.store_name})</span></label>
                            <AutoComplete
                                style={{ width: "50% !important" }}
                                // value={storeData.store_name}
                                className="auto-complete"
                                dataSource={storeDataRow && storeDataRow.map((item, index) => item.store_name)}
                                onSelect={handleAutoComplete}
                                // onChange={changeHandler}
                                disabled={isDisabled}
                                filterOption={(inputValue, option) =>
                                    option.props.children
                                        .toUpperCase()
                                        .indexOf(inputValue.toUpperCase()) !== -1
                                }
                            />
                        </div>

                    </div>



                    <div className="col-6 col-lg-6">
                        <div class="mb-3">
                            <label for="" class="form-label">Partner Name<span style={{ color: "blue", fontSize: "25px" }}> ({partnersData.partner})</span></label>

                            <AutoComplete
                                style={{ width: "50% !important" }}
                                // value={storeData.store_name}
                                className="auto-complete"
                                dataSource={partnerIdData && partnerIdData.map((item, index) => item.partner)}
                                onSelect={handleAutoComplete1}
                                // onChange={changeHandler}
                                disabled={isDisabled}
                                filterOption={(inputValue, option) =>
                                    option.props.children
                                        .toUpperCase()
                                        .indexOf(inputValue.toUpperCase()) !== -1
                                }
                            />
                        </div>
                    </div>
                    <div className="col-6 col-lg-6">
                        <div class="mb-3">
                            <label for="" class="form-label">Camp ID</label>
                            <input type="text" class="form-control" name="camp_id" onChange={changeHandler} value={approvalData.camp_id} required={true} />
                        </div>
                    </div>

                    {/* 
                    <div className="col-6 col-lg-6">
                        <div class="mb-3">
                            <label for="" class="form-label">Expected Date<span style={{ color: "blue", fontSize: "15px" }}> ({dateData.exp_date})</span></label>
                            <DatePicker onChange={handleExpDateChange} required />
                        </div>
                    </div> */}
                    <div className="col-6 col-lg-6">
                        <div class="mb-3">
                            <label for="" class="form-label">After Days</label>
                            <input type="text" class="form-control" name="after_days" onChange={changeHandler} disabled={after.after_month || after.exact_day} value={approvalData.after_days} />
                        </div>
                    </div>
                    <div className="col-6 col-lg-6">
                        <div class="mb-3">
                            <label for="" class="form-label">After Month</label>
                            <input type="text" class="form-control" name="after_month" onChange={changeHandler} disabled={after.after_days} value={approvalData.after_month} />
                        </div>
                    </div>

                    <div className="col-6 col-lg-6 mt-4">
                        <div class="mb-3">
                            <label for="" class="form-label">Exact Day</label>
                            <input type="text" class="form-control" name="exact_day" onChange={changeHandler} disabled={after.after_days} value={approvalData.exact_day} />
                        </div>
                    </div>

                    <div className="col-6 col-lg-6">
                        <div class="mb-3">
                            <label class="form-label">DeepLink Support</label>
                            <Radio.Group onChange={changeHandler} name="deeplink_support" value={approvalData.deeplink_support} >
                                <Radio value={1} type="radio" checked={approvalData.deeplink_support == 1}>Supported</Radio>
                                <Radio value={0} type="radio" checked={approvalData.deeplink_support == 0}>Not Supported</Radio>
                            </Radio.Group>
                        </div>
                    </div>
                    <div className="col-6 col-lg-6 mt-4 ">
                        <div class="mb-3">
                            <label for="" class="form-label">Payment Date<span style={{ color: "blue", fontSize: "15px" }}> ({dateData.payment_date})</span></label>
                            <DatePicker onChange={handlePayDateChange} required />
                        </div>
                    </div>

                    <div className="col-6 col-lg-6">
                        <div class="mb-3">
                            <button name='utmAddModal' type="submit" class="btn btn-primary form-control" style={{ marginTop: "30px" }} onClick={handleCondition}>UTM ADD</button>
                        </div>
                    </div>


                    <div className="col-6 col-lg-6">
                        <div class="mb-3">
                            <button name='utmBlankModal' type="submit" class="btn btn-primary form-control" style={{ marginTop: "30px" }} onClick={handleCondition}>UTM blank</button>
                        </div>
                    </div>


                    <div className="col-6 col-lg-6 mt-4">
                        <div class="mb-3">
                            <Checkbox onChange={onChange}>Make this Checked Partner</Checkbox>
                        </div>
                    </div>

                    <div className="col-12 col-lg-12 my-3 text-center">
                        <button type="submit" class="btn btn-primary" >Submit</button>
                    </div>
                </div>
            </form>

        </>
    )
}

export default AddAffLink;
