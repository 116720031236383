import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { Editor } from "react-draft-wysiwyg";
import {
  convertFromHTML,
  ContentState,
  EditorState,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from "draft-js-export-html";
import {
  Typography,
  Divider,
  Input,
  Radio,
  AutoComplete,
} from "antd";
import htmlToDraft from 'html-to-draftjs';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addDescription, getStoreData, updateDescriptionData } from "../../../../redux/slices/manageStoreSlice";

const { Title, Text } = Typography;

const { TextArea } = Input;

const TopContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const DescriptionBox = styled.div`
  height: 300px;
  overflow: auto;
  width: 50%;
  border: 1px solid #000;
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 480px) {
    display: block;
  }
`;

const RadioContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  padding: 5px 0;
  > div {
    color: #666;
    font-size: 14px;
    margin-right: 30px;
  }
`;

const Data = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  > label {
    width: 30%;
  }
  >span{
    font-size: 18px;
    font-weight: bolder;
  }
  > input[type="text"] {
    width: 50%;
  }
  >div{
    width: 50% !important;
  }
  @media screen and (max-width: 480px) {
    display: block;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubmitButton = styled.div`
  width: 140px;
  height: 40px;
  display: flex;
  background: #0000ff;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  cursor:pointer;
  >p{
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 0px;
    color: #fff;
    text-align: center;
  }
`;

const DataRow = styled.div`
 display:flex;
 margin-bottom:20px;
 justify-content:space-between;
`;


const AddDescription = (props) => {
  const dispatch = useDispatch();
  const { descriptionDataRow, storedata } = useSelector((s) => s.manageStores);
  // let history = useHistory();  //useHistory is being deprecated in react-router v6 or above
  const navigate = useNavigate();
  const [allValues, setAllValues] = useState({
    seo_title: '',
    seo_description: '',
    h1_title: '',
    related_description: '',
    d_type: 'text'
  });

  const [isUpdatedData, setUpdatedData] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const [editorState, setEditor] = useState('');
  const [details, setDetails] = useState('');
  // const [catName, setCatName] = useState('');
  const [storeData, setStoreData] = useState({
    store_name: "",
    store_id: ""
  });
  // const [categoryData, setCategoryData] = useState({
  //   category_name: "",
  //   category_id: ""
  // });

  // const [storesMapData, setStoresMapData] = useState([])

  const cb_func = () => {
    props.setNextAdditionalPage();
    navigate("/managestores/addadditionalstore");
  }

  const changeHandler = e => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  }

  // const changeHandler1 = e => {
  //   // setCategories(...categorystate, e.target.value);
  //   //console.log(e);
  //   setCatName(e);
  //   setCatArray([...catArray, e]);
  //   const { categoryData } = props;
  //   if (categoryData.length >= 1) {
  //     for (var i = 0; i < categoryData.length; i++) {
  //       // //console.log(categoryData[i])
  //       if (e == categoryData[i].category_name) {
  //         setCatId([...catIdArray, categoryData[i].c_id])
  //       }
  //     }
  //   }
  //   // //console.log(categorystate);
  // }

  // const handleSelect = (e) => {
  //   //console.log(e);
  //   setId(e);
  //   alert(e);
  // }

  const handleAutoComplete = e => {
    //console.log(e);
    setStoreData({ ...storeData, store_name: e })
    if (storedata.length > 0) {
      for (var i = 0; i < storedata.length; i++) {
        // //console.log(categoryData[i])
        if (e == storedata[i].store_name) {
          setStoreData({ ...storeData, store_id: storedata[i].store_id, store_name: storedata[i].store_name })
        }
      }
    }
    // //console.log(categorystate);
  }
  // const handleAutoComplete1 = e => {
  //   //console.log(e);
  //   setCategoryData({ ...categoryData, category_name: e })
  //   if (props.categoryData.length > 1) {
  //     for (var i = 0; i < props.categoryData.length; i++) {
  //     // //console.log(categoryData[i])
  //       if (e == props.categoryData[i].category_name) {
  //         setCategoryData({ ...categoryData, category_id: props.categoryData[i].c_id, category_name: props.categoryData[i].category_name })
  //       }
  //     }
  //   }
  //   // //console.log(categorystate);
  // }
  // const onRemove = e => {
  //   //console.log(e);
  //   setCatArray(catArray.filter(item => item !== e));
  //   for (var i = 0; i < props.categoryData.length; i++) {
  //     if (e == props.categoryData[i].category_name) {
  //       setCatId(catIdArray.filter(item => item == props.categoryData[i].c_id))
  //     }
  //   }
  // }

  const onEditorStateChange = editorState => {
    setEditor(editorState);
    var html = stateToHTML(editorState.getCurrentContent());
    setDetails(html);
  };

  const handleDescChange = event => {
    setDetails(event.target.value);
    const blocksFromHTML = convertFromHTML(event.target.value);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    setEditor(EditorState.createWithContent(state));
  }

  const submitHandler = (e) => {
    e.preventDefault();
    let data = {};
    // let data1 = {};
    data.store_id = storeData.store_id;
    data.seo_title = allValues.seo_title;
    data.seo_description = allValues.seo_description;
    data.h1_title = allValues.h1_title;
    data.related_description = allValues.related_description;
    data.d_type = allValues.d_type;
    data.description = details;
    // try{
    //   JSON.parse(storeSchema)
    // }
    // catch{
    //   alert('storeSchema is not a valid json');
    // }
    // data.store_schema = storeSchema;
    // console.log(data.schema);
    // data1.id = storeData.store_name;
    // data1.id = storeData.store_id;
    // data1.category_id = catIdArray;
    //console.log(data);
    // //console.log(data1, "data1");
    if (isUpdatedData) {
      dispatch(updateDescriptionData(data));
    } else {
      dispatch(addDescription(data));
      cb_func();

    }

  }

  useEffect(() => {
    dispatch(getStoreData());
    if (descriptionDataRow.store_id) {
      setIsDisable(!isDisable);
      setUpdatedData(!isUpdatedData);
      setStoreData({
        ...storeData, store_name: descriptionDataRow.store_name, store_id: descriptionDataRow.store_id
      })

      setAllValues({
        ...allValues,
        seo_title: descriptionDataRow.seo_title,
        seo_description: descriptionDataRow.seo_description,
        h1_title: descriptionDataRow.h1_title,
        related_description: descriptionDataRow.related_description,
        d_type: descriptionDataRow.d_type
      })
      setDetails([
        ...details, descriptionDataRow.description
      ])

      const blocksFromHtml = htmlToDraft(descriptionDataRow.description);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const editorState = EditorState.createWithContent(contentState);
      setEditor(editorState);
    }

  }, []);

  return (
    <>
      <TopContainer>
        <Title level={3}>Add Description</Title>
      </TopContainer>
      <Divider />
      {/* <Container> */}
      <form onSubmit={submitHandler}>
        <div className="row">
          <div className="col-12 col-lg-12">
            <DataRow>
              <label for="" className="form-label w-75">
                Store Name
              </label>
              <h6>{storeData.store_name}</h6>
              <AutoComplete
                required={true}
                className="auto-complete form-control"
                dataSource={storedata && storedata.map((item, index) => item.store_name)}
                disabled={isDisable}
                onSelect={handleAutoComplete}
                filterOption={(inputValue, option) =>
                  option.props.children
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </DataRow>
            <DataRow>
              <label for="" className="form-label w-75">
                SEO Title
              </label>
              <input
                type="text"
                className="form-control"
                name="seo_title"
                value={allValues.seo_title}
                onChange={changeHandler}
                required={true}

              />
            </DataRow>
            <DataRow>
              <label for="" className="form-label w-75">
                SEO Description
              </label>
              <textarea className="w-100 form-control" name="seo_description" rows="4" cols="50" value={allValues.seo_description} placeholder="SEO Description....." onChange={changeHandler}
                required={true} />

            </DataRow>
            <DataRow>
              <label for="" className="form-label w-75">
                H1 Ttitle
              </label>
              <input
                type="text"
                className="form-control"
                name="h1_title"
                value={allValues.h1_title}
                onChange={changeHandler}
                required={true}
              />
            </DataRow>
            <DataRow>
              <label for="" className="form-label w-75">
                Related Description
              </label>
              <textarea className="w-100 form-control" name="related_description" rows="4" cols="50" value={allValues.related_description} placeholder="Related Description....." onChange={changeHandler}
                required={true} />

            </DataRow>

            {/* <Data>
              <Text>Store Schema</Text>
              <DescriptionBox className="editor-change">
                <TextArea
                  style={{ width: "100%", height: "100%" }}
                  size="large"
                  type="text"
                  className="form-control"
                  value={storeSchema}
                  name="storeSchema"
                  // required={true}
                  placeholder="Store-Schema....."
                  onChange={handleDescChange1}
                />
              </DescriptionBox>
              
            </Data> */}
            {/* <DataRow className="hi2" >
              <label for="" className="form-label w-75">
                Category Type
              </label>
              <h6>{catName}</h6>
              <AutoComplete
                // className="hi1"
                disabled={false}
                // style={{ width: "50% !important" }}
                // value={catName.category_name}
                className="auto-complete form-control"
                dataSource={props.categoryData && props.categoryData.map((item, index) => item.category_name)}
                style={{ width: 500 }}
                onSelect={changeHandler1}
                disabled={isDisable}
                filterOption={(inputValue, option) =>
                  option.props.children
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </DataRow> */}
            <Data>
              <Text>Description Type</Text>
              <RadioContainer>
                <Radio.Group onChange={changeHandler} name="d_type" value={allValues.d_type}>
                  <Radio
                    type="radio"
                    value={"text"}
                    checked={allValues.d_type == "text"}
                  >Text
                  </Radio>
                  <Radio
                    type="radio"
                    value={"html"}
                    checked={allValues.d_type == "html"}
                  >HTML
                  </Radio>
                </Radio.Group>
              </RadioContainer>
            </Data>
            <Data>
              <Text>Description Editor</Text>
              {allValues.d_type == "text" ? (
                <DescriptionBox className="editor-change">
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={onEditorStateChange}
                  />
                </DescriptionBox>
              ) : (
                <DescriptionBox className="editor-change">
                  <TextArea
                    style={{ width: "100%", height: "100%" }}
                    size="large"
                    //  autosize={{ minRows: 2, maxRows: 10 }}
                    type="text"
                    className="form-control"
                    value={details}
                    name="details"
                    required={true}
                    placeholder="Related Description....."
                    onChange={handleDescChange}
                  />
                </DescriptionBox>)}
            </Data>
          </div>
          <div className="col-12 col-lg-12 my-3 text-center ">
            <button
              type="submit"
              className="btn btn-primary"
            // onClick={submitForm}
            >
              Submit
            </button>
          </div>

        </div>
      </form>
      {/* </Container> */}
    </>
  );
}
export default AddDescription;
