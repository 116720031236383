import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import AppConstants from "../../../constants/appConstants";
import {
  Typography,
  Divider,
  Input,
  Button,
  message, AutoComplete
} from "antd";
import Popup from "../../Repeated/Popup";
import { useDispatch, useSelector } from "react-redux";
import { addheaderfooter, getGalleryUploadImage, getMainMenu } from "../../../redux/slices/headerFooterSlice";

const { Title, Text } = Typography;

const { TextArea } = Input;


const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  @media screen and (max-width: 480px) {
    display: block;
  }
`;

const Wrapper2 = styled.div`
  width: 100%;
  height: 310px;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const ImageMainContainer = styled.div`
  position: absolute;
  top: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 300px;
  overflow: auto;
`;

const ImageContainer = styled.div`
  width: 200px;
  display: flex;
  height: 200px;
  flex-direction: row;
  padding: 50px;
  border: ${(props) => (props.active ? "1px solid #ffff00" : "none")};
  justify-content: space-between;
`;

const Data = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  > label {
    width: 30%;
  }
  >span{
    font-size: 18px;
    font-weight: bolder;
  }
  > input[type="text"] {
    width: 50%;
  }
  @media screen and (max-width: 480px) {
    display: block;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubmitButton = styled.div`
  width: 140px;
  height: 40px;
  display: flex;
  background: #0000ff;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  cursor:pointer;
  >p{
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 0px;
    color: #fff;
    text-align: center;
  }
`;
const DataRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const AddMainMenu = (props) => {
  const dispatch = useDispatch();
  const { galleryUploadImages } = useSelector(s => s.headerFooter);

  const [allValues, setAllValues] = useState({
    menu_name: '',
    menu_url: '',
    image: "",
  });

  const [imageId, setImageId] = useState({
    id: "",
    url: "",
  });
  const [isOpen, setIsOpen] = useState(false);

  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  }

  const cb_func = () => {
  }


  const imageHandler = (img) => {
    setImageId({
      id: img.id,
      url: img.url,
    });
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    dispatch(getMainMenu());
    dispatch(getGalleryUploadImage());
  }, []);

  const submitHandler = (e) => {
    e.prventDefault();
    let data = {};
    data.menu_name = allValues.menu_name;
    data.menu_url = allValues.menu_url;
    data.menu_type = props.menu_type;
    data.image = imageId.id;
    dispatch(addheaderfooter(data));
    cb_func();
  };
  return (
    <Container className="mt-5">
      <form onSubmit={submitHandler}>
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="mb-5 d-flex justify-content-between">
              <label for="" className="form-label">
                Image
              </label>
              <img
                src={`${AppConstants.imageURL}${imageId.url}`}
                value={allValues.image}
                alt="image selected"
                style={{
                  width: "70px",
                  position: "absolute",
                  right: "0",
                  margin: "-10px 0 20px",
                }}
              />
              <Button
                size="large"
                type="primary"
                name="image"
                className="form-control mt-5 w-auto float-right"
                onClick={(e) => {
                  setIsOpen(!isOpen);
                }}
              >
                Choose Image
              </Button>
              {isOpen && (
                <Popup
                  content={
                    <>
                      <b>Main Menu Images</b>
                      <p>
                        <Wrapper2>
                          <ImageMainContainer>
                            {galleryUploadImages &&
                              galleryUploadImages.map((item, index) => (
                                <ImageContainer>
                                  <img
                                    src={`${AppConstants.imageURL}${item.url}`}
                                    onClick={() => {
                                      imageHandler(item);
                                    }}
                                    alt={`image${index}`}
                                  />
                                  <br />
                                </ImageContainer>
                              ))}
                          </ImageMainContainer>
                        </Wrapper2>
                      </p>
                    </>
                  }
                  handleClose={() => {
                    setIsOpen(!isOpen);
                  }}
                />
              )}
            </div>
            <DataRow className="w-100">
              <label for="" className="form-label w-75  mt-3">
                Menu Name
              </label>
              <input
                type="text"
                className="form-control mt-3"
                name="menu_name"
                size="large"
                onChange={changeHandler}
                required={true}
              />
            </DataRow>
            <DataRow>
              <label for="" className="form-label w-75">
                Menu URL
              </label>
              <input
                type="url"
                className="form-control"
                name="menu_url"
                size="large"
                onChange={changeHandler}
                required={true}
              />
            </DataRow>
            {/* <Data>
          <Text>Menu URL</Text>
          <Input
            size="large"
            type="text"
            name="menu_url"
            onChange={changeHandler}
          />
        </Data> */}
            {/* <Data>
          <ButtonContainer>
            <SubmitButton onClick={submitHandler}>
              <p>Submit</p>
            </SubmitButton>
          </ButtonContainer>
        </Data> */}
          </div>
          <div className="col-12 col-lg-12 my-3 text-center ">
            <button
              type="submit"
              className="btn btn-primary"
            // onClick={submitForm}

            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </Container>
  );
}

export default AddMainMenu;