import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import {
  Typography,
  Divider,
  Input,
  Radio,
  Button,
  Checkbox,
  AutoComplete
} from "antd";
import Popup from "../../../Repeated/Popup";
import { useDispatch, useSelector } from "react-redux";
import { addCategory, getCategoryData, getStoreData, getStoreUploadImage, getalltags, updateStoreCategoryData } from "../../../../redux/slices/manageStoreSlice";

const { Title, Text } = Typography;

const { TextArea } = Input;

const ImageMainContainer = styled.div`
  position: absolute;
  top: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 300px;
  overflow: auto;
`;
const Wrapper2 = styled.div`
  width: 100%;
  height: 310px;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;
const ImageContainer = styled.div`
  width: auto;
  display: flex;
  height: 200px;
  flex-direction: row;
  padding: 50px;
  border: ${(props) => (props.active ? "1px solid #ffff00" : "none")};
  justify-content: space-between;
`;
const TopContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Container = styled.div`
  width: 100%;   
  margin: 0 auto;
  @media screen and (max-width: 480px) {
    display: block;
  }
`;


const Data = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  > label {
    // width: 30%;
  }
  >span{
    font-size: 18px;
    font-weight: bolder;
  }
  > input[type="text"] {
    width: 50%;
  }
  @media screen and (max-width: 480px) {
    display: block;
  }
`;

const RadioContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  padding: 5px 0;
  > div {
   color: #666;
   font-size: 14px;
   margin-right: 30px;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubmitButton = styled.div`
  width: 140px;
  height: 40px;
  display: flex;
  background: #0000ff;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  cursor:pointer;
  >p{
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 0px;
    color: #fff;
    text-align: center;
  }
`;


const AddCategory = () => {
  const dispatch = useDispatch();
  const { storecategoryData, storecategoryDataRow, allTags, storedata, uploadStoreImagesData } = useSelector((s) => s.manageStores);
  const data = useSelector((s) => s.manageStores);
  const [isDisable, setIsDisable] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [tags, settags] = useState('');
  const [storeData, setStoreData] = useState({
    store_name: "",
    store_id: ""
  });
  const [allValues, setAllValues] = useState({
    category_id: "",
    catName: "",
    tags: ""
  });
  const [imageId, setImageId] = useState({
    id: "",
    url: "",
  });
  const [type, setType] = useState("addcategory");
  const [isUpdatedData, setUpdatedData] = useState(false);
  const [checkedArray, setCheckedArray] = useState([]);
  const [tagArray, setTagArray] = useState([]);
  const [category, setCategory] = useState('');

  const changeHandler = e => {
    setCategory(e.target.value);
  }
  const changeTagHandler = e => {
    settags(e.target.value);
  }

  const changeHandler1 = e => {
    console.log(e.target.name, e.target.value, "value")
    setType(e.target.value);
  }
  const imageHandler = (img) => {
    setImageId({
      id: img.id,
      url: img.url,
    });
    setIsOpen(!isOpen);
  };
  const handleAdd = (item) => {
    if (checkedArray.indexOf(item) == -1) {
      setCheckedArray([...checkedArray, item]);
    }
    else if (checkedArray.includes(item)) {
      handleRemove(item);
    }
  }

  const handleRemove = (item) => {
    if (checkedArray.includes(item)) {
      const newArr = [...checkedArray];
      let index = newArr.indexOf(item);
      newArr.splice(index, 1)
      setCheckedArray(newArr)
    }
  }
  const handleChecked = (e) => {
    let checked = e.target.checked;
    const value = e.target.value;
    if (checked && checked) {
      handleAdd(value)
    } else {
      handleRemove(value)
    }
  };
  const handleTagChecked = (e) => {
    let checked = e.target.checked;
    const value = e.target.value;
    if (checked && checked) {
      handleTagAdd(value)
    } else {
      handleTagRemove(value)
    }
  };

  const handleTagAdd = (item) => {
    if (tagArray.indexOf(item) == -1) {
      setTagArray((tagsArr) => [...tagsArr, item]);
    }
    else if (tagArray.includes(item)) {
      handleTagRemove(item);
    }
  }

  const handleTagRemove = (item) => {
    if (tagArray.includes(item)) {
      const newArr = [...tagArray];
      let index = newArr.indexOf(item);
      newArr.splice(index, 1)
      setTagArray(newArr)
    }
  }
  const cb_func = () => {
    dispatch(getalltags());
  }

  const handleAutoComplete = (e) => {
    setStoreData({ ...storeData, storeName: e })
    if (storedata.length > 1) {
      for (var i = 0; i < storedata.length; i++) {
        if (e == storedata[i].store_name) {
          setStoreData({ ...storeData, store_id: storedata[i].store_id, store_name: storedata[i].store_name })
        }
      }
    }
  }

  const submitHandler = e => {
    let data = {};
    data.type = type;
    data.tags = tags;
    data.tagArray = tagArray;
    data.category = category;
    data.category_image = imageId.id
    data.store_id = storeData.store_id;
    data.category_id = checkedArray;
    console.log(data, "category data===");
    if (isUpdatedData) {
      dispatch(updateStoreCategoryData(data));
      cb_func();
    } else {
      dispatch(addCategory(data));
      cb_func();
    }
  }

  useEffect(() => {
    let data_type = {};
    data_type.type = "Category";

    dispatch(getStoreUploadImage(data_type));
    dispatch(getalltags());
    dispatch(getCategoryData());
    dispatch(getStoreData());
    if (storecategoryDataRow.store_id) {
      setIsDisable(!isDisable);
      setUpdatedData(!isUpdatedData);
      setAllValues({ ...allValues, catName: storecategoryDataRow.category_name, category_id: storecategoryDataRow.category_id, tags: storecategoryDataRow.tags && storecategoryDataRow.tags })
      if (storecategoryDataRow.tags) setTagArray(JSON.parse(storecategoryDataRow.tags))
      setType(storecategoryDataRow.type);
      setStoreData({
        ...storeData, store_id: storecategoryDataRow.store_id, store_name: storecategoryDataRow.store_name
      })
      let catarray = storecategoryDataRow.category_id.split(',').map(Number);
      setCheckedArray(catarray);
    }
  }, []);
  const ticked = checkedArray.sort();
  return (
    <Fragment>
      <TopContainer>
        <Title level={3}>Add Category</Title>
      </TopContainer>
      <Divider />
      <Container>
        <Data>
          <Text>Select Type</Text>
          <RadioContainer>
            {/* <Radio.Group onChange={changeHandler1} name="type"> */}
            <Radio
              type="radio"
              name="addcategory"
              value={"addcategory"}
              onChange={changeHandler1}
              checked={type == "addcategory"}
            >
              Add Category
            </Radio>
            <Radio
              type="radio"
              name="assigncategory"
              onChange={changeHandler1}
              value={"assigncategory"}
              checked={type == "assigncategory"}
            >
              Assign Category
            </Radio>
            <Radio
              type="radio"
              name="addTags"
              onChange={changeHandler1}
              value={"addTags"}
              checked={type == "addTags"}
            >
              Add Tags
            </Radio>
            {/* </Radio.Group> */}
          </RadioContainer>
        </Data>
        {type === "addcategory" &&
          <Container>
            <Data>
              <Text>Category Name</Text>
              <Input
                size="large"
                type="text"
                name="category"
                onChange={changeHandler}
              />
            </Data>
            <div className="mb-5" style={{ position: "relative" }}>
              <label for="" className="form-label">
                Image
              </label>
              <img
                src={`${imageId.url}`}
                // src={storeData.image}
                // value={imageId.url}
                value={storeData.image}
                alt="image selected"
                style={{
                  width: "70px",
                  border: "1px solid black",
                  padding: "10px",
                  marginLeft: "30%",
                }}
              />
              <Button
                size="large"
                type="primary"
                name="image"
                style={{ width: "50%", float: "right" }}
                className="form-control my-5"
                onClick={(e) => {
                  setIsOpen(!isOpen);
                }}
              >
                Choose Image
              </Button>
              {isOpen && (
                <Popup
                  content={
                    <>
                      <b>Category Images</b>
                      <p>
                        <Wrapper2>
                          <ImageMainContainer>
                            {uploadStoreImagesData &&
                              uploadStoreImagesData.map((item, index) => (
                                <ImageContainer>
                                  <img
                                    src={item.url}
                                    onClick={() => {
                                      imageHandler(item);
                                    }}
                                    alt={`image${index}`}
                                  />
                                  <br />
                                </ImageContainer>
                              ))}
                          </ImageMainContainer>
                        </Wrapper2>
                      </p>
                    </>
                  }
                  handleClose={() => {
                    setIsOpen(!isOpen);
                  }}
                />
              )}
            </div>
          </Container>}
        {type === "assigncategory" &&
          <Container>
            <Data style={{ display: "flex" }}>
              <Text style={{ width: "50%" }} >Store Name</Text>
              <h6>{storeData.store_name}</h6>
              <AutoComplete
                style={{ width: "50%" }}
                // value={storeData.store_name}
                required={true}
                className="auto-complete form-control"
                dataSource={storedata && storedata.map((item, index) => item.store_name)}
                disabled={isDisable}
                onSelect={handleAutoComplete}
                filterOption={(inputValue, option) =>
                  option.props.children
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Data>
            <Data style={{ display: "block" }}>
              <Text style={{ display: "flex" }}>Change Category Type</Text>
              <h6 className="my-2">{`(${allValues.catName})`}</h6>
              {storecategoryData && storecategoryData.map((item, index) => (
                <Checkbox onChange={handleChecked} checked={checkedArray.includes(item.c_id)} value={item.c_id} name={item.category_name} >{item.category_name}</Checkbox>
              ))}
              <p style={{ "position": "absolute", "font-weight": "bold", "padding": "0px 4px", "margin-top": "7px" }}>Checked Values:-{checkedArray.join(" | ")}
              </p>
            </Data>
            <Data className="mt-5" style={{ display: "block" }}>
              <Text style={{ display: "flex" }}>Select Tags</Text>
              <h6 className="my-2">{`(${allValues.tags})`}</h6>
              {allTags && allTags.map((item, index) => (
                <Checkbox onChange={handleTagChecked} checked={tagArray.includes(item.tags)} value={item.tags} name={item.tags} >{item.tags}</Checkbox>
              ))}
              {/* <p style={{ "position": "absolute", "font-weight": "bold", "padding": "0px 4px", "margin-top": "7px" }}>Checked Values:-{checkedArray.join(" | ")}
              </p> */}
            </Data>
          </Container>
        }

        {type === "addTags" &&
          <Container>
            <Data>
              <Text>Tag Name</Text>
              <Input
                size="large"
                type="text"
                name="tags"
                onChange={changeTagHandler}
              />
            </Data>
          </Container>
        }
        <ButtonContainer>
          <SubmitButton onClick={submitHandler}>
            <p>Submit</p>
          </SubmitButton>
        </ButtonContainer>
      </Container>
    </Fragment>
  );
}

export default AddCategory;