import axios from "axios";
import AppConstants from "../../constants/appConstants";

const API = axios.create({
  baseURL: `${AppConstants.baseURL}`,
});

export const Top10Results = () => API.get("/crm/pendingpayout");
export const AllOrdersCrmDetails = (data) =>
  API.post("/crm/allordersdata", data); ////not dispatching any data from component yet
export const performanceReportsApi = (data) =>
  API.post("/crm/performreport", data); ////not dispatching any data from component yet

export const showCashbackClaims = () => API.get("/crm/showcashbackclaims");
export const CashbackClaimsStatusMaster = (data) =>
  API.get("/crm/claimsstatusmaster", data);
export const updateCashbackClaimStatus = (data) =>
  API.put("/crm/updateclaimstatus", data); //// Recheck again
export const getInfoByClickIdApi = (data) =>
  API.post("/crm/infobyclickid", data);
export const searchByClickId = (data) => API.post("/crm/searchbyclicks", data);
export const SearchByClickIdByDate = (data) =>
  API.post("/crm/clicksbydate", data);

export const storeFixedTermsAndConditionsApi = () =>
  API.get("/crm/storestatictermsconditions");
