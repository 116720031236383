import React, { useEffect, useState } from "react";

import styled from "styled-components";
// import { Typography, Divider, DatePicker, Radio, Select } from "antd";
import {
  Table,
  TableCell,
  TableRow,
  TextField,
  tableCellClasses,
  Button,
  TableBody,
  Paper,
  Stack,
  Typography,
  Toolbar,
  Chip,
  TextareaAutosize,
  Autocomplete,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { getAllPanelUsers } from "../../redux/slices/userRoleManagementSlice";
import { addTodoTaskReminder, updateTodoTaskRemind } from "../../redux/slices/taskReminderSlice";

const Wrapper = styled.div`
  width: 100%;

  > div {
    > div {
      > button {
        background: linear-gradient(
            259deg,
            rgb(236, 94, 58) 0%,
            rgb(249, 186, 99) 100%
          )
          0% 0% no-repeat padding-box padding-box transparent;
        padding: 7px 20px;
        border-radius: 20px;
        float: right;
      }
    }
  }
  .modal_todo > div {
    max-width: 800px !important;
  }
  .modal-dialog {
    @media only screen and (max-width: 3000px) {
      max-width: 800px !important;
    }
  }
`;

function dateConverter(str) {
  // console.log(str, "str")
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  // let hours = ("0" + date.getHours()).slice(-2);
  // let minutes = ("0" + date.getMinutes()).slice(-2);
  // let seconds = ("0" + date.getSeconds()).slice(-2);
  let year = date.getFullYear();
  return `${year}-${mnth}-${day}`;
}

const AddTask = () => {
  const dispatch = useDispatch();
  const data = useSelector((s) => s);
  const allPanelUsersData = useSelector((s) => s.userRoleManagement.data.getAllPanelUsers);
  const allRolesData = useSelector((s) => s.userRoleManagement.data.userRoles);
  const {updatedTaskData} = useSelector(s=>s.taskReminder)
  const [createNewTask, setcreateNewTask] = useState({
    id: "",
    title: "",
    description: "",
    assigned_to: [],
    team_id: [],
    reminder_date: "",
    deadline_days: "",
    recurrence: "",
  });
  const [isUpdated, setIsUpdated] = useState(false);
  ///////////states for assign users
  const [assignToData, setassignToData] = useState([]);
  const [assignUserId, setAssignUserId] = useState([]);
  const [assignUser, setassignUser] = useState({
    user_id: "",
    name: "",
    user_role: "",
  });

  ///////////states for assign teams
  const [teamData, setTeamData] = useState([]);
  const [assignTeamId, setAssignTeamId] = useState([]);
  const [assignTeam, setassignTeam] = useState({
    role_id: "",
    name: "",
    user_role: "",
  });

  const handleAddBtn = () => {
    // console.log(assignToData, '---d');
    if (
      assignUser.name === "" ||
      assignUser.name === null ||
      assignUser.name === undefined
    ) {
      return alert("No user selected");
    }
    // console.log(assignUserId, assignUser.user_id)
    const index = assignUserId.indexOf(assignUser.user_id);
    // console.log(index, "idx");
    if (index === -1) {
      setAssignUserId([...assignUserId, assignUser.user_id]);
      setassignToData([...assignToData, assignUser]);
    } else {
      alert("User already added");
    }
  };

  const handleAddBtnTeam = () => {
    // console.log(assignTeam, "assignTeam")
    if (
      assignTeam.name === "" ||
      assignTeam.name === null ||
      assignTeam.name === undefined
    ) {
      return alert("No Team selected");
    }
    const index = assignTeamId.indexOf(assignTeam.role_id);
    if (index === -1) {
      setAssignTeamId([...assignTeamId, assignTeam.role_id]);
      setTeamData([...teamData, assignTeam]);
    } else {
      alert("team already added");
    }
  };
  const recurrence_date = [
    { id: 0, days: "none" },
    { id: 1, days: "one day" },
    { id: 7, days: "Weekly" },
    { id: 30, days: "Monthly" },
    { id: 90, days: "Quaterly" },
    { id: 180, days: "Half Yearly" },
  ];

  const handleDelete = (item) => {
    // console.log(item, assignUser)
    setAssignUserId(assignUserId.filter((key) => key !== item));
    setassignToData(assignToData.filter((key) => key.user_id !== item));
  };

  const handleDeleteTeam = (item) => {
    setAssignTeamId(assignTeamId.filter((key) => key !== item));
    // console.log(teamData, "teamData", item);
    setTeamData(teamData.filter((key) => key.role_id !== item));
  };

  // handle form userData
  const handleUsersData = (e, value) => {
    e.preventDefault();
    // console.log(value, "select");
    if (allPanelUsersData.length > 0) {
      for (var i = 0; i < allPanelUsersData.length; i++) {
        // setcreateNewTask({ ...createNewTask, recurrence: value })
        if (value == allPanelUsersData[i].name) {
          setassignUser({
            ...assignUser,
            user_id: allPanelUsersData[i].user_id,
            name: allPanelUsersData[i].name,
            user_role: allPanelUsersData[i].role,
          });
        }
      }
    }
    // //console.log(categorystate);
  };

  ////=========================================handleTeamData====================================

  const handleTeamData = (e, value) => {
    e.preventDefault();
    // console.log(value, "select");
    if (allRolesData.length > 0) {
      for (var i = 0; i < allRolesData.length; i++) {
        // setcreateNewTask({ ...createNewTask, recurrence: value })
        if (value == allRolesData[i].user_type) {
          setassignTeam({
            ...assignTeam,
            role_id: allRolesData[i].id,
            name: allRolesData[i].user_type,
            user_role: allRolesData[i].user_type,
          });
        }
      }
    }
  };

  // handle form datepicker
  const handleDatePicker = (dateString, name) => {
    console.log(dateString, "name", name);
    if (name == "deadline_days") {
      setcreateNewTask({
        ...createNewTask,
        deadline_days: dateConverter(dateString),
      });
    } else if (name == "reminder_date") {
      setcreateNewTask({
        ...createNewTask,
        reminder_date: dateConverter(dateString),
      });
    }
  };

  // handle form autocomplete
  const handleRecurrenceAutoComplete = (e, value) => {
    e.preventDefault();
    console.log(value, "select");
    if (recurrence_date.length > 0) {
      for (var i = 0; i < recurrence_date.length; i++) {
        // setcreateNewTask({ ...createNewTask, recurrence: value })
        if (value == recurrence_date[i].days) {
          setcreateNewTask({
            ...createNewTask,
            recurrence: recurrence_date[i].id,
          });
        }
      }
    }
  };

  // ================================================handle form submit=======================================

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isUpdated) {
      let update_data = {};
      update_data.id = createNewTask.id;
      update_data.title = createNewTask.title;
      update_data.description = createNewTask.description;
      if (assignUserId) update_data.assigned_to = assignUserId;
      update_data.reminder_date = dateConverter(createNewTask.reminder_date);
      update_data.deadline_days = dateConverter(createNewTask.deadline_days);
      update_data.recurrence = createNewTask.recurrence;
      if (assignTeamId) update_data.team_id = assignTeamId;
      // console.log(update_data, "updateData", createNewTask)
      dispatch(updateTodoTaskRemind(update_data));
    } else dispatch(addTodoTaskReminder(createNewTask));
    console.log(createNewTask, "createNewTask");
  };
  // handle value changes
  const handleChange = (key) => (event) => {
    setcreateNewTask({ ...createNewTask, [key]: event.target.value });
  };

  //==================================================useEffect=====================================
  useEffect(() => {
    // getAllPanelUsers();
    dispatch(getAllPanelUsers());
    
  }, []);

  useEffect(() => {
    setcreateNewTask({ ...createNewTask, assigned_to: assignUserId });
  }, [assignUserId]);
  useEffect(() => {
    setcreateNewTask({ ...createNewTask, team_id: assignTeamId });
  }, [assignTeamId]);

  function getDeadlineDays(date11, date22) {
    var date1 = new Date(date11);
    var date2 = new Date(date22);
    var diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
    return diffDays;
  }
  useEffect(() => {
    console.log(updatedTaskData, "updatedTaskData");
    if (updatedTaskData.title) {
      setIsUpdated(!isUpdated);
      setcreateNewTask({
        ...createNewTask,
        id: updatedTaskData.id,
        title: updatedTaskData.title,
        description: updatedTaskData.description,
        reminder_date: updatedTaskData.reminder_date,
        deadline_days: getDeadlineDays(
          updatedTaskData.reminder_date,
          updatedTaskData.deadline_date
        ),
        recurrence: updatedTaskData.recurrence,
      });

      if (updatedTaskData.assigned_members) {
        setassignToData(updatedTaskData.assigned_members);
        for (let i = 0; i < updatedTaskData.assigned_members.length; i++) {
          if (
            !assignUserId.includes(updatedTaskData.assigned_members[i].user_id)
          )
            assignUserId.push(updatedTaskData.assigned_members[i].user_id);
        }
        setAssignUserId(assignUserId);
        // setcreateNewTask({ ...createNewTask, assigned_to: assignUserId });
      }

      if (updatedTaskData.assigned_team) {
        setTeamData(updatedTaskData.assigned_team);
        for (let i = 0; i < updatedTaskData.assigned_team.length; i++) {
          if (!assignTeamId.includes(updatedTaskData.assigned_team[i].role_id))
            assignTeamId.push(updatedTaskData.assigned_team[i].role_id);
        }
        setAssignTeamId(assignTeamId);
        // setcreateNewTask({ ...createNewTask, team_id: assignTeamId });
      }
    }
  }, []);

  // console.log(teamData, assignTeamId, "assigntodata");

  // console.log(createNewTask, "create")
  return (
    <Wrapper>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <form onSubmit={handleSubmit}>
          <Stack spacing={3} direction="column">
            <Toolbar
              mt={3}
              sx={{
                padding: "1.2rem",
                borderBottom: "2px solid var(--primary-color)",
              }}
            >
              <Typography
                sx={{
                  color: "var(--primary-color)",
                  fontWeight: "bold",
                }}
                variant="h5"
                id="tableTitle"
              >
                Create New Task
              </Typography>
            </Toolbar>
            <Table
              size="small"
              sx={{
                width: "auto",
                margin: "8px !important",
                marginTop: "16px !important",
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Enter Task Title:</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      placeholder="Task Title"
                      fullWidth
                      required
                      value={createNewTask.title}
                      onChange={handleChange("title")}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Enter Task Description:</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextareaAutosize
                      minRows={1}
                      maxRows={4}
                      value={createNewTask.description}
                      onChange={handleChange("description")}
                      style={{
                        width: "100%",
                        padding: "8px 12px",
                        fontSize: "1rem",
                      }}
                      placeholder="Text Description....."
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Assing To User: </b>
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Stack direction="row" spacing={3}>
                      <Autocomplete
                        freeSolo
                        size="small"
                        id="user-combo-box"
                        options={
                          allPanelUsersData &&
                          allPanelUsersData.map((item) => item.name)
                        }
                        sx={{ width: 256 }}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Select User" />
                        )}
                        onChange={(e, value) => handleUsersData(e, value)}
                      />
                      <Button
                        variant="contained"
                        color="success"
                        // startIcon={<AddCircleOutlineIcon />}
                        onClick={handleAddBtn}
                      >
                        <strong>Add</strong>
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
                {assignToData.length > 0 ? (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>
                      <Stack spacing={1}>
                        {assignToData.map((item, index) => (
                          <Chip
                            key={`chip-${index}`}
                            label={item.name}
                            color="warning"
                            sx={{ width: "fit-content" }}
                            onDelete={() => handleDelete(item.user_id)}
                          />
                        ))}
                      </Stack>
                    </TableCell>
                  </TableRow>
                ) : null}
                .
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Assing To Team: </b>
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Stack direction="row" spacing={3}>
                      <Autocomplete
                        freeSolo
                        size="small"
                        id="user-combo-box"
                        options={
                          allRolesData &&
                          allRolesData.map((item) => item.user_type)
                        }
                        sx={{ width: 256 }}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Select Team" />
                        )}
                        onChange={(e, value) => handleTeamData(e, value)}
                      />
                      <Button
                        variant="contained"
                        color="success"
                        // startIcon={<AddCircleOutlineIcon />}
                        onClick={handleAddBtnTeam}
                      >
                        <strong>Add</strong>
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
                {teamData.length > 0 ? (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>
                      <Stack spacing={1}>
                        {teamData.map((item, index) => (
                          <Chip
                            key={`chip-${index}`}
                            label={item.name}
                            color="warning"
                            sx={{ width: "fit-content" }}
                            onDelete={() => handleDeleteTeam(item.role_id)}
                          />
                        ))}
                      </Stack>
                    </TableCell>
                  </TableRow>
                ) : null}
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Set Reminder_date: </b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {/* <TimePicker
                      label="Time"
                      value={createNewTask.reminder_date && createNewTask.reminder_date}
                      onChange={(newValue) => handleDatePicker(newValue, "reminder_date")}
                      renderInput={(params) => (
                        <TextField {...params} helperText="Clear Initial State" />
                      )}
                    /> */}
                    <DesktopDatePicker
                      label="Date desktop"
                      inputFormat="YYYY/MM/DD"
                      value={
                        createNewTask.reminder_date &&
                        createNewTask.reminder_date
                      }
                      onChange={(newValue) =>
                        handleDatePicker(newValue, "reminder_date")
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          helperText="Clear Initial State"
                        />
                      )}
                    />
                    {/* <DateTimePicker
                      value={createNewTask.reminder_date && createNewTask.reminder_date}
                      onChange={(newValue) => handleDatePicker(newValue, "reminder_date")}
                      renderInput={(params) => (
                        <TextField {...params} helperText="Clear Initial State" />
                      )}
                    /> */}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Enter Deadline Days:</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      placeholder="Deadline Days"
                      fullWidth
                      required
                      value={createNewTask.deadline_days}
                      onChange={handleChange("deadline_days")}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Recurrence: </b>
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Stack direction="row" spacing={3}>
                      <Autocomplete
                        freeSolo
                        size="small"
                        id="user-combo-box"
                        value={createNewTask.recurrence}
                        options={
                          recurrence_date &&
                          recurrence_date.map((elem) => elem.days)
                        }
                        sx={{ width: 256 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Recurrence"
                          />
                        )}
                        onChange={(e, value) =>
                          handleRecurrenceAutoComplete(e, value)
                        }
                        // onChange={(e, value) => setcreateNewTask({ ...createNewTask, recurrence: value })}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Button
                      variant="contained"
                      type="submit"
                      size="large"
                      className="d-block mx-auto mt-4 mb-3"
                      onClick={handleSubmit}
                    >
                      <strong>Create </strong>
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Stack>
        </form>
      </LocalizationProvider>
      {/* </Modal.Body>
      </Modal> */}
    </Wrapper>
  );
};

export default AddTask;
