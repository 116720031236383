import keyMirror from "keymirror";
export default keyMirror({
  GOT_PROFILE: null,
  LOGOUT: null,
  GOT_ACTIVITIES_DATA: null,
  GOT_PARTNER_ID: null,
  GOT_TESTIMONIALS: null,
  GOT_TESTIMONIAL_UPLOAD_IMAGE: null,
  GOT_NEWS: null,
  GOT_NEWS_UPLOAD_LOGO: null,
  GOT_IMAGE_BY_STORE: null,
  GOT_ALL_IMAGES: null,
  GOT_IMAGES: null,
  GOT_DESCRIPTIONS: null,
  GOT_LIVE_BANNERS: null,
  GOT_LIVE_OFFERS: null,
  GOT_ALL_MANAGE_BANNERS_IMAGES: null,
  GOT_HEADERFOOTER: null,
  GOT_MAIN_MENU: null,
  GOT_CONTACTED_USERS: null,
  GOT_HEADER_FOOTER: null,
  GOT_HEADER_FOOTER_SUBMENU: null,
  GOT_STORE_CATEGORY_DATA_ROW: null,
  GOT_IMAGE_TYPE: null,
  GOT_DIMENSION: null,
  GOT_CATEGORY_DATA: null,
  GOT_STORE_DATA: null,
  GOT_ACTIVE_PARTNERS: null,
  GOT_STORES: null,
  GOT_ACTIVITY_CODE_DATA: null,
  GOT_STORE_CATEGORY_DATA: null,
  GOT_UPLOAD_IMAGE: null,
  GOT_STORE_UPLOAD_IMAGE: null,
  GOT_ACTIVITIY_UPLOAD_IMAGE: null,
  GOT_POINTS: null,
  GOT_DESCRIPTION_ROW: null,
  GOT_ADDITIONAL_STORE_ROW: null,
  GOT_ADDITIONAL_STORE_DATA: null,
  GOT_ACTIVITIES_DATA_ROW: null,
  GOT_TESTIMONIALS_DATA_ROW: null,
  GOT_NEWS_DATA_ROW: null,
  GOT_POINTS_DATA_ROW: null,
  GOT_STORES_DATA_ROW: null,
  GOT_REDEEM_DATA: null,
  GOT_REDEEM_DATA_ROW: null,
  GOT_SHOW_REDEEM_DATA: null,
  GOT_ALLOWED_ACTIVITY_DATA: null,
  GOT_EMAIL_DATA: null,
  GOT_EMAIL_MASTER_DATA: null,
  GOT_EMAIL_DATA_ROW: null,
  GOT_GALLERY_IMAGE: null,
  GOT_AFF_LINKS_DATA: null,
  GOT_PARTNER_MASTER_DATA: null,
  GOT_PARTNER_MASTER_DATA_ROW: null,
  GOT_DEEPLINK_DATA: null,
  GOT_DEEPLINK_DATA_ROW: null,
  GOT_PARTNERS_AS_STORES: null,
  GOT_PARTNERASSTORES_DATA_ROW: null,
  GOT_PAYMENT_STATUS_DATA: null,
  GOT_PAYMENT_STATUS_DATA_ROW: null,
  GOT_REDEMPTION_STATUS_MASTER: null,
  GOT_ALL_PARTNERS: null,
  GOT_ALL_ACTIVITIES: null,
  GOT_RESPONSE_ACTIVE_PARTNERS: null,
  GOT_RESPONSE_MASTER_DATA_CSV: null,
  GOT_CASHBACK_CLAIMS_DATA: null,
  GOT_CASHBACK_CLAIMS_MASTER_DATA: null,
  GOT_INFO_BY_CLICK_ID: null,
  GOT_USER_CLICK_INFO: null,
  GOT_CLICKS_BY_DATE: null,
  GOT_DASHBOARD_DATA: null,
  GOT_ALL_ORDERS_CRM: null,
  GOT_STORE_SCHEMA: null,
  GOT_STORE_SCHEMA_ROW: null,
  GOT_HIGHEST_PENDING_PAYOUT: null,
  GOT_ALL_TAGS: null,
  GOT_NEW_USERS_DETAILS: null,
  GOT_USER_LOG_CLICKS_DETAILS: null,
  GOT_MANUAL_BONUS_POINTS: null,
  GOT_MODULES_USERS_DETAILS: null,
  GOT_SHOW_ROLES_DATA: null,
  GOT_USERTYPE_MODULE_DATA: null,
  GOT_ALL_PANEL_USERS: null,
  GOTA_ALL_PERMISSION_MODULE: null,
  GOT_EXTSTOREDOMAIN_DATA: null,
  GOT_TASK_REMINDER_DATA: null,
  GOT_TASK_COMPLETE: null,
  GOT_RECURRENCE_TASK: null,
  GOT_ASSIGNEDTASK_BY_ADMIN: null,
  GOT_UPDATETASK_BY_ADMIN: null,
  GOT_STORE_FEATURES: null,
  GOT_STORE_FEATURES_ROW: null,
  // GOT_TASK_REMINDER_DATA_DATE_INCREMENT : null,
  // GOT_SIGNUP_USERS_DASHBOARD: null,
  // GOT_REDEMPTION_DASHBOARD: null,
  // GOT_ORDERS_DASHBOARD: null,
  // GOT_CLAIMS_DASHBOARD: null,
  // GOT_BALANCE_DASHBOARD: null,
  // GOT_CLICKS_DASHBOARD: null,
  // GOT_PARTNER_RESP_BY_CLICK_ID:null,
});
