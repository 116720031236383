import React, { Fragment, useState, useEffect, useCallback } from "react";
import MUIDataTable from "mui-datatables";
import { Popconfirm, Typography, message, Divider } from 'antd';
import { useNavigate } from "react-router-dom";
import queryString from 'query-string';

import { getOffers, activatedDealsData, senddatatoEdit } from "../../../redux/slices/dealsSlice";
import { useDispatch, useSelector } from "react-redux";

const ShowDeals = (props) => {
    const dispatch = useDispatch();
    const { getOffersData } = useSelector((state) => ({ ...state.deals }));
    // let history = useHistory();  //useHistory is being deprecated in react-router v6 or above

    const navigate = useNavigate();
    useEffect(() => {
        dispatch(getOffers());
    }, []);

    const cancel = () => {
        //console.log("Cancelled");
    };

    const edit = (e, value) => {
        let data = {};
        data.id = value[2];
        data.store_id = value[3];
        data.store_name = value[4]
        data.title = value[5];
        data.img_url = value[6];
        data.detail = value[7];
        data.description = value[8];
        data.cashback_upto = value[9];
        data.mrp = value[10];
        data.offer_price = value[11];
        data.product_url = value[12];
        data.start_date = value[13];
        data.end_date = value[14];
        data.d_type = "html";
        dispatch(senddatatoEdit(data)); 
        props.changeTab("addDeals");
    };

    const callbackFunc = () => {
        dispatch(getOffers());
    };

    const activated = (e, value) => {
        let data = {};
        data.id = value[2];
        data.type = "activate";
        let query = queryString.stringify(data);
        dispatch(activatedDealsData(query, callbackFunc));
        message.success("Store data Activated Successfully");
    };

    const deactivated = (e, value) => {
        let data = {};
        data.id = value[2];
        data.type = "deactivate";
        let query = queryString.stringify(data);
        dispatch(activatedDealsData(query, callbackFunc));
        message.success("Store data Deactivated Successfully");
    };
    const columns = [
        {
            name: "Action",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (

                    <div>
                        <Popconfirm
                            title="activate the activity"
                            onConfirm={e => activated(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#" style={{ padding: 5, fontWeight: "bolder", color: "#078101", border: "1px solid #aaa", borderRadius: "5px", boxShadow: "2px 2px 0px 0px", cursor: "pointer", marginBottom: "10px" }}>Activate</a>
                        </Popconfirm>
                        <Divider type="vertical"></Divider>
                        <Popconfirm
                            title="Deactivated"
                            onConfirm={e => deactivated(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#" style={{ padding: 5, fontWeight: "bolder", color: "#FF1502", border: "1px solid #aaa", borderRadius: "5px", boxShadow: "2px 2px 0px 0px", cursor: "pointer" }}>Deactivate</a>
                        </Popconfirm>
                    </div>
                )
            }
        },
        {
            name: "opertaion",
            label: "Operation",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div>
                        <Popconfirm
                            title="Edit this task?"
                            onConfirm={e => edit(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#">Edit</a>
                        </Popconfirm>
                    </div>
                )
            }
        },
        {
            name: "id",
            label: "id",
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "store_id",
            label: "Store",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "store_name",
            label: "Store Name",
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "title",
            label: "Product Title",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "img_url",
            label: "Product Image",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "detail",
            label: "Product Details",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => <div dangerouslySetInnerHTML={{
                    __html: value
                }}></div>
            }
        },
        {
            name: "description",
            label: "Product Description",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => <div dangerouslySetInnerHTML={{
                    __html: value
                }}></div>
            }
        },
        {
            name: "cashback_upto",
            label: "Hyyzo Cashback Upto",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "mrp",
            label: "Price Actual",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "offer_price",
            label: "Offer Price",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "product_url",
            label: "Product link",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "start_date",
            label: "Start Date",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "end_date",
            label: "End Date",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "active",
            label: "Active",
            options: {
                filter: false,
                sort: false,
            }
        },
    ];

    const options = {
        filterType: 'store_name',
    };

    return (
        <Fragment>
            <MUIDataTable
                title={"Show Deals  (search by store Name)"}
                data={getOffersData && getOffersData}
                columns={columns}
                options={options}
            />
        </Fragment>
    )

}

export default ShowDeals;