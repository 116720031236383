import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";
import { Typography, Input, Button, message, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { showRoles, signup } from "../../redux/slices/authSlice";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/authContext.js";

const { Title, Text } = Typography;

const Wrapper = styled.div`
  width: 500px;
  height: 560px;
  padding: 10px;
  display: flex;
  align-items: center;
  background: darkslategrey;
  flex-direction: column;
`;

const Logo = styled.div`
  height: 120px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    width: 100%;
  }
`;

const Body = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
  > p {
    margin-bottom: 50px;
    font-size: 20px;
    font-weight: bolder;
    color: #000;
  }
`;

const Data = styled.div`
  margin-top: 10px;
  width: 350px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  > p {
    width: 150px;
    font-size: 16px;
    font-weight: bolder;
    color: #cc6600;
    margin-bottom: 0px;
  }
  > input {
    width: 350px;
  }
`;

const LoginButton = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #99ff99;
  border: none;
  margin-right: 15px;
  cursor: pointer;
  > p {
    font-size: 17px;
    color: #000;
    font-weight: bolder;
    margin-bottom: 0px;
  }
`;
const SignupButton = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #bbb;
  border: none;
  margin-left: 15px;
  cursor: pointer;
  margin-left: 0px;
  > p {
    font-size: 17px;
    color: #000;
    font-weight: bolder;
    margin-bottom: 0px;
  }
`;

const ButtonContainer = styled.div`
  display: block;
  flex-direction: row;
  justify-content: space-between;
  width: 73%;
  > div {
    margin-bottom: 5%;
  }
`;

const AdminData = [
  { name: "Admin", value: "admin" },
  { name: "User", value: "user" },
];

const Signup = (props) => {
  const dispatch = useDispatch();
  const show_roles = useSelector((state) => console.log(state.auth.show_roles));
  // const show_roles = "heelo";
  const navigate = useNavigate();
  const [rolesArr, setRolesArr] = useState([]);
  const { signupUser } = useAuth();
  const [signupData, setSignupData] = useState({
    name: "",
    email: "",
    pass1: "",
    pass2: "",
    role: "",
    roleName: "",
  });
  useEffect(() => {
    dispatch(showRoles())
      .then((action) => setRolesArr(action.payload.data))
      .catch((err) => console.log(err));
  }, []);
  console.log(rolesArr, "rolesArr--------------");
  const handleChange = (event) =>
    setSignupData({ ...signupData, [event.target.name]: event.target.value });
  const handleRoleChange = (value) => {
    setSignupData({ ...signupData, role: value });
    if (rolesArr && rolesArr.length > 0) {
      for (var i = 0; i < rolesArr.length; i++) {
        if (value == rolesArr[i].user_type) {
          setSignupData({ ...signupData, role: rolesArr[i].user_type });
        }
      }
    }
  };

  const signupHandler = async () => {
    const { pass1, pass2, name, role, email } = signupData;
    if (pass1 == pass2) {
      let data = {};
      data.name = name;
      data.email = email;
      data.password = pass2;
      data.role = role;
      console.log(data, "signupdata");
      await signupUser(data);
      // await dispatch(signup(data));
      navigate("/login");
    } else {
      message.error("Password Mismatch", 5);
    }
  };

  return (
    <Wrapper>
      <h3 className="ok4">Sign Up</h3>
      <Data>
        <Input
          size="large"
          type="name"
          name="name"
          placeholder="Name"
          value={signupData.name}
          onChange={handleChange}
          className="email-input"
        />
      </Data>
      <Data>
        {/* <p>Email * </p> */}
        <Input
          size="large"
          type="email"
          name="email"
          placeholder="Email"
          value={signupData.email}
          onChange={handleChange}
          className="email-input"
        />
      </Data>
      <Data>
        {/* <p>Password </p> */}
        <Input
          size="large"
          type="password"
          name="pass1"
          placeholder="Password"
          value={signupData.pass1}
          onChange={handleChange}
          className="password-input-signup"
        />
      </Data>
      <Data>
        {/* <p>Confirm Password </p> */}
        <Input.Password
          size="large"
          type="pass2"
          name="pass2"
          placeholder="Confirm Password"
          style={{ width: "350px" }}
          value={signupData.pass2}
          onChange={handleChange}
          className="password-input-signup"
        />
      </Data>
      <Data>
        {/* <p>Select Role </p> */}
        <label className="w-50">Select Role</label>
        <Select className="w-50" onChange={handleRoleChange}>
          {rolesArr &&
            rolesArr?.map((item, index) => {
              console.log(item, "item");
              return <Option value={item.user_type}>{item.user_type}</Option>;
            })}
        </Select>
      </Data>
      <ButtonContainer>
        <LoginButton onClick={signupHandler}>
          <p>Sign Up</p>
        </LoginButton>
        <NavLink to="/login">
          <SignupButton>
            <p>Cancel</p>
          </SignupButton>
        </NavLink>
      </ButtonContainer>
    </Wrapper>
  );
};

export default Signup;
