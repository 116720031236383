import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { Typography, Divider, Input, Button, Radio, Icon, message } from "antd";
import Popup from "../../Repeated/Popup";
import AppConstants from "../../../constants/appConstants";
import { useDispatch, useSelector } from "react-redux";
import { addTestimonials, getTestimonialUploadImage, updateTestimonialsData } from "../../../redux/slices/testimonialSlice";
const { Title, Text } = Typography;

const { TextArea } = Input;

const TopContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  @media screen and (max-width: 480px) {
    display: block;
  }
`;

const Data = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  > label {
    width: 30%;
  }
  > span {
    font-size: 18px;
    font-weight: bolder;
  }
  > input[type="text"] {
    width: 50%;
  }
  @media screen and (max-width: 480px) {
    display: block;
  }
`;
const Wrapper2 = styled.div`
  width: 100%;
  height: 310px;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;
const ImageMainContainer = styled.div`
  position: absolute;
  top: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 300px;
  overflow: auto;
`;

const RadioContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  padding: 5px 0;
  > div {
    color: #666;
    font-size: 14px;
    margin-right: 30px;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubmitButton = styled.div`
  width: 140px;
  height: 40px;
  display: flex;
  background: #0000ff;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  cursor: pointer;
  > p {
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 0px;
    color: #fff;
    text-align: center;
  }
`;
const ImageContainer = styled.div`
  width: 200px;
  display: flex;
  height: 200px;
  flex-direction: row;
  padding: 50px;
  justify-content: space-between;
`;
const DataRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const AddTestimonials = () => {
  const dispatch = useDispatch();
  const { testimonialsDataRow, testimonialUploadImage } = useSelector(s => s.testimonails.data);
  // console.log(testimonialsDataRow)
  const [isUpdatedData, setUpdatedData] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [imageId, setImageId] = useState({
    id: "",
    url: "",
  });
  const [allValues, setAllValues] = useState({
    name: "",
    description: "",
    t_id: "",
    // link: ''
  });

  useEffect(() => {
    dispatch(getTestimonialUploadImage());
    if (testimonialsDataRow.image) {
      setUpdatedData(true);
      let str = testimonialsDataRow.image
      let url = str.replace("https://cdn.hyyzo.com/", "");
      setImageId({
        ...imageId,
        id: testimonialsDataRow.id,
        url: url,
      });
      setAllValues({
        ...allValues,
        name: testimonialsDataRow.name,
        description: testimonialsDataRow.description,
        t_id: testimonialsDataRow.t_id,
      });
    }
  }, []);

  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
    // console.log(e);
  };
  const cb_func = () => {
    console.log("Executed");
  };
  const submitHandler = (e) => {
    e.preventDefault();
    let data = {};
    data.t_id = allValues.t_id;
    data.name = allValues.name;
    data.image = imageId.id;
    data.description = allValues.description;
    if (isUpdatedData) {
      dispatch(updateTestimonialsData(data));
    } else {
      dispatch(addTestimonials(data));
    }
  };

  const imageHandler = (img) => {
    console.log(img, "img testimoniual")
    setImageId({
      id: img.id,
      url: img.url,
    });
  };
  return (
    <Fragment>
      <TopContainer>
        <Title level={3}>Add Testimonials</Title>
      </TopContainer>
      <Divider />
      <Container className="outer">
        <form onSubmit={submitHandler}>
          <div className="row">
            <div className="col-12 col-lg-12">
              <DataRow>
                <label for="" className="form-label w-75">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  size="large"
                  value={allValues.name}
                  // value={allValues.tips}
                  onChange={changeHandler}
                  required={true}
                />
              </DataRow>
              {/* <Data>
                <Text>Name</Text>
                <Input
                  size="large"
                  type="text"
                  name="name"
                  value={allValues.name}
                  onChange={changeHandler}
                />
              </Data> */}
              <Data className="my-5">
                <Text>Image</Text>
                <img
                  src={`${AppConstants.imageURL}${imageId.url}`}
                  value={imageId.url}
                  alt="image selected"
                  style={{
                    width: "70px",
                    position: "absolute",
                    right: "270px",
                  }}
                />
                <Button
                  size="large"
                  type="primary"
                  name="image"
                  onClick={(e) => {
                    setIsOpen(!isOpen);
                  }}
                >
                  Choose Image
                </Button>
                {isOpen && (
                  <Popup
                    content={
                      <>
                        <b>Activity images</b>
                        <p>
                          <Wrapper2>
                            <ImageMainContainer>
                              {testimonialUploadImage &&
                                testimonialUploadImage.map(
                                  (item, index) => (
                                    <ImageContainer>
                                      <img
                                        src={`${AppConstants.imageURL}${item.url}`}
                                        onClick={() => {
                                          imageHandler(item);
                                        }}
                                        alt="image"
                                      />
                                      {/* <p>{title}</p> */}
                                      {/* <p>{altText}</p> */}

                                      <br />
                                    </ImageContainer>
                                  )
                                )}
                            </ImageMainContainer>
                          </Wrapper2>
                        </p>

                        <form
                          onClick={() => {
                            setIsOpen(!isOpen);
                          }}
                        >
                          <br />
                          <input type="button" value="Select" />
                        </form>
                      </>
                    }
                    handleClose={() => {
                      setIsOpen(!isOpen);
                    }}
                  />
                )}
              </Data>
              <label for="" className="form-label w-75">
                Description
              </label>
              <textarea className="w-100" name="description" rows="4" cols="50" value={allValues.description} placeholder="Person Description....." onChange={changeHandler}
                required={true} />
              {/* <Data>
                <Text>Description</Text>
                <TextArea
                  style={{ width: "50%" }}
                  size="large"
                  autosize={{ minRows: 2, maxRows: 10 }}
                  type="text"
                  name="description"
                  value={allValues.description}
                  placeholder="Person Description....."
                  onChange={changeHandler}
                />
              </Data> */}
              {/* <DataRow>
                <label for="" className="form-label w-75">
                Link
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="link"
                  // value={allValues.tips}
                  onChange={changeHandler}
                  required={true}
                />
              </DataRow> */}


            </div>
            <div className="col-12 col-lg-12 my-3 text-center ">
              <button
                type="submit"
                className="btn btn-primary"
              // onClick={submitForm}

              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </Container>
    </Fragment>
  );
};

export default AddTestimonials;
