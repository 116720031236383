import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Popconfirm, Typography } from 'antd';
import { useNavigate } from "react-router-dom";
import queryString from 'query-string';
import { gotPartnerAsStoresDataRow, showPartnersAsStore } from "../../../../redux/slices/affiliateSlice";
import { useDispatch, useSelector } from "react-redux";


const ShowStoresPartners = (props) => {
    // let history = useHistory();  //useHistory is being deprecated in react-router v6 or above
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { partnerAsStoresData } = useSelector((s) => ({ ...s.affiliate }));
    console.log(partnerAsStoresData, "partnerAsStoresData");

    useEffect(() => {
        dispatch(showPartnersAsStore());
    }, []);

    const edit = (e, value) => {
        let data = {};
        data.partner = value[1];
        data.partner_id = value[2]
        data.aff_links_id = value[3]
        data.store_id = value[4];
        data.store_name = value[5];
        dispatch(gotPartnerAsStoresDataRow(data));
        props.setAffType("select_partner"); //used for component rendering do not remove
    };
    const callbackFunc = () => {
        dispatch(showPartnersAsStore());
    };
    const cancel = () => {
        // console.log("Cancelled");
    };

    // const activated = (e, value) => {
    //     let data = {};
    //     data.id = value[2];
    //     data.type = "activate";
    //     let query = queryString.stringify(data);
    //     props.activateDeactivateAffLinks(query, callbackFunc)
    //     // console.log(value, "hello")
    // }
    // const deactivated = (e, value) => {
    //     let data = {};
    //     data.id = value[2];
    //     data.type = "deactivate";
    //     let query = queryString.stringify(data);
    //     // console.log(value, "hello")
    //     props.activateDeactivateAffLinks(query, callbackFunc);

    // }
    const columns = [
        // {
        //     name: "Action",
        //     label: "Action",
        //     options: {
        //         filter: false,
        //         customBodyRender: (value, tableMeta, updateValue) => (

        //             <div>
        //                 <Popconfirm
        //                     title="activate the activity"
        //                     onConfirm={e => activated(e, tableMeta.rowData)}
        //                     onCancel={cancel}
        //                     okText="Yes"
        //                     cancelText="No"
        //                 >
        //                     <a href="#" style={{ padding: 5, fontWeight: "bolder", color: "#078101", border: "1px solid #aaa", borderRadius: "5px", boxShadow: "2px 2px 0px 0px", cursor: "pointer", marginBottom: "10px" }}>Activated</a>
        //                 </Popconfirm>
        //                 {/* <Divider type="vertical"></Divider> */}
        //                 <Popconfirm
        //                     title="Deactivated"
        //                     onConfirm={e => deactivated(e, tableMeta.rowData)}
        //                     onCancel={cancel}
        //                     okText="Yes"
        //                     cancelText="No"
        //                 >
        //                     <a href="#" style={{ padding: 5, fontWeight: "bolder", color: "#FF1502", border: "1px solid #aaa", borderRadius: "5px", boxShadow: "2px 2px 0px 0px", cursor: "pointer" }}>Deactivated</a>
        //                 </Popconfirm>
        //             </div>
        //         )
        //     }
        // },
        {
            name: "opertaion",
            label: "Operation",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div>
                        <Popconfirm
                            title="Edit this task?"
                            onConfirm={e => edit(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#">Edit</a>
                        </Popconfirm>
                    </div>
                )
            }
        },
        {
            name: "partner",
            label: "Partner_name",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "partner_id",
            label: "Partner_Id",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "aff_links_id",
            label: "Aff Links Id",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "store_id",
            label: "Store ID",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "store_name",
            label: "Store Name",
            options: {
                filter: true,
                sort: true,
            }
        },
    ];
    const options = {
        filterType: 'partner',
    };

    return (
        <Fragment>
            <MUIDataTable
                title={"Show Partners Data As Stores (search by partner)"}
                data={partnerAsStoresData && partnerAsStoresData}
                columns={columns}
                options={options}
            />
        </Fragment>
    );
};

export default ShowStoresPartners;




