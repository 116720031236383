import ActionTypes from "../constants/actionTypes";
import initialState from "../stores/initialState";

export default (state = initialState.managestores, action) => {
  switch (action.type) {
    case ActionTypes.GOT_DESCRIPTIONS:
      return {
        ...state,
        descriptionData: action.payload,
      };
    case ActionTypes.GOT_STORE_UPLOAD_IMAGE:
      return {
        ...state,
        uploadStoreImagesData: action.payload.data,
      };
    case ActionTypes.GOT_CATEGORY_DATA:
      return {
        ...state,
        categoryData: action.payload
      };
    case ActionTypes.GOT_STORE_DATA:
      return {
        ...state,
        storeData: action.payload
      };
    case ActionTypes.GOT_ACTIVE_PARTNERS:
      return {
        ...state,
        activePartnersData: action.payload
      };
    case ActionTypes.GOT_STORES:
      return {
        ...state,
        allStores: action.payload
      };

    case ActionTypes.GOT_STORE_CATEGORY_DATA:
      return {
        ...state,
        storecategoryData: action.payload
      };

    case ActionTypes.GOT_DESCRIPTION_ROW:
      return {
        ...state,
        descriptionDataRow: action.payload
      }
    case ActionTypes.GOT_ADDITIONAL_STORE_ROW:
      return {
        ...state,
        additionalStoreDataRow: action.payload
      }
    case ActionTypes.GOT_ADDITIONAL_STORE_DATA:
      return {
        ...state,
        additionalStoreData: action.payload
      }
    case ActionTypes.GOT_STORES_DATA_ROW:
      return {
        ...state,
        storesDataRow: action.payload
      }
    case ActionTypes.GOT_STORE_CATEGORY_DATA_ROW:
      return {
        ...state,
        storecategoryDataRow: action.payload
      }
    case ActionTypes.GOT_DEEPLINK_DATA:
      return {
        ...state,
        deeplinkData: action.payload
      }
    case ActionTypes.GOT_DEEPLINK_DATA_ROW:
      return {
        ...state,
        deeplinkDataRow: action.payload
      }
    case ActionTypes.GOT_STORE_SCHEMA:
      return {
        ...state,
        store_schema_data: action.payload
      }
    case ActionTypes.GOT_STORE_SCHEMA_ROW:
      return {
        ...state,
        store_schema_data_row: action.payload
      }
    case ActionTypes.GOT_ALL_TAGS:
      return {
        ...state,
        allTags: action.payload
      }

    case ActionTypes.GOT_EXTSTOREDOMAIN_DATA:
      return {
        ...state,
        extStoreDomainData: action.payload
      }

    case ActionTypes.GOT_STORE_FEATURES:
      return {
        ...state,
        featuredStoresData: action.payload
      }

    case ActionTypes.GOT_STORE_FEATURES_ROW:
      return {
        ...state,
        featuredStoresDataROW: action.payload
      }



    default:
      return state;
  }
};
