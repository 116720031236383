import axios from "axios";
import AppConstants from "../../constants/appConstants";

const API = axios.create({
    baseURL: `${AppConstants.baseURL}`,
});


export const addRedemption = (data) => API.post("/redemption/addredeem", data);
export const showRedemption = () => API.get("/redemption/showredeem" )
export const getAllowedActivity = () => API.get("/redemption/getallowedactivity" )

