import React, { Component, useState } from "react";
import styled from "styled-components";
import { Typography, Input, AutoComplete, Button, message } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signIn } from "../../redux/slices/authSlice";
import { useAuth } from "../../context/authContext.js";

const { Title, Text } = Typography;

const Wrapper = styled.div`
  width: 500px;
  height: 560px;
  padding: 10px;
  display: flex;
  align-items: center;
  background: darkslategrey;
  flex-direction: column;
`;

const Data = styled.div`
  margin-top: 10px;
  width: 350px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  > p {
    width: 150px;
    font-size: 16px;
    font-weight: bolder;
    color: #cc6600;
    margin-bottom: 0px;
  }
  > input {
    width: 350px;
  }
`;

const LoginButton = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #99ff99;
  border: none;
  margin-right: 15px;
  cursor: pointer;
  > p {
    font-size: 17px;
    color: #000;
    font-weight: bolder;
    margin-bottom: 0px;
  }
`;
const SignupButton = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #bbb;
  border: none;
  margin-left: 15px;
  cursor: pointer;
  margin-left: 0px;
  > p {
    font-size: 17px;
    color: #000;
    font-weight: bolder;
    margin-bottom: 0px;
  }
`;

const ButtonContainer = styled.div`
  display: block;
  flex-direction: row;
  justify-content: space-between;
  > div {
    margin-bottom: 10%;
  }
`;

const login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setLoginData({ ...loginData, [name]: value });
  };
  const { login } = useAuth();

  const loginHandler = async () => {
    if (loginData.email && loginData.password) {
      await login(loginData);
      // dispatch(signIn(loginData))
      //   .then(() => {
      //     message.success("Login Successfully");
      //     setTimeout(() => {
      //       window.location.reload();
      //       // navigate('/');
      //     }, 100);
      //   })
      //   .catch((error) => {
      //     message.error({ msg: error });
      //   });
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    }
  };
  return (
    <Wrapper>
      {/* <Logo>
          <img src={Images.logo} />
        </Logo> */}
      <h3 className="ok3">Login</h3>
      <form>
        <Data>
          {/* <p>Email * </p> */}
          <Input
            size="large"
            type="email"
            name="email"
            placeholder="Email"
            value={loginData.email}
            onChange={handleChange}
          />
        </Data>
        <Data>
          {/* <p>Password *</p> */}
          <Input.Password
            size="large"
            type="password"
            name="password"
            placeholder="Password"
            value={loginData.password}
            onChange={handleChange}
          />
        </Data>
        <ButtonContainer>
          <LoginButton onClick={loginHandler}>
            <p>Login</p>
          </LoginButton>
          <NavLink to="/signup">
            <SignupButton className="sign-up-m">
              <p>Sign Up</p>
            </SignupButton>
          </NavLink>
        </ButtonContainer>
      </form>
    </Wrapper>
  );
};

export default login;
