import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    CashbackClaimsStatusMaster,
    getInfoByClickId,
    showCashbackClaims,
    updateCashbackClaimStatus,
} from "../../../redux/slices/crmSlice";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import {
    Typography,
    Autocomplete,
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Divider,
    Table,
    TableRow,
    TableBody,
    TableCell,
    tableCellClasses,
    Tooltip,
} from "@mui/material";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
var moment = require("moment");
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { message } from "antd";
import { Modal } from "antd";
import AffClicksTable from "./affClicksTable";
import ShowPartnerRespClicks from "./partnerRepClickTable";
import AllAffClicksByDate from "./allAffClicksByDateTable";


const PostCashbackClaimsData = () => {
    const dispatch = useDispatch();
    const {
        cashbackClaimsData,
        infoByClickId,
        partnerRespByClickId,
        getAllDateAffClicksId,
        matchPointPostCashbackClaimsData,
    } = useSelector((s) => s.crm.data);


    // console.log(matchPointPostCashbackClaimsData, "dataaa")
    const [open, setOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [visible, setVisible] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const handleClose = () => {
        setDialogOpen(false);
    };

    const [editSelectedUserData, setEditSelectedUserData] = useState({
        id: "",
        status: "",
        remarks: "",
    })
    const [editUserData, setEditUserData] = useState({
        id: "",
        status: "",
        remarks: "",
    });
    const [claimData, setClaimData] = useState({
        click_id: "",
        time: "",
        // pageSize: props.matchPointPostCashbackClaimsData && props.matchPointPostCashbackClaimsData ? props.matchPointPostCashbackClaimsData.length : 0,
        // page: 0
    });
    function editUser(data) {
        setEditUserData({
            id: data.id,
            status: data.status,
            remarks: data.remarks != null ? data.remarks : "",
        });
        setDialogOpen(true);
    }

    // handle user data for edit
    const handleEditSelectedUserData = (key) => (e) => {
        setEditSelectedUserData({ ...editSelectedUserData, [key]: e.target.value });
    };
    const handleEditUserData = (key) => (e) => {
        setEditUserData({ ...editUserData, [key]: e.target.value });
    };



    const handleModal = (e) => {
        const clickId = e.value;
        const row = matchPointPostCashbackClaimsData.find((claim) => claim.click_id === clickId);
        if (row) {
            setClaimData({
                ...claimData,
                click_id: row.click_id,
                time: row.time,
            });
            let data = {
                click_id: row.click_id,
                uid: row.uid,
                time: moment(row.time).format("YYYY-MM-DD"),
            };
            dispatch(getInfoByClickId(data));
        }
        setVisible(true);
    };

    const getRoleIdByRole = (role) => {
        const user = matchPointPostCashbackClaimsData.find((user) => user.user_type === role);
        return user ? user.id : null;
    };
    const statusOptions = [
        { label: 'Raised', value: 1 },
        { label: 'Resolved', value: 2 },
        { label: 'Rejected', value: 3 },
    ];

    const createFormData = (ids, userData) => {
        return {
            id: [ids],
            status: userData.status,
            remarks: userData.remarks
        };
    };

    const handleSubmitSelectedUsers = async () => {
        editSelectedUserData.role_id = getRoleIdByRole(editSelectedUserData.role);
        const formData = createFormData(selectedRows, editSelectedUserData);
        console.log(formData, "submitmultiple");
        dispatch(updateCashbackClaimStatus(formData));
        dispatch(showCashbackClaims());
        handleCloseEdit();
    };

    const handleSubmit = async () => {
        editUserData.role_id = getRoleIdByRole(editUserData.role);
        const formData = createFormData(editUserData.id, editUserData);
        console.log(formData, "editpost");
        dispatch(updateCashbackClaimStatus(formData));
        dispatch(showCashbackClaims());
        handleClose();
        console.log(formData,"dataaa")
    };


    useEffect(() => {
        dispatch(showCashbackClaims());
        dispatch(CashbackClaimsStatusMaster());
    }, []);

    const tableColumns = [
        {
            headerName: "ID",
            field: "id",
            type: "number",
            width: 74,
            disableColumnMenu: true,
        },
        { headerName: "uid", field: "uid", disableColumnMenu: true },
        { headerName: "store_id", field: "store_id", disableColumnMenu: true },
        {
            headerName: "click Id",
            field: "click_id",
            disableColumnMenu: true,
            renderCell: (params) => (
                <>
                    <p
                        variant="text"
                        style={{ color: "blue" }}
                        onClick={() => handleModal(params)}
                    >
                        {params.value}
                    </p>
                </>
            ),
        },
        { headerName: "order_id", field: "order_id", disableColumnMenu: true },
        { headerName: "amount", field: "amount", disableColumnMenu: true },
        {
            headerName: "description",
            field: "description",
            disableColumnMenu: true,
        },
        {
            headerName: "image",
            width: 200,
            field: "image",
            disableColumnMenu: true,
        },
        {
            headerName: "status",
            field: "status",
            valueFormatter: (params) => {
                if (params.value === 1) {
                    return "raised";
                } else if (params.value === 2) {
                    return "resolved";
                } else {
                    return "rejected";
                }
            },
        },
        { headerName: "remarks", field: "remarks" },
        { headerName: "time", field: "time" },
        { headerName: "resolve_time", field: "resolve_time" },
        {
            field: "edit",
            headerName: "Edit",
            type: "actions",
            minWidth: 80,
            sortable: false,
            disableColumnMenu: true,
            getActions: (params) => {
                // Check if the current row's ID is in the selected rows
                const isRowSelected = selectedRows.includes(params.id);
                if (!isRowSelected) {
                    return [
                        <Tooltip title="Edit">
                            <GridActionsCellItem
                                icon={<EditRoundedIcon />}
                                label="Edit"
                                onClick={() => editUser(params.row)}
                            />
                        </Tooltip>
                    ];
                }
                // Optionally return a disabled button or nothing
                return [
                    <Tooltip title="Edit">
                        <GridActionsCellItem
                            icon={<EditRoundedIcon />}
                            label="Edit"
                            onClick={() => { }}
                            disabled
                        />
                    </Tooltip>
                ];
            },
        },
    ];

    const handleOpen = () => {
        setOpen(true);
    };

    const handleCloseEdit = () => {
        setOpen(false);
    };


    const handleSelectionChange = (selectionModel) => {
        // const selectedData = matchPointPostCashbackClaimsData.filter((row) =>
        //     selectionModel.includes(row.id)
        // );
        setSelectedRows(selectionModel);
    };

    // function generateRandom() {
    //     var length = 8,
    //         charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    //         retVal = "";
    //     for (var i = 0, n = charset.length; i < length; ++i) {
    //         retVal += charset.charAt(Math.floor(Math.random() * n));
    //     }
    //     return retVal;
    // }


    return (
        <Fragment>
            <div style={{ textAlign: 'center', marginTop: '40px' }}>
                <h2>Match Point Post<br />Cashback Claims Data</h2>
            </div>
            <Button variant="contained" disableElevation color="primary" onClick={handleOpen} disabled={selectedRows.length === 0} style={{padding:"10px" , margin:"15px"}}>
                Edit
            </Button>

            <Dialog open={open} onClose={handleCloseEdit} aria-labelledby="form-dialog-title">
                <DialogTitle>
                    <b>Edit Selected users</b>
                </DialogTitle>
                <Divider sx={{ borderColor: "black" }} />
                <DialogContent>
                    <Table
                        size="small"
                        sx={{
                            width: "100%",
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: "none",
                            },
                        }}
                    >
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                                        <b>Remarks</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="text"
                                        fullWidth
                                        value={editSelectedUserData?.remarks}
                                        size="small"
                                        onChange={handleEditSelectedUserData("remarks")}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                                        <b>Status</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Autocomplete
                                        disableClearable
                                        size="small"
                                        id="user-combo-box"
                                        value={
                                            statusOptions.find(option => option.value === editSelectedUserData?.status) || null
                                        }
                                        options={statusOptions}
                                        sx={{ width: 200 }}
                                        renderInput={(params) => <TextField {...params} />}
                                        onChange={(e, val) => {
                                            setEditSelectedUserData({ ...editSelectedUserData, status: val ? val.value : null })
                                          }
                                          }
                                        
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button color="error" size="large" onClick={handleCloseEdit}>
                        <b>Cancel</b>
                    </Button>
                    <Button variant="contained" size="large" onClick={handleSubmitSelectedUsers}>
                        <b>Save</b>
                    </Button>
                </DialogActions>
            </Dialog>

            <DataGrid
                //   getRowId={(row) =>  generateRandom()}


                autoHeight
                checkboxSelection
                rows={matchPointPostCashbackClaimsData}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                columns={tableColumns}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                pageSizeOptions={[5, 10, 25, matchPointPostCashbackClaimsData?.length]}
                onSelectionModelChange={handleSelectionChange}

            />

            {visible && (
                <Modal
                    title={`Click_ID----> ${claimData.click_id}`}
                    centered
                    open={visible}
                    onOk={() => setVisible(false)}
                    onCancel={() => setVisible(false)}
                    width={1000}
                >
                    {infoByClickId && infoByClickId.length > 0 && (
                        <AffClicksTable infoByClickId={infoByClickId} />
                    )}
                    {partnerRespByClickId && partnerRespByClickId.length > 0 && (
                        <ShowPartnerRespClicks
                            partnerRespByClickId={partnerRespByClickId}
                        />
                    )}
                    {getAllDateAffClicksId && getAllDateAffClicksId.length > 0 && (
                        <AllAffClicksByDate getAllDateAffClicksId={getAllDateAffClicksId} />
                    )}
                </Modal>
            )}
            <Dialog open={dialogOpen} onClose={handleClose} fullWidth>
                <DialogTitle>
                    <b>Edit User</b>
                </DialogTitle>
                <Divider sx={{ borderColor: "black" }} />
                <DialogContent>
                    <Table
                        size="small"
                        sx={{
                            width: "100%",
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: "none",
                            },
                        }}
                    >
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                                        <b>Remarks</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="text"
                                        fullWidth
                                        value={editUserData?.remarks}
                                        size="small"
                                        onChange={handleEditUserData("remarks")}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                                        <b>Status</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Autocomplete
                                        disableClearable
                                        size="small"
                                        id="user-combo-box"
                                        value={
                                            statusOptions.find(option => option.value === editUserData?.status) || null
                                        }
                                        options={statusOptions}
                                        getOptionLabel={(option) => option.label}
                                        sx={{ width: 200 }}
                                        renderInput={(params) => <TextField {...params} />}
                                        onChange={(e, val) => {
                                            setEditUserData({ ...editUserData, status: val ? val.value : null })
                                        }
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button color="error" size="large" onClick={handleClose}>
                        <b>Cancel</b>
                    </Button>
                    <Button variant="contained" size="large" onClick={handleSubmit}>
                        <b>Save</b>
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default PostCashbackClaimsData;