import React, { useState, useEffect } from "react";
import { Button, Select, Table, message } from "antd";
const csvtojson = require("csvtojson");
import * as CSV from "csv-string";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import {
  getActivities,
  getResponseActivePartners,
  getResponseMasterDataCSV,
} from "../../../redux/slices/affiliateSlice";
import { NavLink, useNavigate } from "react-router-dom";
import {
  downloadReportsWithUniqueIdHaveIssue,
  finalApprovalSubmitReport,
  getUploadCsvApprovalReport,
  uploadResponseCsv,
} from "../../../redux/slices/pointerPosterSlice";
import { set } from "lodash";

const UploadCSV = (props) => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { responseActivePartner, responseCSV } = useSelector((s) => ({
    ...s.affiliate,
  }));
  const types = ["approved", "pending", "rejected"];

  const [activities, setAllActivities] = useState("purchase");
  const [trans_type, setTrans_type] = useState("pending");
  const [recordNotFound, setRecordNotFound] = useState(null);
  const [pointsDiffData, setPointsDiffData] = useState(null);
  const [csvId, setCsvId] = useState(null);
  const [partner, setPartner] = useState({
    id: "",
    name: "",
    resp_id: "",
  });
  // const [ShowcsvData, setShowCsvData] = useState("");
  const [stats, setStats] = useState({
    count: 0,
    Payout_sum: 0,
    Points_posted: 0,
  });
  const [csvData, setcsvdata] = useState({});

  const resetAll = (level) => {
    if (level < 2) setTrans_type("pending");
    if (level < 3) {
      setPartner({ id: "", name: "", resp_id: "" });
      dispatch(getResponseMasterDataCSV(""));
    }
    // setShowCsvData("");
    setStats({ count: 0, Payout_sum: 0, Points_posted: 0 });
    setcsvdata({});
  };

  useEffect(() => {
    dispatch(getActivities());
    dispatch(getResponseActivePartners());
  }, []);

  const onChange = (e) => {
    let reader = new FileReader();
    resetAll(4);
    // processcsv(e.target.files[0])
    // console.log(reader, "reader-------------------");
    reader.onload = () => {
      // Use reader.result
      // console.log(reader.result, "reader.result");
      replaceheader(reader.result);
      // console.log(reader.result, "reader.result");
    };
    reader.readAsText(e.target.files[0]);
    console.log(e.target.files[0], "e.target.files[0]");
    // console.log(e.target.files[0], "e.target.files[0]");
    // setCsvFile(e.target.files[0])
    // let reader = new FileReader();
    message.success("Upload Successfuly!");
  };

  const replaceheader = (data) => {
    // with String
    // console.log(data, "data--------");
    const header = CSV.parse(data)[0];
    // console.log(header, "header");
    // console.log(pend_canc_csv, "pend_canc_csv");
    // console.log(responseCSV, "responseCSV");
    let csv_head = pend_canc_csv[0];
    if (trans_type == "pending" && !responseCSV) return false;
    if (trans_type == "pending") csv_head = responseCSV[0];
    let notpresent = [];
    let validKeys = [];
    for (let [key, value] of Object.entries(csv_head)) {
      if (
        !value ||
        value == "null" ||
        key == "id" ||
        key == "partner_id" ||
        key == "active" ||
        key == "partner"
      )
        continue;
      value = value.trim();
      // console.log(header, "header", value, "value");
      if (header.includes(value)) {
        validKeys.push(key);
        let index = header.indexOf(value);
        header[index] = key;
      } else notpresent.push(key);
    }
    if (notpresent.length)
      return message.error(
        "following fields are not present in csv :" + notpresent.toString(),
        10
      );
    // console.log(data, "data----------");
    processcsv(data, header, validKeys);
  };

  const processcsv = (data, headers, validKeys) => {
    let final_data = [];
    const conversions = csvtojson({
      noheader: false,
      headers: headers,
    })
      .fromString(data)
      .then((jsonObj) => {
        let total_amount_in_file = 0;
        let total_points_posted = 0;
        jsonObj.forEach((element) => {
          Object.keys(element).forEach(
            (key) => validKeys.includes(key) || delete element[key]
          );
          if (trans_type == "pending") {
            element.partner = partner.id;
            element.type = "csv";
            element.status = trans_type;
            element.currency = "INR";
            if (element.action_date)
              element.action_date = moment(element.action_date).format(
                "YYYY-MM-DD HH:mm:ss"
              );
            if (element.conversion_date)
              element.conversion_date = moment(element.conversion_date).format(
                "YYYY-MM-DD HH:mm:ss"
              );
          }
          element.payout = parseFloat(element.payout.replace(/,/g, ""));
          total_amount_in_file += element.payout;
          element.payout = Math.round(element.payout);
          total_points_posted += element.payout;
          final_data.push(element);
        });
        setStats({
          count: final_data.length,
          Payout_sum: total_amount_in_file,
          Points_posted: total_points_posted,
        });
        // setShowCsvData(final_data);
        // console.log(final_data, "final_data");

        setcsvdata(final_data);
      });
    // header = conversions[0];

    // with Stream
    // const stream = CSV.createStream();
    // stream.on('data', rows => {
    //   process.stdout.write(CSV.stringify(rows, ','));
    // });
    // process.stdin.pipe(stream);
    // let inputStream = Fs.createReadStream(file, 'utf8');
    // inputStream
    //   .pipe(new CsvReadableStream({ parseNumbers: true, parseBooleans: true, trim: true }))
    //   .on('data', function (row) {
    //       console.log('A row arrived: ', row);
    //   })
    //   .on('end', function () {
    //       console.log('No more rows!');
    //   });
    // setShowCsvData(data);
  };

  const handleSubmit = () => {
    try {
      document.getElementById("error").innerHTML = "";
      document.getElementById("success").innerHTML = "";
      let formData = new FormData();
      formData.append("data", JSON.stringify(csvData));
      formData.append("type", activities);
      formData.append("trans_type", trans_type);
      formData.append("partner", JSON.stringify(partner));
      formData.append("stats", JSON.stringify(stats));
      if (trans_type != "approved") dispatch(uploadResponseCsv(formData));

      // console.log(JSON.stringify(csvData), "JSON.stringify(csvData)");
      // console.log(formData, "formData--------------");
      if (trans_type == "approved") {
        dispatch(getUploadCsvApprovalReport(formData))
          .then((data) => {
            console.log(data, "data=============");
            if (data?.error?.message)
              return message.error(data?.error?.message);

            let csv_id = data?.payload?.csv_id;
            let ponits_diff_data = data?.payload?.ponits_diff_data;
            let record_not_found = data?.payload?.record_not_found;
            // console.log(csv_id, ponits_diff_data, record_not_found, "data");
            if (record_not_found)
              setRecordNotFound(record_not_found.toString());
            if (ponits_diff_data.length) setPointsDiffData(ponits_diff_data);
            if (csv_id) setCsvId(csv_id);

            // navigate("/points/uploadcsv/approvalreport", {
            //   state: { final_camp_grp_data, final_approval_row_data },
            // });
            cb();
          })
          .catch((error) => {
            console.log(error, "error");
          });
      }
    } catch (error) {
      console.log(error, "error");
    }

    // Display the key/value pairs
    // for (var pair of formData.entries()) {
    //   console.log(pair[0]+ ', ' + pair[1]);
    // }
    // let data={};
    // data.activity_id=activities.id;
    // data.partner_id=partner.id;
    // data.trans_type=trans_type;
  };

  const handleFinalSubmit = (csv_id) => {
    dispatch(finalApprovalSubmitReport({ csv_id }));
  };

  const handleDownloadedFileNotFound = (csv_id) => {
    let data = {};
    data.not_found = true;
    data.csv_id = csv_id || csvId;
    console.log("handleDownloaderror", data);
    dispatch(downloadReportsWithUniqueIdHaveIssue(data))
      .then((data) => {
        console.log(data.payload, "data.payload");
        if (data?.error?.message) return message.error(data?.error?.message);
        let csv_data = data?.payload;
        console.log(csv_data, "csv_data");
        let csv = CSV.stringify(csv_data, ",");
        let csvContent = "data:text/csv;charset=utf-8," + csv;
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${new Date()}notFound.csv`);
        document.body.appendChild(link); // Required for FF
        link.click();
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const handleDownloadedFilePointStatusDiff = (csv_id) => {
    let data = {
      diff_found: true,
      csv_id: csv_id || csvId, // Assuming csvId is defined elsewhere in your component
    };

    console.log("handleDownloaderror 2", data);

    dispatch(downloadReportsWithUniqueIdHaveIssue(data))
      .then((response) => {
        if (response.error && response.error.message) {
          message.error(response.error.message);
          return; // Exit function after handling error
        }

        let csvData = response.payload; // Assuming response structure contains payload directly
        console.log(csvData, "csvData");
        let csvContent = "data:text/csv;charset=utf-8,";
        let headerString =
          "CSV ID,ID,Old Payout,Old Status,Payout,Res ID,Status,Store Task ID,Upload Status\n";
        let rowString = csvData
          .map(
            (item) =>
              `${item.csv_id},${item.id},${item.old_payout},${item.old_status},${item.payout},${item.res_id},${item.status},${item.store_task_id},${item.upload_status}\n`
          )
          .join("");

        csvContent += headerString + rowString;
        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");

        // Create a date string that's filename friendly
        const dateString = new Date().toISOString().replace(/:/g, "-");

        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${dateString}_pointsDiff.csv`);
        document.body.appendChild(link);
        link.click();

        // Clean up by removing the element from the body
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.log(error, "error");
        message.error("An error occurred while downloading the file.");
      });
  };

  const cb = (data) => {
    if (data?.error) document.getElementById("error").innerHTML = data?.error;
    if (data?.success)
      document.getElementById("success").innerHTML = data?.success;

    // console.log(data, "data");
  };
  const pend_canc_csv = [{ res_id: "id", payout: "payout" }];

  // const handleAutoComplete = e => {
  //   setPartner({ ...partner, id: e })
  //   if (responseActivePartner.length > 0) {
  //     for (var i = 0; i < responseActivePartner.length; i++) {
  //       if (e == responseActivePartner[i].id) {
  //         setPartner({ ...partner, id: responseActivePartner[i].id, resp_id: responseActivePartner[i].resp_id })
  //         let data = {};
  //         data.resp_id = responseActivePartner[i].resp_id;
  //         props.getResponseMasterDataCSV(data);
  //       }
  //     }
  //   }
  // }
  const handlePartners = (e) => {
    // e.preventDefault();
    resetAll(2);
    let value = e.split("-");
    setPartner({
      id: value[0],
      name: value[1],
      resp_id: value[0],
    });
    let data = {};
    data.resp_id = value[0];
    dispatch(getResponseMasterDataCSV(data));
  };

  const handleAutoComplete2 = (e) => {
    resetAll(1);
    setTrans_type(e);
  };
  // const handleTypeAutoComplete = e => {
  //   let value = e.split("-");
  //   let id = value[2]
  //   setAllActivities({ ...activities, id: id })
  //   if (props.allActivities.length > 0) {
  //     for (var i = 0; i < props.allActivities.length; i++) {
  //       if (id == props.allActivities[i].id) {
  //         setAllActivities({ ...activities, id: props.allActivities[i].id })
  //       }
  //     }
  //   }
  // }
  const handleTypeSelect = (e) => {
    resetAll(1);
    setAllActivities(e);
  };
  const columns_1 = [
    {
      name: "res_id",
      // dataIndex: "unique_id",
      label: "Unique Id",
      // title: "Unique Id"
    },
    {
      name: "payout",
      // dataIndex: "payout",
      label: "Payout",
      // title: "Payout",
    },
    // { name: "store_id", label: "Store Id" },
  ];
  const columns = [
    // {
    //   name: "id",
    //   label: "Response ID",
    //   options: {
    //     filter: false,
    //     sort: false,
    //   }
    // },
    // {
    //   name: "partner_id",
    //   label: "Partner ID",
    //   options: {
    //     filter: false,
    //     sort: false,
    //   }
    // },
    // {
    //   name: "partner",
    //   label: "partner Name",
    //   options: {
    //     filter: true,
    //     sort: true,
    //   }
    // },
    {
      name: "unique_id",
      label: "Unique Id",
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    //   name: "store_id",
    //   label: "Store Id",
    //   options: {
    //     filter: true,
    //     sort: true,
    //   }
    // },
    {
      name: "camp_name",
      label: "Camp name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "partner_camp_id",
      label: "Partner Camp Id",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "action_date",
      label: "Action date",
      options: {
        filter: false,
        sort: false,
      },
    },
    // {
    //   name: "attribution_date",
    //   label: "Attribution Date",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   }
    // },
    {
      name: "sale_amount",
      label: "Sale Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "click_id",
      label: "Click Id",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "order_id",
      label: "Order Id",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "sub_id2",
      label: "Sub Id 2",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "sub_id3",
      label: "Sub Id 3",
      options: {
        filter: true,
        sort: false,
      },
    },
    // {
    //   name: "status",
    //   label: "Status",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   }
    // },
    {
      name: "payout",
      label: "Payout",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "conversion_date",
      label: "Conversion Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "currency",
      label: "Currency",
      options: {
        filter: true,
        sort: false,
      },
    },
    // {
    //   name: "point_posted",
    //   label: "Point Posted",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   }
    // }, {
    //   name: "type",
    //   label: "Point Posted",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   }
    // }, {
    //   name: "active",
    //   label: "Point Posted",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   }
    // }
  ];

  const antcolumns = (columns) => {
    return columns.map((head) => {
      head.title = head.label;
      head.dataIndex = head.name;
      return head;
    });
  };
  const options = {
    filter: false,
    search: false,
    pagination: false,
    print: false,
    pagination: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    sort: false,
    viewColumns: false,
  };
  console.log(pointsDiffData, "pointsDiffData");
  return (
    <>
      <div className="col-6 col-lg-6">
        <div className="mb-3 my-3">
          <label className="form-label">Type</label>
          <Select
            className="auto-complete"
            disabled={false}
            onChange={handleTypeSelect}
            defaultValue={activities}
          >
            <Option value="purchase" selected>
              Purchase
            </Option>
            <Option value="bonus">Bonus</Option>
          </Select>
        </div>
      </div>
      <div className="col-6 col-lg-6">
        <div className="mb-3">
          <label className="form-label">Transactions Type</label>
          <Select
            className="auto-complete"
            disabled={false}
            onChange={handleAutoComplete2}
            // defaultValue="pending"
            value={trans_type}
          >
            {types &&
              types.map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
          </Select>
        </div>
      </div>
      {trans_type == "pending" && (
        <div className="col-6 col-lg-6">
          <div className="mb-3">
            <label className="form-label">Partner</label>
            <Select
              className="auto-complete"
              // onChange={handleAutoComplete}
              onSelect={handlePartners}
              value={partner.name}
              showSearch
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
            >
              {responseActivePartner &&
                responseActivePartner.map((item, index) => (
                  <Option key={item.id} value={`${item.id}-${item.partner}`}>
                    {" "}
                    {item.partner}
                  </Option>
                ))}
            </Select>
          </div>
        </div>
      )}
      {trans_type != "pending" && (
        <div style={{ padding: "0 15px" }}>
          {/* <MUIDataTable
            className="my-3"        
            title={"CSV format for "+trans_type+" transactions"}
            data={pend_canc_csv}
            // data={transpose(responseCSV, keep.names = "col", make.names = "col0")}
            columns={columns_1}
            options={options}
          /> */}
          <Table
            className="tablefit"
            title={() => "CSV format for " + trans_type + " transactions"}
            dataSource={pend_canc_csv}
            columns={antcolumns(columns_1)}
          />
          <form>
            <div className="form-group">
              <label for="exampleFormControlFile1">Upload CSV</label>
              <input
                type="file"
                accept=".csv"
                className="form-control-file"
                id="exampleFormControlFile1"
                onChange={onChange}
              />
            </div>
          </form>
        </div>
      )}
      {partner.id && responseCSV && (
        <div style={{ padding: "0 15px" }}>
          <MUIDataTable
            className="my-3"
            // title={"CSV format for " + responseCSV[0].partner}
            data={responseCSV && responseCSV}
            // data={transpose(responseCSV, keep.names = "col", make.names = "col0")}
            columns={columns}
            options={options}
          />
          {/* <Table
        className="tablefit"        
        title={()=>"CSV format for "+responseCSV[0].partner}
        dataSource={responseCSV}
        // data={transpose(responseCSV, keep.names = "col", make.names = "col0")}
        columns={antcolumns(columns)}
        // options={options}
      /> */}
          <form className=" ">
            <div className="form-group">
              <label for="exampleFormControlFile1">Upload CSV</label>
              <input
                type="file"
                accept=".csv"
                className="form-control-file"
                id="exampleFormControlFile1"
                onChange={onChange}
              />
            </div>
          </form>
        </div>
      )}
      {csvData && csvData.length && (
        <>
          <div>
            {/* <CsvToHtmlTable
          data={csvData}
          csvDelimiter=","
        /> */}
            <table style={{ width: "300px" }}>
              <thead>
                <tr>
                  <th style={{ padding: "10px 20px" }}>Total</th>
                  <th style={{ padding: "10px 20px" }}>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: "10px 20px" }}>#Rows</td>
                  <td style={{ padding: "10px 20px", fontWeight: "bold" }}>
                    {stats.count}
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: "10px 20px" }}>Total Payout</td>
                  <td style={{ padding: "10px 20px", fontWeight: "bold" }}>
                    {stats.Payout_sum}
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: "10px 20px" }}>Total Points</td>
                  <td style={{ padding: "10px 20px", fontWeight: "bold" }}>
                    {stats.Points_posted}
                  </td>
                </tr>
              </tbody>
            </table>
            {/* <MUIDataTable
          className="my-3"
          title={"Top 5 rows"}
          data={csvData.slice(0,5)}
          columns={trans_type=="pending"?columns:columns_1}
          options={options}
        /> */}
            <Table
              className="tablefit"
              title={() => "Top 5 rows"}
              dataSource={csvData.slice(0, 5)}
              columns={
                trans_type == "pending"
                  ? antcolumns(columns)
                  : antcolumns(columns_1)
              }
              // scroll={{ y: 300 }}
              // options={options}
            />
          </div>
          <div className="col-12 col-lg-12 my-3 text-center">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </>
      )}

      {recordNotFound && csvId && (
        <div>
          {" "}
          <p style={{ border: "2px solid yellow", padding: "10px" }}>
            {recordNotFound}{" "}
          </p>
          <button
            className="btn btn-primary m-2"
            onClick={() => handleDownloadedFileNotFound(csvId)}
          >
            Download the file
          </button>
        </div>
      )}
      {pointsDiffData && csvId && pointsDiffData.length && (
        <>
          <div className="my-3">
            <table>
              <thead>
                <tr>
                  <th>Campaign Name</th>
                  <th>Points Decreased</th>
                  <th>Points Increased</th>
                  <th>Status Changed</th>
                </tr>
              </thead>
              <tbody>
                {pointsDiffData?.map((item, index) => (
                  <tr key={index}>
                    <td>{item?.camp_name}</td>
                    <td>{item?.points_decreased}</td>
                    <td>{item?.points_increased}</td>
                    <td>{item?.status_changed}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button
            className="btn btn-primary mt-2"
            onClick={() => handleDownloadedFilePointStatusDiff(csvId)}
          >
            Download Points/Status Data
          </button>
        </>
      )}
      {(recordNotFound && csvId) ||
      (csvId && pointsDiffData && pointsDiffData.length) ? (
        <button
          className="btn btn-primary mt-2"
          onClick={() => handleFinalSubmit(csvId)}
        >
          Submit Anyway
        </button>
      ) : (
        <></>
      )}
      <div id="error" style={{ color: "red" }}></div>
      <div id="success" style={{ color: "green" }}></div>
    </>
  );
};

export default UploadCSV;
