import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Typography } from "antd";
const { Title } = Typography;

import AddTask from "./AddTask";
import ShowTask from "./ShowTask";
import AssignedTaskByAdmin from "./assignedTaskByAdmin";
import RecurrenceTaskUser from "./recurrenceTaskUser";
import { useDispatch, useSelector } from "react-redux";
import { getAllPanelUsers, gotUpdateTaskData } from "../../redux/slices/userRoleManagementSlice";
const Wrapper = styled.div`
  width: 100%;

  > div {
    > div {
      > button {
        background: linear-gradient(
            259deg,
            rgb(236, 94, 58) 0%,
            rgb(249, 186, 99) 100%
          )
          0% 0% no-repeat padding-box padding-box transparent;
        padding: 7px 20px;
        border-radius: 20px;
        float: right;
      }
    }
  }
`;
const BodyContainer = styled.div`
  overflow: auto;
`;

const TaskReminder = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((s) => s.auth.getProfile);

  const {
    gotUpdateTaskData,
  } = props;

  const [screenComp, setScreenComp] = useState("showTodoReminder");

  const changeTab = (val) => {
    setScreenComp(val);
  };
  useEffect(() => {
    // getAllPanelUsers();
    dispatch(getAllPanelUsers());
  }, []);

  return (
    <Wrapper>
      {/* <Title className="my-3" level={3}>Create/show Todo Task Reminder </Title> */}
      <div className="row">
        <div className="col-12 col-lg-12">
          {screenComp == "showTodoReminder" && user.role == "admin" && (
            <>
              <button onClick={() => changeTab("assignedTaskByAdmin")}>
                Show Todo Task Reminder By admin
              </button>
              <button onClick={() => changeTab("addTodoReminder")}>
                Create Todo Task
              </button>
              <button onClick={() => changeTab("recurrenceTaskuser")}>
                Show Recurrence Task user
              </button>
            </>
          )}

          {screenComp == "addTodoReminder" && (
            <>
              <button onClick={() => changeTab("showTodoReminder")}>
                Show Todo Task Reminder
              </button>
              {user.role == "admin" && (
                <>
                  <button onClick={() => changeTab("assignedTaskByAdmin")}>
                    Show Todo Task Reminder By admin
                  </button>
                  <button onClick={() => changeTab("recurrenceTaskuser")}>
                    Show Recurrence Task user
                  </button>
                </>
              )}
            </>
          )}
          {screenComp == "assignedTaskByAdmin" && user.role == "admin" && (
            <>
              <button onClick={() => changeTab("addTodoReminder")}>
                Add Todo Task{" "}
              </button>
              <button onClick={() => changeTab("showTodoReminder")}>
                Show Todo Task Reminder
              </button>
              <button onClick={() => changeTab("recurrenceTaskuser")}>
                Show Recurrence Task user
              </button>
            </>
          )}

          {screenComp == "recurrenceTaskuser" && user.role == "admin" && (
            <>
              <button onClick={() => changeTab("addTodoReminder")}>
                Add Todo Task{" "}
              </button>
              <button onClick={() => changeTab("showTodoReminder")}>
                Show Todo Task Reminder
              </button>
              <button onClick={() => changeTab("assignedTaskByAdmin")}>
                Show Todo Task Reminder By admin
              </button>
            </>
          )}
        </div>
      </div>
      <BodyContainer>
        {screenComp == "addTodoReminder" && user.role == "admin" && (
          <AddTask />
        )}
        {screenComp == "showTodoReminder" && (
          <ShowTask />
        )}
        {screenComp == "assignedTaskByAdmin" && user.role == "admin" && (
          <AssignedTaskByAdmin changeTab={changeTab} />
        )}
        {screenComp == "recurrenceTaskuser" && (
          <RecurrenceTaskUser
            gotUpdateTaskData={gotUpdateTaskData}
            changeTab={changeTab}
          />
        )}
      </BodyContainer>
    </Wrapper>
  );
};

export default TaskReminder;
