import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api/manageBannerOfferApi";
import { message } from "antd";


export const getAllManageBannersImages = createAsyncThunk(
  "/gallery/getallimages",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.getAllManageBannersImages();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const getUploadImage = createAsyncThunk(
  "/managebanners/getuploadimage",
  async (query, { rejectWithValue }) => {
    try {
      const resp = await api.getUploadImage(query);
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const saveUploadImage = createAsyncThunk(
  "/managebanners/saveUploadImage",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.saveUploadImage(data);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Saved Successfully!');
        // return resp.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Not Saved / Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

const uploadImageSlice = createSlice({
  name: "uploadImage",
  initialState: {
    data: {
      getAllManageBannersImages: [],
      getUploadImage: [],
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllManageBannersImages.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllManageBannersImages.fulfilled, (state, action) => {
      state.data.getAllManageBannersImages = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getAllManageBannersImages.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    // ====================================================================================/

    builder.addCase(getUploadImage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUploadImage.fulfilled, (state, action) => {
      state.data.getUploadImage = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getUploadImage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // =====================================saveUploadImage===============================================/

    builder.addCase(saveUploadImage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveUploadImage.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(saveUploadImage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const {} = uploadImageSlice.actions;

export default uploadImageSlice.reducer;
