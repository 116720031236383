import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Typography, Radio } from "antd";
import FlipCard from "./FlipCard";
import Popup from "../../Repeated/Popup";
import AppConstants from "../../../constants/appConstants";
import { useDispatch, useSelector } from "react-redux";
import { getAllManageBannersImages, getUploadImage, saveUploadImage } from "../../../redux/slices/uploadImagesSlice";
import { getAllImages } from "../../../redux/slices/gallerySlice";

const { Title, Text } = Typography;

const Wrapper = styled.div`
  width: 100%;
  height: 560px;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;
const Wrapper2 = styled.div`
  width: 100%;
  height: 310px;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const Line = styled.div`
  border-left: 2px solid lightgrey;
  height: 100%;
`;

const ImageMainContainer = styled.div`
  position: absolute;
  top: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 300px;
  overflow: auto;
`;

const ShowImage = styled.div`
  margin-top: 70px;
  margin-left: -500px;
`;

const ImageContainer = styled.div`
  // width: 200px;
  display: flex;
  height: 200px;
  flex-direction: row;
  padding: 50px;
  justify-content: space-between;
`;
const Default = styled.div`
  width: 0px;
  height: 0px;
`;
const Inp = styled.div`
  width: 100px;
`;

const Container = styled.div`
  height: 560px;
  width: 50%;
  padding: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Data = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  border: 1px dotted black;
  width: 70%;
  min-height: 150px;
`;

const Data1 = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const RadioContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  padding: 5px 0;
  > div {
    color: #666;
    font-size: 14px;
    margin-right: 30px;
  }
`;

const Body = styled.div`
  display: flex;
  width: 42%;
  flex-direction: column;
  margin-top: 40px;
  > p {
    margin-bottom: 40px;
    font-size: 20px;
    font-weight: bolder;
    color: #000;
  }
`;
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubmitButton = styled.div`
  width: 140px;
  height: 40px;
  display: flex;
  background: #0000ff;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  cursor: pointer;
  > p {
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 0px;
    color: #fff;
    text-align: center;
  }
`;

const UploadImage = () => {
  const dispatch = useDispatch();
  const uploadImagesData = useSelector(state => state.uploadImage.data.getUploadImage);
  const allImages = useSelector((state) => state.gallery.data.getAllImagesData);
  console.log(allImages, "allImages");
  const [type, setType] = useState("Banners");
  const [imgForm, setImgform] = useState(false);
  const [url, setUrl] = useState("");
  // const [priority, setPriority] = useState("");
  const [imageUrl, setImageUrl] = useState();
  const [store_name, setStore_name] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [bool, setBool] = useState('');
  const [imgId, setImgId] = useState("")
  useEffect(() => {
    dispatch(getAllManageBannersImages());
    if (type == "Banners") {
      dispatch(getUploadImage("Banners"));
    }
    else {
      dispatch(getUploadImage("Offers"));
    }
  }, [type]);

  useEffect(() => {
    let limit = 40;
    dispatch(getAllImages(limit));
  }, []);

  const togglePopup = () => {
    setImgform(false);
    setIsOpen(!isOpen);
  };

  const changeRadio = (e) => {
    setType(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {};
    data.type = type;
    data.image = imgId;
    // data.alt_text = Alt;
    data.live = bool;
    data.url = url;
    // data.priority = priority;
    // data.title = title;
    // data.store_name = store_name;
    //console.table("data ", data);
    // data.append("alt_text", Alt);
    // data.append("url", url);
    // data.append("title", title);
    // data.append("store_name", store_name);
    // data.append("live", bool);
    // data.forEach((e) => //console.log(e));
    dispatch(saveUploadImage(data));
    // setAlt("");
    setUrl("");
    // setPriority("");
    // setTitle("");
    // setStore_name("");
  };

  const imageHandler = (img) => {
    setImgform(!imgForm);
    setImageUrl(img.url);
    setImgId(img.id);
  }
  useEffect(() => {
    if (type == "Banner") {
      dispatch(getUploadImage("Banners"));
    }
    else if (type == "Offers") {
      dispatch(getUploadImage("Offers"));

    }
  }, [type]);




  const handleReload = (e) => {
    e.preventDefault()
    if (type == "Banners") {
      dispatch(getUploadImage("Banners"));
    }
    else if (type == "Offers") {
      dispatch(getUploadImage("Offers"));

    }
    console.log("clickclick")
  };


  return (
    <Wrapper className="upload-image-main">
      <Container className="left-container-upload-image">
        <Data1>
          <Text>Select Type</Text>
          <RadioContainer>
            <Radio.Group onChange={changeRadio}>
              <Radio type="radio" value={"Banners"} name="type">
                Banners
              </Radio>
              <Radio type="radio" value={"Offers"} name="type">
                Offers
              </Radio>
            </Radio.Group>
          </RadioContainer>
        </Data1>

        {/* {type === "Banners" || type === "Offers" ? ( */}
        <ShowImage>
          <input type="button" value="Show images" onClick={togglePopup} />
          {isOpen && (
            <Popup
              content={
                <>
                  <b style={{padding:'10px'}}>Images of {type}</b>
                  <button onClick={handleReload}>Reload Images</button>
                  <p>
                    <Wrapper2>
                      <ImageMainContainer>
                        {/* <ImageContainer>
          {drop_type == "Select Type"
            ? allImages &&
              allImages.map((item, index) => (
                <ImageContainer>
                  <img src={require(item.url).default} alt="image" />
                </ImageContainer>
              ))
            : allImages &&
              allImages.map((item, index) =>
                item.store_name == drop_type ? (
                  <ImageContainer>
                    <img src={require(item.url).default} alt="image" />
                  </ImageContainer>
                ) : (
                  <Default />
                )
              )}
        </ImageContainer> */}

                        {/* <ImageContainer>
                            <img
                              src={
                                require("C:/Users/Asus/Pictures/Screenshots/Screenshot.png")
                                  .default
                              }
                              onClick={handleImage}
                              alt="image"
                            />
                          </ImageContainer>
                          <ImageContainer>
                            <img
                              src={
                                require("C:/Users/Asus/Pictures/Screenshots/Screenshot.png")
                                  .default
                              }
                              onClick={handleImage}
                              alt="image"
                            />
                          </ImageContainer>
                          <ImageContainer>
                            <img
                              src={
                                require("C:/Users/Asus/Pictures/Screenshots/Screenshot.png")
                                  .default
                              }
                              onClick={handleImage}
                              alt="image"
                            />
                          </ImageContainer>
                          <ImageContainer>
                            <img
                              src={
                                require("C:/Users/Asus/Pictures/Screenshots/Screenshot.png")
                                  .default
                              }
                              onClick={handleImage}
                              alt="image"
                            />
                          </ImageContainer>
                          <ImageContainer>
                            <img
                              src={
                                require("C:/Users/Asus/Pictures/Screenshots/Screenshot.png")
                                  .default
                              }
                              onClick={handleImage}
                              alt="image"
                            />
                          </ImageContainer>
                          <ImageContainer>
                            <img
                              src={
                                require("C:/Users/Asus/Pictures/Screenshots/Screenshot.png")
                                  .default
                              }
                              onClick={handleImage}
                              alt="image"
                            />
                          </ImageContainer>
                          <ImageContainer>
                            <img
                              src={
                                require("C:/Users/Asus/Pictures/Screenshots/Screenshot.png")
                                  .default
                              }
                              onClick={handleImage}
                              alt="image"
                            />
                          </ImageContainer>
                          <ImageContainer>
                            <img
                              src={
                                require("C:/Users/Asus/Pictures/Screenshots/Screenshot.png")
                                  .default
                              }
                              onClick={handleImage}
                              alt="image"
                            />
                          </ImageContainer>
                          <ImageContainer>
                            <img
                              src={
                                require("C:/Users/Asus/Pictures/Screenshots/Screenshot.png")
                                  .default
                              }
                              onClick={handleImage}
                              alt="image"
                            />
                          </ImageContainer>
                          <ImageContainer>
                            <img
                              src={
                                require("C:/Users/Asus/Pictures/Screenshots/Screenshot.png")
                                  .default
                              }
                              onClick={handleImage}
                              alt="image"
                            />
                          </ImageContainer>
                          <ImageContainer>
                            <img
                              src={
                                require("C:/Users/Asus/Pictures/Screenshots/Screenshot.png")
                                  .default
                              }
                              onClick={handleImage}
                              alt="image"
                            />
                          </ImageContainer>
                          <ImageContainer>
                            <img
                              src={
                                require("C:/Users/Asus/Pictures/Screenshots/Screenshot.png")
                                  .default
                              }
                              onClick={handleImage}
                              alt="image"
                            />
                          </ImageContainer> */}
                        {uploadImagesData &&
                          uploadImagesData.map((item, index) =>
                            // item.store_name === type ? (
                            <ImageContainer>
                              {/* <img src={item.url} alt="image" /> */}
                              <img
                                src={`${AppConstants.imageURL}${item.url}`}
                                // src={item.url}
                                onClick={() => imageHandler(item)}
                                alt="image"
                              />
                              <br />
                            </ImageContainer>
                            // ) : (
                            //   <Default />
                            // )
                          )}
                      </ImageMainContainer>
                    </Wrapper2>
                  </p>
                  {/* <button>Test button</button> */}

                  {imgForm ? (
                    <form onSubmit={handleSubmit}>
                      <Inp>
                        <label>URL :</label>
                        <input
                          type="text"
                          value={url}
                          // required
                          onChange={(e) => setUrl(e.target.value)}
                          placeholder={`Enter ${type} URL`}
                        />
                      </Inp>
                      {/* <Inp>
                          <label>Priority :</label>
                          <input
                            type="number"
                            value={priority}
                            required
                            onChange={(e) => setPriority(e.target.value)}
                            placeholder={`Enter priority in Number`}
                          />
                        </Inp> */}
                      {/* <Inp>
                          <label>Title:</label>
                          <br />
                          <input
                            name="title"
                            type="text"
                            value={title}
                            required
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </Inp> */}
                      {/* <Inp>
                          <label>Store Name:</label>
                          <br />
                          <input
                            name="Store name"
                            type="text"
                            value={store_name}
                            required
                            onChange={(e) => setStore_name(e.target.value)}
                          />
                        </Inp> */}
                      <Text>Live </Text>
                      <RadioContainer>
                        <Radio.Group
                          onChange={(e) => setBool(e.target.value)}
                        >
                          <Radio type="radio" value={1} name="type">
                            Yes
                          </Radio>
                          <Radio type="radio" value={0} name="type">
                            No
                          </Radio>
                        </Radio.Group>
                      </RadioContainer>

                      <input type="submit" value="Submit" />
                    </form>
                  ) : (
                    <Default />
                  )}
                </>
              }
              handleClose={togglePopup}
            />
          )}
        </ShowImage>
        {/* ) : (
          <Default />
        )} */}
      </Container>
      <Line className="line-upload-image" />
      <Container className="right-container-upload-image">
        {/*<Data className="right-cont-sub">
          {allImages &&
            allImages.map((item, index) => <FlipCard all={item} />)}
          ;
        </Data> */}
      </Container>
    </Wrapper>
  );
};

export default UploadImage;
