import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api/activitesApi";
import { message } from 'antd';

export const getActivityUploadImage = createAsyncThunk(
  "/user/getactivityuploadimage",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.getActivityUploadImage();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'something went wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const getPartnerId = createAsyncThunk(
  "/managebanners/getpartners",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.getPartnerId();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.message.data : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const addActivities = createAsyncThunk(
  "/user/addactivities",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.addActivities(data);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Added Successfully!');
        // return resp.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Not Added/Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const updateActivitiesData = createAsyncThunk(
  "/user/updateactivities",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.updateActivitiesData(data);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Updated Successfully!');
        // return resp.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Not Updated/Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const showActivitiesData = createAsyncThunk(
  "/user/showactivities",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.showActivitiesData();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const activatedTasksData = createAsyncThunk(
  "/user/activatetasks",
  async (query, { rejectWithValue }) => {
    try {
      const resp = await api.activatedTasksData(query);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Activated/Deactivated Successfully!');
        cbFunc();
        // return resp.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Not Activated/Deactivated OR Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

const activitiesSlice = createSlice({
  name: "activities",
  initialState: {
    data: {
      getPartnerId: [],
      activityImageData: [],
      activityData: [],
      gotActivitiesDataRow: null,
    },
  },
  reducers: {
    gotActivitiesDataRow: (state, action) => {
      state.data.gotActivitiesDataRow = action.payload;
    },
  },
  extraReducers: (builder) => {
    // =================================================================================================

    builder.addCase(getPartnerId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPartnerId.fulfilled, (state, action) => {
      state.data.getPartnerId = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getPartnerId.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // =================================================================================================

    builder.addCase(getActivityUploadImage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getActivityUploadImage.fulfilled, (state, action) => {
      state.data.activityImageData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getActivityUploadImage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // =================================================================================================

    builder.addCase(showActivitiesData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showActivitiesData.fulfilled, (state, action) => {
      state.data.activityData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(showActivitiesData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ====================================addActivities=============================================

    builder.addCase(addActivities.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addActivities.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(addActivities.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ====================================updateActivitiesData=============================================

    builder.addCase(updateActivitiesData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateActivitiesData.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateActivitiesData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ====================================activatedTasksData=============================================

    builder.addCase(activatedTasksData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(activatedTasksData.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(activatedTasksData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { gotActivitiesDataRow } = activitiesSlice.actions;

export default activitiesSlice.reducer;
