import React, { Fragment, useState, useEffect, useCallback } from "react";
import MUIDataTable from "mui-datatables";


const ShowAffClicksData = (props) => {
    const columns = [
        {
            name: "uid",
            label: "User_id",
            options: {
                filter: true,
                sort: false,
            }
        },

        {
            name: "name",
            label: "User Name",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "store_name",
            label: "Store Name",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "task_short_name",
            label: "Task Name",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "partner",
            label: "Partner",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "click_date",
            label: "click_date",
            options: {
                filter: true,
                sort: false,
            }
        }
    ];
    const options = {
        filterType: 'name',
    };
    return (
        <Fragment>
            <MUIDataTable
                title={"Show Aff Clicks  (search by User name)"}
                data={props.infoByClickId && props.infoByClickId}
                columns={columns}
                options={options}
            />
        </Fragment>
    );
}
export default ShowAffClicksData;