import React, { useState } from 'react'
import styled from 'styled-components';
import { Typography, Divider, Input, Radio, Icon, message } from "antd";
import SelectAction from './SelectAction';

const { Title } = Typography;
const Wrapper = styled.div`
  width: 100%;
`;
const BodyContainer = styled.div`

`;
const Container = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const HeaderFooter = () => {
    const [menu_type, setMenuType] = useState("header");
    const handleRadio = (e) => {
        setMenuType(e.target.value);
    }

    return (
        <Wrapper>
            <Title level={3}>Header & Footer</Title>
            <div className="row">
                <div className="col-6 col-lg-6">
                    {/* <Radio.Group  name="menu_type"> */}
                    <Radio onChange={handleRadio} value={"header"} type="radio" checked={menu_type == "header"}>Header</Radio>
                    <Radio onChange={handleRadio} value={"footer"} type="radio" checked={menu_type == "footer"}>Footer</Radio>
                    {/* </Radio.Group> */}
                </div>

            </div>
            <BodyContainer>
                {menu_type == "header" && (
                    <SelectAction menu_type={menu_type} />)}
                {menu_type == "footer" && (
                    <SelectAction menu_type={menu_type} />)}
            </BodyContainer>
        </Wrapper>
    )
}

export default HeaderFooter;
