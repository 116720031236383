import axios from "axios";
import AppConstants from "../../constants/appConstants";


const API = axios.create({
    baseURL: `${AppConstants.baseURL}`,
});

export const getProfile = () => API.get("/auth/profile");
export const showRoles = () => API.get("/auth/showroles");
// export const changePassword = (data) => API.post("/auth/changepassword", data);
export const addRoles = (data)=> API.post("/auth/addroles", data);
export const updateRoles = (data) => API.post("/auth/updateroles", data);
export const showUserTypeModulesData = (data) => API.get("/auth/showmoduleusertype", data);