import ActionTypes from "../constants/actionTypes";
import initialState from "../stores/initialState";

export default (state = initialState.contactedusers, action) => {
  switch (action.type) {
    case ActionTypes.GOT_CONTACTED_USERS:
      return {
        ...state,
        contactedusersData: action.payload
      };
    //       case ActionTypes.GOT_IMAGE_BY_STORE:
    //       return {
    //         ...state,
    //         imageData: action.payload.data
    //       };
    default:
      return state;
  }
};