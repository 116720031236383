import React, { useState, useEffect, forwardRef, Fragment } from "react";
import styled from "styled-components";
import { Typography, Radio } from "antd";
import MaterialTable from "material-table";

import { DatePicker, Space } from 'antd';
const { RangePicker } = DatePicker;

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { useDispatch, useSelector } from "react-redux";
import { Top10Results } from "../../../redux/slices/crmSlice";

const { Title } = Typography;
const Wrapper = styled.div`
  width: 100%;
`;
const BodyContainer = styled.div``;

const RadioContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  padding: 5px 0;
  > div {
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    margin-right: 30px;
  }
`;

const Top10Data = () => {
    const data = useSelector(state => state.crm.data.Top10Results)
    const top10Orders = useSelector(state => state.crm.data.top10Orders)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(Top10Results());
    }, []);
    const pendingData = data.map(o => ({ ...o }));
    const top10OrdersData = top10Orders.map(o => ({ ...o }));



    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };


    const tableColumns = [
        { title: "User id", field: "uid", editable: 'never' },
        { title: "User Name", field: "name", editable: 'never' },
        { title: "Email", field: "email", editable: 'never' },
        { title: "Pending", field: "pending", editable: 'never' },
        { title: "Mobile", field: "mobile", editable: 'never' },
    ];


    const tableColumns1 = [
        { title: "User id", field: "uid", editable: 'never' },
        { title: "User Name", field: "name", editable: 'never' },
        { title: "Email", field: "email", editable: 'never' },
        { title: "Count", field: "count_uid", editable: 'never' },
        { title: "Mobile", field: "mobile", editable: 'never' },
    ];

    return (
        <Fragment>
            <MaterialTable
                title="Top 10 Highest Pending Payout "
                columns={tableColumns}
                icons={tableIcons}
                data={pendingData && pendingData}
                options={{ search: true, actionsColumnIndex: -1, exportButton: true, }}

            />

            <MaterialTable
                className="mt-4"
                title="Top 10 Highest Orders "
                columns={tableColumns1}
                icons={tableIcons}
                data={top10OrdersData && top10OrdersData}
                options={{ search: true, actionsColumnIndex: -1, exportButton: true, }}
            // options={{
            //     actionsColumnIndex: -1,
            //     selection: true,
            //     showFirstLastPageButtons: true,
            //     pageSize: 10,
            //     padding: 'dense',
            //     pageSizeOptions: [10, props.top10Orders  ? props.top10Orders.length : 100],
            //     exportButton: true
            //   }}

            />
            {/* <MaterialTable
                className="mt-4"
                title="Top 10 Highest Pending Payout "
                columns={tableColumns}
                icons={tableIcons}
                data={props.allOrdersCrm && props.allOrdersCrm}
                options={{ search: true, actionsColumnIndex: -1, exportButton: true, }}

            /> */}
        </Fragment>
    );
}


export default Top10Data;
