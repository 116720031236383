import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Popconfirm, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import {
  getPaymentStatus,
  UpdateRedemptionStatus,
} from "../../../redux/slices/paymentStatusSlice";
import NativeSelect from "@mui/material/NativeSelect";
import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const ShowProcessingPaymentStatus = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { paymentStatusProcessing } = useSelector((s) => s.paymentStatus);
  //   const [paymentStatus, setPaymentStatus] = useState({
  //     id: "",
  //     status: "",
  //   });
  const handleChange = (e, rowIndex) => {
    const { name, value } = e.target;
    const updatedPaymentStatus = JSON.parse(
      JSON.stringify(paymentStatusProcessing)
    );
    let status = value;
    let id = updatedPaymentStatus[rowIndex].id;

    let data = { id, status };
    dispatch(UpdateRedemptionStatus(data))
      .then(() => {
        dispatch(getPaymentStatus());
        props.setMenuType("show");
      })
      .catch((err) => {
        console.log(err, "errr");
      });
    console.log(data, "data==========");
  };

  useEffect(() => {
    dispatch(getPaymentStatus());
  }, []);

  const columns = [
    {
      name: "id",
      label: " ID",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "uid",
      label: "User Id",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "name",
      label: "User Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "mobile",
      label: "User mobile",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "points",
      label: "Points",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          // {console.log(value,"value")}
          <div>
            <FormControl sx={{ m: 1 }} variant="standard">
              <InputLabel id="demo-customized-select-native">Status</InputLabel>
              <NativeSelect
                labelId="demo-customized-select-native"
                id="demo-customized-select"
                name="status"
                defaultValue={value}
                // value={paymentStatus.status}
                onChange={(e) => handleChange(e, tableMeta.rowIndex)}
                input={<BootstrapInput />}
              >
                <option value={value}>{value}</option>
                <option value={"pending"}>Pending</option>
                <option value={"paid"}>Paid</option>
                <option value={"rejected"}>Rejected</option>
                <option value={"failed"}>Failed</option>
              </NativeSelect>
            </FormControl>
          </div>
        ),
      },
    },
    {
      name: "type",
      label: "type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "method",
      label: "method",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "remarks",
      label: "Remarks",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "details",
      label: "Details",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "created_at",
      label: "Created Daters",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "updated_at",
      label: "Updated Date",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];
  const options = {
    filterType: "uid",
  };

  return (
    <Fragment>
      <MUIDataTable
        title={"Show Payment Status  (Search by redeem Id)"}
        data={paymentStatusProcessing && paymentStatusProcessing}
        columns={columns}
        options={options}
      />
    </Fragment>
  );
};

export default ShowProcessingPaymentStatus;
