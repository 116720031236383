import React, { useState, useEffect, useCallback } from "react";
import {
  AutoComplete,
  Input,
  Typography,
  Table,
  DatePicker,
  Radio,
} from "antd";
import styled from "styled-components";
// import AppConstants from '../../../constants/appConstants';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addAdditionalStoreData,
  getStoreData,
  updateAdditionalStoreData,
} from "../../../../redux/slices/manageStoreSlice";
import { StoreFixedTermsAndConditions } from "../../../../redux/slices/crmSlice";
const { Text } = Typography;

const TableContainer = styled.div`
  overflow: auto;
  max-height: 250px;
`;

const Data = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  > label {
    width: 30%;
  }
  > span {
    font-size: 18px;
    font-weight: bolder;
  }
  > input[type="text"] {
    width: 50%;
  }
  > div {
    width: 50% !important;
  }
  @media screen and (max-width: 480px) {
    display: block;
  }
`;
const DataRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const AddAdditionalStoreData = () => {
  const dispatch = useDispatch();
  const { additionalStoreDataRow, storedata } = useSelector(
    (s) => s.manageStores
  );

  const { storeFixedtermsConditionData } = useSelector((s) => s.crm);
  //   console.log(data);
  // let history = useHistory();  //useHistory is being deprecated in react-router v6 or above
  const navigate = useNavigate();
  // console.log(storeFixedtermsConditionData, "storeFixedtermsConditionData");

  const [storeData, setStoreData] = useState({
    store_name: "",
    store_id: "",
  });
  const [isUpdatedData, setUpdatedData] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [allValues, setAllValues] = useState({
    tips: "",
    key: "",
    values: "",
    tracking_speed: "",
    missing_cashback: "",
  });
  const [dateData, setDateData] = useState("");
  const [termsArr, setTermsArr] = useState([]);

  const [tips, setTips] = useState("");
  const [finalArray, setFinalArray] = useState([]);

  // const [disabledInput, setDisabledInput] = useState(false);
  const handleDateChange = (date, dateString) => {
    setDateData(dateString);
  };
  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };
  const onTipsChange = (e) => {
    setTips(e, "input");
  };

  const cbFunc = (res, code) => {
    navigate("/affiliate/applinking");
  };

  const handleAutoComplete = (e) => {
    setStoreData({ ...storeData, storeName: e });
    if (storedata.length > 0) {
      for (var i = 0; i < storedata.length; i++) {
        if (e == storedata[i].store_name) {
          setStoreData({
            ...storeData,
            store_id: storedata[i].store_id,
            store_name: storedata[i].store_name,
          });
        }
      }
    }
  };

  const handleAutoCompleteFixedTerms = (e) => {
    // setStoreData({ ...storeData, storeName: e });
    console.log(e, "e------------------");
    setTips(e);
    // if (storedata.length > 0) {
    //   for (var i = 0; i < storedata.length; i++) {
    //     if (e == storedata[i].store_name) {
    //       setStoreData({
    //         ...storeData,
    //         store_id: storedata[i].store_id,
    //         store_name: storedata[i].store_name,
    //       });
    //     }
    //   }
    // }
  };

  const submitForm = (e) => {
    e.preventDefault();
    let data = {};
    data.store_id = storeData.store_id;
    data.tips = finalArray;
    // data.terms = termsArr;
    data.tracking_speed = allValues.tracking_speed;
    data.missing_cashback = allValues.missing_cashback;
    data.expected_approval_date = dateData;
    //console.log(data, "data of additional Store")
    // cbFunc();
    if (isUpdatedData) {
      dispatch(updateAdditionalStoreData(data));
    } else {
      dispatch(addAdditionalStoreData(data));
      cbFunc();
    }
  };

  const add = useCallback(
    (e) => {
      e.preventDefault();
      if (tips) {
        setFinalArray((oldArray) => [...oldArray, tips]);
      }
    },
    [tips]
  );
  const remove = (e) => {
    const newArr = [...finalArray];
    let i = 0;
    for (const item of newArr) {
      if (item === e.target.name) break;
      i++;
    }
    if (i == newArr.length) return console.log("not found");
    newArr.splice(i, 1);
    setFinalArray(newArr);
  };

  useEffect(() => {
    if (additionalStoreDataRow.store_name) {
      setIsDisable(!isDisable);
      setUpdatedData(!isUpdatedData);
      setStoreData({
        ...storeData,
        store_name: additionalStoreDataRow.store_name,
        store_id: additionalStoreDataRow.store_id,
      });

      setAllValues({
        ...allValues,
        // tips: additionalStoreDataRow.tips,
        // key: '',
        // values: '',
        tracking_speed: additionalStoreDataRow.tracking_speed,
        missing_cashback: additionalStoreDataRow.missing_cashback,
      });
      if (additionalStoreDataRow.tips) {
        // tips.push(additionalStoreDataRow.tips)
        // }
        setFinalArray(additionalStoreDataRow.tips);
      }
      setDateData({
        exp_approval_date: additionalStoreDataRow.exp_approval_date,
      });
    }
    dispatch(getStoreData());
    dispatch(StoreFixedTermsAndConditions());
  }, []);

  const add1 = (e) => {
    setTermsArr([
      ...termsArr,
      { key: allValues.key, values: allValues.values },
    ]);
  };

  const remove1 = (e) => {
    const newArr = [...termsArr];
    newArr.splice(
      newArr.findIndex((item) => item.name === e.target.name),
      1
    );
    setTermsArr(newArr);
  };

  const columns = [
    {
      title: "Tips",
      dataIndex: "tips",
      key: "tips",
      render: (text, record) => <p>{record}</p>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <a onClick={remove} name={`${record}`}>
          Delete
        </a>
      ),
    },
  ];

  // const columns1 = [
  //   {
  //     title: "Key",
  //     dataIndex: "key",
  //     key: "key",
  //   },
  //   {
  //     title: "Value",
  //     dataIndex: "values",
  //     key: "values",
  //   },
  //   {
  //     title: "Action",
  //     key: "action",
  //     render: (text, record) => <a onClick={remove1}>Delete</a>,
  //   },
  // ];
  // const columns2 = [
  //     {
  //         title: 'Tracking Speed',
  //         dataIndex: 'track_speed',
  //         key: 'track_speed',
  //         editable: true
  //     },
  //     {
  //         title: 'Missing Cashback',
  //         dataIndex: 'miss_cashback',
  //         key: 'miss_cashback',
  //         editable: true
  //     },
  //     {
  //         title: 'Expected Approval Date',
  //         dataIndex: 'exp_approval_date',
  //         key: 'exp_approval_date',
  //     },
  //     {
  //         title: 'Action',
  //         key: 'action',
  //         render: (text, record) => (
  //             <a onClick={removeExtraValues}>Delete</a>
  //         ),
  //     },

  // ]
  // const newData = finalArray.forEach((element) => {
  //     let data = [];
  //     data.push({ tips: element })
  //     setTipsTableData(data);
  // })
  // //console.log(allValues, "finalData");
  //console.log(finalArray, "finaal tips data");

  return (
    <>
      <h2 className="my-4">Add Additional Store Data</h2>

      <form onSubmit={submitForm}>
        <div className="row">
          <div className="col-12 col-lg-12">
            <DataRow>
              <label for="" className="form-label w-75">
                Select Store
              </label>
              <h6 className="mr-3">{storeData.store_name}</h6>
              <AutoComplete
                required={true}
                // style={{ width: "50% !important" }}
                // value={storeData.store_name}
                className="auto-complete"
                dataSource={
                  storedata && storedata.map((item, index) => item.store_name)
                }
                onSelect={handleAutoComplete}
                disabled={isDisable}
                filterOption={(inputValue, option) =>
                  option.props.children
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </DataRow>
            <DataRow>
              <label for="" className="form-label w-75">
                Add Useful Tips
              </label>
              {/* <input
                type="text"
                className="form-control"
                name="tips"
                // value={allValues.tips}
                onChange={onTipsChange}
                required={true}
              /> */}

              <AutoComplete
                required={true}
                // style={{ width: "50% !important" }}
                // value={storeData.store_name}
                className="auto-complete"
                dataSource={
                  storeFixedtermsConditionData &&
                  storeFixedtermsConditionData.map((item, index) => item.tips)
                }
                onSelect={handleAutoCompleteFixedTerms}
                // disabled={isDisable}
                onChange={onTipsChange}
                filterOption={(inputValue, option) =>
                  option.props.children
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </DataRow>
            <div className="justify-content-end mb-3 d-flex">
              <button type="submit" class="btn btn-primary" onClick={add}>
                Add
              </button>
            </div>
            <TableContainer>
              <Table dataSource={finalArray} columns={columns} />
            </TableContainer>

            <DataRow className="mt-5">
              <label for="" className="form-label w-75">
                Tracking Speed
              </label>
              <input
                type="text"
                className="form-control"
                name="tracking_speed"
                value={allValues.tracking_speed}
                onChange={changeHandler}
                required={true}
              />
            </DataRow>
            <p style={{ float: "right" }}>
              e.g---<span style={{ color: "blue" }}>20hours,20h,20days</span>
            </p>

            <Data>
              <Text>Missing Cashback</Text>
              <Radio.Group
                onChange={changeHandler}
                name="missing_cashback"
                value={allValues.missing_cashback}
              >
                <Radio
                  value={1}
                  type="radio"
                  checked={allValues.missing_cashback == 1}
                >
                  Allowed
                </Radio>
                <Radio
                  value={0}
                  type="radio"
                  checked={allValues.missing_cashback == 0}
                >
                  Not Allowed
                </Radio>
              </Radio.Group>
            </Data>

            <Data>
              <Text>Estimated Approval Date</Text>
              <DatePicker
                onChange={handleDateChange}
                value={dateData.expected_approval_date}
              />
            </Data>
          </div>
          <div className="col-12 col-lg-12 my-3 text-center ">
            <button
              type="submit"
              className="btn btn-primary"
              // onClick={submitForm}
            >
              Submit
            </button>
          </div>
        </div>
      </form>

      {/* <form id="myform"> */}

      {/* Add tips Data */}

      {/* Add trackSpeed,missing cashback,expectedDate Data */}

      {/* <div className="justify-content-end mb-3 d-flex">
                <button type="submit" class="btn btn-primary" onClick={addExtraValues}>Add Values</button>
            </div> */}
      {/* <TableContainer>
                <Table dataSource={allValues} columns={columns2} />
            </TableContainer> */}

      {/* Add terms Data */}

      {/* <div className="d-none">
                <Data className="my-3">
                    <Text>Key</Text>
                    <Input
                        size="large"
                        type="text"
                        name="key"
                        // value={allValues.add_tips}
                        onChange={changeHandler}
                    />
                </Data>

                <Data>
                    <Text>Values</Text>
                    <Input
                        size="large"
                        type="text"
                        name="values"
                        // value={allValues.add_tips}
                        onChange={changeHandler}
                    />
                </Data>
                <div className="justify-content-end mb-3  d-flex">
                    <button type="submit" class="btn btn-primary" onClick={add1}>Add</button>
                </div>
                <TableContainer>
                    <Table dataSource={termsArr} columns={columns1} />
                </TableContainer>
            </div>

            <div className="col-12 col-lg-12 my-5 text-center">
                <button type="submit" class="btn btn-primary" onClick={submitForm}>Submit</button>
            </div> */}
      {/* </form> */}
    </>
  );
};

export default AddAdditionalStoreData;
