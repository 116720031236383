import React, { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
import {
  // Button,
  // message,
  AutoComplete,
  Radio,
  // Checkbox,
  Input,
  Typography,
  DatePicker,
  Space,
} from "antd";

import styled from "styled-components";
// import AddIcon from "@material-ui/icons/Add";
// import RemoveIcon from "@material-ui/icons/Remove";
// import { IconButton } from "@material-ui/core";

import { Editor } from "react-draft-wysiwyg";
import { convertFromHTML, ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { stateToHTML } from "draft-js-export-html";

import { getOffers, updateDeals, addOffers } from "../../../redux/slices/dealsSlice";
import { useDispatch, useSelector } from "react-redux";

const DescriptionBox = styled.div`
  height: 300px;
  overflow: auto;
  width: 50%;
  border: 1px solid #000;
`;
const { TextArea } = Input;
const RadioContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  padding: 5px 0;
  > div {
    color: #666;
    font-size: 14px;
    margin-right: 30px;
  }
`;
// const ButtonContainer = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// const SubmitButton = styled.div`
//   width: 140px;
//   height: 40px;
//   display: flex;
//   background: #0000ff;
//   justify-content: center;
//   align-items: center;
//   border-radius: 15px;
//   cursor: pointer;
//   > p {
//     font-size: 16px;
//     font-weight: bolder;
//     margin-bottom: 0px;
//     color: #fff;
//     text-align: center;
//   }
// `;
const Data = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  > label {
    width: 30%;
  }
  > span {
    font-size: 18px;
    font-weight: bolder;
  }
  > input[type="text"] {
    width: 50%;
  }
  > div {
    width: 50% !important;
  }
  @media screen and (max-width: 480px) {
    display: block;
  }
`;

const DataRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const AddDeals = () => {
  const dispatch = useDispatch();
  const { getOffersData, datatobeUpdated } = useSelector((state) => ({ ...state.deals }));
  console.log(datatobeUpdated, "datatobeUpdated");

  const [editorState, setEditor] = useState("");
  const [editorState2, setEditor2] = useState("");

  const [isUpdatedData, setUpdatedData] = useState(false);

  const [isDisable, setIsDisable] = useState(false);

  const [addFromData, setAddFormData] = useState({
    id: "",
    store_id: "",
    store_name: "",
    title: "",
    detail: "",
    description: "",
    img_url: "",
    cashback_upto: "",
    mrp: "",
    offer_price: "",
    product_url: "",
  });

  const [allValues, setAllValues] = useState({
    d_type: "text",
  });
  const [allValues2, setAllValues2] = useState({
    d_type: "text",
  });

  const [defPaymentDate, setDefPaymentDate] = useState("");
  const [defPaymentDate2, setDefPaymentDate2] = useState("");
  const changeHandler = (e) => {
    setAddFormData({ ...addFromData, [e.target.name]: e.target.value });
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
    setAllValues2({ ...allValues2, [e.target.name]: e.target.value });
  };

  // const blocksFromHTML = convertFromHTML(event.target.value);
  // const state = ContentState.createFromBlockArray(
  //   blocksFromHTML.contentBlocks,
  //   blocksFromHTML.entityMap
  // );

  // setEditor(EditorState.createWithContent(state));
  const handleDescChange2 = (event) => {
    const blocksFromHTML = convertFromHTML(event.target.value);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    setEditor2(EditorState.createWithContent(state));
    console.log(state, blocksFromHTML);
  };

  const onEditorStateChange = (editorState) => {
    setEditor(editorState);
    var html = stateToHTML(editorState.getCurrentContent());
    setAddFormData({ ...addFromData, description: html });
  };

  const onEditorStateChange2 = (editorState2) => {
    setEditor2(editorState2);
    var html = stateToHTML(editorState2.getCurrentContent());
    setAddFormData({ ...addFromData, detail: html });
  };

  const handleDateChange = (date, dateString) => {
    setDefPaymentDate(dateString);
  };
  const handleDateChange2 = (date, dateString) => {
    setDefPaymentDate2(dateString);
  };

  const handleAutoComplete = (e) => {
    setAddFormData({ ...addFromData, store_name: e });
    if (getOffersData.length > 0) {
      for (var i = 0; i < getOffersData.length; i++) {
        if (e == getOffersData[i].store_name) {
          setAddFormData({ ...addFromData, store_id: getOffersData[i].store_id, store_name: getOffersData[i].store_name });
        }
      }
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    let data = {};
    data.id = addFromData.id;
    data.title = addFromData.title;
    data.store_id = addFromData.store_id;
    data.img_url = addFromData.img_url;
    data.description = addFromData.description;
    data.detail = addFromData.detail;
    data.mrp = addFromData.mrp;
    data.offer_price = addFromData.offer_price;
    data.cashback_upto = addFromData.cashback_upto;
    data.product_url = addFromData.product_url;
    data.start_date = defPaymentDate;
    data.end_date = defPaymentDate2;
    isUpdatedData && isUpdatedData ? dispatch(updateDeals(data)) : dispatch(addOffers(data)); //rtk
  };

  useEffect(() => {
    dispatch(getOffers());
  }, []);

  // useEffect(() => {
  //   // props.getStoreData();
  //   if (props.dealsRowData) {
  //     setIsDisable(!isDisable);
  //     setUpdatedData(!isUpdatedData);
  //     setAddFormData({
  //       ...addFromData,
  //       id: props.dealsRowData.id,
  //       title: props.dealsRowData.title,
  //       store_id: props.dealsRowData.store_id,
  //       img_url: props.dealsRowData.img_url,
  //       mrp: props.dealsRowData.mrp,
  //       offer_price: props.dealsRowData.offer_price,
  //       cashback_upto: props.dealsRowData.cashback_upto,
  //       product_url: props.dealsRowData.product_url,
  //     })
  //     setDefPaymentDate(props.dealsRowData.start_date);
  //     setDefPaymentDate2(props.dealsRowData.end_date);
  //     setAllValues({ d_type: props.dealsRowData.d_type });
  //     setAllValues2({ d_type: props.dealsRowData.d_type })

  //     if (props.dealsRowData.description) {

  //       const blocksFromHtml = htmlToDraft(props.dealsRowData.description);
  //       const { contentBlocks, entityMap } = blocksFromHtml;
  //       const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  //       const editorState = EditorState.createWithContent(contentState);
  //       setEditor(editorState);
  //     }
  //     if (props.dealsRowData.detail) {
  //       const blocksFromHtml = htmlToDraft(props.dealsRowData.detail);
  //       const { contentBlocks, entityMap } = blocksFromHtml;
  //       const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  //       const editorState = EditorState.createWithContent(contentState);
  //       setEditor2(editorState);
  //     }
  //   }
  // }, []);


  //below useEffect is not working correctly due to  Editor related changes
  useEffect(() => {
    if (datatobeUpdated) {
      setIsDisable(!isDisable);
      setUpdatedData(!isUpdatedData);
      setAddFormData({
        id: datatobeUpdated ? datatobeUpdated.id : "",
        store_id: datatobeUpdated ? datatobeUpdated.store_id : "",
        store_name: datatobeUpdated ? datatobeUpdated.store_name : "",
        title: datatobeUpdated ? datatobeUpdated.title : "",
        detail: datatobeUpdated ? datatobeUpdated.detail : "",
        description: datatobeUpdated ? datatobeUpdated.description : "",
        img_url: datatobeUpdated ? datatobeUpdated.img_url : "",
        cashback_upto: datatobeUpdated ? datatobeUpdated.cashback_upto : "",
        mrp: datatobeUpdated ? datatobeUpdated.mrp : "",
        offer_price: datatobeUpdated ? datatobeUpdated.offer_price : "",
        product_url: datatobeUpdated ? datatobeUpdated.product_url : "",
      })
    }
  }, [datatobeUpdated]);

  return (
    <>
      <form onSubmit={submitForm}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div>
                <DataRow className="mb-3">
                  <label for="" className="form-label w-75">
                    Product Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    onChange={changeHandler}
                    value={addFromData.title}
                  />
                </DataRow>
              </div>
              <DataRow className="mb-3">
                <label className="form-label w-75">Image</label>
                <input
                  type="url"
                  className="form-control"
                  name="img_url"
                  onChange={changeHandler}
                  value={addFromData.img_url}
                />
              </DataRow>

              <DataRow className="mb-3 ">
                <label for="" className="form-label w-75">
                  Store
                </label>
                <AutoComplete
                  className="auto-complete form-control"
                  // dataSource={getOffersData && getOffersData.map((item) => item.store_name)}
                  disabled={isDisable}
                  onSelect={handleAutoComplete}
                  value={addFromData.store_id}
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </DataRow>

              {/* //temporary disabled to be resolved  */}
              {/* <div className="mb-3 ">
                <Data>
                  <Text>Product description</Text>
                  <RadioContainer>
                    <Radio.Group
                      onChange={changeHandler}
                      name="d_type"
                      value={allValues.d_type}
                    >
                      <Radio
                        type="radio"
                        value={"text"}
                        checked={allValues.d_type == "text"}
                      >
                        Text
                      </Radio>
                      <Radio
                        type="radio"
                        value={"html"}
                        checked={allValues.d_type == "html"}
                      >
                        HTML
                      </Radio>
                    </Radio.Group>
                  </RadioContainer>
                </Data>

                <Data>
                  <Text>Product Description</Text>
                  {allValues.d_type == "text" ? (
                    <DescriptionBox className="editor-change">
                      <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={onEditorStateChange}
                      />
                    </DescriptionBox>
                  ) : (
                    <DescriptionBox className="editor-change">
                      <TextArea
                        style={{ width: "100%", height: "100%" }}
                        size="large"
                        //  autosize={{ minRows: 2, maxRows: 10 }}
                        type="text"
                        className="form-control"
                        value={addFromData.description}
                        name="description"
                        placeholder="Product Description....."
                        onChange={handleDescChange}
                      />
                    </DescriptionBox>
                  )}
                </Data>
              </div> */}

              {/* <div className="mb-3">
                <Data>
                  <Text>Product Details</Text>
                  <RadioContainer>
                    <Radio.Group
                      onChange={changeHandler}
                      name="d_type"
                      value={allValues2.d_type}
                    >
                      <Radio
                        type="radio"
                        value={"text"}
                        checked={allValues2.d_type == "text"}
                      >
                        Text
                      </Radio>
                      <Radio
                        type="radio"
                        value={"html"}
                        checked={allValues2.d_type == "html"}
                      >
                        HTML
                      </Radio>
                    </Radio.Group>
                  </RadioContainer>
                </Data>

                <Data>
                  <Text>Product Details</Text>
                  {allValues2.d_type == "text" ? (
                    <DescriptionBox className="editor-change">
                      <Editor
                        editorState={editorState2}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={onEditorStateChange2}
                      />
                    </DescriptionBox>
                  ) : (
                    <DescriptionBox className="editor-change">
                      <TextArea
                        style={{ width: "100%", height: "100%" }}
                        size="large"
                        //  autosize={{ minRows: 2, maxRows: 10 }}
                        type="text"
                        value={addFromData.detail}
                        className="form-control"
                        name="detail"
                        placeholder="Related Description....."
                        onChange={handleDescChange2}
                      />
                    </DescriptionBox>
                  )}
                </Data>
              </div> */}

              <DataRow className="mb-3 ">
                <label for="" className="form-label w-75">
                  Price MRP/Actual
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="mrp"
                  onChange={changeHandler}
                  value={addFromData.mrp}
                />
              </DataRow>
              <DataRow className="mb-3 ">
                <label for="" className="form-label w-75">
                  Offer Price
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="offer_price"
                  onChange={changeHandler}
                  value={addFromData.offer_price}
                />
              </DataRow>
              <DataRow className="mb-3 ">
                <label for="" className="form-label w-75">
                  Hyyzo Cashback Upto
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="cashback_upto"
                  onChange={changeHandler}
                  value={addFromData.cashback_upto}
                />
              </DataRow>

              <DataRow className="mb-3 ">
                <label for="" className="form-label w-75">
                  Product link
                </label>
                <input
                  type="url"
                  className="form-control"
                  name="product_url"
                  onChange={changeHandler}
                  value={addFromData.product_url}
                />
              </DataRow>
              <div className="mb-3 ">
                <label for="" className="form-label w-25">
                  Start Date ({defPaymentDate && defPaymentDate})
                </label>
                <DatePicker showTime onChange={handleDateChange} />
              </div>

              <div className="mb-3 ">
                <label for="" className="form-label w-25">
                  End Date ({defPaymentDate2 && defPaymentDate2})
                </label>
                <DatePicker showTime onChange={handleDateChange2} />
              </div>

              <div className="text-center">
                <button type="submit" className="btn btn-primary px-5  py-2">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddDeals;
