import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Typography, Divider, Input, Radio, message, AutoComplete } from "antd";
import { DatePicker } from "antd";
import { Button } from "antd";
import Popup from "../../Repeated/Popup";
import moment from "moment";
import AppConstants from "../../../constants/appConstants";

// import { stateToHTML } from "draft-js-export-html";
import { Editor } from "react-draft-wysiwyg";
import { convertFromHTML, ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from "draft-js-export-html";
import { useDispatch, useSelector } from "react-redux";
import {
  addActivities,
  getActivityUploadImage,
  getPartnerId,
  updateActivitiesData,
} from "../../../redux/slices/activitiesSlice";

const { RangePicker } = DatePicker;

const { Title, Text } = Typography;

const { TextArea } = Input;

const Wrapper = styled.div`
  width: 100%;
`;

const TopContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Container = styled.div`
  width: 90%;
  margin: 0 auto;
`;

const Wrapper2 = styled.div`
  width: 100%;
  height: 310px;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const ImageContainer = styled.div`
  width: 200px;
  display: flex;
  height: 200px;
  flex-direction: row;
  padding: 50px;
  justify-content: space-between;
`;

const ImageMainContainer = styled.div`
  position: absolute;
  top: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 300px;
  overflow: auto;
`;
const Inp = styled.div`
  width: 90px;
  height: 50px;
`;
const Data = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  > label {
    width: 30%;
  }
  > span {
    font-size: 18px;
    font-weight: bolder;
  }
  > input[type="text"] {
    width: 50%;
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: baseline;
    > input[type="text"] {
      width: 90%;
    }
  }
`;

const RadioContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  padding: 5px 0;
  > div {
    color: #666;
    font-size: 14px;
    margin-right: 30px;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubmitButton = styled.div`
  width: 140px;
  height: 40px;
  display: flex;
  background: #0000ff;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  cursor: pointer;
  > p {
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 0px;
    color: #fff;
    text-align: center;
  }
`;
const DescriptionBox = styled.div`
  height: 300px;
  overflow: auto;
  width: 50%;
  border: 1px solid #000;
`;
const DataRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const AddNewActivity = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.activities.data);
  let activityDataRow = data.gotActivitiesDataRow;

  const partnerIdData = data.getPartnerId;
  const activityImageData = data.activityImageData;

  const [isUpdatedData, setUpdatedData] = useState(false);
  // const borderStyle = () => document.querySelector("img").style.border = "unset";
  // const [borderData, setBorderData] = useState('document.querySelector("img").style.border = "unset"');
  const [isOpen, setIsOpen] = useState(false);
  const [imageId, setImageId] = useState({
    id: "",
    url: "",
  });
  // const [imageUrl, setImageUrl] = useState('');
  const [altText, setAltText] = useState("");
  const [title, setTitle] = useState("");

  const [editorState, setEditor] = useState("");
  const [details, setDetails] = useState("");
  const [state, setState] = useState({
    start_date: "",
    end_date: "",
  });
  const [approvalDate, setApprovalDate] = useState("");

  const [allValues, setAllValues] = useState({
    task_id: "",
    task_title: "",
    task_short_name: "",
    task_url: "",
    task_description: "",
    link: "",
    priority: "",
    d_type: "text",
  });
  const [partnerId, setPartnerId] = useState({
    id: "",
    partner: "",
  });

  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };

  const titleChangeHandler = (e) => {
    // let url = e.target.value.toLowerCase().replaceAll(" ", "-");
    setAllValues({ ...allValues, task_title: e.target.value });
  };

  const getConvertedDate = (isoDate) => {
    let newDate = moment(isoDate)
      .add(330, "minutes")
      .utc()
      .format("YYYY-MM-DD");
    return newDate;
  };
  const changeDate = (date, dateString) => {
    setState({ start_date: dateString[0], end_date: dateString[1] });
  };
  const changeApprovalDate = (date, dateString) => {
    setApprovalDate({ approval_date: dateString });
  };
  const cb_func = () => {};

  const onEditorStateChange = (editorState) => {
    setEditor(editorState);
    var html = stateToHTML(editorState.getCurrentContent());
    setDetails(html);
  };
  const handleDescChange = (event) => {
    setDetails(event.target.value);
    const blocksFromHTML = convertFromHTML(event.target.value);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    setEditor(EditorState.createWithContent(state));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let data = {};
    data.partner_id = partnerId.id;
    data.task_title = allValues.task_title;
    data.task_url = allValues.task_url;
    data.task_short_name = allValues.task_short_name;
    data.task_description = allValues.task_description;
    data.image = imageId.id;
    data.link = allValues.link;
    data.start_date = state.start_date;
    data.end_date = state.end_date;
    data.approval_date = approvalDate.approval_date;
    data.html = details;
    data.d_type = allValues.d_type;
    data.image_url = imageId.url;
    data.priority = allValues.priority;
    // console.log(isUpdatedData,"isUpdatedData")

    if (isUpdatedData) {
      // console.log(isUpdatedData,"isUpdatedData")

      data.task_id = allValues.task_id;
      console.log("data", data);
      dispatch(updateActivitiesData(data));
    } else {
      dispatch(addActivities(data));
    }
  };
  // console.log(isUpdatedData,"isUpdatedData")
  useEffect(() => {
    dispatch(getPartnerId());
    dispatch(getActivityUploadImage());
    // console.log(activityDataRow, "data.gotActivitiesDataRow.priority");
    // console.log(
    //   data.gotActivitiesDataRow,
    //   "data.gotActivitiesDataRow.priority"
    // );
    // console.log(activityDataRow,"activityDataRow")
    if (activityDataRow) {
      setUpdatedData(true);
      setAllValues({
        ...allValues,
        task_title: activityDataRow.task_title,
        task_short_name: activityDataRow.task_short_name,
        task_url: activityDataRow.task_url,
        task_description: activityDataRow.task_description,
        link: activityDataRow.link,
        d_type: activityDataRow.d_type,
        task_id: activityDataRow.task_id,
        priority: activityDataRow.priority,
      });
      setImageId({
        ...imageId,
        id: activityDataRow.imageId,
        url: activityDataRow.url,
      });
      setState({
        ...state,
        start_date: getConvertedDate(activityDataRow.start_date),
        end_date: getConvertedDate(activityDataRow.end_date),
      });
      // let apDate = getConvertedDate(activityDataRow.approval_date);
      setApprovalDate({
        ...approvalDate,
        approval_date: getConvertedDate(activityDataRow.approval_date),
      });
      setPartnerId({
        ...partnerId,
        id: activityDataRow.partner_id,
        partner: activityDataRow.partner_name,
      });
      setDetails([...details, activityDataRow.html]);

      // const blocksFromHtml = htmlToDraft(activityDataRow.html);
      // const { contentBlocks, entityMap } = blocksFromHtml;
      // const contentState = ContentState.createFromBlockArray(
      //   contentBlocks,
      //   entityMap
      // );
      // const editorState = EditorState.createWithContent(contentState);
      // setEditor(editorState);
    }
  }, []);

  const handleAutoComplete = (e) => {
    setPartnerId({ ...partnerId, partner: e });
    if (partnerIdData.length > 1) {
      for (var i = 0; i < partnerIdData.length; i++) {
        if (e == partnerIdData[i].partner) {
          setPartnerId({
            ...partnerId,
            id: partnerIdData[i].id,
            partner: partnerIdData[i].partner,
          });
        }
      }
    }
  };

  const imageHandler = (img) => {
    setImageId({
      id: img.id,
      url: img.url,
    });
    setIsOpen(!isOpen);
    setTitle(img.title);
    setAltText(img.alt_text);
    // setImageUrl(img.url);
    // setBorderData(document.querySelector("img").style.border = "1px solid black")
  };

  const changeTaskUrl = (e) => {
    setAllValues({ ...allValues, task_url: e.target.value });
  };

  const handleReload = (e) => {
    e.preventDefault()
    dispatch(getActivityUploadImage());
  };



  return (
    <Wrapper>
      <Title level={3}>Add Activity Images</Title>
      <Divider />
      <Container>
        <form onSubmit={submitHandler}>
          <div className="row">
            <div className="col-12 col-lg-12">
              <Data>
                <Text className="w-75">
                  Partner ID
                  <span style={{ color: "blue", fontSize: "25px" }}>
                    {" "}
                    ({partnerId.partner})
                  </span>
                </Text>
                <AutoComplete
                  style={{ width: "50% !important" }}
                  // value={storeData.store_name}
                  className="auto-complete"
                  dataSource={
                    partnerIdData && partnerIdData.map((item) => item.partner)
                  }
                  onSelect={handleAutoComplete}
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </Data>
              <DataRow>
                <label for="" className="form-label w-75">
                  Task Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="task_title"
                  size="large"
                  value={allValues.task_title}
                  // value={allValues.tips}
                  onChange={titleChangeHandler}
                  required={true}
                />
              </DataRow>

              {/* <Data>
          <Text>Task Title</Text>
          <Input
            size="large"
            type="text"
            name="task_title"
            value={allValues.task_title}
            onChange={titleChangeHandler}
          // rules={[{ required: true, message: 'Please input your TaskTitle!' }]}
          />
        </Data> */}
              <DataRow>
                <label for="" className="form-label w-75">
                  Task Url
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="TaskUrl"
                  size="large"
                  value={allValues.task_url}
                  // value={allValues.tips}
                  onChange={changeTaskUrl}
                  required={true}
                />
              </DataRow>
              <DataRow>
                <label for="" className="form-label w-75">
                  Task Short Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="task_short_name"
                  size="large"
                  value={allValues.task_short_name}
                  // value={allValues.tips}
                  onChange={changeHandler}
                  required={true}
                />
              </DataRow>
              {/* <Data>
          <Text>Task Url</Text>
          <Input
            size="large"
            type="text"
            name="taskUrl"
            value={allValues.task_url}
            onChange={changeTaskUrl}
          />
        </Data> */}
              <DataRow>
                <label for="" className="form-label">
                  Task Description
                </label>
                <div style={{ width: "58%" }}>
                  <textarea
                    className="w-100"
                    name="task_description"
                    rows="4"
                    cols="50"
                    value={allValues.task_description}
                    placeholder="Task Description....."
                    onChange={changeHandler}
                    required={true}
                  />
                </div>
              </DataRow>
              <DataRow>
                <label for="" className="form-label">
                  Task Priority
                </label>
                <div style={{ width: "58%" }}>
                  <input
                    type="text"
                    className="form-control"
                    name="priority"
                    size="large"
                    value={allValues.priority}
                    // value={allValues.tips}
                    onChange={changeHandler}
                    required={true}
                  />
                </div>
              </DataRow>
              {/* <Data>
          <Text>Task Description</Text>
          <TextArea
            style={{ width: "50%" }}
            size="large"
            autosize={{ minRows: 2, maxRows: 10 }}
            type="text"
            value={allValues.task_description}
            name="task_description"
            placeholder="Task Description....."
            onChange={changeHandler}
          />
        </Data> */}
              <Data>
                <Text>Image</Text>
                {/* <p>{item.activityImageData}</p> */}
                <img
                  src={`${AppConstants.imageURL}${imageId.url}`}
                  value={imageId.url}
                  alt="image selected"
                  style={{
                    width: "70px",
                    position: "absolute",
                    right: "270px",
                    height: "45px",
                  }}
                />
                <Button
                  size="large"
                  type="primary"
                  name="image"
                  onClick={(e) => {
                    setIsOpen(!isOpen);
                  }}
                >
                  Choose Image
                </Button>
                {isOpen && (
                  <Popup
                    content={
                      <>
                        <b style={{padding:'10px'}}>Task images</b>
                          <button onClick={handleReload}>Reload Images</button>
                        <p>
                          <Wrapper2>

                            <ImageMainContainer>
                              {activityImageData &&
                                activityImageData.map((item, index) => (
                                  <ImageContainer>
                                    <img
                                      src={`${AppConstants.imageURL}${item.url}`}
                                      srcSet={item.resized}
                                      onClick={() => {
                                        imageHandler(item);
                                      }}
                                      alt="image"
                                    />

                                    <br />
                                  </ImageContainer>
                                ))}
                            </ImageMainContainer>
                          </Wrapper2>
                        </p>

                        {/* <form
                          onSubmit={() => {
                            setIsOpen(!isOpen);
                          }}
                        >
                          <br />
                          <input type="submit" value="Select" />
                        </form> */}
                      </>
                    }
                    handleClose={() => {
                      setIsOpen(!isOpen);
                    }}
                  />
                )}
              </Data>
              <DataRow>
                <label for="" className="form-label w-75">
                  Link
                </label>
                <input
                  type="url"
                  className="form-control"
                  name="link"
                  value={allValues.link}
                  onChange={changeHandler}
                  required={true}
                />
              </DataRow>
              {/* <Data>
          <Text>Link</Text>
          <Input
            size="large"
            type="text"
            name="link"
            value={allValues.link}
            onChange={changeHandler}
          />
        </Data> */}
              <Data>
                <Text>Approval Date</Text>
                <p style={{ position: "absolute", right: "270px" }}>
                  Approval Date:- {approvalDate.approval_date}
                </p>
                <DatePicker onChange={changeApprovalDate} />
              </Data>
              <Data>
                <Text>Date Range</Text>
                <p style={{ position: "absolute", right: "570px" }}>
                  Start Date:-{state.start_date}
                </p>
                <p style={{ position: "absolute", right: "400px" }}>
                  End Date:-{state.end_date}
                </p>
                <RangePicker
                  onChange={changeDate}
                  //  defaultValue={new Date(), null}
                />
                {/* <DateRange
                   editableDateInputs={true}
                   onChange={item => setState([item.selection])}
                   moveRangeOnFirstSelection={false}
                   ranges={state}
                   showPreview={true}
                   name="dateRange"
                />  */}
              </Data>
              <Data>
                <Text>Description Type</Text>
                <RadioContainer>
                  <Radio.Group onChange={changeHandler} name="d_type">
                    <Radio
                      type="radio"
                      value={"text"}
                      checked={allValues.d_type == "text"}
                    >
                      Text
                    </Radio>
                    <Radio
                      type="radio"
                      value={"html"}
                      checked={allValues.d_type == "html"}
                    >
                      HTML
                    </Radio>
                  </Radio.Group>
                </RadioContainer>
              </Data>
              <Data>
                <Text>Description Editor</Text>
                {allValues.d_type == "text" ? (
                  <DescriptionBox className="editor-change">
                    <Editor
                      editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange}
                      // onContentStateChange={this.onContentStateChange}
                      // ref="draftRef"
                    />
                  </DescriptionBox>
                ) : (
                  <DescriptionBox className="editor-change">
                    <TextArea
                      style={{ width: "100%", height: "100%" }}
                      size="large"
                      //  autosize={{ minRows: 2, maxRows: 10 }}
                      type="text"
                      value={details}
                      name="details"
                      placeholder="Related Description....."
                      onChange={handleDescChange}
                    />
                  </DescriptionBox>
                )}
              </Data>
              {/* <ButtonContainer>
          <SubmitButton onClick={submitHandler}>
            <p>Submit</p>
          </SubmitButton>
        </ButtonContainer> */}
            </div>
            <div className="col-12 col-lg-12 my-3 text-center ">
              <button
                type="submit"
                className="btn btn-primary"
                // onClick={submitForm}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </Container>
    </Wrapper>
  );
};
export default AddNewActivity;
