import axios from "axios";
import AppConstants from "../../constants/appConstants";

const API = axios.create({
  baseURL: `${AppConstants.baseURL}`,
});
export const addAffiliate = (data) => API.post("/affiliate/addaffiliate", data);
export const updateAffiliateLinkDetails = (data) =>API.post("/affiliate/updateaffiliatelinks", data);
export const addPartners = (data) => API.post("/affiliate/addpartners", data);

export const activateDeactivateAffLinks = (query) => API.get(`/affiliate/activateafflinks?${query}`);
export const updatePartnerAsStore = (data) => API.post("/affiliate/updatepartnerstore", data);
export const SearchPartnersByStores = (data) => API.post("/affiliate/searchpartnersbystore", data);
export const getPartnerMasterData = () =>API.get("/affiliate/getpartnermaster");
export const activatePartnerMaster = (data) =>API.post("/affiliate/activatepartnermaster", data);
export const updateMasterPartnerDetails = (data) => API.post("/affiliate/updatemasterpartner", data);
export const showPartnersAsStore = () => API.get("/affiliate/getpartnerstore");


// =====================POINT POSTER =================================




export const getPartners = () => API.post("/affiliate/getallpartners");  
export const getActivities = () => API.post("/affiliate/getallactivities"); //removed formData 
export const getResponseActivePartners = () => API.post("/affiliate/getresponseactivepartners");  
export const getResponseMasterDataCSV = (data) => API.post("/affiliate/responsemastercsv",data);  

