import React, { useState, useEffect } from 'react'
import { DatePicker, Radio, Input, AutoComplete } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getStoreData } from '../../../../redux/slices/layoutSlice';
import { SearchPartnersByStores, updatePartnerAsStore } from '../../../../redux/slices/affiliateSlice';

const SelectPartners = () => {

    const dispatch = useDispatch()
    const { partnerAsStoresDataRow, activePartnersData } = useSelector((s) => ({ ...s.affiliate }));
    const storesData = useSelector(s => s.layout.data.storeData)
    const dataa = useSelector(s => s)
    const [storeData, setStoreData] = useState({
        store_name: "",
        store_id: ""
    });
    const [partnersData, setPartnersData] = useState({
        partner_id: "",
        partner: "",
        aff_links_id: "",
    });

    const [isDisabled, setIsDisabled] = useState(false);


    const handleAutoComplete = e => {
        setStoreData({ ...storeData, storeName: e });
        let data = {};
        if (storesData.length > 1) {
            for (var i = 0; i < storesData.length; i++) {
                if (e == storesData[i].store_name) {
                    data.store_id = storesData[i].store_id;
                    dispatch(SearchPartnersByStores(data))
                    setStoreData({ ...storeData, store_id: storesData[i].store_id, store_name: storesData[i].store_name })
                }
            }
        }
    }
    const handleAutoComplete1 = e => {
        setPartnersData({ ...partnersData, partner: e })
        if (activePartnersData.length > 1) {
            for (var i = 0; i < activePartnersData.length; i++) {
                if (e == activePartnersData[i].partner) {
                    setPartnersData({ ...partnersData, partner_id: activePartnersData[i].id, partner: activePartnersData[i].partner, aff_links_id: activePartnersData[i].id })
                }
            }
        }
    }

    useEffect(() => {
        dispatch(getStoreData());
        if (partnerAsStoresDataRow.partner) {
            // setIsDisabled(!isDisabled)
            setStoreData({
                ...storeData,
                store_name: partnerAsStoresDataRow.store_name,
                store_id: partnerAsStoresDataRow.store_id
            });
            setPartnersData({
                aff_links_id: partnerAsStoresDataRow.aff_links_id,
                partner_id: partnerAsStoresDataRow.partner_id,
                partner: partnerAsStoresDataRow.partner,
            });

        }

    }, [])

    const submitForm = (e) => {
        e.preventDefault();
        let data = {};
        let data1 = {};
        data1.store_id = storeData.store_id;
        data.store_id = storeData.store_id;
        data.partner_id = partnersData.partner_id;
        data.partner = partnersData.partner;
        data1.aff_links_id = partnersData.aff_links_id;
        dispatch(updatePartnerAsStore(data1));
        // if (isUpdatedData) {
        //     props.updateAffiliateLinkDetails(data);
        // }
        // else {
        //     props.addAffiliate(data);
        // }
    }



    return (
        <>
            <h2 className="my-4">Select Partners and stores</h2>
            <form onSubmit={submitForm}>
                <div className='row'>
                    <div className="col-6 col-lg-6">
                        <div class="mb-3">
                            <label for="" class="form-label">Store Name<span style={{ color: "blue", fontSize: "25px" }}> ({storeData.store_name})</span></label>

                            <AutoComplete
                                style={{ width: "50% !important" }}
                                // value={storeData.store_name}
                                className="auto-complete"
                                dataSource={storesData && storesData.map((item, index) => item.store_name)}
                                onSelect={handleAutoComplete}
                                // onChange={changeHandler}
                                disabled={isDisabled}
                                filterOption={(inputValue, option) =>
                                    option.props.children
                                        .toUpperCase()
                                        .indexOf(inputValue.toUpperCase()) !== -1
                                }
                            />
                        </div>

                    </div>

                    <div className="col-6 col-lg-6">
                        <div class="mb-3">
                            <label for="" class="form-label">Partner Name<span style={{ color: "blue", fontSize: "25px" }}> ({partnersData.partner})</span></label>

                            <AutoComplete
                                style={{ width: "50% !important" }}
                                // value={storeData.store_name}
                                className="auto-complete"
                                dataSource={activePartnersData && activePartnersData.map((item, index) => item.partner)}
                                onSelect={handleAutoComplete1}
                                // onChange={changeHandler}
                                disabled={isDisabled}
                                filterOption={(inputValue, option) =>
                                    option.props.children
                                        .toUpperCase()
                                        .indexOf(inputValue.toUpperCase()) !== -1
                                }
                            />
                        </div>
                    </div>
                    <div className="col-12 col-lg-12 my-3 text-center">
                        <button type="submit" class="btn btn-primary" >Submit</button>
                    </div>
                </div>
            </form>

        </>
    )
}

export default SelectPartners;
