import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Typography, Divider, Input, Radio, Icon } from "antd";

import ClickIdOperations from "./ClickIdOperations";
import CashbackClaimsData from "./CashbackClaimsData";
import AllOrdersCRMData from "./AllOrdersCrmData";
import PerformanceReports from "./PerformaceReport";
import Top10Data from "../crm/Top10Data";

const { Title } = Typography;

const Wrapper = styled.div`
  width: 100%;
`;
const BodyContainer = styled.div`
  overflow: auto;
`;
const Container = styled.div`
  width: 90%;
  margin: 0 auto;
`;

const CRM = (props) => {
  const [storeType, setStoreType] = useState("createStore");
  const changeMenu = (e) => {
    console.log(window.location.pathname);
    setStoreType(window.location.pathname);
  };
  //   const setMainPage = () => {
  //     setStoreType("createStore");
  //   };
  //   const setDisplayType = () => {
  //     setStoreType("addDescription");
  //   };
  //   const setNextAdditionalPage = () => {
  //     setStoreType("additionalStoreData");
  //   };
  //   const categoryType = () => {
  //     setStoreType("category");
  //   };
  //console.log(props, "main");

  // allOrdersCrm,AllOrdersCrmDetails
  return (
    <Wrapper>
      <Title className="text-center" level={3}>
        CRM(Customer Relationship Model){" "}
      </Title>
      <BodyContainer onChange={changeMenu}>
        {window.location.pathname == "/crm/redeemreq" && (
          // <CashbackClaimsData cashbackClaimsData={props.cashbackClaimsData} cashbackClaimsMasterData={props.cashbackClaimsMasterData} infoByClickId={props.infoByClickId} partnerRespByClickId={props.partnerRespByClickId} getAllDateAffClicksId={props.getAllDateAffClicksId} showCashbackClaims={props.showCashbackClaims} CashbackClaimsStatusMaster={props.CashbackClaimsStatusMaster} updateCashbackClaimStatus={props.updateCashbackClaimStatus} getInfoByClickId={props.getInfoByClickId} />
          <CashbackClaimsData />
        )}
        {window.location.pathname == "/crm/searchbyclickid" && (
          <ClickIdOperations
            searchByClickId={props.searchByClickId}
            userInfo={props.userInfo}
            userBalanceInfo={props.userBalanceInfo}
            clicksDataInfo={props.clicksDataInfo}
            orderDetailsInfo={props.orderDetailsInfo}
            redemptionInfo={props.redemption_info}
            SearchByClickIdByDate={props.SearchByClickIdByDate}
            clicksByDate={props.clicksByDate}
            redemptionPaymentInfo={props.redemptionPaymentInfo}
            requestedBalanceInfo={props.requestedBalanceInfo}
          />
        )}

        {window.location.pathname == "/crm/allordersdata" && (
          <AllOrdersCRMData
            // AllOrdersCrmDetails={props.AllOrdersCrmDetails}
            // allOrdersCrm={props.allOrdersCrm}
          />
        )}
        {window.location.pathname == "/crm/performreport" && (
          <PerformanceReports
            // AllOrdersCrmDetails={props.AllOrdersCrmDetails}
            // allOrdersCrm={props.allOrdersCrm}
          />
        )}

        {window.location.pathname == "/crm/top10data" && (
          // <Top10Data pendingPayout={props.pendingPayout} Top10Results={props.Top10Results} top10Orders={props.top10Orders} />
          <Top10Data />
        )}

        {/* pendingPayout ,Top10Results*/}
      </BodyContainer>
    </Wrapper>
  );
};

export default CRM;
