/**
 * Represents Auth Components to handle Login and Signup.
 */
import React, { Component } from "react";
import styled from "styled-components";
import Login from "./login";
import Signup from "./signup";
import Authentication from "../../routes/auth";
import img1 from "../../images/bg1.jpg";

const Wrapper = styled.div`
  width: 100%;
  height: 1024px;
  padding: 10px;
  // background: linear-gradient(to bottom, #00ffff 0%, #006699 100%);
  display: flex;
  justify-content: space-between;
  background-size: 100% 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  width: 45%;
  height: 100px;
  margin-top: 20%;
  > h1 {
    font-size: 30px;
    font-weight: bolder;
    color: darkslategray;
  }
`;

const Body = styled.div`
  display: flex;
  width: 42%;
  flex-direction: column;
  margin-top: 40px;
  > p {
    margin-bottom: 40px;
    font-size: 20px;
    font-weight: bolder;
    color: #000;
  }
`;

class Auth extends Component {
  state = {
    login: true
  };

  methodHandler = () => {
    this.setState({
      login: !this.state.login
    });
  };

  // componentDidMount = () => {
  //   //console.log("did mount called");

  // };

  // componentDidUpdate = () => {

  // };

  cbFuncP = () => {
    //console.log("got profile", this.props);
    const { location } = this.props;
    const { state } = location;
    if (state && state.from) {
      this.props.history.push(state.from);
    }
    // else go to home
    else {
      this.props.history.push("/");
    }

  };

  cbFunc = () => {
    this.props.getProfile(this.cbFuncP);
  };

  render() {
    const { login } = this.state;
    return (
      <Wrapper className="ok1" style={{ backgroundImage: `url(${img1})`, width: "100vw", height: "100vh"}}>
        <Header className="ok2">
          <h1>Welcome to Hyyzo Admin Panel</h1>
        </Header>
        <Body>
          {/* <p>Please Login to Continue</p> */}
          {login ? (
            <Login
              methodHandler={this.methodHandler}
              login={this.props.login}
              cbFunc={this.cbFunc}
            />
          ) : (
            <Signup
              methodHandler={this.methodHandler}
              signup={this.props.signup}
              show_roles={this.props.show_roles}
              showRoles={this.props.showRoles}
            // cbFunc={this.cbFunc}
            // history={this.props.history}
            />
          )}
        </Body>
      </Wrapper>
    );
  }
}

export default Auth;
