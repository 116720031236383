//npm i required with --legacy-peer-deps
import React, { useEffect, useState } from "react";
import {
  Paper,
  Stack,
  Typography,
  Toolbar,
  Box,
  Card,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { getTodoTaskByUser, snoozeTodoTaskByUser, updateTodoStatus } from "../../redux/slices/taskReminderSlice";

const StyledBox = styled((props) => <Box {...props} />)(({ theme }) => ({
  padding: "2px 8px",
  borderRadius: "5px",
  height: "fit-content",
  "& *": {
    display: "inline-block",
  },
  "& .MuiTypography-root:first-of-type": {
    color: "var(--primary-color)",
    opacity: "0.8",
  },
  "& .MuiTypography-root:nth-of-type(2)": {
    color: "var(--bs-gray-700)",
  },
}));
// import Accordion from 'react-bootstrap/Accordion';

function dateConverter(str) {
  // console.log(str, "str")
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  let year = date.getFullYear();
  return `${year}-${mnth}-${day}`;
}
const ShowTask = () => {
  const dispatch = useDispatch();
  const { taskReminderData, upcomingResults, completeTaskReminderData, task_statusData } = useSelector(s => s.taskReminder);
  // Dialog input
  const [dialogOpen, setDialogOpen] = useState(false);

  //state for dialog open
  const [item, setItem] = useState({});

  const handleOpen = (val) => {
    setItem(val);
    setDialogOpen(true);
    // console.log(state, "statein onclick");
    // console.log(id, "id");
  };

  const handleClose = () => {
    setItem({});
    setDialogOpen(false);
  };

  // const [status, setStatus] = useState(taskReminderData && taskReminderData[0].status);
  const cbFunc = () => {
    dispatch(getTodoTaskByUser());
  };
  const handleChange = async (event, val) => {
    // console.log(event.target.value, val, "val from");
    // setStatus(event.target.value);
    let data = {};
    data.id = val;
    data.status = event.target.value;
    await dispatch(updateTodoStatus(data))
    cbFunc
  };

  const handleSnooze = async (val) => {
    // console.log("handle snooze working correctly", val);
    let modified_date = Date.now(val.reminder_date) + 3600 * 1000 * 24;
    let remidate = new Date(modified_date);
    let data = {};
    data.id = val.id;
    data.snooze_date = dateConverter(remidate);
    await dispatch(snoozeTodoTaskByUser(data));
    cbFunc();
    // console.log(dateConverter(remidate), "reminder data =======>");
  };

  useEffect(() => {
    dispatch(getTodoTaskByUser());
    // getTodoTaskByUser();
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1%",
          marginTop: "30px",
        }}
      >
        {/*====================================== current task==================== */}

        <Paper elevation={3} sx={{ width: "33%" }}>
          <Stack spacing={3} direction="row">
            <Toolbar
              mt={3}
              sx={{
                padding: "1.2rem",
                borderBottom: "2px solid var(--primary-color)",
              }}
            >
              <Typography
                sx={{
                  flex: "1 1 100%",
                  color: "var(--primary-color)",
                  fontWeight: "bold",
                }}
                variant="h5"
                id="tableTitle"
              >
                current Tasks
              </Typography>
            </Toolbar>
          </Stack>

          <Box
            p={4}
            pt={1}
            className="d-grid"
            sx={{
              gridTemplateColumns: "repeat(auto-fill,minmax(300px,1fr))",
              gap: "24px",
            }}
          >
            {taskReminderData &&
              taskReminderData.map((item) => {
                if (item.status != "Completed")
                  return (
                    <Card
                      sx={{
                        minWidth: "fit-content",
                        border: "2px solid var(--bs-border-color)",
                      }}
                      onClick={() => handleOpen(item)}
                    >
                      <CardContent>
                        <Stack spacing={1}>
                          <StyledBox>
                            <Typography>
                              <b>Assinged By :&nbsp;</b>
                            </Typography>
                            <Typography textTransform="capitalize">
                              <b>
                                {item.name && item.name} (
                                {item.assigned_by && item.assigned_by})
                              </b>
                            </Typography>
                          </StyledBox>

                          <StyledBox>
                            <Typography>
                              <b>Title :&nbsp;</b>
                            </Typography>
                            <Typography textTransform="capitalize">
                              <b>{item.title && item.title}</b>
                            </Typography>
                          </StyledBox>
                          {/* <StyledBox>
                        <Typography >
                          <b>ID :&nbsp;</b>
                        </Typography>
                        <Typography >
                          <b>{item.id && item.id}</b>
                        </Typography>
                      </StyledBox> */}
                          <StyledBox>
                            <Typography>
                              <b>Description :&nbsp;</b>
                            </Typography>
                            <Typography>
                              <b>{item.description && item.description}</b>
                            </Typography>
                          </StyledBox>
                          {/* <StyledBox>
                        <Typography >
                          <b>Recurrence :&nbsp;</b>
                        </Typography>
                        <Typography >
                          <b>{item.recurrence && item.recurrence}</b>
                        </Typography>
                      </StyledBox> */}
                          {/* <StyledBox>
                          <Typography >
                            <b>Reminder Date :&nbsp;</b>
                          </Typography>
                          <Typography >
                            <b>{dateConverter(item.reminder_date && item.reminder_date)}</b>
                          </Typography>
                        </StyledBox> */}

                          <StyledBox>
                            <Typography>
                              <b>Deadline Date :&nbsp;</b>
                            </Typography>
                            <Typography>
                              <b>
                                {dateConverter(
                                  item.deadline_date && item.deadline_date
                                )}
                              </b>
                            </Typography>
                          </StyledBox>
                          {/* 
                        <StyledBox>
                          <Typography >
                            <b>Status :&nbsp;</b>
                          </Typography>
                          <Typography textTransform="capitalize">
                            <b style={{ display: "flex" }}>
                              {item.status && item.status}
                              <FormControl fullWidth>
                                <Select
                                  // value={item.status && item.status}
                                  defaultValue={item.status}
                                  onChange={(e, val) => handleChange(e, item.id)}
                                  style={{ marginLeft: "5px" }}
                                >
                                  {task_statusData && task_statusData.map((item) => {
                                    if (item.status != "Closed")
                                      return (<MenuItem value={item.id}>{item.status}</MenuItem>)
                                  })}

                                </Select>
                              </FormControl>
                            </b>
                          </Typography>
                        </StyledBox> */}
                          {/* <StyledBox>
                          <Button variant="contained" onClick={() => handleSnooze(item)} color='info' >
                            <strong>Snooze Task Reminder</strong>
                          </Button>
                        </StyledBox> */}
                        </Stack>
                      </CardContent>
                    </Card>
                  );
              })}
          </Box>
        </Paper>

        {/*====================================== upcoming task==================== */}

        <Paper elevation={3} sx={{ width: "33%" }}>
          <Stack spacing={3} direction="row">
            <Toolbar
              mt={3}
              sx={{
                padding: "1.2rem",
                borderBottom: "2px solid var(--primary-color)",
              }}
            >
              <Typography
                sx={{
                  flex: "1 1 100%",
                  color: "var(--primary-color)",
                  fontWeight: "bold",
                }}
                variant="h5"
                id="tableTitle"
              >
                Upcoming Tasks
              </Typography>
            </Toolbar>
          </Stack>
          <Box
            p={4}
            pt={1}
            className="d-grid"
            sx={{
              gridTemplateColumns: "repeat(auto-fill,minmax(300px,1fr))",
              gap: "24px",
            }}
          >
            {upcomingResults &&
              upcomingResults.map((item) => {
                if (item.status != "Completed")
                  return (
                    <Card
                      sx={{
                        minWidth: "fit-content",
                        border: "2px solid var(--bs-border-color)",
                      }}
                    >
                      <CardContent>
                        <Stack spacing={1}>
                          <StyledBox>
                            <Typography>
                              <b>Assinged By :&nbsp;</b>
                            </Typography>
                            <Typography textTransform="capitalize">
                              <b>
                                {item.name && item.name} (
                                {item.assigned_by && item.assigned_by})
                              </b>
                            </Typography>
                          </StyledBox>
                          <StyledBox>
                            <Typography>
                              <b>Title :&nbsp;</b>
                            </Typography>
                            <Typography textTransform="capitalize">
                              <b>{item.title && item.title}</b>
                            </Typography>
                          </StyledBox>
                          {/* <StyledBox>
                        <Typography >
                          <b>ID :&nbsp;</b>
                        </Typography>
                        <Typography >
                          <b>{item.id && item.id}</b>
                        </Typography>
                      </StyledBox> */}
                          <StyledBox>
                            <Typography>
                              <b>Description :&nbsp;</b>
                            </Typography>
                            <Typography>
                              <b>{item.description && item.description}</b>
                            </Typography>
                          </StyledBox>
                          {/* <StyledBox>
                        <Typography >
                          <b>Recurrence :&nbsp;</b>
                        </Typography>
                        <Typography >
                          <b>{item.recurrence && item.recurrence}</b>
                        </Typography>
                      </StyledBox> */}
                          <StyledBox>
                            <Typography>
                              <b>Reminder Date :&nbsp;</b>
                            </Typography>
                            <Typography>
                              <b>
                                {dateConverter(
                                  item.reminder_date && item.reminder_date
                                )}
                              </b>
                            </Typography>
                          </StyledBox>

                          <StyledBox>
                            <Typography>
                              <b>Deadline Date :&nbsp;</b>
                            </Typography>
                            <Typography>
                              <b>
                                {dateConverter(
                                  item.deadline_date && item.deadline_date
                                )}
                              </b>
                            </Typography>
                          </StyledBox>
                        </Stack>
                      </CardContent>
                    </Card>
                  );
              })}
          </Box>
        </Paper>

        {/*====================================== Completed task==================== */}

        <Paper elevation={3}>
          <Stack spacing={3} direction="column">
            <Toolbar
              mt={3}
              sx={{
                padding: "1.2rem",
                borderBottom: "2px solid var(--primary-color)",
              }}
            >
              <Typography
                sx={{
                  flex: "1 1 100%",
                  color: "var(--primary-color)",
                  fontWeight: "bold",
                }}
                variant="h5"
                id="tableTitle"
              >
                Completed Tasks
              </Typography>
            </Toolbar>
          </Stack>
          <Box
            p={4}
            pt={1}
            className="d-grid"
            sx={{
              gridTemplateColumns: "repeat(auto-fill,minmax(300px,1fr))",
              gap: "24px",
            }}
          >
            {completeTaskReminderData &&
              completeTaskReminderData.map((item) => {
                return (
                  <Card
                    sx={{
                      minWidth: "fit-content",
                      border: "2px solid var(--bs-border-color)",
                    }}
                  >
                    <CardContent>
                      <Stack spacing={1}>
                        <StyledBox>
                          <Typography>
                            <b>Assinged By :&nbsp;</b>
                          </Typography>
                          <Typography textTransform="capitalize">
                            <b>
                              {item.assigned_name && item.assigned_name} (
                              {item.assigned_by && item.assigned_by})
                            </b>
                          </Typography>
                        </StyledBox>
                        <StyledBox>
                          <Typography>
                            <b>Completed By :&nbsp;</b>
                          </Typography>
                          <Typography textTransform="capitalize">
                            <b style={{ display: "flex" }}>
                              {item.completed_name && item.completed_name}(
                              {item.completed_by && item.completed_by})
                            </b>
                          </Typography>
                        </StyledBox>
                        <StyledBox>
                          <Typography>
                            <b> Task ID :&nbsp;</b>
                          </Typography>
                          <Typography>
                            <b>{item.id && item.id}</b>
                          </Typography>
                        </StyledBox>
                        <StyledBox>
                          <Typography>
                            <b>Title :&nbsp;</b>
                          </Typography>
                          <Typography textTransform="capitalize">
                            <b>{item.title && item.title}</b>
                          </Typography>
                        </StyledBox>

                        <StyledBox>
                          <Typography>
                            <b>Deadline Date :&nbsp;</b>
                          </Typography>
                          <Typography>
                            <b>
                              {item.completed_date &&
                                dateConverter(item.completed_date)}
                            </b>
                          </Typography>
                        </StyledBox>
                        <StyledBox>
                          <Typography>
                            <b>Status :&nbsp;</b>
                          </Typography>
                          <Typography textTransform="capitalize">
                            <b style={{ display: "flex" }}>
                              {item.status && item.status}
                            </b>
                          </Typography>
                        </StyledBox>
                      </Stack>
                    </CardContent>
                  </Card>
                );
              })}
          </Box>
        </Paper>

        {/* //===============================Dialog Box========================== */}

        <Dialog open={dialogOpen} onClose={handleClose}>
          <DialogTitle>
            <b>Dialog Box User</b>
          </DialogTitle>
          <Divider sx={{ borderColor: "black" }} />
          <DialogContent>
            <Card
              sx={{
                minWidth: "fit-content",
                border: "2px solid var(--bs-border-color)",
              }}
              onClick={() => handleOpen(item)}
            >
              <CardContent>
                <Stack spacing={1}>
                  <StyledBox>
                    <Typography>
                      <b>Assinged By :&nbsp;</b>
                    </Typography>
                    <Typography textTransform="capitalize">
                      <b>
                        {item.name && item.name} (
                        {item.assigned_by && item.assigned_by})
                      </b>
                    </Typography>
                  </StyledBox>
                  <StyledBox>
                    <Typography>
                      <b>Title :&nbsp;</b>
                    </Typography>
                    <Typography textTransform="capitalize">
                      <b>{item.title && item.title}</b>
                    </Typography>
                  </StyledBox>
                  <StyledBox>
                    <Typography>
                      <b>ID :&nbsp;</b>
                    </Typography>
                    <Typography>
                      <b>{item.id && item.id}</b>
                    </Typography>
                  </StyledBox>
                  <StyledBox>
                    <Typography>
                      <b>Description :&nbsp;</b>
                    </Typography>
                    <Typography>
                      <b>{item.description && item.description}</b>
                    </Typography>
                  </StyledBox>
                  <StyledBox>
                    <Typography>
                      <b>Reminder Date :&nbsp;</b>
                    </Typography>
                    <Typography>
                      <b>
                        {dateConverter(
                          item.reminder_date && item.reminder_date
                        )}
                      </b>
                    </Typography>
                  </StyledBox>

                  <StyledBox>
                    <Typography>
                      <b>Deadline Date :&nbsp;</b>
                    </Typography>
                    <Typography>
                      <b>
                        {dateConverter(
                          item.deadline_date && item.deadline_date
                        )}
                      </b>
                    </Typography>
                  </StyledBox>

                  <StyledBox>
                    <Typography>
                      <b>Status :&nbsp;</b>
                    </Typography>
                    <Typography textTransform="capitalize">
                      <b style={{ display: "flex" }}>
                        {item.status && item.status}
                        {item.status != "Completed" && (
                          <FormControl fullWidth>
                            <Select
                              // value={item.status && item.status}
                              defaultValue={item.status}
                              onChange={(e, val) => handleChange(e, item.id)}
                              style={{ marginLeft: "5px" }}
                            >
                              {task_statusData &&
                                task_statusData.map((item) => {
                                  if (item.status != "Closed")
                                    return (
                                      <MenuItem value={item.id}>
                                        {item.status}
                                      </MenuItem>
                                    );
                                })}
                            </Select>
                          </FormControl>
                        )}
                      </b>
                    </Typography>
                  </StyledBox>
                  <StyledBox>
                    <Button
                      variant="contained"
                      onClick={() => handleSnooze(item)}
                      color="info"
                    >
                      <strong>Snooze Task Reminder</strong>
                    </Button>
                  </StyledBox>
                </Stack>
              </CardContent>
            </Card>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};
export default ShowTask;
