import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Typography,
  Modal,
  Button,
  Input,
  AutoComplete
} from "antd";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import EditImageModal from "./editImageModal";
import AddImage from "./addImage";
import AppConstants from "../../../constants/appConstants";
import { useDispatch, useSelector } from "react-redux";
import { getAllImages, getImageType, saveShowImage, searchImage, updateImage, deleteImage } from "../../../redux/slices/gallerySlice";
const { Title, Text } = Typography;

const Wrapper = styled.div`
  width: 100%;
`;

const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

// const ImageContainer = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: row wrap;
//   justify-content: space-between;
//   margin-top: 50px;
//   padding: 20px;
// `;

const ImageHolder = styled.div`
  width: 140px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #000;
  border-radius: 10px;
  > img {
    width: 100%;
    border-radius: 10px;
  }
`;

const Data = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  > label {
    width: 30%;
  }
  > span {
    font-size: 18px;
    font-weight: bolder;
  }
  > input[type="text"] {
    width: 50%;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SearchButton = styled.div`
  width: 160px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #0000ff;
  background: #fff;
  border-radius: 15px;
  :hover {
    background: #0000ff;
    border: 1px solid #fff;
    > p {
      color: #fff;
    }
  }
  > p {
    font-size: 16px;
    font-weight: bolder;
    color: #0000ff;
    margin-bottom: 0px;
  }
`;

const SearchImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eee;
  margin-top: 50px;
  padding: 20px;
`;

const ImageMainContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  width: 100%;
  height: 700px;
  overflow: auto;
`;
const ImageContainer = styled.div`
  display: inline-block;
  flex-direction: row;
  padding: 20px;
  flex-flow: row wrap;
  justify-content: space-between;
  height: 200px;
  > img {
    width: 150px;
    // border: ${(props) => props.active ? "2px solid #00ff00" : "2px solid #000"};
  }
`;
const Default = styled.div`
  width: 0px;
  height: 0px;
`;

const DeleteButton = styled.div`
  width: 100%;
  display: flex;
  padding: 5px;
  border: 2px solid #000;
  border-radius: 10px;
  margin-top: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #fff;
  > p {
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 0px;
  }
`;

const FilterBarContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  border: 1px solid #eee;
  box-shadow: 0px 4px 4px 0px #bbb;
  align-items: center;
`;

const AddImageButton = styled.div`
  width: 120px;
  padding: 5px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #c9356e;
  cursor: pointer;
  margin-left: 15px;
  border-radius: 5px;
  > p {
    font-size: 16px;
    font-weight: bolder;
    color: #fff;
    margin-bottom: 0px;
  }
`;

const SearchButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  > input {
    width: 30%;
    margin-right: 10px;
  }
`;

const LoadMoreContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  > p {
    font-size: 14px;
    margin-bottom: 0px;
  }
  > div {
    width: 200px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #c9356e;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 15px;
    > p {
      color: #fff;
      font-size: 14px;
      margin-bottom: 0px;
    }
  }
`;

const BackButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  > div {
    width: 100px;
    height: 30px;
    border: 1px solid #bbb;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
    border-radius: 5px;
    > p {
      font-size: 14px;
      color: #000;
      margin-bottom: 0px;
      font-weight: bolder;
    }
  }
`;

const ShowImages = (props) => {
  const dispatch = useDispatch();
  const allImages = useSelector((state) => state.gallery.data);
  let getAllImagesData = allImages.getAllImagesData;
  let getAllImagesLimit = allImages.getAllImagesLimit;
  let getAllImagesTotal = allImages.getAllImagesTotal;

  const imageTypeData = useSelector((state) => state.gallery.data.getImageType);

  const [limit, setLimit] = useState(0);
  const [imgForm, setImgform] = useState(false);
  const [drop_type, setDropType] = useState("Select Type");
  const [imageData, setImageData] = useState({
    alt_text: "",
    url: "",
    title: "",
    description: "",
    id: "",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddImage, setIsAddImage] = useState(false);

  useEffect(() => {
    let newLimit = limit + 40;
    dispatch(getAllImages(newLimit));
    dispatch(getImageType());
  }, []);
  const handleSelect = (e) => {
    setDropType(e);
  };
  const handleImage = (data) => {
    setImgform(true);
    setImageData({
      ...imageData,
      alt_text: data.alt_text,
      url: data.url,
      title: data.title,
      id: data.id,
    });
    showModal();
  };

  const handleChange = (e) => {
    setImageData({ ...imageData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = new FormData();
    data.append("alt_text", imageData.alt_text);
    data.append("url", imageData.url);
    data.append("title", imageData.title);
    data.forEach((e) => console.log(e));
    dispatch(saveShowImage(data));
    setImageData({ ...imageData, alt_text: "", url: "", title: "" });
    alert("Details updated");
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    let data = {};
    data.id = imageData.id;
    data.title = imageData.title;
    data.alt_text = imageData.alt_text;
    data.description = imageData.description;
    dispatch(updateImage(data));
    callBackFunc();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleLoadMore = () => {
    if (allImages) {
      let newLimit = getAllImagesLimit + 40;
      dispatch(getAllImages(newLimit));
    }
  };

  const handleSearch = (e) => {
    let data = {};
    data.term = e.target.value;
    dispatch(searchImage(data));
  };

  const handleDelete = (url) => {
    let data = {};
    data.url = url;
    dispatch(deleteImage(data));
    callBackFunc();
  };

  const callBackFunc = () => {
    let newLimit = limit + 40;
    dispatch(getAllImages(newLimit));
    setIsModalVisible(false);
  };

  const backHandler = () => {
    setIsAddImage(false);
  };

  const handleAutoChange = (e) => {
    setDropType("Select Type");
  };

  return (
    <Wrapper>
      <Modal
        title="Edit Image"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
      >
        {imgForm ? (
          <EditImageModal
            handleChange={handleChange}
            imageData={imageData}
            handleDelete={handleDelete}
          />
        ) : (
          <Default />
        )}
      </Modal>
      {isAddImage && (
        <BackButton onClick={backHandler}>
          <div>
            <p>Back</p>
          </div>
        </BackButton>
      )}
      <FilterBarContainer>
        {isAddImage ? (
          <AddImage
            dimensionData={props.dimensionData}
            callBackFunc={callBackFunc}
            storeData={props.storeData}
          />
        ) : (
          <>
            <AddImageButton onClick={() => setIsAddImage(!isAddImage)}>
              <p>+ Add New</p>
            </AddImageButton>
            <AutoComplete
              disabled={false}
              placeholder="Select Type here"
              style={{ marginLeft: "20px", width: "200px" }}
              dataSource={
                imageTypeData && imageTypeData.map((item, index) => item.type)
              }
              onSelect={handleSelect}
              onChange={handleAutoChange}
            />
            <SearchButtonContainer>
              <Input
                type="text"
                placeholder="Search image here"
                onChange={handleSearch}
              />
            </SearchButtonContainer>
          </>
        )}
      </FilterBarContainer>
      <ImageMainContainer>
        <ImageContainer>
          {drop_type == "Select Type"
            ? allImages &&
            getAllImagesData.map((item, index) => (
              <ImageContainer>
                <img
                  src={`${AppConstants.imageURL}${item.url}`}
                  alt="image"
                  onClick={() => handleImage(item)}
                />
              </ImageContainer>
            ))
            : allImages &&
            getAllImagesData.map((item, index) =>
              item.image_type == drop_type ? (
                <ImageContainer>
                  <img src={`${AppConstants.imageURL}${item.url}`} alt="image" />
                </ImageContainer>
              ) : (
                <Default />
              )
            )}
        </ImageContainer>
      </ImageMainContainer>
      <LoadMoreContainer>
        <p style={{ position: "inherit" }}>
          Showing {allImages && getAllImagesLimit} of{" "}
          {allImages && getAllImagesTotal}
        </p>
        {allImages && getAllImagesTotal > limit && (
          <div onClick={handleLoadMore}>
            <p>Load More</p>
          </div>
        )}
      </LoadMoreContainer>
    </Wrapper>
  );
};

export default ShowImages;
