import axios from "axios";
import AppConstants from "../../constants/appConstants";

const API = axios.create({
    baseURL: `${AppConstants.baseURL}`,
});


export const addTodoTaskReminder = (data) => API.post("/auth/addtaskremind", data);
export const getTodoTaskByUser = () => API.get("/auth/showtodotask" );
export const updateTodoTaskRemind = (data) => API.post("/auth/updatetaskremind", data);
export const deleteTodoTaskRemind = (data) => API.post("/auth/deletetaskremind", data);
export const updateTodoStatus = (data) => API.post("/auth/updatetodostatus", data);
export const snoozeTodoTaskByUser = (data) => API.post("/auth/snoozetodotask", data);
export const showRecurrenceTask = (data) => API.post("/auth/showrecurrencetask", data);
export const showTodoTaskAssignedAdmin = () => API.get("/auth/taskassignbyadmin" );