import React, { Fragment, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Popconfirm, Typography, message, Divider } from 'antd';
import { useNavigate } from "react-router-dom";
import queryString from 'query-string';
import { useDispatch, useSelector } from "react-redux";
import { showNews, activatedNewsData } from "../../../redux/slices/newsSlice";

const ShowNews = (props) => {
    const dispatch = useDispatch()
    const { newsData } = useSelector((state) => ({...state.news}));
    // let history = useHistory();  //useHistory is being deprecated in react-router v6 or above
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(showNews());
    }, []);

    const edit = (e, value) => {
        let data = {};
        data.n_id = value[2];
        data.link = value[3];
        data.image = value[4];
        data.img_id = value[5];
        // props.gotNewsDataRow(data);
        navigate("/news/addnews", {state: data});
    }
    const cancel = () => {
        //console.log("Cancelled");
    }

    const callbackFunc = () => {
        dispatch(showNews());
    }

    const activated = (e, value) => {
        let data = {};
        data.n_id = value[2];
        data.type = "activate";
        let query = queryString.stringify(data);
        dispatch(activatedNewsData(query), callbackFunc);
        message.success("News data Activated Successfully");
    }
    const deactivated = (e, value) => {
        let data = {};
        data.n_id = value[2];
        data.type = "deactivate";
        let query = queryString.stringify(data);
        dispatch(activatedNewsData(query), callbackFunc);
        message.success("News data Deactivated Successfully");
    };

    const columns = [
        {
            name: "Action",
            label: "Action",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div>
                        <Popconfirm
                            title="activate the activity"
                            onConfirm={e => activated(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#" style={{ padding: 7, fontWeight: "bolder", color: "#078101", border: "1px solid #aaa", borderRadius: "5px", boxShadow: "2px 2px 0px 0px", cursor: "pointer", marginBottom: "10px" }}>Activate</a>
                        </Popconfirm>
                        <Divider type="vertical"></Divider>
                        <Popconfirm
                            title="Deactivated"
                            onConfirm={e => deactivated(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#" style={{ padding: 5, fontWeight: "bolder", color: "#FF1502", border: "1px solid #aaa", borderRadius: "5px", boxShadow: "2px 2px 0px 0px", cursor: "pointer" }}>Deactivate</a>
                        </Popconfirm>
                    </div>
                )
            }
        },
        {
            name: "opertaion",
            label: "Operation",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div>
                        <Popconfirm
                            title="Edit this task?"
                            onConfirm={e => edit(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#">Edit</a>
                        </Popconfirm>
                    </div>
                )
            }
        },
        {
            name: "n_id",
            label: "News ID",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "link",
            label: "News Link",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "url",
            label: "Image",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => <img
                    src={`${value}`} alt="image" style={{ width: "100px" }} />
            }
        },
        {
            name: "image",
            label: "Image Id",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "action_taken",
            label: "Action Taken",
            options: {
                filter: true,
                sort: false,
            }
        },


        {
            name: "active",
            label: "Active",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "created",
            label: "Created",
            options: {
                filter: true,
                sort: false,
            }
        }
    ];
    const options = {
        filterType: 'link',
    };

    return (
        <Fragment>
            <MUIDataTable
                title={"Show Description"}
                data={newsData && newsData}
                columns={columns}
                options={options}
            />
        </Fragment>
    );
}
export default ShowNews;