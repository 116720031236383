import React, { useState, useEffect, forwardRef } from "react";
import { Radio, AutoComplete } from "antd";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  getStoreData,
  showExtStoreDomain,
  addExtDomainStore,
} from "../../../redux/slices/manageStoreSlice";
import Datatable from "../../Datatable/Datatable";

const MainDiv = styled.div`
  button {
    background: linear-gradient(
        259deg,
        rgb(236, 94, 58) 0%,
        rgb(249, 186, 99) 100%
      )
      0% 0% no-repeat padding-box padding-box transparent;
    padding: 7px 20px;
    border-radius: 20px;
    float: right;
  }
`;

const ExtensionStoreDomain = () => {
  const dispatch = useDispatch();
  const state = useSelector((s) => s.manageStores);
  console.log(state, "state");
  const { extStoreDomainData, storedata } = useSelector((s) => s.manageStores);

  console.log(extStoreDomainData, "extStoreDomainData");
  const [data, setData] = useState([]);

  const [storeType, setStoreType] = useState("showdom");

  const [dataToSend, setDataToSend] = useState({
    domain_name: "",
    subdomain_id: "",
    subdomain_store: "",
    subdomain: "",
    match_pattern: "",
    cart_page: "",
    active: 1,
  });

  const changeTab = () => {
    setStoreType("adddom");
  };
  const changeTab2 = () => {
    setStoreType("showdom");
  };

  useEffect(() => {
    dispatch(showExtStoreDomain());
    dispatch(getStoreData());
  }, []);

  useEffect(() => {
    setData(extStoreDomainData);
  }, []);

  const tableColumns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "store_name",
      label: "Store Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "domain_name",
      label: "Domain Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "subdomain_id",
      label: "subdomain_id",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "subdomain",
      label: "subdomain",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "match_pattern",
      label: "Match Pattern",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "cart_page",
      label: "Cart Page",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "instered_on",
      label: "Instered On",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "updated_on",
      label: "Updated On",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const cbfunc = () => {
    dispatch(showExtStoreDomain());
  };

  const handleAutoComplete = (e) => {
    setDataToSend({ ...dataToSend, subdomain_store: e });
    if (storedata.length > 0) {
      for (var i = 0; i < storedata.length; i++) {
        if (e == storedata[i].store_name) {
          setDataToSend({
            ...dataToSend,
            subdomain_id: storedata[i].store_id,
            subdomain_store: storedata[i].store_name,
          });
        }
      }
    }
  };

  const chandleHandler2 = (e) => {
    setDataToSend({ ...dataToSend, [e.target.name]: e.target.value });
  };
  const submitForm = (e) => {
    e.preventDefault();

    const updatedData = { ...data };

    if (dataToSend.domain_name)
      updatedData.domain_name = dataToSend.domain_name;
    if (dataToSend.subdomain_id)
      updatedData.subdomain_id = dataToSend.subdomain_id;
    if (dataToSend.subdomain) updatedData.subdomain = dataToSend.subdomain;
    if (dataToSend.match_pattern)
      updatedData.match_pattern = dataToSend.match_pattern;
    if (dataToSend.cart_page) updatedData.cart_page = dataToSend.cart_page;
    if (dataToSend.active) updatedData.active = dataToSend.active;

    dispatch(addExtDomainStore(updatedData));
  };

  return (
    <>
      <MainDiv className="row">
        <div className="col-12 col-lg-12">
          {storeType == "showdom" && (
            <button onClick={changeTab}>Add Domain deatils</button>
          )}
          {storeType == "adddom" && (
            <button onClick={changeTab2}>Show Domain Details</button>
          )}
        </div>
      </MainDiv>
      {storeType == "adddom" && (
        <form onSubmit={submitForm}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-6 col-md-6">
                <div className="mb-3">
                  <label for="" className="form-label">
                    Domain Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="domain_name"
                    onChange={chandleHandler2}
                    value={dataToSend.domain_name}
                    required={true}
                  />
                </div>
                <div className="mb-3">
                  <label for="" className="form-label w-75">
                    Domain ID
                  </label>
                  <AutoComplete
                    required={true}
                    className="auto-complete form-control"
                    dataSource={
                      storedata &&
                      storedata.map((item, index) => item.store_name)
                    }
                    onSelect={handleAutoComplete}
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                </div>
                <div className="mb-3">
                  <label for="" className="form-label">
                    subdomain
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="subdomain"
                    onChange={chandleHandler2}
                    value={dataToSend.subdomain}
                    required={true}
                  />
                </div>
                <div className="mb-3">
                  <label for="" className="form-label">
                    match pattern
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="match_pattern"
                    onChange={chandleHandler2}
                    value={dataToSend.match_pattern}
                  />
                </div>
                <div className="mb-3">
                  <label for="" className="form-label">
                    cart_page
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="cart_page"
                    onChange={chandleHandler2}
                    value={dataToSend.cart_page}
                  />
                </div>
                <div className="mb-3">
                  <Radio.Group
                    onChange={chandleHandler2}
                    name="active"
                    value={dataToSend.active}
                  >
                    <Radio
                      value={1}
                      type="radio"
                      checked={dataToSend.active == 1}
                    >
                      Active
                    </Radio>
                    <Radio
                      value={0}
                      type="radio"
                      checked={dataToSend.active == 0}
                    >
                      Not Active
                    </Radio>
                  </Radio.Group>
                </div>
              </div>

              <div className="col-12 col-lg-12 my-3 ">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
      {storeType == "showdom" && (
        <Datatable
          name="Download List"
          columns={tableColumns}
          data={data && data}
        />
      )}
    </>
  );
};

export default ExtensionStoreDomain;
