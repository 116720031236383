import ActionTypes from "../constants/actionTypes";
import initialState from "../stores/initialState";

export default (state = initialState.auth, action) => {
  switch (action.type) {
    case ActionTypes.GOT_PROFILE:
      return {
        ...state,
        user: action.payload.data
      };
    // case ActionTypes.GOT_ALL_USER:
    //   return {
    //     ...state,
    //     allUser: action.payload
    //   };
    case ActionTypes.LOGOUT:
      return {
        ...state,
        user: null
      };
    case ActionTypes.GOT_NEW_USERS_DETAILS:
      return {
        ...state,
        newUserDetails: action.payload
      };
    case ActionTypes.GOT_USER_LOG_CLICKS_DETAILS:
      return {
        ...state,
        userlogClicks: action.payload
      };
    case ActionTypes.GOT_MODULES_USERS_DETAILS:
      return {
        ...state,
        modules_by_users: action.payload
      };

    case ActionTypes.GOT_SHOW_ROLES_DATA:
      return {
        ...state,
        show_roles: action.payload.data,
        module_data: action.payload.modules_data
      };

    case ActionTypes.GOT_USERTYPE_MODULE_DATA:
      return {
        ...state,
        userTypeModules: action.payload.data,
        // module_data:action.payload.modules_data
      };
    case ActionTypes.GOT_ALL_PANEL_USERS:
      return {
        ...state,
        allPanelUsersData: action.payload.data,
        allRolesData: action.payload.roleUsers
        // module_data:action.payload.modules_data
      };
    case ActionTypes.GOTA_ALL_PERMISSION_MODULE:
      return {
        ...state,
        permssionUserByModules: action.payload.data,
        excludePermissionModules: action.payload.userExcludedRole,
        includePermissionModules: action.payload.userIncludedRoles,
        includedarray: action.payload.includedarray,
        excludedarray: action.payload.excludedarray
        // module_data:action.payload.modules_data
      };


    // case ActionTypes.GOT_USER_MODULES:
    // return {
    //   ...state,
    //   userModules: action.payload.data
    // };
    default:
      return state;
  }
};
