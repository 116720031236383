import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Card from "react-bootstrap/Card";
import { FormControl, InputLabel } from "@material-ui/core";
import Selects from "react-select";
import { Select } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import UserRoleManagementChange from "./UserRoleManagementChange";
import { useDispatch, useSelector } from "react-redux";
import { showRoles } from "../../redux/slices/profileSlice";
import { addModulePermission, allPermissionByUser, getAllPanelUsers } from "../../redux/slices/userRoleManagementSlice";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const UserRoleManagement = (props) => {
  const dispatch = useDispatch();
  const { userRoles, userRoleManagement, addModulePermissionData } = useSelector((state)=> ({...state.userRoleManagement.data}));
  const state = useSelector(state => state);
  let allPanelUsersData = state.userRoleManagement.data.getAllPanelUsers;
  let show_roles = state.userRoleManagement.data.showRoles

  let excludePermissionModules = state.userRoleManagement.data.allPermissionByUser.userExcludedRole
  let includePermissionModules = state.userRoleManagement.data.allPermissionByUser.userIncludedRoles


  const classes = useStyles();

  const [role, setRole] = useState({
    id: "",
    user_type: "",
  });
  const [tab, setTab] = useState(false);
  const [userData, setUserData] = useState({
    id: "",
    username: "",
    role: "",
  });
  useEffect(() => {
    dispatch(getAllPanelUsers());
    dispatch(allPermissionByUser());
    dispatch(showRoles());

  }, []);
  let filteredArray = [];

  const handleChange = (value) => {
    setRole({ ...role, user_type: value });
    if (show_roles.length > 0) {
      for (var i = 0; i < show_roles.length; i++) {
        if (value == show_roles[i].user_type) {
          setRole({
            ...role,
            id: show_roles[i].id,
            user_type: show_roles[i].user_type,
          });
        }
      }
    }
    filteredArray.push(show_roles && show_roles.filter((item) => (
      item.user_type === value.user_type
    )))
  };

  const handleClick = (event) => {
    let clickedId = event.currentTarget.id;
    let clickedName = event.currentTarget.getAttribute("username");
    let clickedRole = event.currentTarget.role;

    let data = {};
    data.user_id = clickedId;
    data.role = clickedRole;
    dispatch(allPermissionByUser(data))

    setUserData({
      ...userData,
      username: clickedName,
      id: clickedId,
      role: clickedRole,
    });

    setTab(true);
  };

  return (
    <Wrapper>
      {!tab && (
        <div className="container-fluid">
          <div
            className="selectctrl"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ marginBottom: "20px" }}
            >
              {/* <InputLabel htmlFor="outlined-age-native-simple">Role</InputLabel> */}

              <Select placeholder="select role" onChange={handleChange}>
                <Option value='all'>All Roles</Option>
                {show_roles &&
                  show_roles.map((item, index) => {
                    return (
                      <Option value={item.user_type} key={index.toString()}>{item.user_type}</Option>
                    );
                  })}
              </Select>
            </FormControl>
          </div>

          <div className="row">
            {allPanelUsersData &&
              allPanelUsersData.map((data, index) => {
                if (
                  role &&
                  role.user_type !== "all" &&
                  role.user_type !== "" &&
                  role.user_type != data.role
                ) {
                  return <></>;
                }
                return (
                  <div
                    className="col-md-3"
                    style={{ marginBottom: "20px" }}
                    id={data.user_id}
                    key={index.toString()}
                  >
                    <Card
                      onClick={handleClick}
                      style={{ cursor: "pointer" }}
                      id={data?.user_id}
                      username={data?.name}
                      role={data?.role}
                    >
                      <Card.Body>
                        <Card.Title>user_id: {data?.user_id}</Card.Title>
                        <Card.Text>Name: {data?.name}</Card.Text>
                        <Card.Text>Email: {data?.email}</Card.Text>
                        <Card.Text>Role: {data?.role}</Card.Text>
                        <Card.Text>Approved: {data?.approved}</Card.Text>
                        <Card.Text>Active: {data?.active}</Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                );
              })}
          </div>
        </div>
      )
      }

      {tab && (
        <UserRoleManagementChange
          clickedUserData={userData}
          excludePermissionModules={excludePermissionModules}
          includePermissionModules={includePermissionModules}
          addModulePermission={addModulePermissionData}
          includedarray={includePermissionModules}
          excludedarray={excludePermissionModules}
          setTab={setTab}
        />
      )}
    </Wrapper>
  );
};

export default UserRoleManagement;