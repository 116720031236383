import React, { useState, useEffect } from "react";
import { DatePicker, Radio, AutoComplete, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getStoreData } from "../../../../redux/slices/manageStoreSlice";
import { getPartners } from "../../../../redux/slices/affiliateSlice";
import {
  addPoints,
  updatePointerPoster,
} from "../../../../redux/slices/pointerPosterSlice";

const AddresponseDataMaster = () => {
  const dispatch = useDispatch();
  const { pointsDataRow } = useSelector((s) => s.pointerPoster);
  const { storedata } = useSelector((s) => s.manageStores);
  const { allPartners } = useSelector((s) => s.affiliate);
  const state = useSelector((s) => s);
  const [isUpdatedData, setUpdatedData] = useState(false);

  const [responseData, setResponseData] = useState({
    id: "",
    partner_id: "",
    partner_name: "",
    unique_id: "",
    // camp_id: "",
    camp_name: "",
    partner_camp_id: "",
    // partner_name: "",
    sale_amount: "",
    click_id: "",
    order_id: "",
    // sub_id_1: "",
    sub_id_2: "",
    sub_id_3: "",
    // sub_id_4: "",
    // sub_id_5: "",
    // advSub_id_1: "",
    // advSub_id_2: "",
    // advSub_id_3: "",
    // advSub_id_4: "",
    // advSub_id_5: "",
    // status: "",
    payout: "",
    currency: "",
    action_date: "",
    // attribution_date: "",
    conversion_date: "",
    // advUnique_id_1: "",
    // advUnique_id_2: null,
    // advUnique_id_3: null,
    // advUnique_id_4: null,
    // advUnique_id_5: null,
    // point_posted: null,
    // types: null
  });
  const [storeData, setStoreData] = useState({
    store_name: "",
    store_id: "",
  });

  // const [dateData, setDateData] = useState({
  //     action_date: "",
  //     attribution_date: "",
  //     conversion_date: ""
  // });
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    dispatch(getPartners());
    dispatch(getStoreData());
    if (pointsDataRow.action_date) {
      console.log(pointsDataRow, "pointsDataRow.action_date");
      setUpdatedData(!isUpdatedData);
      setIsDisabled(!isDisabled);
      setResponseData({
        ...responseData,
        id: pointsDataRow.id,
        partner_id: pointsDataRow.partner_id,
        partner_name: pointsDataRow.partner_name,
        unique_id: pointsDataRow.unique_id,
        // partner_name: "",
        camp_name: pointsDataRow.camp_name,
        partner_camp_id: pointsDataRow.partner_camp_id,
        action_date: pointsDataRow.action_date,
        // attribution_date: pointsDataRow.attribution_date,
        sale_amount: pointsDataRow.sale_amount,
        click_id: pointsDataRow.click_id,
        order_id: pointsDataRow.order_id,
        sub_id_2: pointsDataRow.sub_id2,
        sub_id_3: pointsDataRow.sub_id3,
        // status: pointsDataRow.status,
        payout: pointsDataRow.payout,
        conversion_date: pointsDataRow.conversion_date,
        currency: pointsDataRow.currency,
        // point_posted: pointsDataRow.point_posted,
        // types: pointsDataRow.types,
      });
      setStoreData({
        ...storeData,
        store_name: pointsDataRow.store_name,
        store_id: pointsDataRow.store_id,
      });
    }
  }, []);
  // const handleAutoComplete = (e) => {
  //   //console.log(e);
  //   setStoreData({ ...storeData, storeName: e });
  //   if (storedata.length > 0) {
  //     for (var i = 0; i < storedata.length; i++) {
  //       // //console.log(categoryData[i])
  //       if (e == storedata[i].store_name) {
  //         setStoreData({
  //           ...storeData,
  //           store_id: storedata[i].store_id,
  //           store_name: storedata[i].store_name,
  //         });
  //       }
  //     }
  //   }
  // };

  const handleAutoComplete2 = (e) => {
    let value = e.split("-");
    let partner_id = value[0];
    let partner_name = value[1];
    setResponseData({
      ...responseData,
      partner_id: partner_id,
      partner_name: partner_name,
    });
    if (allPartners.length > 0) {
      for (var i = 0; i < allPartners.length; i++) {
        // //console.log(categoryData[i])
        if (partner_id == allPartners[i].id) {
          setResponseData({
            ...responseData,
            partner_id: allPartners[i].id,
            partner_name: allPartners[i].partner,
          });
        }
      }
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    let data = {};
    data.type = "response_master";
    data.id = responseData.id;
    data.partner_id = responseData.partner_id;
    data.unique_id = responseData.unique_id;
    // data.store_id = storeData.store_id;
    // data.camp_id = responseData.responseData;
    data.camp_name = responseData.camp_name;
    data.partner_camp_id = responseData.partner_camp_id;
    // data.partner_name = responseData.partner_name;
    data.action_date = responseData.action_date;
    // data.attribution_date = responseData.attribution_date;
    data.conversion_date = responseData.conversion_date;
    data.sale_amount = responseData.sale_amount;
    data.click_id = responseData.click_id;
    data.order_id = responseData.order_id;
    // data.sub_id_1 = responseData.sub_id_1;
    data.sub_id_2 = responseData.sub_id_2;
    data.sub_id_3 = responseData.sub_id_3;
    // data.sub_id_4 = responseData.sub_id_4;
    // data.sub_id_5 = responseData.sub_id_5;
    // data.advSub_id_1 = responseData.advSub_id_1;
    // data.advSub_id_2 = responseData.advSub_id_2;
    // data.advSub_id_3 = responseData.advSub_id_3;
    // data.advSub_id_4 = responseData.advSub_id_4;
    // data.advSub_id_5 = responseData.advSub_id_5;
    // data.status = responseData.status;
    data.payout = responseData.payout;
    data.currency = responseData.currency;
    // data.advUnique_id_1 = responseData.advUnique_id_1;
    // data.advUnique_id_2 = responseData.advUnique_id_2;
    // data.advUnique_id_3 = responseData.advUnique_id_3;
    // data.advUnique_id_4 = responseData.advUnique_id_4;
    // data.advUnique_id_5 = responseData.advUnique_id_5;
    // data.point_posted = responseData.point_posted;
    // data.types = responseData.types;
    if (isUpdatedData) {
      dispatch(updatePointerPoster(data));
    } else {
      dispatch(addPoints(data));
    }
  };

  const changeHandler = (e) => {
    setResponseData({ ...responseData, [e.target.name]: e.target.value });
    // setStoreData({ ...storeData, [e.target.name]: e.target.value });
  };
  // const handleDateChange = (date, dateString) => {
  //     // setDateData(dateString);
  //     //console.log(dateString,"date")
  //     setDateData({
  //         ...dateData,
  //         action_date: dateString,
  //         attribution_date: dateString,
  //         conversion_date: dateString
  //     });

  // }
  // const handleActionDateChange = (date, dateString) => {
  //     setDateData({ ...dateData, action_date: dateString });
  // }
  // const handleAttrDateChange = (date, dateString) => {
  //     setDateData({ ...dateData, attribution_date: dateString });
  // }
  // const handleConvDateChange = (date, dateString) => {
  //     setDateData({ ...dateData, conversion_date: dateString });
  // }
  return (
    <>
      <h2 className="my-4">Add Response Master</h2>
      <form onSubmit={submitForm}>
        <div className="row">
          {/* <div className="col-6 col-lg-6">
                        <div className="mb-3">
                            <label  className="form-label">Partner ID</label>
                            <input type="text" className="form-control" name="partner_id" onChange={changeHandler} required value={responseData.partner_id} />
                        </div>
                    </div> */}
          <div className="col-6 col-lg-6">
            <div className="mb-3">
              <label className="form-label required">
                Partner Name
                <span style={{ color: "blue", fontSize: "25px" }}>
                  {" "}
                  {responseData.partner_name}
                </span>
              </label>
              <Select
                style={{ width: "50% !important" }}
                // value={storeData.store_name}
                className="auto-complete"
                // dataSource={allPartners && allPartners.map((item, index) => `${item.id}-${item.partner}`)}
                onSelect={handleAutoComplete2}
                disabled={isDisabled}
                required
                // filterOption={(inputValue, option) =>
                //     option.props.children
                //         .toUpperCase()
                //         .indexOf(inputValue.toUpperCase()) !== -1
                // }
              >
                {allPartners &&
                  allPartners.map((item, index) => (
                    <Option key={item.id} value={`${item.id}-${item.partner}`}>
                      {item.partner}
                    </Option>
                  ))}
              </Select>
            </div>
          </div>
          <div className="col-6 col-lg-6">
            <div className="mb-3">
              <label className="form-label required">Unique ID</label>
              <input
                type="text"
                className="form-control"
                name="unique_id"
                onChange={changeHandler}
                required
                value={responseData.unique_id}
              />
            </div>
          </div>
          {/*<div className="col-6 col-lg-6">
                        <div className="mb-3">
                            <label  className="form-label">Store Name<span style={{ color: "blue", fontSize: "25px" }}> ({storeData.store_name})</span></label>
                            <AutoComplete
                                style={{ width: "50% !important" }}
                                // value={storeData.store_name}
                                className="auto-complete"
                                dataSource={storedata && storedata.map((item, index) => item.store_name)}
                                onSelect={handleAutoComplete}
                                disabled={isDisabled}
                                filterOption={(inputValue, option) =>
                                    option.props.children
                                        .toUpperCase()
                                        .indexOf(inputValue.toUpperCase()) !== -1
                                }
                            />
                        </div>
                    </div> */}
          <div className="col-6 col-lg-6">
            <div className="mb-3">
              <label className="form-label">Camp Name</label>
              <input
                type="text"
                className="form-control"
                name="camp_name"
                onChange={changeHandler}
                value={responseData.camp_name}
              />
            </div>
          </div>
          <div className="col-6 col-lg-6">
            <div className="mb-3">
              <label className="form-label">Partner Camp ID</label>
              <input
                type="text"
                className="form-control"
                name="partner_camp_id"
                onChange={changeHandler}
                value={responseData.partner_camp_id}
              />
            </div>
          </div>
          {/* <div className="col-6 col-lg-6">
                        <div className="mb-3">
                            <label  className="form-label">Partner Name</label>
                            <input type="text" className="form-control" name="partner_name" onChange={changeHandler} />
                        </div>
                    </div> */}
          <div className="col-6 col-lg-6">
            <div className="mb-3">
              <label className="form-label">Sale Amount</label>
              <input
                type="text"
                className="form-control"
                name="sale_amount"
                onChange={changeHandler}
                value={responseData.sale_amount}
              />
            </div>
          </div>
          <div className="col-6 col-lg-6">
            <div className="mb-3">
              <label className="form-label required">Click ID</label>
              <input
                type="text"
                className="form-control"
                name="click_id"
                onChange={changeHandler}
                required
                value={responseData.click_id}
              />
            </div>
          </div>

          <div className="col-6 col-lg-6">
            <div className="mb-3">
              <label className="form-label required">Order ID</label>
              <input
                type="text"
                className="form-control"
                name="order_id"
                onChange={changeHandler}
                required
                value={responseData.order_id}
              />
            </div>
          </div>
          {/* <div className="col-6 col-lg-6">
                        <div className="mb-3">
                            <label  className="form-label">Sub-ID-1</label>
                            <input type="text" className="form-control" name="sub_id_1" onChange={changeHandler} />
                        </div>
                    </div> */}
          <div className="col-6 col-lg-6">
            <div className="mb-3">
              <label className="form-label">Sub Id2</label>
              <input
                type="text"
                className="form-control"
                name="sub_id_2"
                onChange={changeHandler}
                value={responseData.sub_id_2}
              />
            </div>
          </div>
          <div className="col-6 col-lg-6">
            <div className="mb-3">
              <label className="form-label">Sub Id3</label>
              <input
                type="text"
                className="form-control"
                name="sub_id_3"
                onChange={changeHandler}
                value={responseData.sub_id_3}
              />
            </div>
          </div>
          {/* <div className="col-6 col-lg-6">
                        <div className="mb-3">
                            <label  className="form-label">Sub-ID-4</label>
                            <input type="text" className="form-control" name="sub_id_4" onChange={changeHandler} />
                        </div>
                    </div>
                    <div className="col-6 col-lg-6">
                        <div className="mb-3">
                            <label  className="form-label">Sub-ID-5</label>
                            <input type="text" className="form-control" name="sub_id_5" onChange={changeHandler} />
                        </div>
                    </div> */}
          {/* <div className="col-6 col-lg-6">
                        <div className="mb-3">
                            <label  className="form-label">Advertiser Sub-ID-1</label>
                            <input type="text" className="form-control" name="advSub_id_1" onChange={changeHandler} />
                        </div>
                    </div>
                    <div className="col-6 col-lg-6">
                        <div className="mb-3">
                            <label  className="form-label">Advertiser Sub-ID-2</label>
                            <input type="text" className="form-control" name="advSub_id_2" onChange={changeHandler} />
                        </div>
                    </div>
                    <div className="col-6 col-lg-6">
                        <div className="mb-3">
                            <label  className="form-label">Advertiser Sub-ID-3</label>
                            <input type="text" className="form-control" name="advSub_id_3" onChange={changeHandler} />
                        </div>
                    </div>
                    <div className="col-6 col-lg-6">
                        <div className="mb-3">
                            <label  className="form-label">Advertiser Sub-ID-4</label>
                            <input type="text" className="form-control" name="advSub_id_4" onChange={changeHandler} />
                        </div>
                    </div>
                    <div className="col-6 col-lg-6">
                        <div className="mb-3">
                            <label  className="form-label">Advertiser Sub-ID-5</label>
                            <input type="text" className="form-control" name="advSub_id_5" onChange={changeHandler} />
                        </div>
                    </div> 
                    <div className="col-6 col-lg-6">
                        <div className="mb-3">
                            <label  className="form-label">Status</label>
                            <input type="text" className="form-control" name="status" onChange={changeHandler} required value={responseData.status} />
                        </div>
                    </div> */}
          <div className="col-6 col-lg-6">
            <div className="mb-3">
              <label className="form-label required">Payout </label>
              <input
                type="text"
                className="form-control"
                name="payout"
                onChange={changeHandler}
                required
                value={responseData.payout}
              />
            </div>
          </div>
          <div className="col-6 col-lg-6">
            <div className="mb-3">
              <label className="form-label required">Action Date</label>
              {/* <DatePicker onChange={handleActionDateChange} /> */}
              <input
                type="text"
                className="form-control"
                required
                name="action_date"
                onChange={changeHandler}
                value={responseData.action_date}
              />
            </div>
          </div>
          <div className="col-6 col-lg-6">
            <div className="mb-3">
              <label className="form-label required">Conversion Date</label>
              {/* <DatePicker onChange={handleConvDateChange} /> */}
              <input
                type="text"
                className="form-control"
                required
                name="conversion_date"
                onChange={changeHandler}
                value={responseData.conversion_date}
              />
            </div>
          </div>
          {/* <div className="col-6 col-lg-6">
                        <div className="mb-3">
                            <label  className="form-label">Attribution Date</label>
                            <input type="text" className="form-control" required name="attribution_date" onChange={changeHandler} value={responseData.attribution_date} />
                        </div>
                    </div> */}
          <div className="col-6 col-lg-6">
            <div className="mb-3">
              <label className="form-label">Currency</label>
              <input
                type="text"
                className="form-control"
                name="currency"
                onChange={changeHandler}
                value={responseData.currency}
              />
            </div>
          </div>
          {/* <div className="col-6 col-lg-6">
                        <div className="mb-3">
                            <label  className="form-label">Advertiser Unique-Id-1</label>
                            <input type="text" className="form-control" name="advUnique_id_1" onChange={changeHandler} />
                        </div>
                    </div>
                    <div className="col-6 col-lg-6">
                        <div className="mb-3">
                            <label  className="form-label">Advertiser Unique-Id-2</label>
                            <input type="text" className="form-control" name="advUnique_id_2" onChange={changeHandler} />
                        </div>
                    </div>
                    <div className="col-6 col-lg-6">
                        <div className="mb-3">
                            <label  className="form-label">Advertiser Unique-Id-3</label>
                            <input type="text" className="form-control" name="advUnique_id_3" onChange={changeHandler} />
                        </div>
                    </div>
                    <div className="col-6 col-lg-6">
                        <div className="mb-3">
                            <label  className="form-label">Advertiser Unique-Id-4</label>
                            <input type="text" className="form-control" name="advUnique_id_4" onChange={changeHandler} />
                        </div>
                    </div>
                    <div className="col-6 col-lg-6">
                        <div className="mb-3">
                            <label  className="form-label">Advertiser Unique-Id-5</label>
                            <input type="text" className="form-control" name="advUnique_id_5" onChange={changeHandler} />
                        </div>
                    </div> 
                    <div className="col-6 col-lg-6">
                        <div className="mb-3">
                            <label  className="form-label">Point Posted</label>
                            <input type="text" className="form-control" name="point_posted" onChange={changeHandler} required value={responseData.point_posted} />
                        </div>
                    </div>
                    <div className="col-6 col-lg-6">
                        <div className="mb-3">
                            <label  className="form-label">Type</label>
                            <input type="text" className="form-control" required name="types" onChange={changeHandler} value={responseData.types} />
                        </div>
                    </div>*/}

          <div className="col-12 col-lg-12 my-3 text-center">
            <button
              type="submit"
              className="btn btn-primary"
              onSubmit={submitForm}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddresponseDataMaster;
