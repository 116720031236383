import React, { useState, useEffect } from "react";
import { DatePicker, Radio, Input, Modal, message } from "antd";
import QueryURLModal from "./queryUrlModal";
import { useDispatch, useSelector } from "react-redux";
import {
  addPartners,
  updateMasterPartnerDetails,
} from "../../../../redux/slices/affiliateSlice";
const AddPartners = () => {
  const { partnerMasterDataRow } = useSelector((s) => s.affiliate);
  const dispatch = useDispatch();
  const [partnersData, setPartnersData] = useState({
    id: "",
    partner: "",
    base_url: "",
    sub_id2: "",
    sub_id3: "",
    click_id: "",
    deeplink_pattern: "",
  });
  const [defPaymentDate, setDefPaymentDate] = useState("");
  const [query_url_array, setQuery_url_array] = useState([]);
  const [isVisible, setVisible] = useState(false);
  const [typeOfModal, setTypeOfModal] = useState("queryUrlModal");
  const [query_url_data, setQuery_url_data] = useState({
    key_id: "",
    key_value: "",
  });
  const [isUpdatedData, setUpdatedData] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const changeHandler = (e) => {
    setPartnersData({ ...partnersData, [e.target.name]: e.target.value });
  };
  const handleDateChange = (date, dateString) => {
    setDefPaymentDate(dateString);
  };

  const handleCondition = (e) => {
    // setTypeOfModal(e.target.name);
    e.preventDefault();
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  useEffect(() => {
    console.log(partnerMasterDataRow, "partnermaster row");
    if (partnerMasterDataRow) {
      setUpdatedData(true);
      setIsDisabled(true);
      setPartnersData({
        ...partnersData,
        id: partnerMasterDataRow.id,
        partner: partnerMasterDataRow.partner,
        base_url: partnerMasterDataRow.base_url,
        sub_id2: partnerMasterDataRow.sub_id2,
        sub_id3: partnerMasterDataRow.sub_id3,
        click_id: partnerMasterDataRow.click_id,
        deeplink_pattern: partnerMasterDataRow.deeplink_pattern,
      });

      let Query_url_array = [];
      try {
        let q_url = JSON.parse(partnerMasterDataRow.query_url);
        Object.keys(q_url).forEach(function (key) {
          Query_url_array.push({ key_id: key, key_value: q_url[key] });
        });
        setQuery_url_array(Query_url_array);
      } catch (e) {
        console.log(e);
      }

      setDefPaymentDate(partnerMasterDataRow.updated);
    }
  }, []);

  console.log(isUpdatedData, "isudpate data");

  const submitForm = (e) => {
    e.preventDefault();
    let query_url = {};
    query_url_array.map((item) => {
      query_url[item.key_id] = item.key_value;
    });

    let data = {};
    data.id = partnersData.id;
    data.partner = partnersData.partner;
    data.base_url = partnersData.base_url;
    data.query_url = query_url;
    data.sub_id2 = partnersData.sub_id2;
    data.sub_id3 = partnersData.sub_id3;
    data.updated = defPaymentDate;
    data.click_id = partnersData.click_id;
    data.deeplink_pattern = partnersData.deeplink_pattern;
    console.log(isUpdatedData, "isudpate data");
    if (isUpdatedData) {
      console.log(data, "data");
      dispatch(updateMasterPartnerDetails(data))
        .then(() => message.success("Updated Successfully!"))
        .catch((error) => {
          message.error({ msg: error });
        });
    } else {
      dispatch(addPartners(data))
        .then(() => message.success("Added Successfully!"))
        .catch((error) => {
          message.error({ msg: error });
        });
    }
  };

  return (
    <>
      <Modal
        title="Basic Modal"
        visible={isVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
      >
        <QueryURLModal
          query_url_data={query_url_data}
          typeOfModal={typeOfModal}
          query_url_array={query_url_array}
          setQuery_url_array={setQuery_url_array}
          setQuery_url_data={setQuery_url_data}
        />
      </Modal>
      <section className="mt-5">
        <h2>Add Partners Data</h2>
        <form onSubmit={submitForm}>
          <div className="row">
            {/* <div className="col-6 col-lg-6">
                        <div class="mb-3">
                            <label class="form-label">Id</label>
                            <input type="text" class="form-control" name="id" onChange={changeHandler} />
                        </div>
                    </div> */}
            <div className="col-6 col-lg-6">
              <div class="mb-3">
                <label class="form-label">Partner Name</label>
                <input
                  type="text"
                  class="form-control"
                  name="partner"
                  value={partnersData.partner}
                  onChange={changeHandler}
                  required
                />
              </div>
            </div>
            <div className="col-6 col-lg-6">
              <div class="mb-3">
                <label class="form-label">Base Url</label>
                <input
                  type="url"
                  class="form-control"
                  name="base_url"
                  value={partnersData.base_url}
                  onChange={changeHandler}
                  required
                />
              </div>
            </div>
            <div className="col-6 col-lg-6">
              <div class="mb-3">
                <label class="form-label">DeepLink Pattern</label>
                <input
                  type="text"
                  class="form-control"
                  name="deeplink_pattern"
                  value={partnersData.deeplink_pattern}
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div className="col-6 col-lg-6">
              <div class="mb-3">
                <label class="form-label">Click Id</label>
                <input
                  type="text"
                  class="form-control"
                  name="click_id"
                  value={partnersData.click_id}
                  onChange={changeHandler}
                  required
                />
              </div>
            </div>
            <div className="col-6 col-lg-6">
              <div class="mb-3">
                <label class="form-label">Sub Id 2</label>
                <input
                  type="text"
                  class="form-control"
                  name="sub_id2"
                  value={partnersData.sub_id2}
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div className="col-6 col-lg-6">
              <div class="mb-3">
                <label class="form-label">Sub Id 3</label>
                <input
                  type="text"
                  class="form-control"
                  name="sub_id3"
                  value={partnersData.sub_id3}
                  onChange={changeHandler}
                />
              </div>
            </div>

            <div className="col-6 col-lg-6 mt-4">
              <div class="mb-3">
                <label class="form-label">
                  Updated Date
                  <span style={{ color: "blue", fontSize: "15px" }}>
                    {" "}
                    ({defPaymentDate})
                  </span>
                </label>
                <DatePicker onChange={handleDateChange} />
              </div>
            </div>

            <div className="col-6 col-lg-6">
              <div class="mb-3">
                <button
                  name="queryUrlModal"
                  type="submit"
                  class="btn btn-primary form-control"
                  style={{ marginTop: "30px" }}
                  onClick={handleCondition}
                >
                  Query URL
                </button>
              </div>
            </div>

            <div className="col-12 col-lg-12 my-3 text-center">
              <button type="submit" class="btn btn-primary">
                Submit
              </button>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default AddPartners;
