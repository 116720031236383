import ActionTypes from "../constants/actionTypes";
import initialState from "../stores/initialState";

export default (state = initialState.affiliate, action) => {
  switch (action.type) {
    case ActionTypes.GOT_PARTNER_MASTER_DATA:
      return {
        ...state,
        partnerMasterData: action.payload
      };
    case ActionTypes.GOT_PARTNER_MASTER_DATA_ROW:
      return {
        ...state,
        partnerMasterDataRow: action.payload
      }
    case ActionTypes.GOT_PARTNERS_AS_STORES:
      return {
        ...state,
        partnerAsStoresData: action.payload
      }
    case ActionTypes.GOT_PARTNERASSTORES_DATA_ROW:
      return {
        ...state,
        partnerAsStoresDataRow: action.payload
      }
    case ActionTypes.GOT_ALL_PARTNERS:
      return {
        ...state,
        allPartners: action.payload
      }
    case ActionTypes.GOT_ALL_ACTIVITIES:
      return {
        ...state,
        allActivities: action.payload
      }
    case ActionTypes.GOT_RESPONSE_ACTIVE_PARTNERS:
      return {
        ...state,
        responseActivePartner: action.payload
      }
      case ActionTypes.GOT_RESPONSE_MASTER_DATA_CSV:
        return {
          ...state,
          responseCSV: action.payload
        }
      

    default:
      return state;
  }
};
