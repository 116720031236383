import React, { useState } from "react";
import styled from "styled-components";
import { Typography, Divider, DatePicker, Input, Button, Radio, Icon, message } from "antd";


const { Title, Text } = Typography;
const DataRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const TopContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Voucher = () => {
  const [voucherData, setVoucherData] = useState({
    user_id: "",
    email_id: "",
    name: "",
    id: "",
    pin: "",
    issue_date: "",
    expiry_date: "",
    amount: "",
  });
  const [defPaymentDate, setDefPaymentDate] = useState("");
  const [defPaymentDate2, setDefPaymentDate2] = useState("");

  const changeHandler = (e) => {
    setVoucherData({ ...voucherData, [e.target.name]: e.target.value });
  };

  const submitForm = (e) => {
    e.preventDefault();
    let data = {};
    if (voucherData.user_id === "") {
      console.log("enter data");
    } else {
      data.user_id = voucherData.user_id;
      data.email_id = voucherData.email_id;
      data.name = voucherData.name;
      data.id = voucherData.id;
      data.pin = voucherData.pin;
      data.issue_date = defPaymentDate;
      data.expiry_date = defPaymentDate2;
      data.amount = voucherData.amount;
      console.log(data, "vocher data");
    }
  };
  const handleDateChange = (date, dateString) => {
    setDefPaymentDate(dateString);
  };

  const handleDateChange2 = (date, dateString) => {
    setDefPaymentDate2(dateString);
  };

  return (
    <>
      <TopContainer>
        <Title level={3}>Voucher</Title>
      </TopContainer>
      <Divider />
      <form onSubmit={submitForm}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <DataRow className="mb-3">
                <label for="" className="form-label w-75">
                  User Id
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="user_id"
                  onChange={changeHandler}
                  value={voucherData.user_id}
                />
              </DataRow>
              <DataRow className="mb-3">
                <label for="" className="form-label w-75">
                  Email ID
                </label>
                <input
                  type="email"
                  className="form-control"
                  name="email_id"
                  onChange={changeHandler}
                  value={voucherData.email_id}
                />
              </DataRow>
              <DataRow className="mb-3">
                <label for="" className="form-label w-75">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  onChange={changeHandler}
                  value={voucherData.name}
                />
              </DataRow>
              <DataRow className="mb-3">
                <label for="" className="form-label w-75">
                  Id
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="id"
                  onChange={changeHandler}
                  value={voucherData.id}
                />
              </DataRow>
              <DataRow className="mb-3">
                <label for="" className="form-label w-75">
                  PIN
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="pin"
                  onChange={changeHandler}
                  value={voucherData.pin}
                />
              </DataRow>
              {/* <DataRow className="mb-3">
                <label for="" className="form-label w-75">
                  Expiry
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="expiry"
                  onChange={changeHandler}
                  value={voucherData.expiry}
                />
              </DataRow> */}
              <DataRow className="mb-3">
                <label for="" className="form-label w-75">
                  Amount
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="amount"
                  onChange={changeHandler}
                  value={voucherData.amount}
                />
              </DataRow>
              <div className="mb-3 ">
                <label for="" className="form-label">
                  Issue Date
                </label>
                <DatePicker showTime onChange={handleDateChange} />
              </div>
              <div className="mb-3 ">
                <label for="" className="form-label">
                  Expiry Date
                </label>
                <DatePicker showTime onChange={handleDateChange2} />
              </div>

              <div className="col-12 col-lg-12 my-3 text-center">
                <button type="submit" className="btn btn-primary px-5  py-2">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Voucher;
