import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api/profileApi";
import { message } from "antd";


// export const getProfile = createAsyncThunk(
//   "/auth/profile",
//   async (_, { rejectWithValue }) => {
//     try {
//       const resp = await api.getProfile();
//       if (resp.data.code === 200) {
//         return resp.data.data;
//       } else {
//         console.log(resp.data, ">>>>>>> GET PROFILE DATA");
//         return rejectWithValue(resp.data);
//       }
//     } catch (error) {
//       rejectWithValue(error.resp.data);
//       console.log(error.resp.data, ">>>>>>> Resp PROFILE DATA");
//     }
//   }
// );
// ====================================================================================/

export const showRoles = createAsyncThunk(
  "/auth/showroles",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.showRoles();
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

/////post apis
// ====================================================================================/

// export const changePassword = createAsyncThunk(
//   "/auth/changepassword",
//   async (data, { rejectWithValue }) => {
//     try {
//       const resp = await api.changePassword(data);
//       if (resp.data.code === 200) {
//         return resp.data;
//       } else {
//         return rejectWithValue(resp.data);
//       }
//     } catch (error) {
//       rejectWithValue(error.resp.data);
//     }
//   }
// );
// ====================================================================================

export const addRoles = createAsyncThunk(
  "/auth/addRoles",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.addRoles(data);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Added Successfully!');
        // return resp.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Not Added OR Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
// ====================================================================================/

export const updateRoles = createAsyncThunk(
  "/auth/updateRoles",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.updateRoles(data);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Updated Successfully!');
        // return resp.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Not Updated / Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
// ====================================================================================/

export const showUserTypeModulesData = createAsyncThunk(
  "/auth/showUserTypeModulesData",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.showUserTypeModulesData();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
// ====================================================================================/

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    data: {
      getProfile: [],
      showRoles: [],
      userTypeModules: [],
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    // builder.addCase(getProfile.pending, (state) => {
    //   state.loading = true;
    // });
    // builder.addCase(getProfile.fulfilled, (state, action) => {
    //   state.data.getProfile = action.payload;
    //   state.data.isAuthenticated = true;
    //   console.log(action.payload, "actionpayload");
    // });
    // builder.addCase(getProfile.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.error.message;
    //   state.data.isAuthenticated = false;

    // });

    // ====================================================================================/

    builder.addCase(showRoles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showRoles.fulfilled, (state, action) => {
      state.data.showRoles = action.payload.data;
      state.data.modules_data = action.payload.modules_data;
    });
    builder.addCase(showRoles.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ====================================================================================/

    builder.addCase(showUserTypeModulesData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showUserTypeModulesData.fulfilled, (state, action) => {
      state.data.userTypeModules = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(showUserTypeModulesData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // =============================addRoles========================================/

    builder.addCase(addRoles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addRoles.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(addRoles.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // =============================updateRoles========================================/
    builder.addCase(updateRoles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateRoles.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateRoles.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { } = profileSlice.actions;

export default profileSlice.reducer;
