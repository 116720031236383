import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api/newsApi";
import { message } from "antd";


export const showNews = createAsyncThunk(
  "/news/shownews",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.showNews();
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const activatedNewsData = createAsyncThunk(
  "/news/activatenews",
  async (query, { rejectWithValue }) => {
    try {
      const resp = await api.activatedNewsData(query);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Activated/Deactivated Successfully!');
        // return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Not Activated/Deactivated OR Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getNewsUploadLogo = createAsyncThunk(
  "/news/getnewsuploadlogo",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.getNewsUploadLogo();
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const addNews = createAsyncThunk(
  "/news/addnews",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.addNews(data);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Added Successfully!');
        // return resp.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Not Added / Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const updateNewsData = createAsyncThunk(
  "/news/updatenews",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.updateNewsData(data);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Updated Successfully!');
        // return resp.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Not Updated / Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

const newsSlice = createSlice({
  name: "news",
  initialState: {
    loading: false,
    data: {
      newsData: [],
      newsUploadImageData: []
    },
  },
  reducers: {},
  extraReducers: (builder) => {

    // ===================================showNews=======================================
    builder.addCase(showNews.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showNews.fulfilled, (state, action) => {
      state.data.newsData = action.payload;
      state.loading = false;
    });
    builder.addCase(showNews.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ===============================getNewsUploadLogo================================
    builder.addCase(getNewsUploadLogo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getNewsUploadLogo.fulfilled, (state, action) => {
      state.data.newsUploadImageData = action.payload;
      state.loading = false;
    });
    builder.addCase(getNewsUploadLogo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    // ===============================activatedNewsData================================

    builder.addCase(activatedNewsData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(activatedNewsData.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(activatedNewsData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ===============================addNews================================
    builder.addCase(addNews.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addNews.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addNews.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ===============================updateNewsData================================
    builder.addCase(updateNewsData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateNewsData.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateNewsData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

// export const {} = newsSlice.actions;
export default newsSlice.reducer;
