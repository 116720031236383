import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Typography, Divider, Input, Radio, Icon, message } from "antd";
import MUIDataTable from "mui-datatables";
import AddDeals from "./AddDeals";
import ShowDeals from "./ShowDeals";

const { Title } = Typography;
const Wrapper = styled.div`
  width: 100%;
  > div {
    > div {
      > button {
        background: linear-gradient(
            259deg,
            rgb(236, 94, 58) 0%,
            rgb(249, 186, 99) 100%
          )
          0% 0% no-repeat padding-box padding-box transparent;
        padding: 7px 20px;
        border-radius: 20px;
        float: right;
      }
    }
  }
`;

const BodyContainer = styled.div`
  overflow: auto;
`;
const Container = styled.div`
  width: 90%;
  margin: 0 auto;
`;

const Deals = () => {

  const [dealsType, setDealsType] = useState("showDeals");
  const changeTab = () => {
    setDealsType("addDeals");
  };
  const changeTab2 = () => {
    setDealsType("showDeals");
  };

  return (
    <Wrapper>
      <Title className="my-3" level={3}>
        Deals
      </Title>
      <div className="row">
        <div className="col-12 col-lg-12">
          {dealsType == "showDeals" && (
            <button onClick={changeTab}>Add Deals</button>
          )}
          {dealsType == "addDeals" && (
            <button onClick={changeTab2}>Show Deals</button>
          )}
        </div>
      </div>

      <BodyContainer>
        {dealsType == "showDeals" && (
          <ShowDeals changeTab={setDealsType} />
        )}

        {dealsType == "addDeals" && (
          <AddDeals />
        )}
      </BodyContainer>
    </Wrapper>
  );
};

export default Deals;

