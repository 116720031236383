import React, { Fragment, useEffect } from 'react';
import { Table } from 'antd';
import styled from "styled-components";
import { useDispatch, useSelector } from 'react-redux';
import { showheaderfooter, showheaderfootersubmenu } from '../../../redux/slices/headerFooterSlice';

const Data = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  > label {
    width: 30%;
  }
  >span{
    font-size: 18px;
    font-weight: bolder;
  }
  > input[type="text"] {
    width: 50%;
  }
  @media screen and (max-width: 480px) {
    display: block;
  }
`;

const ShowHeaderFooter = () => {
  const dispatch = useDispatch();
  const { headerfootersubmenuData, headerfooterData } = useSelector(s => s.headerFooter);


  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Type', dataIndex: 'type', key: 'type' },
    { title: 'Menu Name', dataIndex: 'menu_name', key: 'menu_name' },
    { title: 'Menu URL', dataIndex: 'menu_url', key: 'menu_url' },
    { title: 'Active', dataIndex: 'active', key: 'active' },
    { title: 'Created', dataIndex: 'created', key: 'created' },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: () => <a>Delete</a>,
    },
  ];
  useEffect(() => {
    dispatch(showheaderfooter());
    dispatch(showheaderfootersubmenu());

  }, []);
  return (
    <>
      <Fragment>
        <Table
          columns={columns}
          expandedRowRender={(e, i) => <p style={{ margin: 0 }}>{e.submenu_name}{e.submenu_url}</p>}
          expandIconAsCell={true}
          expandIconColumnIndex={0}
          dataSource={headerfooterData && headerfooterData}
        />
        {headerfootersubmenuData && headerfootersubmenuData.map((item, index) => <p>{item.submenu_name}</p>)}
      </Fragment>
    </>
  );
}

export default ShowHeaderFooter;
