import ActionTypes from "../constants/actionTypes";
import initialState from "../stores/initialState";

export default (state = initialState.redemption, action) => {
  switch (action.type) {
    case ActionTypes.GOT_REDEEM_DATA:
      return {
        ...state,
        redeemData: action.payload
      };
    case ActionTypes.GOT_SHOW_REDEEM_DATA:
      return {
        ...state,
        showRedeemData: action.payload
      };
    case ActionTypes.GOT_ALLOWED_ACTIVITY_DATA:
      return {
        ...state,
        allowedActivityData: action.payload
      };
    case ActionTypes.GOT_REDEEM_DATA_ROW:
      return {
        ...state,
        redeemDataRow: action.payload
      };
    case ActionTypes.GOT_PAYMENT_STATUS_DATA:
      return {
        ...state,
        paymentStatusData: action.payload.data,
        paymentStatusRejeactedApproval:action.payload.results2
      };
    case ActionTypes.GOT_PAYMENT_STATUS_DATA_ROW:
      return {
        ...state,
        paymentStatusDataRow: action.payload
      };
    case ActionTypes.GOT_REDEMPTION_STATUS_MASTER:
      return {
        ...state,
        redemptionStatus: action.payload
      };

    default:
      return state;
  }
};