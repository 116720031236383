import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api/authApi";
import { message } from "antd";

export const signIn = createAsyncThunk("auth/login", async (loginData) => {
  try {
    const response = await api.login(loginData);
    return response.data;
  } catch (err) {
    return console.log(err, "Error from auth slice");
  }
});

export const logout = createAsyncThunk("/auth/logout", async () => {
  try {
    const response = await api.logout();
    console.log(response);
  } catch (error) {}
});

export const signup = createAsyncThunk(
  "/auth/signup",
  async (signupData, { rejectWithValue }) => {
    try {
      const response = await api.signupApi(signupData);
      if (response.data.code === 200) {
        message.success("Signup successfully");
        return response.data;
      } else {
        message.error(
          response.data.message
            ? response.data.message
            : "Something went Wrong!"
        );
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.log(error);
      return rejectWithValue(response.data);
    }
  }
);

export const getProfile = createAsyncThunk(
  "/auth/profile",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.getProfile();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const showRoles = createAsyncThunk(
  "/auth/showroles",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.showRolesApi();
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const changePassword = createAsyncThunk(
  "/auth/changepassword",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.changePassword(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message
            ? resp.data.message
            : "Password Changed Successfully!"
        );
        // return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    isAuthenticated: false,
    getProfile: [],
    show_roles: [],
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(signIn.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(signIn.fulfilled, (state, { payload }) => {
      state.user = payload;
      state.isAuthenticated = true;
    });
    builder.addCase(signIn.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(signup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(signup.fulfilled, (state, { payload }) => {
      state.loading = false;
    });
    builder.addCase(signup.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // =====================================================================================================================================

    builder.addCase(logout.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(logout.fulfilled, (state, { payload }) => {
      state.user = null;
      state.isAuthenticated = false;
    });
    builder.addCase(logout.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ===========================================================================================================

    builder.addCase(getProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.getProfile = action.payload;
      state.isAuthenticated = true;
      // console.log(action.payload, "actionpayload");
    });
    builder.addCase(getProfile.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.isAuthenticated = false;
    });
    // ===========================================================================================================

    builder.addCase(showRoles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showRoles.fulfilled, (state, action) => {
      state.show_roles = action.payload.data;
      // console.log(action.payload, "actionpayload");
    });
    builder.addCase(showRoles.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.isAuthenticated = false;
    });

    //=================================changePassword===========================//
    builder.addCase(changePassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});

export const { setUser } = authSlice.actions;

export default authSlice.reducer;
