import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api/galleryApi";
import { message } from 'antd';


export const getAllImages = createAsyncThunk(
  "/gallery/allimage",
  async (limit, { rejectWithValue }) => {
    try {
      const resp = await api.getAllImages(limit);
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getImageType = createAsyncThunk(
  "/gallery/imagetype",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.getImageType();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const saveShowImage = createAsyncThunk(
  "/gallery/saveShowImage",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.saveShowImage(data);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Saved Successfully!');
        // return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Not Saved / Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const deleteImage = createAsyncThunk(
  "/gallery/deleteimage",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.deleteImage(data);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Deleted Successfully!');
        // return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Not Deleted / Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const updateImage = createAsyncThunk(
  "/gallery/updateimage",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.updateImage(data);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Updated Successfully!');
        // return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Not Updated / Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const searchImage = createAsyncThunk(
  "/gallery/searchimage",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.searchImage(data);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Search Successfully!');
        // return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Not Searched / Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const saveGalleryImage = createAsyncThunk(
  "/gallery/a",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.saveGalleryImage(data);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Saved Successfully!');
        // return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Not Saved / Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const checkImageDimension = createAsyncThunk(
  "/gallery/checkDimension",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.checkImageDimensionApi();
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : 'Dimension Checked Successfully!');
        // return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

const gallerySlice = createSlice({
  name: "gallery",
  initialState: {
    data: {
      getAllImagesTotal: [],
      getAllImagesLimit: [],
      getAllImagesData: [],
      getImageType: [],
    },
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllImages.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllImages.fulfilled, (state, action) => {
      state.data.getAllImagesTotal = action.payload.total;
      state.data.getAllImagesLimit = action.payload.limit;
      state.data.getAllImagesData = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getAllImages.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    ////////////////GET IMAGES TYPE/////////////////

    builder.addCase(getImageType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getImageType.fulfilled, (state, action) => {
      state.data.getImageType = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getImageType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ==========================saveGalleryImage=================================
    builder.addCase(saveGalleryImage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveGalleryImage.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(saveGalleryImage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ===========================deleteImage=================================
    builder.addCase(deleteImage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteImage.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteImage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ===========================checkImageDimension=================================
    builder.addCase(checkImageDimension.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(checkImageDimension.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(checkImageDimension.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ===========================searchImage=================================
    builder.addCase(searchImage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchImage.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(searchImage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ===========================saveShowImage=================================
    builder.addCase(saveShowImage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveShowImage.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(saveShowImage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ===========================updateImage=================================
    builder.addCase(updateImage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateImage.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateImage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { } = gallerySlice.actions;

export default gallerySlice.reducer;
