import axios from "axios";
import AppConstants from "../../constants/appConstants";


const API = axios.create({
    baseURL: `${AppConstants.baseURL}`,
});

export const getAllImages = (limit) =>API.get(`/gallery/allimage?limit=${limit}`);
export const getImageType = () => API.get("/gallery/imagetype");
export const saveShowImage = (data) => API.post("/gallery/saveShowImage", data);
export const deleteImage = (data) => API.post("/gallery/deleteimage", data);
export const updateImage = (data) => API.post("/gallery/updateimage", data);
export const searchImage = (data) => API.post("/gallery/searchimage", data);
export const saveGalleryImage = (data) => API.post("/gallery/a", data);
export const checkImageDimensionApi = () => API.get("/gallery/checkDimension");



