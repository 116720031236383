import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CashbackClaimsStatusMaster,
  getInfoByClickId,
  showCashbackClaims,
  updateCashbackClaimStatus,
} from "../../../redux/slices/crmSlice";
import { DataGrid, GridToolbar, GridActionsCellItem, GridToolbarContainer } from "@mui/x-data-grid";
import PostCashbackClaimsData from "./PostCashbackClaimsData"
import {
  Typography,
  Autocomplete,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Table,
  TableRow,
  TableBody,
  TableCell,
  tableCellClasses,
  Tooltip,
} from "@mui/material";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
var moment = require("moment");
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { message } from "antd";
import { Modal } from "antd";
import AffClicksTable from "./affClicksTable";
import ShowPartnerRespClicks from "./partnerRepClickTable";
import AllAffClicksByDate from "./allAffClicksByDateTable";


const CashbackClaimsData = () => {
  const dispatch = useDispatch();
  const {
    cashbackClaimsData,
    infoByClickId,
    partnerRespByClickId,
    getAllDateAffClicksId,
    matchPointPostCashbackClaimsData,
  } = useSelector((s) => s.crm.data);


  // console.log(partnerRespByClickId, "partnerRespByClickId=============");
  // console.log(infoByClickId, "infoByClickId=============");
  // console.log(getAllDateAffClicksId, "getAllDateAffClicksId=============");
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const handleClose = () => {
    setDialogOpen(false);
  };

  const [editSelectedUserData, setEditSelectedUserData] = useState({
    id: "",
    status: "",
    remarks: "",
  })
  const [editUserData, setEditUserData] = useState({
    id: "",
    status: "",
    remarks: "",
  });
  const [claimData, setClaimData] = useState({
    click_id: "",
    time: "",
    // pageSize: props.cashbackClaimsData && props.cashbackClaimsData ? props.cashbackClaimsData.length : 0,
    // page: 0
  });
  function editUser(data) {
    setEditUserData({
      id: data.id,
      status: data.status,
      remarks: data.remarks != null ? data.remarks : "",
    });
    setDialogOpen(true);
  }

  // handle user data for edit
  const handleEditSelectedUserData = (key) => (e) => {
    setEditSelectedUserData({ ...editSelectedUserData, [key]: e.target.value });
  };
  const handleEditUserData = (key) => (e) => {
    setEditUserData({ ...editUserData, [key]: e.target.value });
  };

  // const handleModal = (e) => {
  //   // console.log(e.value, "lkjh");
  //   // console.log(props.cashbackClaimsData, "props.cashbackClaimsData");
  //   setClaimData({ ...claimData, click_id: e.value });
  //   if (cashbackClaimsData.length > 0) {
  //     for (var i = 0; i < cashbackClaimsData.length; i++) {
  //       if (e.value == cashbackClaimsData[i].click_id) {
  //         // console.log(props.cashbackClaimsData[i].click_id, props.cashbackClaimsData[i].time, "hnjkhh")
  //         setClaimData({
  //           ...claimData,
  //           click_id: cashbackClaimsData[i].click_id,
  //           time: cashbackClaimsData[i].time,
  //         });
  //         // let date = cashbackClaimsData[i].time.trim().split(",");
  //         // console.log(date, ".....dtae")
  //         let data = {};
  //         data.click_id = cashbackClaimsData[i].click_id;
  //         data.uid = cashbackClaimsData[i].uid;
  //         data.time = moment(cashbackClaimsData[i].time).format("YYYY-MM-DD");
  //         console.log(data, "data................");
  //         dispatch(getInfoByClickId(data));
  //       }
  //     }
  //   }

  //   setVisible(true);
  // };

  const handleModal = (e) => {
    const clickId = e.value;
    const row = cashbackClaimsData.find((claim) => claim.click_id === clickId);
    if (row) {
      setClaimData({
        ...claimData,
        click_id: row.click_id,
        time: row.time,
      });
      let data = {
        click_id: row.click_id,
        uid: row.uid,
        time: moment(row.time).format("YYYY-MM-DD"),
      };
      dispatch(getInfoByClickId(data));
    }
    setVisible(true);
  };

  const getRoleIdByRole = (role) => {
    const user = cashbackClaimsData.find((user) => user.user_type === role);
    return user ? user.id : null;
  };

  const statusOptions = [
    { label: 'Raised', value: 1 },
    { label: 'Resolved', value: 2 },
    { label: 'Rejected', value: 3 },
  ];

  const createFormData = (ids, userData) => {
    return {
      id: [ids],
      status: userData.status,
      remarks: userData.remarks
    };
  };

  const handleSubmitSelectedUsers = async () => {
    editSelectedUserData.role_id = getRoleIdByRole(editSelectedUserData.role);
    const formData = createFormData(selectedRows, editSelectedUserData);
    await dispatch(updateCashbackClaimStatus(formData));
    await dispatch(showCashbackClaims());
    console.log(formData, "submitmultiple");
    handleCloseEdit();
  };

  const handleSubmit = async () => {
    editUserData.role_id = getRoleIdByRole(editUserData.role);
    const formData = createFormData(editUserData.id, editUserData);
    await dispatch(updateCashbackClaimStatus(formData));
    await dispatch(showCashbackClaims());
    handleClose();

    console.log(formData,"dataaa")

  };

  


  useEffect(() => {
    dispatch(showCashbackClaims());
    dispatch(CashbackClaimsStatusMaster());
  }, []);

  const copyImageUrl = async (e, image) => {
    e.preventDefault();
    await navigator.clipboard.writeText(image && image);
    message.success("Image url Copied");
  };

  const tableColumns = [
    {
      headerName: "Claim ID",
      field: "id",
      type: "number",
      width: 74,
      disableColumnMenu: true,
    },
    { headerName: "UID", field: "uid", disableColumnMenu: true },
    { headerName: "User Name", field: "name", disableColumnMenu: true },
    { headerName: "Store Name", field: "store_name", disableColumnMenu: true },
    {
      headerName: "click Id",
      field: "click_id",
      disableColumnMenu: true,
      renderCell: (params) => (
        <>
          <p
            variant="text"
            style={{ color: "blue" }}
            onClick={() => handleModal(params)}
          >
            {params.value}
          </p>
        </>
      ),
    },
    { headerName: "Order ID", field: "order_id", disableColumnMenu: true },
    { headerName: "Partner", field: "partner", disableColumnMenu: true },
    { headerName: "amount", field: "amount", disableColumnMenu: true },
    {
      headerName: "description",
      field: "description",
      disableColumnMenu: true,
    },
    {
      headerName: "image",
      width: 200,
      field: "image",
      disableColumnMenu: true,
    },

    {
      field: "copy_img_url",
      headerName: "Copy Image URL",
      type: "actions",
      minWidth: 80,
      sortable: false,
      disableColumnMenu: true,
      getActions: (params) => [
        <Tooltip title="Edit">
          <GridActionsCellItem
            icon={<ContentCopyIcon sx={{ fontSize: 18 }} />}
            label="Copy Image URL"
            onClick={(e) => {
              copyImageUrl(e, params.row.image);
            }}
          />
        </Tooltip>,
      ],
    },
    {
      headerName: "status",
      field: "status",
      valueFormatter: (params) => {
        if (params.value === 1) {
          return "raised";
        } else if (params.value === 2) {
          return "resolved";
        } else {
          return "rejected";
        }
      },
    },
    { headerName: "remarks", field: "remarks" },
    { headerName: "Click date", field: "click_date" },
    { headerName: "time", field: "time" },
    { headerName: "resolve_time", field: "resolve_time" },
    {
      field: "edit",
      headerName: "Edit",
      type: "actions",
      minWidth: 80,
      sortable: false,
      disableColumnMenu: true,
      getActions: (params) => {
        // Check if the current row's ID is in the selected rows
        const isRowSelected = selectedRows.includes(params.id);
        if (!isRowSelected) {
          return [
            <Tooltip title="Edit">
              <GridActionsCellItem
                icon={<EditRoundedIcon />}
                label="Edit"
                onClick={() => editUser(params.row)}
              />
            </Tooltip>
          ];
        }
        // Optionally return a disabled button or nothing
        return [
          <Tooltip title="Edit">
            <GridActionsCellItem
              icon={<EditRoundedIcon />}
              label="Edit"
              onClick={() => { }}
              disabled
            />
          </Tooltip>
        ];
      },
    },
  ];

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseEdit = () => {
    setOpen(false);
  };


  const handleSelectionChange = (selectionModel) => {
    // const selectedData = cashbackClaimsData.filter((row) =>
    //   selectionModel.includes(row.id)
    // );
    setSelectedRows(selectionModel);
  };


  return (
    <Fragment>
      <Button variant="contained" disableElevation color="primary" onClick={handleOpen} disabled={selectedRows.length === 0} style={{padding:"10px" , margin:"15px"}}>
        Edit
      </Button>

      <Dialog open={open} onClose={handleCloseEdit} aria-labelledby="form-dialog-title">
        <DialogTitle>
          <b>Edit Selected users</b>
        </DialogTitle>
        <Divider sx={{ borderColor: "black" }} />
        <DialogContent>
          <Table
            size="small"
            sx={{
              width: "100%",
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                    <b>Remarks</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    type="text"
                    fullWidth
                    value={editSelectedUserData?.remarks}
                    size="small"
                    onChange={handleEditSelectedUserData("remarks")}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                    <b>Status</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Autocomplete
                    disableClearable
                    size="small"
                    id="user-combo-box"
                    value={
                      statusOptions.find(option => option.value === editSelectedUserData?.status) || null
                    }
                    options={statusOptions}
                    getOptionLabel={(option) => option.label}
                    sx={{ width: 200 }}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(e, val) => {
                      setEditSelectedUserData({ ...editSelectedUserData, status: val ? val.value : null })
                    }
                    }
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button color="error" size="large" onClick={handleCloseEdit}>
            <b>Cancel</b>
          </Button>
          <Button variant="contained" size="large" onClick={handleSubmitSelectedUsers}>
            <b>Save</b>
          </Button>
        </DialogActions>
      </Dialog>

      <DataGrid
        // commented code is for any future reference or to switch to the sorting and filtering features integrated table
        // autoHeight
        // // autoPageSize
        // getRowId={(row) => row.id}
        // rows={cashbackClaimsData && cashbackClaimsData}
        // columns={tableColumns}
        // //pageSize={cashbackClaimsData && cashbackClaimsData?.length/2} //used to render entire table data without pagination feature
        // checkboxSelection
        // components={{
        //     LoadingOverlay: LinearProgress,
        //     Toolbar: GridToolbar, // for export and filter functionality
        // }}
        // pageSizeOptions={[5, 10, 25, cashbackClaimsData?.length]}
        autoHeight
        checkboxSelection
        rows={cashbackClaimsData}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        columns={tableColumns}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        pageSizeOptions={[5, 10, 25, cashbackClaimsData?.length]}
        onSelectionModelChange={handleSelectionChange}

      />

      {visible && (
        <Modal
          title={`Click_ID----> ${claimData.click_id}`}
          centered
          open={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          width={1000}
        >
          {infoByClickId && infoByClickId.length > 0 && (
            <AffClicksTable infoByClickId={infoByClickId} />
          )}
          {partnerRespByClickId && partnerRespByClickId.length > 0 && (
            <ShowPartnerRespClicks
              partnerRespByClickId={partnerRespByClickId}
            />
          )}
          {getAllDateAffClicksId && getAllDateAffClicksId.length > 0 && (
            <AllAffClicksByDate getAllDateAffClicksId={getAllDateAffClicksId} />
          )}
        </Modal>
      )}
      <Dialog open={dialogOpen} onClose={handleClose} fullWidth>
        <DialogTitle>
          <b>Edit User</b>
        </DialogTitle>
        <Divider sx={{ borderColor: "black" }} />
        <DialogContent>
          <Table
            size="small"
            sx={{
              width: "100%",
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                    <b>Remarks</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    type="text"
                    fullWidth
                    value={editUserData?.remarks}
                    size="small"
                    onChange={handleEditUserData("remarks")}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                    <b>Status</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  {/* <Autocomplete
                    disableClearable
                    size="small"
                    id="user-combo-box"
                    value={editUserData?.status}
                    options={["raised", "resolved", "rejected"]}
                    sx={{ width: 200 }}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(e, val) =>
                      setEditUserData({ ...editUserData, status: val })
                    }
                  /> */}
                  <Autocomplete
                    disableClearable
                    size="small"
                    id="status-combo-box"
                    value={
                      statusOptions.find(option => option.value === editUserData?.status) || null
                    }
                    options={statusOptions}
                    getOptionLabel={(option) => option.label}
                    sx={{ width: 200 }}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(e, val) => {
                      setEditUserData({ ...editUserData, status: val ? val.value : null })
                    }

                    }
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button color="error" size="large" onClick={handleClose}>
            <b>Cancel</b>
          </Button>
          <Button variant="contained" size="large" onClick={handleSubmit}>
            <b>Save</b>
          </Button>
        </DialogActions>
      </Dialog>
      <PostCashbackClaimsData data={matchPointPostCashbackClaimsData} />
    </Fragment>
  );
};

export default CashbackClaimsData;