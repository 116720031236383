import axios from "axios";
import AppConstants from "../../constants/appConstants";

const API = axios.create({
    baseURL: `${AppConstants.baseURL}`,
});

// =======================addactivites=====================
export const addActivities = (data) => API.post("/user/addactivities", data);
export const getActivityUploadImage = (data) => API.get("/user/getactivityuploadimage", data)
export const updateActivitiesData = (data) => API.post("/user/updateactivities", data);
export const getPartnerId = () => API.get("/user/getpartners");



// =========================showactivites=========================
export const showActivitiesData = () => API.get("/user/showactivities");
export const activatedTasksData = (query) => API.get(`/user/activatetasks?${query}`);