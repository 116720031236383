export default {
  auth: {},
  dashboard: {},
  coupon: {},
  slider: {},
  blog: {},
  operation: {},
  download: {},
  sale: {},
  task: {},
  newtable: {},
  notify: {},
  crud: {},
  gallery: {},
  managebanners: {},
  activities: {},
  testimonials: {},
  news: {},
  managestores: {},
  headerfooter: {},
  contactedusers: {},
  points: {},
  redemption: {},
  email: {},
  affiliate: {},
  crm: {},
  taskreminder: {},
  deals: {}, 
};
