import { combineReducers } from "redux";

import dashboard from "./dashboard";
import auth from "./auth";
import operation from "./operation";
// import coupon from "./coupon";
// import download from "./download";
// import sale from "./sale";
// import task from "./task";
// import newtable from "./newtable";
// import notify from "./notify";
// import crud from "./crud";
import gallery from "./gallery";
import activities from "./activities";
import managebanners from "./managebanners";
import testimonials from "./testimonials";
import news from "./news";
import stores from "./stores";
import headerfooter from "./headerfooter";
import contactedusers from "./contactedusers";
import points from "./points";
import redemption from "./redemption";
import email from "./email";
import affiliate from "./affiliate";
import crm from "./crm";
import taskreminder from "./taskreminder";

const rootReducer = combineReducers({
  auth,
  dashboard,
  operation,
  gallery,
  activities,
  managebanners,
  testimonials,
  news,
  stores,
  headerfooter,
  contactedusers,
  points,
  redemption,
  email,affiliate,crm,taskreminder,
});

export default rootReducer;
