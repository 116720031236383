// import React from "react";
// import { Provider } from "react-redux";
// import stores from "./stores";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import routes from "./routes/index";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file
// import AllRoute from "./routes/index";

// const App = () => {
//   return (
//     <Provider store={stores}>
//       <BrowserRouter>
//         <Routes>
//           <Route element={<AllRoute/>} />
//         </Routes>
//       </BrowserRouter>
//     </Provider>
//   );
// };

// export default App;

import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from 'react-router-dom';
import AllRoute from "./routes/index";
import { AuthProvider, getUser } from "./context/authContext";
import Loader from "react-js-loader";

const App = React.memo(() => {
  const navigate = useNavigate();
  const [auth, setAuth] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const authData = await getUser();
      // console.log(authData,"authData====")
      setAuth(authData);
      setLoading(false);
    }
    fetchData();
  }, []);

  return (
    isLoading ? (
      <div className="row">
        <div className="item">
          <Loader type="spinner-circle" bgColor={"#000"} title={"Loading"} color={'#000'} size={100} />
        </div>
      </div>
    ) : (
      auth ? (
        <AuthProvider myAuth={auth}>
          <AllRoute />
        </AuthProvider>
      ) : (
        <Navigate to="/login" replace />
        // <>
        //   {
        //     navigate('/login')
        //   }
        // </>
      )
    )
  );
});

export default App;
