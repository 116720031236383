import ActionTypes from "../constants/actionTypes";
import initialState from "../stores/initialState";

export default (state = initialState.operation, action) => {
  switch (action.type) {
    case ActionTypes.GOT_ADDITIONAL_ROLE:
      return {
        ...state,
        additionalRoles: action.payload.data
      };
    case ActionTypes.GOT_USER_ROLE:
      return {
        ...state,
        userRoles: action.payload.data
      };
    case ActionTypes.GOT_MODULES:
      return {
        ...state,
        modulesData: action.payload.data
      };
    case ActionTypes.GOT_ROLE_MODULES:
      return {
        ...state,
        roleModules: action.payload.data
      };
      case ActionTypes.GOT_PERMISSION:
      return {
        ...state,
        permissionData: action.payload
      };
    default:
      return state;
  }
};
